export const CONFIG_AFFILIATES_URL = {
  summary_report: {
    key: '',
    path: '/affiliates/general/list',
    shortenUrl: 'general/list',
  },
  referrer: {
    key: '',
    path: '/affiliates/referrer/list',
    shortenUrl: 'referrer/list',
  },
  referrer_detail: {
    key: '',
    path: '/affiliates/referrer/detail',
    shortenUrl: 'referrer/detail/:id',
  },
};
