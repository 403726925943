import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const AffiliatesEndpoint = `${endpoint}/bake-teach/affiliate/report`;

const AffiliatesAPI = {
  overview: async () =>
    await api
      .get(`${AffiliatesEndpoint}/overview`)
      .then((result) => result.data?.data),
  userRatio: async () =>
    await api
      .get(`${AffiliatesEndpoint}/user-ratio`)
      .then((result) => result.data?.data),
  marketingIndex: async ({ params }) =>
    await api
      .get(`${AffiliatesEndpoint}/marketing-index`, params)
      .then((result) => result.data?.data),
  growthMarketing: async ({ params }) =>
    await api
      .get(`${AffiliatesEndpoint}/growth-marketing`, params)
      .then((result) => result.data?.data),
  topUser: async () =>
    await api
      .get(`${AffiliatesEndpoint}/top-user`)
      .then((result) => result.data?.data),
  recentRevenue: async () =>
    await api
      .get(`${AffiliatesEndpoint}/recent-revenue`)
      .then((result) => result.data?.data),
};

export default AffiliatesAPI;
