import { useQuery } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '_common/constants/common';
import moment from 'moment';
import { useEffect } from 'react';
import { Button, Description, Image, Modal, Spin, Tag } from 'tera-dls';
import UserApi from '../../api';
import {
  GENDER_TYPE,
  USER_STATUS,
  USER_STATUS_COLOR,
  USER_TYPE,
} from '../../constants';

import imageDefault from 'styles/images/image-default.png';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const UserDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    id && fetchData();
  }, [id]);

  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết người dùng'}
        closeIcon={false}
        width={500}
        onCancel={onClose}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={isLoading}>
          <Description label="Ảnh đại diện">
            <div className="flex justify-start mb-2.5">
              <Image
                defaultImage={imageDefault}
                url={detail?.avatar}
                imageClassName="!size-[100px] object-cover"
              />
            </div>
          </Description>
          <Description label="Mã người dùng">{detail?.code}</Description>
          <Description label="Họ và tên">{detail?.full_name}</Description>
          <Description label="Ngày sinh">
            {detail?.birthday && moment(detail?.birthday).format(DATE_FORMAT)}
          </Description>
          <Description label="Số điện thoại">{detail?.phone}</Description>
          <Description label="Email liên hệ">{detail?.email}</Description>
          <Description label="Giới tính">
            {GENDER_TYPE[detail?.gender]}
          </Description>

          <Description label="Loại người dùng">
            {USER_TYPE[detail?.type]}
          </Description>
          <Description label="Trạng thái">
            <Tag color={USER_STATUS_COLOR[detail?.is_active] as any}>
              {USER_STATUS[detail?.is_active]}
            </Tag>
          </Description>
          <Description label="Người tạo">
            <HoverQuickView
              // avatarUrl={submitter?.avatar_url}
              // email={submitter?.email}
              // phone={submitter?.phone}
              // code={submitter?.code}
              name={detail?.user_created?.full_name}
            >
              <span className="line-clamp-1 text-blue-600">
                {detail?.user_created?.full_name}
              </span>
            </HoverQuickView>
          </Description>

          <Description label="Ngày tạo">
            {moment(detail?.created_at).format(DATE_TIME_FORMAT)}
          </Description>
          {detail?.user_updated && (
            <>
              <Description label="Người cập nhật">
                <HoverQuickView
                  // avatarUrl={submitter?.avatar_url}
                  // email={submitter?.email}
                  // phone={submitter?.phone}
                  // code={submitter?.code}
                  name={detail?.user_updated?.full_name}
                >
                  <span className="line-clamp-1 text-blue-600">
                    {' '}
                    {detail?.user_updated?.full_name}
                  </span>
                </HoverQuickView>
              </Description>

              <Description label="Ngày cập nhật">
                {moment(detail?.updated_at).format(DATE_TIME_FORMAT)}
              </Description>
            </>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default UserDetail;
