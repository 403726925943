import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const EvaluateEndpoint = `${endpoint}/bake-teach/rating`;
const EvaluateApi = {
  getList: async (params) =>
    await api
      .get(`${EvaluateEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${EvaluateEndpoint}/detail/${id}`)
      .then((data) => data?.data?.data),
  getSummary: async (params) =>
    await api
      .get(`${EvaluateEndpoint}/list-summary-count-star`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${EvaluateEndpoint}/vote`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${EvaluateEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default EvaluateApi;
