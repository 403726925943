export const USER_URL = {
  list: {
    key: 'user-list',
    path: '/user-management/user/list',
    shortenUrl: 'user/list',
  },
  address: {
    key: 'user-address-list',
    path: '/user-management/user/address',
    shortenUrl: 'user/address/:id',
  },
};
