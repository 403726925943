import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Col, formatNumber, Row } from 'tera-dls';
import UserReportApi from '../../api';
import IconReport4 from 'styles/images/report/icon-report-4.png';
import IconReport5 from 'styles/images/report/icon-report-5.png';

const Overview = () => {
  const { data } = useQuery(
    ['get-user-report-overview'],
    () => UserReportApi.getOverview(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  return (
    <div className="rounded-[10px] p-2.5 bg-white border">
      <Row className="grid-cols-2 gap-2.5">
        <Col className="col-span-1 rounded-[10px] border border-gray-200 flex gap-x-2.5 justify-between p-2.5">
          <div className="flex flex-col gap-5">
            <div className="text-[#6B7280] text-[13px] font-bold">
              Tổng số lượng học viên
            </div>
            <div className="text-[24px]">
              {formatNumber(data?.student_count)}
            </div>
          </div>
          <img src={IconReport4} className="w-[50px] h-[30px]" />
        </Col>
        <Col className="col-span-1 rounded-[10px] border border-gray-200 flex gap-x-2.5 justify-between p-2.5">
          <div className="flex flex-col gap-5">
            <div className="text-[#6B7280] text-[13px] font-bold">
              Tổng số lượng giảng viên
            </div>
            <div className="text-[24px]">
              {formatNumber(data?.lecturer_count)}
            </div>
          </div>
          <img src={IconReport5} className="w-[40px] h-[40px]" />
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
