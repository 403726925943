import menu from '../component/Layout/Menu/menu';
import { useStores } from './useStores';

const useSubMenu = (active?) => {
  const {
    commonStore: { activeMenu },
  } = useStores();

  const getSubMenu = (targetParentKey) =>
    menu.subMenu.filter((item) => item?.parentKey === targetParentKey);

  const menus = menu.parentMenu
    ?.filter((item) => item?.parentKey === (active || activeMenu))
    ?.map((parent) => {
      const defaultChildren = getSubMenu(parent.key);
      return { ...parent, children: defaultChildren ?? [] };
    });

  return menus;
};

export default useSubMenu;
