import CardDetail from '_common/component/CardDetail';

import TableTera from '_common/dof/TableTera';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import { PRODUCT_URL } from 'pages/SaleManagement/Product/url';
import { useNavigate } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';
import { formatCurrency, Image } from 'tera-dls';

const ProductInfo = ({ dataDetail }) => {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 150,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              alt={record?.image_product}
              imageClassName="object-cover"
              src={record?.image_product}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1">
            <p
              className="text-[#1C64F2] cursor-pointer"
              onClick={() =>
                navigate(`${PRODUCT_URL.detail.path}/${record?.product?.id}`)
              }
            >
              {record?.name_product ?? val}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'name_category',
      width: 120,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 120,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      width: 120,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      width: 150,
      render: (price) => formatCurrency(Number(price)),
    },
    // {
    //   title: 'Giá khuyến mãi',
    //   dataIndex: 'promotional_price',
    //   width: 150,
    //   render: (total) => formatCurrency(Number(total)),
    // },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 150,
      render: (total) => formatCurrency(Number(total)),
    },
  ];

  return (
    <CardDetail title="Thông tin sản phẩm" className="h-full">
      <TableTera columns={columns} data={dataDetail?.products || []} />
    </CardDetail>
  );
};

export default ProductInfo;
