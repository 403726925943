import {
  ArrowPathOutlined,
  ArrowsPointingInOutlined,
  ArrowsPointingOuOutlined,
  Cog6ToothOutlined,
  Tooltip,
} from 'tera-dls';

interface HeaderProps {
  isSmallType?: string | boolean;
  title?: string;
  onZoomIn?: () => void;
  onZoomOut?: (value) => void;
  onClickRefresh?: () => void;
  onClickConfig?: () => void;
}

const Header = ({
  isSmallType,
  onZoomIn,
  onZoomOut,
  title,
  onClickRefresh,
  onClickConfig,
}: HeaderProps) => {
  return (
    <div className="flex flex-col gap-2.5 shadow-[0_2px_2px_0_rgba(0,0,0,0.05)]">
      <div className="flex flex-col gap-[5px] pt-[16px]">
        <div className="flex px-[16px] pb-2.5 item-center">
          <div className="font-medium flex-1 text-base">{title}</div>
          <div className="flex gap-2.5">
            <Tooltip title="Làm mới" className="z-[999]">
              <span className="flex items-center">
                <ArrowPathOutlined
                  className="cursor-pointer w-4 h-4"
                  onClick={onClickRefresh}
                />
              </span>
            </Tooltip>

            {onClickConfig && (
              <Tooltip title="Cấu hình tham số">
                <span className="flex items-center">
                  <Cog6ToothOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={onClickConfig}
                  />
                </span>
              </Tooltip>
            )}

            {isSmallType ? (
              <Tooltip title="Mở rộng">
                <span className="flex items-center">
                  <ArrowsPointingOuOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => onZoomOut(true)}
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Thu gọn" className="z-[999]">
                <span className="flex items-center">
                  <ArrowsPointingInOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => onZoomIn()}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
