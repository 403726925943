import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const PromotionEndpoint = `${endpoint}/bake-teach/promotional-offer`;
const PromotionApi = {
  getList: async (params) =>
    await api
      .get(`${PromotionEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  suggestCode: async () =>
    await api
      .get(`${PromotionEndpoint}/suggest-code`)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${PromotionEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${PromotionEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${PromotionEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${PromotionEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  stop: async ({ id }) =>
    await api
      .put(`${PromotionEndpoint}/stop/${id}`)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${PromotionEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default PromotionApi;
