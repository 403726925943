export const PRODUCT_URL = {
  list: {
    key: 'product-list',
    path: '/sale-management/product/list',
    shortenUrl: 'product/list',
  },
  create: {
    key: 'product-create',
    path: '/sale-management/product/create',
    shortenUrl: 'product/create',
  },
  update: {
    key: 'product-update',
    path: '/sale-management/product/update',
    shortenUrl: 'product/update/:id',
  },
  detail: {
    key: 'product-detail',
    path: '/sale-management/product/detail',
    shortenUrl: 'product/detail/:id',
  },
};
