import { useMutation } from '@tanstack/react-query';
import { REGEX } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import UserApi from 'pages/UserManagement/User/api';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import ErrorToast from '../ToastCustom/ErrorsToast';

interface ModalChangePassword {
  open: boolean;
  userId: number | string;
  onClose: () => void;
  onSuccess?: () => void;
}

function ModalChangePassword({
  open,
  userId,
  onClose,
  onSuccess,
}: ModalChangePassword) {
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const { isDirty } = form.formState;

  const { mutate: mutateChangePassword, isLoading: isLoadingMutate } =
    useMutation((params: any) => UserApi.changePassword(params), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onSuccess && onSuccess();
          onClose && onClose();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    });

  const handleSubmitForm = (value) => {
    const data = {
      member_id: userId,
      password: value?.new_password,
    };
    mutateChangePassword(data);
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  return (
    <Modal
      title="Thay đổi mật khẩu"
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={isLoadingMutate}
    >
      <Spin spinning={isLoadingMutate}>
        <FormTera form={form}>
          <FormTeraItem
            label="Mật khẩu mới"
            name="new_password"
            rules={[
              {
                required: messageValidate.emptyText,
                pattern: {
                  value: new RegExp(REGEX.PASSWORD),
                  message: messageValidate.password,
                },
                minLength: {
                  value: 8,
                  message: messageValidate.min_length_password,
                },
                maxLength: {
                  value: 16,
                  message: messageValidate.max_length_password,
                },
              },
            ]}
          >
            <InputPassword />
          </FormTeraItem>
          <FormTeraItem
            label="Nhập lại mật khẩu"
            name="confirm_password"
            rules={[
              {
                required: messageValidate.emptyText,
                validate: {
                  existed: (value, values) => {
                    const password = values?.new_password.trim();

                    if (value?.trim() !== password) {
                      return messageValidate.confirm_password;
                    }
                  },
                },
              },
            ]}
          >
            <InputPassword />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default ModalChangePassword;
