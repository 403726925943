import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourse from '_common/dof/Select/SelectCourse';
import SelectCourseSchedule from '_common/dof/Select/SelectCourseSchedule';
import SelectUser from '_common/dof/Select/SelectUser';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import CourseApi from 'pages/CourseManagement/Course/api';
import {
  COURSE_METHOD,
  COURSE_TYPE,
} from 'pages/CourseManagement/Course/constants';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Col,
  FormItem,
  InputNumber as InputNumberTera,
  Input as InputTera,
  Modal,
  notification,
  Row,
  Spin,
} from 'tera-dls';
import CourseOrderApi from '../../api';

interface IProps {
  open: boolean;
  // value?: string | number;
  onClose: () => void;
}

const CourseOrderForm = (props: IProps) => {
  const { open, onClose } = props;
  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const course_id = form.watch('course_id');
  const user_id = form.watch('user_id');
  const discount_code = form.watch('discount_code');
  const referral_code = form.watch('referral_code');

  const [price, setPrice] = useState<any>();
  const [originalPrice, setOriginalPrice] = useState<any>();

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const { data: detailCourse } = useQuery(
    ['get-course-detail', course_id],
    () => CourseApi.getDetail(course_id),
    {
      enabled: !!course_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );
  // console.log(detail);

  const { mutate: mutateCalculate, isLoading: calculateLoading } = useMutation(
    (variables: any) => CourseOrderApi.calculateDiscountPrice(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          const price = res?.data?.original_price ?? 0;
          const final_price = res?.data?.final_price ?? 0;
          setPrice(final_price);
          setOriginalPrice(price);
        }
      },
      onError(error: any) {
        const price = error?.data?.errors?.original_price ?? 0;
        const final_price = error?.data?.errors?.final_price ?? 0;
        setPrice(final_price);
        setOriginalPrice(price);
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) => CourseOrderApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-order-list']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
        ...(values?.schedule_ids && { schedule_ids: [values?.schedule_ids] }),
      },
      // ...(id && { id }),
    });
  };

  const calculateDiscountDebounce = useCallback(
    _.debounce((val) => {
      course_id &&
        user_id &&
        mutateCalculate({
          course_id,
          user_id,
          discount_code: val.target.value,
          referral_code,
        });
    }, 700),
    [mutateCalculate, user_id, course_id, referral_code],
  );

  const calculateReferralDebounce = useCallback(
    _.debounce((val) => {
      course_id &&
        user_id &&
        mutateCalculate({
          course_id,
          user_id,
          discount_code,
          referral_code: val.target.value,
        });
    }, 700),
    [mutateCalculate, user_id, course_id, discount_code],
  );
  return (
    <>
      <Modal
        open={open}
        title={'Thêm đơn khóa học'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        className="!w-[90%] xmd:!w-[50%]"
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isLoading || calculateLoading,
          disabled: isLoading || calculateLoading,
        }}
      >
        <Spin spinning={calculateLoading}>
          <FormTera form={form} isLoading={false}>
            <Row className="grid-cols-2 gap-3">
              <Col className="col-span-1">
                <FormTeraItem
                  label="Họ và tên"
                  name="user_id"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectUser
                    paramsApi={{
                      include_id: form.watch('user_id'),
                    }}
                    onChangeCustom={() => {
                      form.setValue('course_id', null);
                      form.setValue('schedule_ids', null);
                      setPrice(null);
                      setOriginalPrice(null);
                    }}
                    onClear={() => {
                      form.setValue('course_id', null);
                      form.setValue('schedule_ids', null);
                      setPrice(null);
                      setOriginalPrice(null);
                    }}
                  />
                </FormTeraItem>
                <FormTeraItem
                  label="Khóa học"
                  name="course_id"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectCourse
                    paramsApi={{
                      include_id: course_id,
                      is_active: 1,
                      status: 'approve',
                      ...(!!form.watch('user_id') && {
                        except_requesting_invoice: `${form.watch(
                          'user_id',
                        )},${'create'}`,
                      }),
                    }}
                    disabled={!form.watch('user_id')}
                    onChangeCustom={(val: any) => {
                      form.setValue('schedule_ids', null);
                      if (!val) {
                        setPrice(null);
                        setOriginalPrice(null);
                        return;
                      }
                      val?.value &&
                        user_id &&
                        mutateCalculate({
                          course_id: val?.value,
                          user_id,
                          discount_code,
                          referral_code,
                        });
                    }}
                  />
                </FormTeraItem>
                {detailCourse?.classification == 'online' && (
                  <FormTeraItem
                    label="Thời gian học"
                    name="schedule_ids"
                    rules={[{ required: messageValidate.emptySelect }]}
                  >
                    <SelectCourseSchedule
                      paramsApi={{ course_id: detailCourse?.id }}
                    />
                  </FormTeraItem>
                )}
                <FormItem label="Giảng viên" isRequired={false}>
                  <InputTera
                    disabled
                    value={detailCourse?.lecturer?.full_name ?? ''}
                  />
                </FormItem>
                <FormItem label="Danh mục khóa học" isRequired={false}>
                  <InputTera
                    disabled
                    value={detailCourse?.category?.name ?? ''}
                  />
                </FormItem>
                <FormItem label="Phân loại khóa học" isRequired={false}>
                  <InputTera
                    disabled
                    value={COURSE_METHOD[detailCourse?.classification] ?? ''}
                  />
                </FormItem>
              </Col>
              <Col className="col-span-1">
                <FormItem
                  label="Loại khóa học"
                  isRequired={false}
                  className="mb-3"
                >
                  <InputTera
                    disabled
                    value={COURSE_TYPE[detailCourse?.type] ?? ''}
                  />
                </FormItem>
                <FormTeraItem label="Giá khóa học" name="" className="mb-3">
                  <InputNumberTera
                    disabled
                    value={originalPrice}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </FormTeraItem>
                <FormTeraItem label="Mã giảm giá" name="discount_code">
                  <Input
                    maxLength={255}
                    onChange={(val) => {
                      calculateDiscountDebounce(val);
                    }}
                  />
                </FormTeraItem>

                <FormTeraItem label="Mã giới thiệu" name="referral_code">
                  <Input
                    maxLength={255}
                    onChange={(val) => {
                      calculateReferralDebounce(val);
                    }}
                  />
                </FormTeraItem>

                <FormTeraItem label="Thành tiền" name="">
                  <InputNumberTera
                    disabled
                    value={price}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </FormTeraItem>
              </Col>
            </Row>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default CourseOrderForm;
