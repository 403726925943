import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangeNumber from '_common/dof/Control/RangeNumber';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function WithdrawalRequestFilter({
  open,
  initialValue,
  onClose,
  onFilter,
}: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter({
      ...values,
      from_register_date: values?.time?.[0]?.format(DATE_BACKEND_FORMAT),
      to_register_date: values?.time?.[1]?.format(DATE_BACKEND_FORMAT),
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      time: null,
      from_register_date: null,
      to_register_date: null,
      from_commission_per: null,
      to_commission_per: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;
    form.reset({
      ...initialValue,
      ...(initialValue?.from_register_date &&
        initialValue?.to_register_date && {
          time: [
            moment(initialValue?.from_register_date),
            moment(initialValue?.to_register_date),
          ],
        }),
    });
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Ngày đăng ký" name="time">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>

        <FormTeraItem name="" label="% hoa hồng">
          <RangeNumber
            startFormName="from_commission_per"
            endFormName="to_commission_per"
            endInputProps={{
              min: 0,
              max: 100,
              placeholder: 'Đến khoảng',
            }}
            startInputProps={{
              min: 0,
              max: 100,
              placeholder: 'Từ khoảng',
            }}
          />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default WithdrawalRequestFilter;
