import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface PurchaseReturnFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

// const optionsAll: OptionProps[] = [
//   {
//     label: 'Tất cả',
//     value: null,
//   },
// ];

function PurchaseReturnFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: PurchaseReturnFilterProps) {
  const newForm = useForm({
    mode: 'onChange',
  });

  const handleSubmitForm = (values) => {
    const valuesFilter = {
      start_date: values?.time?.[0]?.format(DATE_BACKEND_FORMAT),
      end_date: values?.time?.[1]?.format(DATE_BACKEND_FORMAT),
    };

    onFilter(valuesFilter);
    onClose();
  };

  const handleReset = () => {
    const values = {
      start_date: null,
      end_date: null,
      time: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    newForm?.reset({
      ...(initialValue?.start_date &&
        initialValue?.end_date && {
          time: [
            moment(initialValue?.start_date),
            moment(initialValue?.end_date),
          ],
        }),
    });
  }, [initialValue]);

  const watchStartDate = newForm?.watch('start_date');
  const watchEndDate = newForm?.watch('end_date');

  useEffect(() => {
    if (watchStartDate && watchEndDate) {
      newForm?.trigger('end_date');
      newForm?.trigger('start_date');
    }
  }, [watchStartDate, watchEndDate, newForm]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => newForm?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={newForm} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Ngày đặt hàng" name="time" className="w-full">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default PurchaseReturnFilter;
