import { useQuery } from '@tanstack/react-query';
import { removeNullObject } from '_common/utils';
import _ from 'lodash';
import CourseApi from 'pages/CourseManagement/Course/api';
import Overview from 'pages/CourseManagement/Course/Overview';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, PaginationProps, updateQueryParams } from 'tera-dls';

const OnlineTab = ({ classification, lecturerId }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const params = _.pick(queryParams, ['lecture']);

  const paramsObject = params?.lecture ? JSON.parse(params?.lecture) : {};

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-course-list', params, lecturerId, classification],
    () =>
      CourseApi.getList({
        page: 1,
        limit: 10,
        classification,
        lecturer_id: lecturerId,
        ...removeNullObject(paramsObject),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    !!lecturerId && refetch();
  }, [lecturerId]);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      lecture: JSON.stringify({
        ...paramsObject,
        ...data,
      }),
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  return (
    <Overview
      {...{
        isDetail: true,
        onSearch: handleSearch,
        loading: isFetching,
        value: response,
        onChangePage: handleChangePage,
        onFilter: handleFilter,
        filterValue: paramsObject,
      }}
    />
  );
};

export default OnlineTab;
