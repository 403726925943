import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const TransportConfigEndpoint = `${endpoint}/bake-teach/config-delivery`;
const TransportConfigApi = {
  getList: async () =>
    await api
      .get(`${TransportConfigEndpoint}/list`)
      .then((data) => data?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${TransportConfigEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${TransportConfigEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${TransportConfigEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${TransportConfigEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default TransportConfigApi;
