import TableTera from '_common/dof/TableTera';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import {
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import { useLocation, useNavigate } from 'react-router-dom';
import HoverQuickView from '_common/component/HoverQuickView';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import useConfirm from '_common/hooks/useConfirm';
import ProductCategoryApi from './api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { useEffect, useState } from 'react';
import { IFormModel } from 'pages/UserManagement/User';
import ProductCategoryForm from './containers/Form';
import Searching from './containers/Searching';
import ProductCategoryDetail from './containers/Detail';
import ProductCategoryFilter from './containers/Filter';

const ProductCategory = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });
  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-product-category-list', queryParams],
    () =>
      ProductCategoryApi.getList({
        page: 1,
        limit: 10,
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => ProductCategoryApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-product-category-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xoá danh mục sản phẩm',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa danh mục sản phẩm</p>
          <p>
            <span className="font-bold break-word">{name}</span> này không?
          </p>
        </>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const dropdownItemsAction = (record: any) => {
    const arrData: DropdownItem[] = [];
    arrData.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        setOpenDetailModel({ open: true, value: record?.id });
      },
    });
    arrData.push({
      key: 2,
      label: 'Sửa',
      onClick: () => {
        setOpenFormModel({ open: true, value: record?.id });
      },
    });
    arrData.push({
      key: 3,
      label: <span className="text-red-500">Xoá</span>,
      onClick: () => handleDelete(record?.id, record?.name),
    });
    return arrData;
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const columns: any = [
    {
      title: 'Mã danh mục',
      dataIndex: 'code',
      width: 150,
      render: (val) => (
        <span className="line-clamp-2 text-blue-600 cursor-pointer inline">
          {val}
        </span>
      ),
    },
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      width: 200,
      render: (val, record) => (
        <span
          className="line-clamp-2  text-blue-600 cursor-pointer inline"
          onClick={() => setOpenDetailModel({ open: true, value: record?.id })}
        >
          {val}
        </span>
      ),
    },
    {
      title: 'Danh mục cha',
      dataIndex: 'parent',
      width: 150,
      render: (val) => val?.name,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      width: 150,
      render: (val) => <div className="line-clamp-3">{val}</div>,
    },
    {
      title: 'Người tạo',
      dataIndex: 'user_created',
      width: 150,
      render: (val) => (
        <HoverQuickView name={val?.full_name}>{val?.full_name}</HoverQuickView>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => moment(created_at).format(DATE_TIME_FORMAT),
    },
    {
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: any) => (
        <ActionDropdown
          dropdownItems={dropdownItemsAction(record)}
          trigger="click"
        />
      ),
    },
  ];

  return (
    <div className="tera-page">
      <HeaderViewListV2
        title="Danh sách danh mục sản phẩm"
        onClickButtonAdd={() => setOpenFormModel({ open: true })}
        actionLeftRender={<Searching onSearch={handleSearch} />}
        onClickFilter={() => setOpenFilter(true)}
        filterCount={{
          filter: queryParams,
          params: ['parent_id'],
        }}
      >
        <TableTera
          rowKey="id"
          columns={columns}
          data={response?.data ?? []}
          loading={isFetching}
          pagination={{
            onChange: handleChangePage,
            total: response?.total || 0,
            current: response?.current_page,
            pageSize: response?.per_page,
            to: response?.to,
            from: response?.from,
          }}
        />
      </HeaderViewListV2>

      {openFormModel.open && (
        <ProductCategoryForm
          value={openFormModel.value}
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
        />
      )}
      {openDetailModel.open && (
        <ProductCategoryDetail
          value={openDetailModel.value}
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
        />
      )}
      {openFilter && (
        <ProductCategoryFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default ProductCategory;
