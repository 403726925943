import { useQuery } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  formatCurrency,
  getQueryParams,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import AffiliateApi from './api';
import Searching from './containers/Searching';
import { AFFILIATE_OVERVIEW_URL } from './url';
import WithdrawalRequestFilter from './containers/Filter';
import History from './containers/History';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const AffiliateOverview = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [openHistory, setOpenHistory] = useState<IFormModel>({
    open: false,
  });

  const params = _.omit(queryParams, ['status']);
  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-affiliate-overview-list', queryParams],
    () =>
      AffiliateApi.getList({
        page: 1,
        limit: 10,
        ...params,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && { status: queryParams?.status }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () =>
          navigate(
            `${AFFILIATE_OVERVIEW_URL.detail.path}/${record?.affiliate?.id}`,
          ),
      },
      {
        key: 2,
        label: <a>Lịch sử cấu hình</a>,
        onClick: () => setOpenHistory({ open: true, value: record?.affiliate }),
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã người dùng',
      dataIndex: 'code',
      width: 150,
      render: (val, record) => (
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() =>
            navigate(
              `${AFFILIATE_OVERVIEW_URL.detail.path}/${record?.affiliate?.id}`,
            )
          }
        >
          {val}
        </span>
      ),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 200,
      render: (value, record) => (
        <HoverQuickView
          name={value}
          avatarUrl={record?.avatar}
          email={record?.email}
          phone={record?.phone}
        >
          <span className="line-clamp-1 text-blue-600">{value}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Mã Affiliate',
      dataIndex: 'affiliate',
      width: 150,
      render: (val) => val?.code,
    },
    {
      title: 'Tổng tiền hoa hồng',
      dataIndex: 'commissions_sum_commission',
      width: 150,
      render: (_, record) =>
        formatCurrency(record?.affiliate?.commissions_sum_commission ?? 0),
    },
    {
      title: '% hoa hồng',
      width: 150,
      dataIndex: 'amount',
      render: (_, record) =>
        record?.affiliate?.commission_per &&
        `${record?.affiliate?.commission_per}%`,
    },
    {
      title: 'Ưu đãi người nhập mã',
      dataIndex: 'promotion',
      width: 150,
      render: (_, record) => {
        if (record?.affiliate?.have_promotional_affiliate) {
          const type = record?.affiliate?.discount_type;
          const value = record?.affiliate?.discount_value;
          if (type == 'per') {
            return `${value}%`;
          } else {
            return formatCurrency(value);
          }
        }
      },
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'created_at',
      width: 150,
      render: (_, record) =>
        record?.affiliate?.registered_at &&
        moment(record?.affiliate?.registered_at).format(DATE_TIME_FORMAT),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH TIẾP THỊ LIÊN KẾT"
          actionLeftRender={<Searching onSearch={handleSearch} />}
          onClickFilter={() => setOpenFilter(true)}
          filterCount={{
            filter: queryParams,
            params: [
              'time',
              // 'from_register_date',
              // 'to_register_date',
              'from_commission_per',
              'to_commission_per',
            ],
          }}
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <TableTera
              loading={isFetching}
              wrapperClassName="shadow-none"
              rowKey={'id'}
              data={response?.data ?? [{ id: 1 }]}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
              columns={columns}
            />
          </div>
        </HeaderViewListV2>
      </div>

      {openFilter && (
        <WithdrawalRequestFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {openHistory?.open && (
        <History
          open={openHistory?.open}
          onClose={() => setOpenHistory({ open: false })}
          value={openHistory?.value}
        />
      )}
    </>
  );
};

export default AffiliateOverview;
