export const APPLY_TYPE = {
  online_course: 'Khóa học online',
  offline_course: 'Khóa học offline',
  // new_join: 'Tham gia mới',
};

export const PROMOTION_TYPE = {
  per: 'Giảm giá theo %',
  money: 'Giảm giá theo tiền',
};
