import React from 'react';
import { Col, formatNumber, Row } from 'tera-dls';
import IconReport6 from 'styles/images/report/icon-report-6.png';
import IconReport7 from 'styles/images/report/icon-report-7.png';

const Overview = () => {
  return (
    <div className="rounded-[10px] p-2.5 bg-white border">
      <Row className="grid-cols-2 gap-2.5">
        <Col className="col-span-1 rounded-[10px] border border-gray-200 flex gap-x-2.5 justify-between p-2.5">
          <div className="flex flex-col gap-5">
            <div className="text-[#6B7280] text-[13px] font-bold">
              Tổng doanh thu
            </div>
            <div className="text-[24px]">{formatNumber(999)}</div>
          </div>
          <img src={IconReport6} className="w-[50px] h-[40px]" />
        </Col>
        <Col className="col-span-1 rounded-[10px] border border-gray-200 flex gap-x-2.5 justify-between p-2.5">
          <div className="flex flex-col gap-5">
            <div className="text-[#6B7280] text-[13px] font-bold">
              Số lượng đơn hàng
            </div>
            <div className="text-[24px]">{formatNumber(12)}</div>
          </div>
          <img src={IconReport7} className="w-[40px] h-[40px]" />
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
