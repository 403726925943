const PlayIcon = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.75 20C0.75 9.36852 9.36852 0.75 20 0.75C30.6315 0.75 39.25 9.36852 39.25 20C39.25 30.6315 30.6315 39.25 20 39.25C9.36852 39.25 0.75 30.6315 0.75 20ZM14.9333 9.75677C15.892 9.36568 17.1241 9.38292 18.153 10.0816L29.1741 17.565C29.991 18.1197 30.5 19.0115 30.5 20C30.5 20.9885 29.991 21.8802 29.174 22.435L18.153 29.9184C17.124 30.6171 15.892 30.6343 14.9333 30.2432C13.9794 29.8541 13 28.9078 13 27.4834V12.5166C13 11.0922 13.9794 10.1459 14.9333 9.75677Z"
      fill="#1B486A"
    />
  </svg>
);
export default PlayIcon;
