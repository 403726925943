import { convertClassNameToInlineStyle } from '_common/component/CkEditor';
import FormTera from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import InformationView from './InformationView';

const Information = ({}, ref) => {
  const form = useForm({ mode: 'onChange' });
  const {
    course: { setInformation, nextStep, information: informationData },
  } = useStores();
  const [image, setImage] = useState<any>();

  const { type } = useParams();
  const typeFile = form.watch('link_url_type');
  const typeDirectFile = form.watch('link_url_direct_type');
  const link_url_type = form.watch('link_url_type');
  const link_url_direct_type = form.watch('link_url_direct_type');
  const price = form.watch('price');

  useEffect(() => {
    if (!!informationData) {
      form.reset({
        ...informationData,
        link_url_direct: informationData?.link_url_direct?.url,
      });
      informationData?.file_upload && setImage(informationData?.file_upload);
    } else {
      type === 'offline'
        ? form.setValue('link_url_type', 'uploaded_video')
        : form.setValue('link_url_direct_type', 'uploaded_video');
      form.setValue('limit_student', 1);
    }
  }, [informationData, type]);

  const handleSubmitForm = (values) => {
    setInformation({
      ...values,
      file_upload: image,
      ...(values?.link_url_direct && {
        link_url_direct: {
          url: values?.link_url_direct,
        },
      }),
      information: convertClassNameToInlineStyle(
        (window as any).editor ? (window as any).editor.getData() : null,
      ),
    });
    nextStep();
  };

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => {
        form.handleSubmit(handleSubmitForm)();
      },
    };
  });

  return (
    <FormTera form={form} isLoading={false}>
      <InformationView
        {...{
          form,
          image,
          setImage,
          type,
          typeFile,
          price,
          typeDirectFile,
          link_url_type,
          link_url_direct_type,
        }}
      />
    </FormTera>
  );
};

export default observer(forwardRef(Information));
