import { IRouteProps } from '_common/interface/router';
import { PROMOTION_URL } from './url';
import Promotion from '.';

export const PromotionRouter: IRouteProps[] = [
  {
    key: PROMOTION_URL.list.key,
    path: PROMOTION_URL.list.shortenUrl,
    component: <Promotion />,
  },
];
