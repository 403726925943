export const EXPENSE_VOUCHER_URL = {
  list: {
    key: '1',
    path: '/finance/expense-voucher/list',
    shortenUrl: 'expense-voucher/list',
  },
  create: {
    key: '2',
    path: '/finance/expense-voucher/create',
    shortenUrl: 'expense-voucher/create',
  },
  update: {
    key: '3',
    path: '/finance/expense-voucher/update',
    shortenUrl: 'expense-voucher/update/:id',
  },
};
