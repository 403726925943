import { Row } from 'tera-dls';
import ChartBarCourseOrder from './containers/ChartPieCourseOrder';
import ChartBarSaleOrder from './containers/ChartPieSaleOrder';
import Overview from './containers/Overview';
import ChartPieOrderReport from './containers/ChartPie';

const OrderReport = () => {
  return (
    <div className="tera-page">
      <div className="text-gray-700 font-semibold text-base uppercase mb-[13px]">
        Báo cáo đơn hàng
      </div>
      <div className="flex flex-col gap-2.5">
        <Overview />
        <ChartPieOrderReport />
        <Row className="grid-cols-2 gap-2.5 p-2.5 border rounded-md bg-white">
          <ChartBarSaleOrder />
          <ChartBarCourseOrder />
        </Row>
      </div>
    </div>
  );
};

export default OrderReport;
