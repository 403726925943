export const COURSE_URL = {
  list: {
    key: 'course-list',
    path: '/course-management/course/list',
    shortenUrl: 'course/list',
  },
  detail: {
    key: 'course-detail',
    path: '/course-management/course/detail',
    shortenUrl: 'course/detail/:id',
  },
  create: {
    key: 'course-create',
    path: '/course-management/course/create',
    shortenUrl: 'course/create/:type',
  },
  update: {
    key: 'course-update',
    path: '/course-management/course/update',
    shortenUrl: 'course/update/:id',
  },
};
