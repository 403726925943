import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useInfiniteScrollTable from '_common/dof/TableTera/Hooks/useInfiniteScrollTable';
import { ITableRowActionRef } from '_common/dof/TableTera/_interfaces';
import {
  INDEX_NUMBER_KEY,
  OPERATION_KEY,
} from '_common/dof/TableTera/constants';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  PlusCircleOutlined,
  formatCurrency,
  notification,
  Spin,
} from 'tera-dls';
import CashReceiptApi from '../../api';
import _ from 'lodash';
import Group from 'styles/images/pages/finance/Group.png';
import ExpenseVoucherApi from 'pages/Finance/ExpenseVoucher/api';

type Mode = 'default' | 'soft' | 'view';
interface IProps {
  tableProps?: any;
  mode?: Mode;
  onChange?: (value: any) => void;
  onTotalChange?: (value: any) => void;
  id?: number;
  limit?: number;
  objectType: string;
  type: 'cash-receipt' | 'expense-voucher';
  onSuccess?: () => void;
}

export interface IExplainTableRef {
  checkError: () => boolean;
  getTotal: () => number;
}

const ExplainTable = (props: IProps, ref: any) => {
  const {
    tableProps = {},
    mode = 'default',
    onChange,
    onTotalChange,
    id,
    limit = 15,
    objectType,
    type,
    onSuccess,
  } = props;

  const actionRef = useRef<ITableRowActionRef>(null);
  const [dataSource, setDataSource] = useState<any>([]);
  const queryClient = useQueryClient();
  const isCashReceiptType = type === 'cash-receipt';
  const [summary, setSummary] = useState<number>(0);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['service-package-infinite-scroll'],
    staleTime: 30000,
    cacheTime: 30000,
    queryFn: ({ pageParam }) =>
      isCashReceiptType
        ? CashReceiptApi.getExplainList({
            id: id,
            params: {
              page: pageParam ?? 1,
              limit,
            },
          })
        : ExpenseVoucherApi.getExplainList({
            id: id,
            params: {
              page: pageParam ?? 1,
              limit,
            },
          }),
    enabled: mode !== 'soft' && !!id,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.last_page - 1 >= lastPage?.current_page
        ? allPages.length + 1
        : undefined;
    },
  });

  useInfiniteScrollTable({
    objectType,
    callback: () => {
      if (hasNextPage && !isFetching && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    enable: mode !== 'soft',
  });

  useEffect(() => {
    if (mode !== 'soft' && !!id) {
      queryClient.setQueryData(
        ['service-package-infinite-scroll'],
        (oldData: any) => {
          return {
            pages: [oldData?.pages?.[0] ?? []],
            pageParams: [1],
          };
        },
      );
      refetch();

      return () => {
        queryClient.invalidateQueries(['service-package-infinite-scroll']);
      };
    }
  }, [mode, id]);

  const { mutateAsync: mutateUpdate, isLoading: isSaving } = useMutation(
    (variables: any) =>
      isCashReceiptType
        ? CashReceiptApi.updateExplain(variables)
        : ExpenseVoucherApi.updateExplain(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutateAsync: mutateCreate } = useMutation(
    (variables: any) =>
      isCashReceiptType
        ? CashReceiptApi.createExplain(variables)
        : ExpenseVoucherApi.createExplain(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutateAsync: mutateDelete } = useMutation(
    (variables: any) =>
      isCashReceiptType
        ? CashReceiptApi.deleteExplain(variables)
        : ExpenseVoucherApi.deleteExplain(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const value = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      return _.unionBy(acc, page?.data ?? [], 'id');
    }, []);
  }, [data]);

  useEffect(() => {
    if (!value) return;
    mode !== 'soft' && id && setDataSource(value);
  }, [value, mode, id]);

  useEffect(() => {
    if (data?.pages?.length > 0 && mode !== 'soft') {
      const page = data?.pages?.[data?.pages?.length - 1];
      onTotalChange && onTotalChange(page?.data?.total);
      setSummary(page?.total_amount);
    }
  }, [data?.pages, mode]);

  useImperativeHandle(
    ref,
    () => ({
      checkError() {
        return actionRef.current?.trigger();
      },
    }),
    [actionRef, ref],
  );

  const isDisable = (isSaving || isFetching || isFetchingNextPage) && !!id;

  const columns = [
    {
      title: '#',
      dataIndex: INDEX_NUMBER_KEY,
      width: 80,
    },
    {
      title: 'Diễn giải',
      dataIndex: 'name',
      width: '50%',
      editable: true,
      rules: [{ required: 'Vui lòng nhập' }],

      inputProps: {
        autoFocus: true,
        maxLength: 255,
      },
      render: (val) => <span className="break-word">{val}</span>,
    },
    {
      title: 'Sô tiền (đ)',
      dataIndex: 'amount',
      width: '30%',
      type: 'int',
      editable: true,
      rules: [{ required: 'Vui lòng nhập' }],
      inputProps: {
        min: 0,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
      render: (val) => formatCurrency(val),
    },
    mode !== 'view' && {
      title: (
        <div className="flex justify-center">
          {isDisable ? (
            <div className="flex justify-center">
              <Spin
                spinning
                svgProps={{ className: 'w-4 h-[20px]' }}
                wrapperClassName=" h-[10px]"
              />
            </div>
          ) : (
            <PlusCircleOutlined
              className={`w-5 cursor-pointer text-green-500 table-row-except-cope ${
                isDisable ? '!text-gray-500' : ''
              }`}
              onClick={() => {
                !isDisable && actionRef.current?.addRow();
              }}
            />
          )}
        </div>
      ),
      dataIndex: OPERATION_KEY,
      width: 60,
    },
  ];

  const handleAdd = async (record) => {
    try {
      const response = await mutateCreate({
        params: {
          accountant_id: id,
          name: record?.name,
          amount: record?.amount,
        },
      });
      if (response?.code !== 200) return;
      queryClient.setQueryData(
        ['service-package-infinite-scroll'],
        (oldData: any) => {
          return {
            pageParams: [oldData?.pageParams?.[0]],
          };
        },
      );
      refetch({
        refetchPage: (page, index) => {
          return index === 0;
        },
      });
    } catch (e) {}
  };

  const handleDelete = async (record, index) => {
    try {
      const response = await mutateDelete({ id: record.id });
      const currentPage = Math.floor(index / limit) + 1;
      if (!currentPage || currentPage <= 0 || response?.code !== 200) return;
      queryClient.setQueryData(
        ['service-package-infinite-scroll'],
        (oldData: any) => {
          return {
            pages: oldData?.pages?.filter(
              (_, index) => index <= currentPage - 1,
            ),
            pageParams: oldData?.pageParams.filter(
              (_, index) => index <= currentPage - 1,
            ),
          };
        },
      );

      refetch({
        refetchPage: (page, index) => {
          return index === currentPage - 1;
        },
      });
    } catch (e) {}
  };

  const handleUpdate = async (record) => {
    try {
      const response: any = await mutateUpdate({
        id: record?.id,
        params: {
          accountant_id: id,
          name: record?.name,
          amount: record?.amount,
        },
      });
      const currentPage = Math.floor(record?.index / limit) + 1;
      if (!currentPage || currentPage <= 0 || response?.code !== 200) return;
      queryClient.setQueryData(
        ['service-package-infinite-scroll'],
        (oldData: any) => {
          return {
            pages: oldData?.pages?.filter(
              (_, index) => index <= currentPage - 1,
            ),
            pageParams: oldData?.pageParams.filter(
              (_, index) => index <= currentPage - 1,
            ),
          };
        },
      );
      refetch({
        refetchPage: (page, index) => {
          return index === currentPage - 1;
        },
      });
    } catch (e) {}
  };

  return (
    <>
      {mode !== 'view' && (
        <div className="flex justify-center items-center">
          <div className="flex flex-col justify-center items-center gap-[16px]">
            <img src={Group} className="h-auto w-[70px]" />
            <p className="text-center grid gap-[1px] font-normal text-sm">
              <p>Chưa có danh sách hạch toán</p>
              <p className="flex justify-center items-center gap-1">
                Bấm
                <PlusCircleOutlined className="w-5 inline text-green-500" />
                để thêm hạch toán
              </p>
            </p>
          </div>
        </div>
      )}
      <TableTera
        objectType={objectType}
        loading={isLoading && !!id}
        loadingIndicator={{
          loading: isFetchingNextPage,
        }}
        recordCreatorProps={{
          record: () => ({ amount: 0 }),
        }}
        scroll={{ y: 360 }}
        {...tableProps}
        summary={(records) => {
          const total =
            mode === 'soft' &&
            (records.reduce(
              (a: number, b: any) => a + Number(b.amount),
              0,
            ) as number);
          return (
            <tr className=" font-medium sticky bottom-0">
              <td className="text-primary !py-2.5 !bg-gray-200" />
              <td className="text-primary !py-2.5 !pl-3 !bg-gray-200 border-r-[5px] border-r-white">
                Tổng giá trị
              </td>
              <td className="text-primary !pl-3 !py-2.5 text-yellow-600 !bg-gray-200 ">
                {formatCurrency(mode !== 'soft' ? summary : total)}
              </td>
              <td className="text-primary !py-2.5 !bg-gray-200" />
            </tr>
          );
        }}
        data={dataSource}
        columns={columns}
        rowKey={'id'}
        mode={mode === 'view' ? 'table' : 'editable-row'}
        actionRef={actionRef}
        editable={{
          ...(tableProps?.editable && tableProps?.editable),
          saveOnClickOut: true,
          onValuesChange(_, recordList) {
            if (mode !== 'default') {
              setDataSource(recordList);
              const value = [...(recordList ?? [])]?.map((item) => {
                const { id, ...rest } = item;
                id;
                return {
                  ...rest,
                };
              });
              onChange && onChange(value);
            }
          },
          ...(mode == 'default' &&
            id && {
              onAdd: handleAdd,
              onDelete: handleDelete,
              onUpdate: handleUpdate,
              isDisabled: isDisable,
            }),
        }}
      />
    </>
  );
};

export default forwardRef<IExplainTableRef, IProps>(ExplainTable);
