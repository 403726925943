import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CourseCategoryEndpoint = `${endpoint}/bake-teach/course-category`;
const CourseCategoryApi = {
  getList: async (params) =>
    await api
      .get(`${CourseCategoryEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CourseCategoryEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${CourseCategoryEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CourseCategoryEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CourseCategoryEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default CourseCategoryApi;
