import { IRouteProps } from '_common/interface/router';
import AffiliateOverview from '.';
import { AFFILIATE_OVERVIEW_URL } from './url';
import AffiliateOverviewDetail from './containers/Detail';

export const AffiliateOverviewRouter: IRouteProps[] = [
  {
    key: AFFILIATE_OVERVIEW_URL.list.key,
    path: AFFILIATE_OVERVIEW_URL.list.shortenUrl,
    component: <AffiliateOverview />,
  },
  {
    key: AFFILIATE_OVERVIEW_URL.detail.key,
    path: AFFILIATE_OVERVIEW_URL.detail.shortenUrl,
    component: <AffiliateOverviewDetail />,
  },
];
