import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CourseActivityEndpoint = `${endpoint}/bake-teach/course-activity`;
const CourseActivityApi = {
  getList: async (params) =>
    await api
      .get(`${CourseActivityEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${CourseActivityEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CourseActivityEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${CourseActivityEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CourseActivityEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  approve: async ({ id }) =>
    await api
      .put(`${CourseActivityEndpoint}/approve/${id}`)
      .then((result) => result?.data),
  reject: async ({ id }) =>
    await api
      .put(`${CourseActivityEndpoint}/reject/${id}`)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CourseActivityEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default CourseActivityApi;
