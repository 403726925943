import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const AffiliateConfigEndpoint = `${endpoint}/bake-teach/affiliate`;
const AffiliateConfigApi = {
  getConfig: async (params) =>
    await api
      .get(`${AffiliateConfigEndpoint}/get-config`, params)
      .then((result) => result?.data?.data),
  save: async ({ params }) =>
    await api
      .put(`${AffiliateConfigEndpoint}/save-config`, params)
      .then((result) => result?.data),
};
export default AffiliateConfigApi;
