import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TRANSACTION_HISTORY_METHOD } from '../../constants';
import { PAYMENT_TYPE } from 'pages/Finance/constants';

interface TransactionHistoryFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function TransactionHistoryFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: TransactionHistoryFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      start_created: values?.created_at?.[0]?.format(DATE_BACKEND_FORMAT),
      end_created: values?.created_at?.[1]?.format(DATE_BACKEND_FORMAT),
      start_updated: values?.updated_at?.[0]?.format(DATE_BACKEND_FORMAT),
      end_updated: values?.updated_at?.[1]?.format(DATE_BACKEND_FORMAT),
    };
    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      methods: null,
      transaction_type: null,
      created_at: null,
      updated_at: null,
      start_updated: null,
      end_updated: null,
      start_created: null,
      end_created: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, ['methods', 'transaction_type']);
    const data = {
      ...values,
      ...(initialValue?.start_updated &&
        initialValue?.end_updated && {
          updated_at: [
            moment(initialValue?.start_updated),
            moment(initialValue?.end_updated),
          ],
        }),
      ...(initialValue?.start_created &&
        initialValue?.end_created && {
          created_at: [
            moment(initialValue?.start_created),
            moment(initialValue?.end_created),
          ],
        }),
    };
    form.reset(data);
  }, [initialValue]);

  const optionsPaymentType = Object.entries(PAYMENT_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );
  const optionsPaymentMethod = Object.entries(TRANSACTION_HISTORY_METHOD).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select options={optionsPaymentType} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select options={optionsPaymentMethod} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Thời gian tạo" name="created_at">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <FormTeraItem label="Thời gian cập nhật" name="updated_at">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default TransactionHistoryFilter;
