import { useQuery } from '@tanstack/react-query';
import DetectMedia from '_common/component/DetectMedia';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import _ from 'lodash';
import { IFormModel } from 'pages/CourseManagement/Course';
import HomeWorkApi from 'pages/CourseManagement/Course/api/HomeWork';
import { STUDENT_HOMEWORK_STATUS } from 'pages/CourseManagement/Course/constants';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  FunnelOutlined,
  Image,
  PaginationProps,
  Toggle,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import StudentHomeWorkDetail from './Detail';
import StudentHomeWorkFilter from './Filter';
import imageDefault from 'styles/images/image-default.png';

const StudentHomeWork = ({ dataDetail }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });
  const [openFilter, setOpenFilter] = useState<any>();

  const params = _.pick(queryParams, ['studentHomeWork']);
  const paramsObject = params?.studentHomeWork
    ? JSON.parse(params?.studentHomeWork)
    : {};

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      studentHomeWork: JSON.stringify({
        ...paramsObject,
        ...data,
      }),
    });
    navigate(location.pathname + paramString);
  };

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-homework-list', params, dataDetail],
    () => {
      const queryParams =
        params?.studentHomeWork && JSON.parse(params?.studentHomeWork);

      return HomeWorkApi.getList({
        page: 1,
        limit: 10,
        course_id: dataDetail?.id,
        type_assignment: 'exercise',
        ...queryParams,
        not_scored_yet: queryParams?.not_scored_yet ? 0 : undefined,
      });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleToggle = (value): void => {
    handleUpdateQueryParams({ not_scored_yet: value, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(response?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => setOpenFormModel({ open: true, value: record?.id }),
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Học viên',
      dataIndex: 'user',
      width: 200,
      render: (user) => (
        <div className="flex gap-2.5 items-center">
          <Image
            defaultImage={imageDefault}
            src={user?.avatar}
            containerClassName="!size-[24px] rounded-full overflow-hidden"
            imageClassName="object-cover"
          />
          <span className="line-clamp-1 text-blue-600">{user?.full_name}</span>
        </div>
      ),
    },
    {
      title: 'Phần học',
      dataIndex: 'section',
      width: 150,
      render: (section) => section?.name,
    },
    {
      title: 'Bài học',
      dataIndex: 'lesson',
      width: 150,
      render: (lesson) => lesson?.name,
    },
    {
      title: 'Nội dung nộp',
      width: 150,
      dataIndex: 'files',
      render: (files) => {
        const listVideo = [...files]?.splice(0, 3);
        const count = files?.length - listVideo?.length;
        return (
          <div className="flex gap-2.5 items-center">
            {listVideo?.map((file) => (
              <DetectMedia
                src={file?.url}
                type={file?.type_file}
                className="w-[40px] h-[40px] rounded overflow-hidden"
              />
            ))}
            {count > 0 && <span>+ {count}</span>}
          </div>
        );
      },
    },
    {
      title: 'Số điểm',
      width: 150,
      dataIndex: 'point',
      render: (val) =>
        (val || val === 0) && (
          <p>
            <span>{val}</span>
            {val < 5 ? (
              <span className="text-red-500"> Chưa đạt</span>
            ) : (
              <span className="text-green-500"> Đạt</span>
            )}
          </p>
        ),
    },
    {
      title: 'Trạng thái',
      width: 150,
      dataIndex: 'scoring_status',
      render: (val) => STUDENT_HOMEWORK_STATUS[val],
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className="space-y-2.5">
        <div className="flex gap-2.5 justify-end">
          <div className="flex gap-2 items-center">
            <span className="text-[13px]">Chưa chấm</span>
            <Toggle
              value={paramsObject?.toggle as any}
              onChange={(e) => {
                handleToggle(e.target.checked);
              }}
            />
          </div>
          <Button
            type="alternative"
            className="rounded-xsm py-1 px-1 bg-blue-50"
            icon={
              <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
            }
            onClick={() => setOpenFilter(true)}
          />
        </div>

        <TableTera
          loading={isFetching}
          wrapperClassName="shadow-none"
          rowKey={'id'}
          data={response?.data || []}
          className="center-table"
          pagination={{
            onChange: handleChangePage,
            total: response?.total || 0,
            current: response?.current_page,
            pageSize: response?.per_page || 10,
            to: response?.to,
            from: response?.from || 10,
          }}
          columns={columns}
        />
      </div>
      {openFilter && (
        <StudentHomeWorkFilter
          courseId={dataDetail?.id}
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={paramsObject}
        />
      )}
      {openFormModel.open && (
        <StudentHomeWorkDetail
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
        />
      )}
    </>
  );
};

export default StudentHomeWork;
