function Menu15(props) {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8626 2.2453V1.95764C10.8626 1.24158 10.3282 0.626424 9.60304 0.551172C8.23172 0.408862 6.83994 0.335938 5.4313 0.335938C4.02266 0.335938 2.63089 0.408862 1.25957 0.551172C0.534427 0.626424 0 1.24158 0 1.95764V11.0067C0 11.7935 0.637824 12.4314 1.42462 12.4314H2.53888C2.75347 13.1446 3.4153 13.6642 4.19848 13.6642C4.98166 13.6642 5.64349 13.1446 5.85808 12.4314H11.7851C11.9997 13.1446 12.6615 13.6642 13.4447 13.6642C14.2279 13.6642 14.8897 13.1446 15.1043 12.4314L15.6021 12.4313C16.3802 12.4313 17.0478 11.7942 16.9974 10.9764C16.8189 8.08103 15.8346 5.40302 14.2664 3.16494C13.8754 2.60689 13.2483 2.28237 12.5866 2.24605C12.5775 2.24555 12.5684 2.2453 12.5592 2.2453H10.8626ZM5.4313 1.33594C4.0572 1.33594 2.69988 1.40707 1.36279 1.54583C1.16009 1.56687 1 1.74087 1 1.95764V7.73326H9.86261V1.95764C9.86261 1.74087 9.70251 1.56687 9.49981 1.54583C8.16273 1.40707 6.80541 1.33594 5.4313 1.33594ZM9.86261 8.73326H1V11.0067C1 11.2413 1.19011 11.4314 1.42462 11.4314H2.53888C2.75347 10.7181 3.4153 10.1985 4.19848 10.1985C4.98166 10.1985 5.64349 10.7181 5.85808 11.4314H9.86261V8.73326ZM10.8626 11.4314H11.7851C11.9997 10.7181 12.6615 10.1985 13.4447 10.1985C14.2278 10.1985 14.8897 10.7181 15.1043 11.4314L15.6021 11.4313C15.8454 11.4313 16.0117 11.2396 15.9993 11.038C15.8325 8.3321 14.9131 5.83046 13.4474 3.73878C13.2454 3.4504 12.9136 3.26918 12.5444 3.2453H10.8626V11.4314ZM4.19848 11.1985C3.79375 11.1985 3.46565 11.5266 3.46565 11.9314C3.46565 12.3361 3.79375 12.6642 4.19848 12.6642C4.60321 12.6642 4.9313 12.3361 4.9313 11.9314C4.9313 11.5266 4.60321 11.1985 4.19848 11.1985ZM13.4447 11.1985C13.0399 11.1985 12.7118 11.5266 12.7118 11.9314C12.7118 12.3361 13.0399 12.6642 13.4447 12.6642C13.8494 12.6642 14.1775 12.3361 14.1775 11.9314C14.1775 11.5266 13.8494 11.1985 13.4447 11.1985Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu15;
