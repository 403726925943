import { Image } from 'tera-dls';
import Video from './Video';

interface DetectMediaProps {
  type: string;
  src: string;
  className?: string;
}

function DetectMedia({ type, src, className }: DetectMediaProps) {
  const media = {
    image: <Image src={src} containerClassName={className} />,
    video: <Video src={src} className={className} />,
  };

  return media[type];
}

export default DetectMedia;
