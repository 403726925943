import Filter from '_common/component/Filter';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import RangeNumber from '_common/dof/Control/RangeNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function UserFilter({ open, initialValue, onClose, onFilter }: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter(values), onClose();
  };

  const handleReset = () => {
    const values = {
      from_quantity_out: null,
      to_quantity_out: null,
      from_count_certification: null,
      to_count_certification: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;
    form.reset(initialValue);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem name="" label="Số sao">
          <RangeNumber
            startFormName="from_quantity_out"
            endFormName="to_quantity_out"
            endInputProps={{
              max: 5,
              min: 0,
              placeholder: 'Đến',
            }}
            startInputProps={{
              max: 5,
              min: 0,
              placeholder: 'Từ',
            }}
          />
        </FormTeraItem>
        <FormTeraItem name="" label="Số lượng chứng chỉ">
          <RangeNumber
            startFormName="from_count_certification"
            endFormName="to_count_certification"
            endInputProps={{
              min: 0,
              max: MAXIMUM_CURRENCY,
              placeholder: 'Đến khoảng',
            }}
            startInputProps={{
              min: 0,
              max: MAXIMUM_CURRENCY,
              placeholder: 'Từ khoảng',
            }}
          />
        </FormTeraItem>
        <a
          className="text-red-500 text-xxs font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default UserFilter;
