import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const AddressEndpoint = `${endpoint}/bake-teach/common`;
const AddressApi = {
  getProvince: async (params) =>
    await api
      .get(`${AddressEndpoint}/province`, params)
      .then((data) => data?.data),
  getDistrict: async ({ id, params }) =>
    await api
      .get(`${AddressEndpoint}/district/${id}`, params)
      .then((data) => data?.data),
  getWard: async ({ id, params }) =>
    await api
      .get(`${AddressEndpoint}/ward/${id}`, params)
      .then((data) => data?.data),
};
export default AddressApi;
