import { messageWarning } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { Modal } from 'tera-dls';
import { STATUS_INVOICE } from '../../constants';

interface ModalChangeStatusProps {
  id: string | number;
  open: boolean;
  status: any;
  onClose?: () => void;
  onSuccess?: () => void;
}

function ModalChangeStatus(props: ModalChangeStatusProps) {
  const { id, open, status, onClose } = props;
  const form = useForm({ mode: 'onChange', defaultValues: { status } });
  const confirm = useConfirm();
  const { isDirty } = form.formState;

  id;

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    console.log(values);
  };

  const statusOptions = Object.entries(STATUS_INVOICE).map(
    ([value, label]) => ({ value, label }),
  );

  return (
    <Modal
      title="Đổi trạng thái"
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
    >
      <FormTera form={form}>
        <FormTeraItem label="Trạng thái" name="status">
          <Select options={statusOptions} allowClear={false} />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
}

export default ModalChangeStatus;
