import { IRouteProps } from '_common/interface/router';
import WithdrawalRequest from '.';
import { WITHDRAWAL_REQUEST_URL } from './url';

export const WithdrawalRequestRouter: IRouteProps[] = [
  {
    key: WITHDRAWAL_REQUEST_URL.list.key,
    path: WITHDRAWAL_REQUEST_URL.list.shortenUrl,
    component: <WithdrawalRequest />,
  },
];
