import { useMutation, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import CourseSectionApi from 'pages/CourseManagement/Course/api/Section';
import { useEffect, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import {
  notification,
  PencilSquareOutlined,
  PlusCircleOutlined,
  Spin,
  TrashOutlined,
} from 'tera-dls';
import LectureForm from './Form';

const Lecture = ({
  courseId,
  mode,
  value,
  selectedValue,
  onUpdateData,
  onSelectedValue,
  onDelete,
  loading = false,
}) => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const isUpdate = mode == 'update';

  const [openForm, setOpenForm] = useState<any>({
    open: false,
  });

  useEffect(() => {
    if (value?.length == 0) {
      onSelectedValue();
    }
    if (selectedValue == undefined) {
      onSelectedValue(value?.[0]?.id);
    }
  }, [value, selectedValue]);

  const { mutate: mutateDelete, isLoading } = useMutation(
    (variables: any) => CourseSectionApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-section-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
          onSelectedValue(undefined);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa học phần',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa học phần </p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        if (isUpdate) {
          mutateDelete({ id });
          return;
        }
        onDelete(id);
      },
    });
  };

  return (
    <>
      <div className="bg-[#F3F4F6] text-base font-medium rounded pb-[80px]">
        <div className="px-2.5 py-[16px] flex justify-between border-b text-blue-600">
          <span>Thêm phần học</span>{' '}
          <PlusCircleOutlined
            className="size-6 cursor-pointer"
            onClick={() => setOpenForm({ open: true })}
          />
        </div>
        <Spin spinning={isUpdate && (loading || isLoading)}>
          {value?.map((item) => (
            <div
              key={item?.id}
              className={customTwMerge(
                'px-2.5 py-[16px] flex gap-3 justify-between items-center cursor-pointer border-b hover:bg-blue-100 ',
                selectedValue == item.id && 'bg-white',
              )}
              onClick={() => {
                onSelectedValue(item?.id);
              }}
            >
              <span className="break-word">{item?.name}</span>{' '}
              <span className="flex gap-2.5">
                <PencilSquareOutlined
                  className="size-4 text-green-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenForm({
                      open: true,
                      value: item?.id,
                      name: item?.name,
                    });
                  }}
                />
                <TrashOutlined
                  className="size-4 text-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(item?.id, item.name);
                  }}
                />
              </span>
            </div>
          ))}
        </Spin>
      </div>
      {openForm.open && (
        <LectureForm
          open={openForm.open}
          onClose={() => setOpenForm({ open: false })}
          value={openForm.value}
          name={openForm.name}
          onSubmit={onUpdateData}
          mode={mode}
          courseId={courseId}
        />
      )}
    </>
  );
};

export default Lecture;
