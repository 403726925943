function Menu4(props) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.15084 8.69231H10.1508C11.6378 8.69231 12.8431 9.8977 12.8431 11.3846C12.8431 12.8715 11.6378 14.0769 10.1508 14.0769H8.997M5.15084 8.69231L7.45853 6.38462M5.15084 8.69231L7.45853 11M16.6893 3.57127V21L12.8431 19.4615L8.997 21L5.15084 19.4615L1.30469 21V3.57127C1.30469 2.43487 2.13144 1.46168 3.26026 1.33068C5.14219 1.11227 7.05648 1 8.997 1C10.9375 1 12.8518 1.11227 14.7337 1.33068C15.8626 1.46168 16.6893 2.43487 16.6893 3.57127Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Menu4;
