import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  formatCurrency,
  getQueryParams,
  notification,
  PaginationProps,
  Tabs,
  Tag,
  updateQueryParams,
} from 'tera-dls';
import WithdrawalRequestApi from './api';
import {
  WITHDRAWAL_REQUEST_STATUS,
  WITHDRAWAL_REQUEST_STATUS_COLOR,
} from './constants';
import UserDetail from './containers/Detail';
import UserFilter from './containers/Filter';
import Searching from './containers/Searching';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const WithdrawalRequest = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const queryClient = useQueryClient();
  queryClient;
  confirm;
  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });
  const params = _.omit(queryParams, ['status']);
  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-withdrawal-request-list', queryParams],
    () =>
      WithdrawalRequestApi.getList({
        page: 1,
        limit: 10,
        ...params,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && { status: queryParams?.status }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-withdrawal-request-summary', queryParams],
    () =>
      WithdrawalRequestApi.getSummaryList({
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(WITHDRAWAL_REQUEST_STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{WITHDRAWAL_REQUEST_STATUS[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const { mutate: mutateApprove } = useMutation(
    (variables: any) => WithdrawalRequestApi.approve(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-withdrawal-request-list']);
          queryClient.invalidateQueries(['get-withdrawal-request-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleApprove = (id: number, amount: number): void => {
    confirm.warning({
      title: 'Xác nhận rút tiền',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận rút tiền</p>
          <p>
            <span className="font-bold break-word">
              {` ${formatCurrency(amount ?? 0)} `}{' '}
            </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateApprove({ id });
      },
    });
  };

  const { mutate: mutateReject } = useMutation(
    (variables: any) => WithdrawalRequestApi.cancel(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-withdrawal-request-list']);
          queryClient.invalidateQueries(['get-withdrawal-request-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleReject = (id: number, amount: number): void => {
    confirm.warning({
      title: 'Xác nhận hủy rút tiền',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận hủy rút tiền</p>
          <p>
            <span className="font-bold break-word">
              {` ${formatCurrency(amount ?? 0)} `}{' '}
            </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateReject({ id });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => setOpenDetailModel({ open: true, value: record?.id }),
      },

      ...(record?.status == 'pending'
        ? [
            {
              key: 2,
              label: <a>Xác nhận</a>,
              onClick: () => handleApprove(record?.id, record?.money),
            },
            {
              key: 3,
              label: <a>Hủy</a>,
              onClick: () => handleReject(record?.id, record?.money),
            },
          ]
        : []),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      width: 150,
      render: (val) => moment(val).format(DATE_TIME_FORMAT),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user',
      width: 200,
      render: (value) => (
        <HoverQuickView
          name={value?.full_name}
          avatarUrl={value?.avatar}
          email={value?.email}
          phone={value?.phone}
        >
          <span className="line-clamp-1 text-blue-600">{value?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
      render: (_, record) => record?.user?.phone,
    },
    {
      title: 'Số tiền muốn rút',
      width: 150,
      dataIndex: 'money',
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Trạng thái',
      width: 150,
      dataIndex: 'status',
      render: (val) => (
        <Tag color={WITHDRAWAL_REQUEST_STATUS_COLOR[val]}>
          {WITHDRAWAL_REQUEST_STATUS[val]}
        </Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH RÚT TIỀN"
          actionLeftRender={<Searching onSearch={handleSearch} />}
          onClickFilter={() => setOpenFilter(true)}
          middleContent={
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
          }
          filterCount={{
            filter: queryParams,
            params: [
              'time',
              // 'start_date',
              // 'end_date',
              'from_money',
              'to_money',
            ],
          }}
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <TableTera
              loading={isFetching}
              wrapperClassName="shadow-none"
              rowKey={'id'}
              data={response?.data ?? []}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
              columns={columns}
            />
          </div>
        </HeaderViewListV2>
      </div>

      {openDetailModel.open && (
        <UserDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
      {openFilter && (
        <UserFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default WithdrawalRequest;
