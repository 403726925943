import { IRouteProps } from '_common/interface/router';
import ProductCategory from '.';
import { CATEGORY_URL } from './url';

export const ProductCategoryRouter: IRouteProps[] = [
  {
    key: CATEGORY_URL.list.key,
    path: CATEGORY_URL.list.shortenUrl,
    component: <ProductCategory />,
  },
];
