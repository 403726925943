export const UNIT_TYPE = {
  gram: 'Gram (g)',
  kilogram: 'Kilogram (kg)',
  piece: 'Cái',
  box: 'Hộp',
  carton: 'Thùng',
  pack: 'Gói',
};

export const STATUS = {
  active: 'Đang hoạt động',
  inactive: 'Ngừng hoạt động',
};

export const STATUS_COLOR = {
  active: 'green03',
  inactive: 'gray02',
};
