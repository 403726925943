export const LECTURER_URL = {
  list: {
    key: 'lecturer-list',
    path: '/lecturer-management/lecturer/list',
    shortenUrl: 'lecturer/list',
  },
  detail: {
    key: 'lecturer-detail',
    path: '/lecturer-management/lecturer/detail',
    shortenUrl: 'lecturer/detail/:id',
  },
  update: {
    key: 'lecturer-update',
    path: '/lecturer-management/lecturer/update',
    shortenUrl: 'lecturer/update/:id',
  },
};
