import React from 'react';
import { Modal } from 'tera-dls';

function ModalVideo({ open, onCancel, src }) {
  return (
    <Modal
      open={open}
      closeIcon={false}
      cancelText="Hủy"
      onCancel={onCancel}
      footer={<></>}
      modalRender={() => (
        <div className="rounded overflow-hidden w-[600px]">
          <video src={src} controls className="w-full h-full" />
        </div>
      )}
    />
  );
}

export default ModalVideo;
