import { IRouteProps } from '_common/interface/router';
import LecturerApplication from '.';
import { LECTURER_APPLICATION_URL } from './url';
import LecturerDetail from './containers/Detail';

export const LecturerApplicationRouter: IRouteProps[] = [
  {
    key: LECTURER_APPLICATION_URL.list.key,
    path: LECTURER_APPLICATION_URL.list.shortenUrl,
    component: <LecturerApplication />,
  },
  {
    key: LECTURER_APPLICATION_URL.detail.key,
    path: LECTURER_APPLICATION_URL.detail.shortenUrl,
    component: <LecturerDetail />,
  },
];
