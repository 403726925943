export const SALE_ORDER_URL = {
  list: {
    key: 'sale-order-list',
    path: '/sale-management/sale-order/list',
    shortenUrl: 'sale-order/list',
  },
  create: {
    key: 'sale-order-create',
    path: '/sale-management/sale-order/create',
    shortenUrl: 'sale-order/create',
  },
  update: {
    key: 'sale-order-update',
    path: '/sale-management/sale-order/update',
    shortenUrl: 'sale-order/update/:id',
  },
  detail: {
    key: 'sale-order-detail',
    path: '/sale-management/sale-order/detail',
    shortenUrl: 'sale-order/detail/:id',
  },
};
