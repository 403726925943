export const STATUS_REFUND = {
  pending: 'Chờ duyệt',
  reject: 'Từ chối',
  approved: 'Đã duyệt',
};

export const TYPE_REFUND = {
  online: 'Khóa học online',
  offline: 'Khóa học offline',
};

export const STATUS_REFUND_COLOR = {
  pending: 'yellow03',
  approved: 'green03',
  reject: 'red03',
};
