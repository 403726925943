import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Button, formatCurrency, Modal, Spin, Tag } from 'tera-dls';
import UserApi from '../../api';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import HoverQuickView from '_common/component/HoverQuickView';
import {
  WITHDRAWAL_REQUEST_STATUS,
  WITHDRAWAL_REQUEST_STATUS_COLOR,
} from '../../constants';
import { renderDescription } from '_common/utils/utils';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const UserDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(
    ['get-withdrawal-request-detail', id],
    () => UserApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );
  useEffect(() => {
    id && fetchData();
  }, [id]);

  const data = [
    {
      key: '1',
      title: 'Thời gian',
      value: moment(detail?.created_at).format(DATE_TIME_FORMAT),
    },
    {
      key: '2',
      title: 'Họ và tên',
      value: (
        <HoverQuickView
          name={detail?.user?.full_name}
          avatarUrl={detail?.user?.avatar}
          email={detail?.user?.email}
          phone={detail?.user?.phone}
        >
          <span className="line-clamp-1 text-blue-600">
            {detail?.user?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      key: '6',
      title: 'Số điện thoại',
      value: detail?.user?.phone,
    },
    {
      key: '3',
      title: 'Số tiền muốn rút',
      value: formatCurrency(detail?.money ?? 0),
    },
    {
      key: '4',
      title: 'Trạng thái',
      value: (
        <Tag color={WITHDRAWAL_REQUEST_STATUS_COLOR[detail?.status] as any}>
          {WITHDRAWAL_REQUEST_STATUS[detail?.status]}
        </Tag>
      ),
    },
  ];
  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết rút tiền'}
        closeIcon={false}
        width={500}
        onCancel={onClose}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={isLoading}>
          <div className="gap-2.5 grid">{renderDescription(data)}</div>
        </Spin>
      </Modal>
    </>
  );
};

export default UserDetail;
