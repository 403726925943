export const TRANSACTION_HISTORY_STATUS_TAB = {
  all: 'Tất cả',
  pending: 'Đang xử lý',
  approved: 'Hoàn thành',
  error: 'Báo lỗi',
  fail: 'Thất bại',
};

export const TRANSACTION_HISTORY_STATUS = {
  pending: 'Đang xử lý',
  approved: 'Hoàn thành',
  error: 'Báo lỗi',
  fail: 'Thất bại',
};

export const TRANSACTION_HISTORY_STATUS_COLOR = {
  pending: 'yellow03',
  approved: 'green03',
  error: 'red03',
  fail: 'gray01',
};

export const TRANSACTION_HISTORY_METHOD = {
  transfer: 'Chuyển khoản',
  wallet: 'Ví của tôi',
  ewallet: 'Ví điện tử',
};
