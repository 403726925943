import { useRef, useState } from 'react';

import {
  LIMIT_MAX_DATE_TIME,
  LIMIT_MIN_DATE_TIME,
} from '_common/constants/common';
import moment from 'moment';
import { ArrowPathOutlined, RangePicker, Row, Tooltip } from 'tera-dls';
import Location, { LocationRefs } from './Location';
import { CourseOrderRefs } from './NumberOfCourse/Content';
import { CourseRefs } from './NumberOfCourseOrder/Content';
import NumberOfCourse from './NumberOfCourse';
import NumberOfCourseOrder from './NumberOfCourseOrder';

function StatisticsOverview() {
  const locationRef = useRef<LocationRefs>(null);
  const CourseRef = useRef<CourseRefs>(null);
  const CourseOrderRef = useRef<CourseOrderRefs>(null);
  const [date, setDate] = useState<any>([
    moment().subtract(1, 'months'),
    moment(),
  ]);

  const handleRefetch = () => {
    locationRef.current.refetchData();
    CourseRef.current.refetchData();
    CourseOrderRef.current.refetchData();
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex justify-end gap-2.5">
        <Tooltip title="Làm mới" className="z-[99]">
          <span className="flex items-center">
            <ArrowPathOutlined
              className="cursor-pointer w-4 h-4"
              onClick={handleRefetch}
            />
          </span>
        </Tooltip>
        <RangePicker
          placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
          className="w-[300px]"
          allowClear={false}
          value={date}
          onChange={setDate}
          maxDate={moment(LIMIT_MAX_DATE_TIME)}
          minDate={moment(LIMIT_MIN_DATE_TIME)}
          presets={[
            {
              label: (
                <div className="px-3 py-1 pr-10 cursor-pointer">Hôm nay</div>
              ),
              value: [moment(), moment()],
            },
            {
              label: (
                <div className="px-3 py-1 pr-10 cursor-pointer">3 ngày</div>
              ),
              value: [moment().subtract(3, 'days'), moment()],
            },
            {
              label: (
                <div className="px-3 py-1  pr-10 cursor-pointer">Tuần này</div>
              ),
              value: () => {
                const currentDate = moment();
                return [
                  currentDate.clone().startOf('week'),
                  currentDate.clone().endOf('week'),
                ];
              },
            },
            {
              label: (
                <div className="px-3 py-1  pr-10 cursor-pointer">Tháng này</div>
              ),
              value: () => {
                const currentDate = moment();
                return [
                  currentDate.clone().startOf('months'),
                  currentDate.clone().endOf('months'),
                ];
              },
            },
            {
              label: (
                <div className="px-3 py-1  pr-10 cursor-pointer">Quý 1</div>
              ),
              value: () => {
                const time = moment().month(0);
                return [
                  time.clone().startOf('quarter'),
                  time.clone().endOf('quarter'),
                ];
              },
            },
            {
              label: (
                <div className="px-3 py-1  pr-10 cursor-pointer">Quý 2</div>
              ),
              value: () => {
                const time = moment().month(3);
                return [
                  time.clone().startOf('quarter'),
                  time.clone().endOf('quarter'),
                ];
              },
            },
            {
              label: (
                <div className="px-3 py-1  pr-10 cursor-pointer">Quý 3</div>
              ),
              value: () => {
                const time = moment().month(6);
                return [
                  time.clone().startOf('quarter'),
                  time.clone().endOf('quarter'),
                ];
              },
            },
            {
              label: (
                <div className="px-3 py-1  pr-10 cursor-pointer">Quý 4</div>
              ),
              value: () => {
                const time = moment().month(9);
                return [
                  time.clone().startOf('quarter'),
                  time.clone().endOf('quarter'),
                ];
              },
            },
          ]}
        />
      </div>
      <Location params={{ date }} ref={locationRef} />
      <Row className="grid-cols-2 gap-4">
        <NumberOfCourse params={{ date }} ref={CourseRef} />
        <NumberOfCourseOrder params={{ date }} ref={CourseOrderRef} />
      </Row>
    </div>
  );
}

export default StatisticsOverview;
