import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import moment from 'moment';

export const adjustRangePickerValue = (dates, type) => {
  if (dates && dates.length === 2) {
    let start = moment(dates[0]);
    let end = moment(dates[1]);

    if (type === 'month') {
      start = start.startOf('month');
      end = end.endOf('month');
    } else if (type === 'quarter') {
      start = start.startOf('quarter');
      end = end.endOf('quarter');
    } else if (type === 'year') {
      start = start.startOf('year');
      end = end.endOf('year');
    } else if (type === 'date') {
      start = start.startOf('day');
      end = end.endOf('day');
    }

    return [start, end];
  }
  return dates;
};

export const formatDateTimeBackend = (momentDate, type = 'date') => {
  if (!momentDate) return '';
  if (type === 'date') return momentDate.format(DATE_BACKEND_FORMAT);
  if (type === 'month') return momentDate.format('YYYY-MM');
  if (type === 'quarter') {
    const quarter = momentDate.clone().quarter();
    const year = momentDate.clone().format('YYYY');
    return `Q${quarter}-${year}`;
  }
  if (type === 'year') return momentDate.format('YYYY');
};

export const formatDateTime = (momentDate, type = 'date') => {
  if (!momentDate) return '';
  if (type === 'date') return momentDate.format(DATE_FORMAT);
  if (type === 'month') return momentDate.format('MM-YYYY');
  if (type === 'quarter') {
    const quarter = momentDate.clone().quarter();
    const year = momentDate.clone().format('YYYY');
    return `Q${quarter}-${year}`;
  }
  if (type === 'year') return momentDate.format('YYYY');
};
