import { IRouteProps } from '_common/interface/router';
import CourseOrder from '.';
import { COURSE_ORDER_URL } from './url';

export const CourseOrderRouter: IRouteProps[] = [
  {
    key: COURSE_ORDER_URL.list.key,
    path: COURSE_ORDER_URL.list.shortenUrl,
    component: <CourseOrder />,
  },
];
