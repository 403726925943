import { Button, Modal } from 'tera-dls';
import ChartPieSaleOrder from '.';

interface IProps {
  open: boolean;
  type?: any;
  params?: any;
  onClose?: () => void;
}
const ModalChart = (props: IProps) => {
  const { open, type, params, onClose } = props;
  return (
    <Modal
      destroyOnClose
      closeIcon={false}
      closable
      onCancel={onClose}
      className="xl:w-[1200px]"
      open={open}
      centered={true}
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <ChartPieSaleOrder
        type={type}
        onClose={onClose}
        params={params}
        chartClassName="!h-[calc(100vh-200px)]"
      />
    </Modal>
  );
};

export default ModalChart;
