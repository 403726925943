import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TableTera from '_common/dof/TableTera';
import _ from 'lodash';
import ProductApi from 'pages/SaleManagement/Product/api';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import { Key, useEffect, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import {
  AnyObject,
  Description,
  formatCurrency,
  Image,
  Modal,
  notification,
  PaginationProps,
  TableRowSelection,
} from 'tera-dls';
import Searching from './Searching';
import { CATEGORY_PARENT_KEY } from 'pages/SaleManagement/Category/constants';
import CourseGiftApi from 'pages/CourseManagement/Course/api/Gift';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import CourseProductApi from 'pages/CourseManagement/Course/api/Product';
import imageDefault from 'styles/images/image-default.png';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
  onSubmit: (val: any) => void;
  selectedValue?: any;
  defaultKey?: keyof typeof CATEGORY_PARENT_KEY;
  mode?: 'create' | 'update';
  courseId?: number;
  type: 'gifts' | 'tool' | 'device' | 'ingredient';
}

const ProductModal = (props: IProps) => {
  const {
    open,
    onClose,
    selectedValue = [],
    onSubmit,
    defaultKey,
    mode,
    courseId,
    type,
  } = props;
  const [params, setParams] = useState<any>({ limit: 10, page: 1 });
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const queryClient = useQueryClient();

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-product-list', params, selectedValue, defaultKey],
    () =>
      ProductApi.getList({
        page: 1,
        limit: 10,
        status: 'active',
        except_id: selectedValue?.map((item) => item?.id)?.join(','),
        ...params,
        key_default: defaultKey,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 150,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              defaultImage={imageDefault}
              alt={''}
              imageClassName="object-cover"
              src={record?.image_url}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1 ">
            <p className="text-[#1C64F2]">{val}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'category',
      width: 120,
      render: (val) => val?.name,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 120,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'unit_price',
      width: 120,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Giá khuyến mãi',
      dataIndex: 'promotional_price',
      width: 120,
      render: (val) => (val || val === 0) && formatCurrency(val ?? 0),
    },
  ];

  const handleSearch = ({ keyword }): void => {
    setParams((prev = {}) => ({ ...prev, keyword, page: 1 }));
  };

  const rowSelection: TableRowSelection<AnyObject> = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, record) => {
      setSelectedRows((prev) =>
        _.uniqBy([...prev, ...record], 'id').filter(
          (i) => !!i && selectedRowKeys?.includes(i?.id),
        ),
      );
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams((prev = {}) => ({ ...prev, limit: pageSize, page: page }));
  };

  const { mutate: mutateCreate } = useMutation(
    (variables: any) =>
      type == 'gifts'
        ? CourseGiftApi.create(variables)
        : CourseProductApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-gift-list']);
          queryClient.invalidateQueries([`get-course-${type}-list`]);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  return (
    <>
      <Modal
        open={open}
        title={'Danh sách sản phẩm'}
        closeIcon={false}
        onCancel={onClose}
        okText="Đồng ý"
        className="!w-[90%] xmd:!w-[80%]"
        onOk={() => {
          if (mode == 'update') {
            mutateCreate({
              params: {
                course_id: courseId,
                gifts: selectedRows?.map((item) => ({
                  id: item.id,
                  quantity: 1,
                })),
                products: selectedRows?.map((item) => ({
                  id: item.id,
                  type,
                })),
              },
            });
          }
          onSubmit && onSubmit(selectedRows);
          onClose();
        }}
        cancelText="Hủy"
      >
        <div className="flex gap-2 mb-2.5 items-center">
          <Searching onSearch={handleSearch} />{' '}
          <Description
            className="grid-cols-1 flex mb-0 italic"
            labelClassName="italic"
            label="Đã chọn: "
          >
            {selectedRowKeys?.length}
          </Description>
        </div>
        <TableTera
          rowKey={'id'}
          loading={isFetching}
          data={response?.data ?? []}
          pagination={{
            onChange: handleChangePage,
            total: response?.total || 0,
            current: response?.current_page,
            pageSize: response?.per_page,
            to: response?.to,
            from: response?.from,
          }}
          columns={columns}
          rowSelection={rowSelection}
          className="center-table"
          onRow={(record: any) => ({
            onClick: () => {
              if (!selectedRowKeys?.includes(record?.id)) {
                setSelectedRows((pre) => [...(pre ?? []), record]);
                setSelectedRowKeys((pre) => [...pre, record?.id]);
                return;
              }
              const filterData = selectedRows?.filter(
                (item) => item?.id !== record?.id,
              );
              const filterId = filterData.map((item) => item?.id);
              setSelectedRows(filterData);
              setSelectedRowKeys(filterId);
            },
            className: selectedRowKeys.includes(record?.id) && '!bg-[#e8f5ff]',
          })}
        />
      </Modal>
    </>
  );
};

export default ProductModal;
