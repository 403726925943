import { Modal } from 'tera-dls';
import GoogleDriveVideo from './GoogleDriveVideo';
import NormalVideo from './NormalVideo';
import YoutubeVideo from './YoutubeVideo';

const ModalPlayVideo = ({ open, onClose, url, type }) => {
  const videoRender = {
    uploaded_video: <NormalVideo url={url} />,
    youtube_link: <YoutubeVideo url={url} />,
    gg_drive_link: <GoogleDriveVideo url={url} />,
  };

  return (
    <Modal
      open={open}
      title={''}
      closeIcon={false}
      cancelText="Hủy"
      className="w-full"
      onCancel={() => {
        onClose();
      }}
      footer={<></>}
      modalRender={() => (
        <div className="rounded-xl overflow-hidden">{videoRender[type]}</div>
      )}
    ></Modal>
  );
};

export default ModalPlayVideo;
