import CopyClipboardText from '_common/component/CopyClipboardText';
import VideoBackground from '_common/component/VideoBackground';
import { useRef, useState } from 'react';
import DriveIcon from 'styles/images/Icons/DriveIcon';
import YoutubeIcon from 'styles/images/Icons/YoutubeIcon';
import customTwMerge from 'tailwind-merge.config';
import {
  ChevronDownOutlined,
  ChevronUpOutlined,
  PencilSquareOutlined,
  TrashOutlined,
} from 'tera-dls';

const Item = ({ item, onEdit, onDelete }) => {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);

  return (
    <div
      key={item?.id}
      className="flex flex-col rounded-[10px] border border-blue-500 p-2.5 py-4  text-gray-700"
    >
      <div className="flex justify-between">
        <p className="font-medium text-[14px]">{item?.name}</p>
        <span className="flex gap-2.5">
          <PencilSquareOutlined
            className="size-4 text-green-500 cursor-pointer"
            onClick={onEdit}
          />
          <TrashOutlined
            className="size-4 text-red-500 cursor-pointer"
            onClick={onDelete}
          />
        </span>
      </div>
      {!!item?.content && (
        <>
          <div
            className={customTwMerge(
              'mt-2.5 break-word text-gray-500',
              open && 'text-blue-600 ',
            )}
          >
            <div
              className="inline-block"
              onClick={(e) => {
                setOpen((prev) => !prev);
                e.stopPropagation();
              }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span className="line-clamp-1 text-[13px] leading-[14px]">
                  Nội dung
                </span>
                <span>
                  {!open ? (
                    <ChevronDownOutlined className="w-4" strokeWidth={2} />
                  ) : (
                    <ChevronUpOutlined className="w-4" />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div ref={ref}>
            <div
              className={customTwMerge(
                'pr-2.5 w-full duration-300 overflow-hidden',
                //   open && 'mb-2.5',
              )}
              style={{
                ...(open
                  ? {
                      // height: height < 100 ? height + 20 : height,
                      // maxHeight: height < 100 ? height + 20 : height,
                    }
                  : {
                      height: 0,
                      maxHeight: 0,
                      visibility: 'hidden',
                    }),
              }}
            >
              <div>
                <div className={'w-full tailwind-preflight'}>
                  <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {item?.link_url_type == 'youtube_link' && !!item?.link_url?.url && (
        <div className="flex gap-2 items-center mt-2.5">
          <YoutubeIcon />
          <span
            onClick={() => {
              window.open(item?.link_url?.url);
            }}
            className="text-blue-500 cursor-pointer hover:underline"
          >
            {item?.link_url?.url}
          </span>
          <CopyClipboardText
            valueCopy={item?.link_url?.url}
            className="text-green-500"
          />
        </div>
      )}
      {item?.link_url_type == 'gg_drive_link' && !!item?.link_url?.url && (
        <div className="flex gap-2 items-center mt-2.5">
          <DriveIcon />{' '}
          <span
            onClick={() => {
              window.open(item?.link_url?.url);
            }}
            className="text-blue-500 cursor-pointer hover:underline"
          >
            {item?.link_url?.url}
          </span>
          <CopyClipboardText
            valueCopy={item?.link_url?.url}
            className="text-green-500"
          />
        </div>
      )}
      {!!item?.link_url?.url && (
        <div className="mt-2.5">
          <VideoBackground
            value={{
              url: item?.link_url?.url,
            }}
            isView
            type={item?.link_url_type}
          />
        </div>
      )}
    </div>
  );
};

export default Item;
