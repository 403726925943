import ImageBackground from '_common/component/ImageBackground';
import { IFileUpload } from '_common/interface';
import { useEffect, useState } from 'react';
import { Image } from 'tera-dls';
type ListImageProductProps = {
  thumbnail: IFileUpload;
  defaultProductImages?: any[];
};
const ListImageProduct = ({
  defaultProductImages,
  thumbnail,
}: ListImageProductProps) => {
  const [listImage, setListImage] = useState<IFileUpload[]>([]);

  useEffect(() => {
    if (!!defaultProductImages?.length) {
      const mapImages = defaultProductImages.map((image) => ({
        id: image.id,
        name: image.id,
        url: image?.image_url ?? '',
      }));
      setListImage(mapImages);
    } else {
      setListImage([]);
    }
  }, [defaultProductImages]);

  return (
    <div className="flex flex-col gap-y-5">
      <div>
        <h3 className="mb-2.5">Ảnh bìa</h3>
        <ImageBackground
          isView
          value={thumbnail}
          imageClassName="h-[100px] w-[100px] object-cover"
        />
      </div>
      <div>
        <h3 className="mb-2.5">Ảnh chính</h3>
        <div className="flex gap-2.5 flex-wrap">
          {listImage.map((image, key) => (
            <div className="relative" key={image.id || key}>
              <Image
                key={image.id}
                src={image.url}
                imageClassName="h-[100px] w-[100px] object-cover"
                borderRadius={10}
                alt={image.name}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListImageProduct;
