import { IRouteProps } from '_common/interface/router';
import { REFUND_URL } from './url';
import Refund from '.';
import RefundDetail from './containers/Detail';

export const RefundRouter: IRouteProps[] = [
  {
    key: REFUND_URL.list.key,
    path: REFUND_URL.list.shortenUrl,
    component: <Refund />,
  },
  {
    key: REFUND_URL.detail.key,
    path: REFUND_URL.detail.shortenUrl,
    component: <RefundDetail />,
  },
];
