import React from 'react';
import { InputNumberProps, InputNumber as InputNumberTera } from 'tera-dls';
import { Controller } from 'react-hook-form';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import classNames from 'classnames';

const InputNumber = React.memo(({ ...props }: InputNumberProps) => {
  const { form } = useTeraForm();
  const { item, config, rules } = useTeraFormItem();
  const { control } = form;

  return (
    <Controller
      {...item}
      control={control}
      defaultValue={null}
      rules={rules}
      render={({ field }) => (
        <InputNumberTera
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
          data-object_type={item?.object_type}
          data-object_id={item?.object_id}
          placeholder="Vui lòng nhập"
          {...field}
          {...props}
          {...config?.field}
          onChange={(val: any) => {
            field?.onChange?.(val);
            props?.onChange?.(val);
          }}
          className={classNames('w-full', {
            [props?.className]: true,
            [config?.class_name]: true,
          })}
        />
      )}
    />
  );
});

export default InputNumber;
