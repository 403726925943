import { useNavigate, useParams } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, Spin } from 'tera-dls';
import { AFFILIATE_OVERVIEW_URL } from '../../url';
import Order from './Order';
import Overview from './Overview';
import TransactionHistory from './TransactionHistory';
import { useQuery } from '@tanstack/react-query';
import AffiliateApi from '../../api';

const AffiliateOverviewDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useQuery(
    ['get-detail-affiliate', id],
    () => AffiliateApi.getDetail({ id }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  console.log('data', data);

  return (
    <>
      <Spin spinning={false}>
        <div className="tera-page-form !gap-0">
          <div className="page-header-sticky bg-[#F3F3F9]">
            <div className="page-header-v2">
              <div className="page-header-v2__breadcrumb">
                <div
                  className="page-header__breadcrumb-back cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <ArrowSmallLeftSolid className="h-6 w-6" />
                </div>
                <Breadcrumb
                  separator={'/'}
                  containerItemClassName="text-sm"
                  items={[
                    {
                      title: (
                        <a className="text-blue-400 hover:text-blue-600">
                          Danh sách tiếp thị liên kết
                        </a>
                      ),
                      onClick: () => navigate(AFFILIATE_OVERVIEW_URL.list.path),
                    },
                    {
                      title: (
                        <span>
                          Chi tiết người giới thiệu{' '}
                          <b>{data?.user_current?.full_name}</b>
                        </span>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className=" page-content-v2 rounded-md px-2.5 h-full mx-2.5 !mb-2.5 gap-y-2.5">
            <Overview />
            <TransactionHistory />
            <Order />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default AffiliateOverviewDetail;
