import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import CourseScheduleApi from 'pages/CourseManagement/Course/api/Schedule';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import { TParamsApiDof } from '../../interfaces';
import SelectEntity from '../SelectEntity';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '_common/constants/common';

export interface SelectDataTypeProps extends SelectProps {
  paramsApi?: TParamsApiDof;
  optionCustom?: OptionProps[];
  isCheckAll?: boolean;
}

const SelectCourseSchedule = forwardRef<HTMLSelectElement, SelectDataTypeProps>(
  (
    { isCheckAll = false, optionCustom = [], paramsApi, mode, ...props },
    ref,
  ) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data } = useQuery(
      ['get-course-schedule-list', searchDebounce, paramsApi],
      () => {
        return CourseScheduleApi.getList({
          page: 1,
          limit: 10,
          keyword: searchDebounce,
          ...paramsApi,
        });
      },
      {
        enabled: !props?.disabled,
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const optionColumnConfig: OptionProps[] = useMemo(() => {
      const options = data?.data?.map((item) => ({
        label: `${moment(item?.date).format(DATE_FORMAT)} - ${moment(
          item?.start_time,
          TIME_FORMAT,
        ).format(TIME_FORMAT)} - ${moment(item?.end_time, TIME_FORMAT).format(
          TIME_FORMAT,
        )}`,
        value: item?.id,
      }));
      return options || [];
    }, [data]);

    return (
      <SelectEntity
        ref={ref}
        mode={mode}
        allowClear
        showSearch
        labelInValue
        options={[...optionCustom, ...optionColumnConfig]}
        searchValue={search}
        onSearch={setSearch}
        isCheckAll={isCheckAll}
        placeholder="Vui lòng chọn"
        {...props}
      />
    );
  },
);

export default SelectCourseSchedule;
