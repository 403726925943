export const CASH_RECEIPT_TYPE = {
  other: 'Thu khác',
  customer: 'Thu tiền khách hàng',
  funds: 'Nộp quỹ',
};

export const EXPENSE_VOUCHER_TYPE = {
  other: 'Chi khác',
  customer: 'Trả tiền khách hàng',
  funds: 'Xuất quỹ',
};

export const CHART_TYPE = {
  receipt_customer: {
    title: 'Thu tiền khách hàng',
    key: 'customer',
    parent: 'receipt',
  },
  payment_customer: {
    title: 'Trả tiền khách hàng',
    key: 'customer',
    parent: 'payment',
  },
  receipt_funds: {
    title: 'Nộp quỹ',
    key: 'funds',
    parent: 'receipt',
  },
  payment_funds: {
    title: 'Xuất quỹ',
    key: 'funds',
    parent: 'payment',
  },
  payment_others: {
    title: 'Chi khác',
    key: 'other',
    parent: 'payment',
  },
  receipt_others: {
    title: 'Thu khác',
    key: 'other',
    parent: 'receipt',
  },
};

export const CHART_TYPE_COLOR = {
  receipt_customer: '#F98080',
  payment_customer: '#E3A008',
  receipt_funds: '#31C48D',
  payment_funds: '#76A9FA',
  receipt_others: '#F17EB8',
  payment_others: '#AC94FA',
};

export const PAYMENT_METHOD_TYPE = {
  transfer: 'Chuyển khoản',
  cash: 'Tiền mặt',
  // wallet: 'Ví SUMI',
  // momo: 'Ví điện tử',
};
export const PAYMENT_METHOD_TYPE_TEXT = {
  transfer: 'Chuyển khoản',
  cash: 'Tiền mặt',
  wallet: 'Ví SUMI',
  momo: 'Ví điện tử',
};

export const PAYMENT_TYPE = {
  payment: 'Thanh toán',
  deposit: 'Nạp tiền',
  withdraw: 'Rút tiền',
};
