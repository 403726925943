import { useStores } from '_common/hooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import LectureContent from './LectureContent';

const Exercise = ({}, ref) => {
  const {
    course: { exercise, setExercise, nextStep },
  } = useStores();

  const [data, setData] = useState<any>([]);
  const [selectedLecture, setSelectedLecture] = useState<any>();

  useEffect(() => {
    if (selectedLecture == undefined && data?.length > 0) {
      setSelectedLecture(data?.[0]?.id);
    }
  }, [data, selectedLecture]);

  useEffect(() => {
    exercise && setData(exercise);
  }, [exercise]);

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => {
        setExercise(data);
        nextStep();
      },
    };
  });

  const handleUpdateLecturer = (values) => {
    setData((prev) => {
      let newData;
      if (!!values?.id) {
        newData = prev.map((i) =>
          i.id == values?.id ? { ...i, name: values?.name } : i,
        );
      } else {
        newData = [...(prev ?? []), { ...values, id: Date.now() }];
      }
      setExercise(newData);
      return newData;
    });
  };

  const handleSelectedLecturer = (id) => {
    setSelectedLecture(id);
  };

  const handleDeleteLecturer = (id) => {
    if (id == selectedLecture) {
      setSelectedLecture(undefined);
    }

    setData((prev) => {
      const newData = prev.filter((i) => i.id != id);
      setExercise(newData);
      return newData;
    });
  };

  const handleUpdateContent = (id, values) => {
    setData((prev) => {
      const clonedData = _.cloneDeep(prev);
      const index = clonedData?.findIndex((item) => item?.id === id);
      if (index !== -1) {
        const lessonIndex = clonedData?.[index]?.lessons?.findIndex(
          (item) => item.id === values?.id,
        );
        if (lessonIndex != undefined && lessonIndex !== -1) {
          const oldLessonsItem =
            clonedData[index]?.lessons?.[lessonIndex] ?? {};

          clonedData[index].lessons[lessonIndex] = {
            ...oldLessonsItem,
            ...values,
          };
        } else {
          clonedData[index] = {
            ...clonedData?.[index],
            lessons: [
              ...(clonedData?.[index]?.lessons ?? []),
              { ...values, id: Date.now() },
            ],
          };
        }
      }
      setExercise(clonedData);
      return clonedData;
    });
  };

  const handleDeleteContent = (parentId, id) => {
    setData((prev) => {
      const clonedData = _.cloneDeep(prev);
      const index = clonedData?.findIndex((item) => item?.id === parentId);
      if (index !== -1) {
        const newLessons = clonedData[index]?.lessons.filter(
          (i) => i.id !== id,
        );
        clonedData[index].lessons = newLessons;
      }
      setExercise(clonedData);

      return clonedData;
    });
  };

  return (
    <>
      <h4 className="text-blue-500 font-medium mb-5 sticky top-0 right-0 z-[55] bg-white pb-1">
        Thêm bài học
      </h4>
      <LectureContent
        {...{
          mode: 'create',
          value: data,
          selectedValue: selectedLecture,
          onSelectedLectureValue: handleSelectedLecturer,
          onUpdateLectureData: handleUpdateLecturer,
          onDeleteLecture: handleDeleteLecturer,
          onUpdateContentData: handleUpdateContent,
          onDeleteContent: handleDeleteContent,
        }}
      />
    </>
  );
};

export default observer(forwardRef(Exercise));
