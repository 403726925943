function Menu17(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.42857 7.15385L1 9L4.42857 10.8462M4.42857 7.15385L9 9.61538L13.5714 7.15385M4.42857 7.15385L1 5.30769L9 1L17 5.30769L13.5714 7.15385M13.5714 7.15385L17 9L13.5714 10.8462M13.5714 10.8462L17 12.6923L9 17L1 12.6923L4.42857 10.8462M13.5714 10.8462L9 13.3077L4.42857 10.8462"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Menu17;
