import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const StudentEndpoint = `${endpoint}/bake-teach/student`;
const StudentApi = {
  getList: async (params) =>
    await api
      .get(`${StudentEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${StudentEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  getSummaryDetail: async (params) =>
    await api
      .get(`${StudentEndpoint}/summary-detail`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${StudentEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${StudentEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${StudentEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  changePassword: async (params) =>
    await api
      .put(`${StudentEndpoint}/change-password`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${StudentEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default StudentApi;
