import { IRouteProps } from '_common/interface/router';
import Product from '.';
import ProductDetail from './containers/Detail';
import ProductForm from './containers/Form';
import { PRODUCT_URL } from './url';

export const ProductRouter: IRouteProps[] = [
  {
    key: PRODUCT_URL.list.key,
    path: PRODUCT_URL.list.shortenUrl,
    component: <Product />,
  },
  {
    key: PRODUCT_URL.create.key,
    path: PRODUCT_URL.create.shortenUrl,
    component: <ProductForm />,
  },
  {
    key: PRODUCT_URL.update.key,
    path: PRODUCT_URL.update.shortenUrl,
    component: <ProductForm />,
  },
  {
    key: PRODUCT_URL.detail.key,
    path: PRODUCT_URL.detail.shortenUrl,
    component: <ProductDetail />,
  },
];
