import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import {
  USER_STATUS,
  USER_STATUS_COLOR,
} from 'pages/UserManagement/User/constants';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  Tabs,
  Tag,
  updateQueryParams,
} from 'tera-dls';
import LecturerApi from './api';
import UserFilter from './containers/Filter';
import Searching from './containers/Searching';
import { LECTURER_URL } from './url';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const Lecturer = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const params = _.omit(queryParams, ['status']);
  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-lecturer-list', queryParams],
    () =>
      LecturerApi.getList({
        page: 1,
        limit: 10,
        ...params,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && { status: queryParams?.status }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-lecturer-summary', queryParams],
    () =>
      LecturerApi.getSummaryList({
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(USER_STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{USER_STATUS[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => LecturerApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-lecturer-list']);
          queryClient.invalidateQueries(['get-lecturer-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa giảng viên',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa giảng viên</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => navigate(`${LECTURER_URL.detail.path}/${record?.id}`),
      },
      {
        key: 2,
        label: <a>Sửa</a>,
        onClick: () => navigate(`${LECTURER_URL.update.path}/${record?.id}`),
      },
      ...(record.is_active === 0
        ? [
            {
              key: 7,
              label: <a className="text-red-500">Xóa</a>,
              onClick: () => handleDelete(record?.id, record?.full_name),
            },
          ]
        : []),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'ID giảng viên',
      dataIndex: 'code_lecturer',
      width: 150,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 200,
      render: (value, record) => (
        <HoverQuickView
          name={value}
          avatarUrl={record?.avatar}
          email={record?.email}
          phone={record?.phone}
        >
          <span className="line-clamp-2 text-blue-600">{value}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: 'Email',
      width: 150,
      dataIndex: 'email',
      render: (email) => <p className="line-clamp-2">{email}</p>,
    },
    {
      title: 'Số lượng học viên',
      width: 150,
      dataIndex: 'students_count',
    },
    {
      title: 'Tổng số sao',
      width: 150,
      dataIndex: 'stars_count',
    },
    {
      title: 'Trạng thái',
      width: 150,
      dataIndex: 'is_active',
      render: (val) => (
        <Tag color={USER_STATUS_COLOR[val]}>{USER_STATUS[val]}</Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH GIẢNG VIÊN"
          actionLeftRender={<Searching onSearch={handleSearch} />}
          onClickFilter={() => setOpenFilter(true)}
          middleContent={
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
          }
          filterCount={{
            filter: queryParams,
            params: [
              'from_quantity_out',
              'to_quantity_out',
              'from_count_certification',
              'to_count_certification',
            ],
          }}
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <TableTera
              loading={isFetching}
              wrapperClassName="shadow-none"
              rowKey={'id'}
              className="center-table"
              data={response?.data ?? []}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
              columns={columns}
            />
          </div>
        </HeaderViewListV2>
      </div>

      {openFilter && (
        <UserFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default Lecturer;
