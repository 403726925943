import { DATE_FORMAT } from '_common/constants/common';
import UploadFiles from '_common/dof/UploadFiles';
import { renderDescription } from '_common/utils/utils';
import moment from 'moment';
import { CardContent } from 'pages/SaleManagement/Product/containers/Form';
import {
  GENDER_TYPE,
  USER_STATUS,
  USER_STATUS_COLOR,
} from 'pages/UserManagement/User/constants';
import UserDetail from 'pages/UserManagement/User/containers/Detail';
import { useEffect, useState } from 'react';
import { Col, Image, Row, Tag } from 'tera-dls';
import { IFormModel } from '../..';
import imageDefault from 'styles/images/image-default.png';

export const GraduateIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 8.7L11.5111 4.5L22.0222 8.7L11.5111 12.9L1 8.7Z"
      fill="#3F83F8"
      stroke="#1C64F2"
      stroke-linejoin="round"
    />
    <path
      d="M22.0225 8.755V13.3666"
      stroke="#1C64F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.77832 10.9126V17.1333C5.77832 17.1333 8.18337 19.4999 11.5117 19.4999C14.84 19.4999 17.245 17.1333 17.245 17.1333V10.9126"
      stroke="#1C64F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Overview = ({ dataDetail }) => {
  const [files, setFiles] = useState<any>([]);
  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });

  useEffect(() => {
    if (dataDetail?.attachments_lecturer) {
      setFiles(
        dataDetail?.attachments_lecturer?.map((i) => ({
          media_id: i?.media_id,
          id: i?.id,
          name: i?.name,
          url: i?.url,
        })),
      );
    }
  }, [dataDetail]);

  const data1 = [
    {
      key: '1',
      title: 'Ảnh đại diện',
      value: (
        <div className="flex justify-start mb-2.5">
          <Image
            defaultImage={imageDefault}
            url={dataDetail?.avatar}
            imageClassName="!size-[100px] object-cover"
          />
        </div>
      ),
    },
  ];

  const data2 = [
    {
      key: '22',
      title: 'Mã người dùng',
      value: (
        <span
          className="cursor-pointer text-blue-600"
          onClick={() =>
            setOpenDetailModel({ open: true, value: dataDetail?.id })
          }
        >
          {dataDetail?.code}
        </span>
      ),
    },
    {
      key: '21',
      title: 'ID giảng viên',
      value: dataDetail?.code_lecturer,
    },
    {
      key: '2',
      title: 'Họ và tên',
      value: dataDetail?.full_name,
    },
    {
      key: '3',
      title: 'Ngày sinh',
      value:
        dataDetail?.birthday &&
        moment(dataDetail?.birthday).format(DATE_FORMAT),
    },
  ];

  const data3 = [
    {
      key: '6',
      title: 'Số điện thoại',
      value: dataDetail?.phone,
    },
    {
      key: '7',
      title: 'Giới tính',
      value: GENDER_TYPE[dataDetail?.gender],
    },
    {
      key: '8',
      title: 'Email liên hệ',
      value: dataDetail?.email,
    },
    {
      key: '5',
      title: 'Trạng thái',
      value: (
        <Tag color={USER_STATUS_COLOR[dataDetail?.is_active] as any}>
          {USER_STATUS[dataDetail?.is_active]}
        </Tag>
      ),
    },
  ];

  const data4 = [
    ...(!!dataDetail?.degree && dataDetail?.degree?.length > 0
      ? [
          {
            key: '9',
            title: 'Bằng cấp',
            value: (
              <div className="flex flex-col gap-2">
                {dataDetail?.degree?.map((item) => (
                  <div className="flex gap-2.5 items-center">
                    <GraduateIcon /> <span>{item}</span>
                  </div>
                ))}
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="divide-y-[1px] divide-dashed">
        <Row className=" gap-3 pb-4">
          <Col className="col-span-2 gap-2.5">
            <CardContent title="Thông tin chung">
              <Row className="grid-cols-2 xmd:grid-cols-3 gap-3">
                <Col className="space-y-2">{renderDescription(data1)}</Col>
                <Col className="space-y-2 flex flex-col justify-center">
                  {renderDescription(data2)}
                </Col>
                <Col className="space-y-2">{renderDescription(data3)}</Col>
              </Row>
            </CardContent>
          </Col>
        </Row>
        <Row className="py-4">
          <CardContent title="Tài liệu đính kèm">
            <div className="w-[70%] xmd:w-[40%]">
              {renderDescription(data4)}
            </div>
            <div className="mt-4">
              <UploadFiles
                fileList={files}
                wrapperClassName={'w-full'}
                isCount={false}
                onReceiveFiles={(_, files) => setFiles(files)}
                mode="view"
                onRemove={(fileDelete) => {
                  const newList = files.filter(
                    (file) => file.id !== fileDelete?.id,
                  );
                  setFiles(newList);
                }}
              />
            </div>
          </CardContent>
        </Row>
        <Row className="py-4">
          <CardContent title="Thông tin giảng viên">
            <div
              className={
                'w-full max-h-[600px] overflow-y-auto mx-auto my-0 tailwind-preflight'
              }
            >
              <div
                dangerouslySetInnerHTML={{ __html: dataDetail?.information }}
              />
            </div>
          </CardContent>
        </Row>
      </div>

      {openDetailModel.open && (
        <UserDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
    </>
  );
};

export default Overview;
