import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CourseEndpoint = `${endpoint}/bake-teach/course`;
const CourseApi = {
  getList: async (params) =>
    await api
      .get(`${CourseEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${CourseEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  getSummaryDetail: async (params) =>
    await api
      .get(`${CourseEndpoint}/summary-detail`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CourseEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${CourseEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CourseEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  approve: async ({ id }) =>
    await api
      .put(`${CourseEndpoint}/approve/${id}`)
      .then((result) => result?.data),
  reject: async ({ id }) =>
    await api
      .put(`${CourseEndpoint}/reject/${id}`)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CourseEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default CourseApi;
