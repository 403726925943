import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCYsvd6MjORXIJCkG2AZtbuzG-2aVqgPPo',
  authDomain: 'bake-teach.firebaseapp.com',
  projectId: 'bake-teach',
  storageBucket: 'bake-teach.firebasestorage.app',
  messagingSenderId: '1075125439079',
  appId: '1:1075125439079:web:b25eacf6513969d4e062c2',
  measurementId: 'G-HPDWVNLBLV',
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        'BKi7AeFa3a81ppe0G_uR6fg3ShI3kuvMyNBNCi05VFq_JOHUB7D8A0bZChg3UCLzWZWCL1nBc3OQ6yrB9-AxEAM',
    });
    console.log('currentToken', currentToken);
    return currentToken;
  } catch (error) {
    console.error('An error occurred while retrieving token:', error);
    return null;
  }
};

export const onMessageListener = (callback) => {
  console.log('payload', callback);
  return onMessage(messaging, (payload) => {
    if (callback) {
      callback(payload);
    }
  });
};
