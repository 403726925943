const LecturerIcon = (props) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1514 10.8462V8.69231C13.1514 7.1629 11.9116 5.92308 10.3822 5.92308H9.15144C8.64164 5.92308 8.22837 5.5098 8.22837 5V3.76923C8.22837 2.23983 6.98854 1 5.45913 1H3.92067M5.76683 1H1.76683C1.25703 1 0.84375 1.41328 0.84375 1.92308V16.0769C0.84375 16.5867 1.25703 17 1.76683 17H12.2284C12.7382 17 13.1514 16.5867 13.1514 16.0769V8.38462C13.1514 4.30621 9.84524 1 5.76683 1Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.71098 14.0877L4.01094 14.0929L3.71098 14.0877ZM9.99575 14.0879L10.2957 14.0827V14.0827L9.99575 14.0879ZM9.82923 14.3525L9.95435 14.6251L9.82923 14.3525ZM3.87749 14.3523L3.75236 14.625H3.75236L3.87749 14.3523ZM6.85336 6.7C5.7409 6.7 4.83908 7.60183 4.83908 8.71429H5.43908C5.43908 7.9332 6.07227 7.3 6.85336 7.3V6.7ZM8.86765 8.71429C8.86765 7.60183 7.96582 6.7 6.85336 6.7V7.3C7.63445 7.3 8.26765 7.9332 8.26765 8.71429H8.86765ZM6.85336 10.7286C7.96582 10.7286 8.86765 9.82675 8.86765 8.71429H8.26765C8.26765 9.49537 7.63445 10.1286 6.85336 10.1286V10.7286ZM4.83908 8.71429C4.83908 9.82675 5.7409 10.7286 6.85336 10.7286V10.1286C6.07227 10.1286 5.43908 9.49537 5.43908 8.71429H4.83908ZM6.85336 10.7C4.97206 10.7 3.4433 12.2088 3.41102 14.0826L4.01094 14.0929C4.03758 12.5459 5.29999 11.3 6.85336 11.3V10.7ZM10.2957 14.0827C10.2635 12.2089 8.73471 10.7 6.85336 10.7V11.3C8.40678 11.3 9.6692 12.5459 9.69579 14.093L10.2957 14.0827ZM9.95435 14.6251C10.1657 14.5281 10.2997 14.3153 10.2957 14.0827L9.69579 14.093C9.69569 14.0874 9.69896 14.0822 9.70412 14.0798L9.95435 14.6251ZM6.85348 15.3C7.95895 15.3 9.00963 15.0586 9.95435 14.6251L9.70412 14.0798C8.83631 14.478 7.87084 14.7 6.85348 14.7V15.3ZM3.75236 14.625C4.69715 15.0586 5.74792 15.3 6.85348 15.3V14.7C5.83603 14.7 4.87048 14.478 4.00261 14.0797L3.75236 14.625ZM3.41102 14.0826C3.40702 14.3151 3.54098 14.528 3.75236 14.625L4.00261 14.0797C4.00777 14.082 4.01103 14.0872 4.01094 14.0929L3.41102 14.0826Z"
      fill="white"
    />
  </svg>
);
export default LecturerIcon;
