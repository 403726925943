import Filter from '_common/component/Filter';
import { MAXIMUM_QUANTITY } from '_common/constants/common';
import RangeNumber from '_common/dof/Control/RangeNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourseCategory from '_common/dof/Select/SelectCourseCategory';
import SelectLecturer from '_common/dof/Select/SelectLecturer';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { COURSE_ACTIVITY_TYPE } from '../../constants';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function CourseCategoryFilter({
  open,
  initialValue,
  onClose,
  onFilter,
}: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter({
      ...values,
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      category_id: null,
      from_number: null,
      to_number: null,
      activity_type: null,
      lecturer_id: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;

    const data = _.pick(initialValue, [
      'category_id',
      'from_number',
      'to_number',
      'activity_type',
      'lecturer_id',
    ]);
    form.reset({
      ...data,
    });
  }, [initialValue]);

  const activityTypeOptions = Object.entries(COURSE_ACTIVITY_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Danh mục khóa học" name="category_id">
          <SelectCourseCategory allowClear />
        </FormTeraItem>
        <FormTeraItem label="Giảng viên" name="lecturer_id">
          <SelectLecturer
            paramsApi={{
              include_id: initialValue?.lecturer_id,
            }}
          />
        </FormTeraItem>

        <FormTeraItem name="" label="Học viên đã đăng ký">
          <RangeNumber
            startFormName="from_number"
            endFormName="to_number"
            endInputProps={{
              max: MAXIMUM_QUANTITY,
              min: 0,
              placeholder: 'Đến',
            }}
            startInputProps={{
              max: MAXIMUM_QUANTITY,
              min: 0,
              placeholder: 'Từ',
            }}
          />
        </FormTeraItem>

        <FormTeraItem label="Loại đơn yêu cầu" name="activity_type">
          <Select options={activityTypeOptions} allowClear />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default CourseCategoryFilter;
