import { useMutation, useQuery } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import { useEffect, useState } from 'react';
import {
  Button,
  formatCurrency,
  formatDate,
  Modal,
  notification,
  Spin,
} from 'tera-dls';
import CertificateApi from '../../api';
import {
  COURSE_METHOD,
  COURSE_TYPE,
} from 'pages/CourseManagement/Course/constants';
import UploadFiles from '_common/dof/UploadFiles';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const CertificateDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const [fileList, setFileList] = useState([]);

  const {
    data: dataDetails,
    refetch: fetchData,
    isLoading,
  } = useQuery(
    ['get-certificate-detail', id],
    () => CertificateApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  const { mutate } = useMutation(
    (params: any) => CertificateApi.upload({ params }),
    {
      onSuccess(res: any, variables) {
        if (res?.code === 200) {
          setFileList([variables]);
          notification.success({ message: res?.msg });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const detail = [
    {
      label: 'Ảnh đại diện',
      value: (
        <DefaultImage
          src={dataDetails?.avatar}
          alt={dataDetails?.avatar}
          className="w-[100px] h-[100px] rounded-[10px] overflow-hidden m-auto object-cover"
        />
      ),
    },
    {
      label: 'Họ và tên',
      value: dataDetails?.user?.full_name,
    },
    {
      label: 'Ngày sinh',
      value: formatDate(dataDetails?.user?.birthday),
    },
    {
      label: 'Tên khóa học',
      value: dataDetails?.course?.name,
    },
    {
      label: 'Địa chỉ giao hàng',
      value: dataDetails?.delivery_address?.full_address,
    },
    {
      label: 'Loại khóa học',
      value: COURSE_TYPE[dataDetails?.type_course],
    },
    {
      label: 'Phân loại khóa học',
      value: COURSE_METHOD[dataDetails?.classification],
    },
    {
      label: 'Ngày hoàn thành',
      value: formatDate(dataDetails?.time_complete_learning),
    },
    {
      label: 'Loại kiểu in',
      value: dataDetails?.type_print,
    },
    {
      label: 'Thành tiền',
      value: formatCurrency(dataDetails?.total),
    },
    {
      label: 'Phí vận chuyển',
      value: formatCurrency(dataDetails?.shipping_fee),
    },
    {
      label: 'Tổng thanh toán',
      value: formatCurrency(dataDetails?.final_total),
    },
  ];

  useEffect(() => {
    if (dataDetails?.attachments) {
      setFileList([
        {
          id: dataDetails?.attachments?.media_id,
          media_id: dataDetails?.attachments?.media_id,
          url: dataDetails?.attachments?.url,
          name: dataDetails?.attachments?.name,
        },
      ]);
    }
  }, [dataDetails]);

  useEffect(() => {
    id && fetchData();
  }, [id]);

  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết chứng chỉ'}
        closeIcon={false}
        width={800}
        maskClosable={false}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={isLoading}>
          <div className="flex flex-col gap-y-2.5 mb-2.5">
            {detail.map((item, index) => (
              <div className="flex items-start" key={index}>
                <div className="detail-key">{item.label}</div>
                <div className="detail-value">{item.value}</div>
              </div>
            ))}
          </div>
          <UploadFiles
            accept=".pdf"
            fileList={fileList}
            isSingle
            onReceiveFiles={(file) => {
              const data = {
                ...file,
                certificate_id: id,
              };
              mutate(data);
            }}
            onRemove={() => setFileList([])}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default CertificateDetail;
