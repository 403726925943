import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CourseSectionEndpoint = `${endpoint}/bake-teach/course/section`;
const CourseSectionApi = {
  getList: async (params) =>
    await api
      .get(`${CourseSectionEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getDetail: async (id) =>
    await api
      .get(`${CourseSectionEndpoint}/detail/${id}`)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CourseSectionEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CourseSectionEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CourseSectionEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default CourseSectionApi;
