import { MAXIMUM_CURRENCY, MAXIMUM_QUANTITY } from '_common/constants/common';
import CheckBox from '_common/dof/Control/CheckBox';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { pick } from 'lodash';
import { APPLY_TYPE, PROMOTION_TYPE } from 'pages/Affiliate/Config/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal, Row } from 'tera-dls';

interface IProps {
  open: boolean;
  value?: any;
  onClose: () => void;
}

const History = (props: IProps) => {
  const { open, value, onClose } = props;
  const form = useForm();
  const discount_type = form.watch('discount_type');
  const have_promotional_affiliate = form.watch('have_promotional_affiliate');

  useEffect(() => {
    if (value) {
      const data = pick(value, [
        'apply_for',
        'commission_per',
        'minimum_withdrawal',
        'time_expire_link',
        'discount_type',
        'discount_value',
      ]);
      form.reset({
        ...data,
        have_promotional_affiliate: Boolean(value?.have_promotional_affiliate),
        apply_for_first_time: Boolean(value?.apply_for_first_time),
      });
    }
  }, [value]);

  const typeOptions = Object.entries(APPLY_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  const promotionTypeOptions = Object.entries(PROMOTION_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <>
      <Modal
        open={open}
        title={'Lịch sử cấu hình'}
        closeIcon={false}
        okText="Lưu"
        cancelText="Hủy"
        width={800}
        maskClosable={false}
        footer={
          <Button className="px-5" onClick={onClose}>
            Đóng
          </Button>
        }
      >
        <FormTera form={form}>
          <Row className="grid-cols-2 gap-3">
            <FormTeraItem
              name="minimum_withdrawal"
              label="Số tiền tối thiểu được rút"
            >
              <InputNumber
                placeholder="Vui lòng nhập"
                min={0}
                disabled
                max={MAXIMUM_CURRENCY}
                suffix={<span className="mr-5">đ</span>}
              />
            </FormTeraItem>
            <FormTeraItem
              name="time_expire_link"
              label="Thời gian tồn tại link giới thiệu"
            >
              <InputNumber
                step={1}
                disabled
                min={0}
                max={MAXIMUM_QUANTITY}
                parser={(value) => value.replace(/\.\d*/g, '')}
                placeholder="Vui lòng nhập"
                suffix={<span className="mr-5">Ngày</span>}
              />
            </FormTeraItem>
            <FormTeraItem name="apply_for" label="Áp dụng hoa hồng cho">
              <Select mode="multiple" options={typeOptions} disabled />
            </FormTeraItem>
            <FormTeraItem
              name="commission_per"
              label="% hoa hồng nhận được"
              help={'<100'}
            >
              <InputNumber
                min={1}
                disabled
                max={100}
                suffix={<span className="mr-5">%</span>}
              />
            </FormTeraItem>
            <FormTeraItem
              name="have_promotional_affiliate"
              label=""
              className="col-span-1"
            >
              <CheckBox labelClassName="font-normal text-[13px]" disabled>
                Ưu đãi người nhập mã
              </CheckBox>
            </FormTeraItem>
            <FormTeraItem
              name="apply_for_first_time"
              label=""
              className="col-span-1"
            >
              <CheckBox labelClassName="font-normal text-[13px]" disabled>
                Áp dụng lần đầu
              </CheckBox>
            </FormTeraItem>
            {have_promotional_affiliate && (
              <>
                <FormTeraItem name="discount_type" label="Loại giảm giá">
                  <Select
                    disabled
                    options={promotionTypeOptions}
                    onChangeCustom={(val) => {
                      if (val === 'per') {
                        form.setValue('discount_value', 1);
                        return;
                      }
                      form.setValue('discount_value', 0);
                    }}
                  />
                </FormTeraItem>

                {discount_type == 'per' ? (
                  <FormTeraItem
                    name="discount_value"
                    label="% hoa hồng nhận được"
                    help={'<100'}
                  >
                    <InputNumber
                      min={1}
                      disabled
                      max={100}
                      suffix={<span className="mr-5">%</span>}
                    />
                  </FormTeraItem>
                ) : (
                  <FormTeraItem name="discount_value" label="Giá trị ưu đãi">
                    <InputNumber
                      placeholder="Vui lòng nhập"
                      min={0}
                      disabled
                      max={MAXIMUM_CURRENCY}
                      suffix={<span className="mr-5">đ</span>}
                    />
                  </FormTeraItem>
                )}
              </>
            )}
          </Row>
        </FormTera>
      </Modal>
    </>
  );
};

export default History;
