import { useMutation, useQuery } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectProvince from '_common/dof/Select/SelectProvince';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import TransportConfigApi from '../../api';

interface ModalTransport {
  open: boolean;
  id: number | string;
  onClose: () => void;
  onSuccess?: () => void;
}

function ModalTransport({ open, id, onClose, onSuccess }: ModalTransport) {
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const { isDirty } = form.formState;

  const { data, isLoading } = useQuery(['get-transport-config-list', id], () =>
    TransportConfigApi.getDetail({ id }),
  );

  const { mutate, isLoading: loadingMutate } = useMutation(
    (params: any) =>
      id
        ? TransportConfigApi.update({ id, params })
        : TransportConfigApi.create({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onSuccess && onSuccess();
          onClose && onClose();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutate(values);
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  useEffect(() => {
    if (data) {
      const dataForm = {
        code_city: data?.province?.code,
        expense: data?.expense,
      };
      form.reset(dataForm);
    }
  }, [data]);

  const loading = id && isLoading && loadingMutate;

  return (
    <Modal
      title={id ? 'Sửa khu vực vận chuyển' : 'Thêm khu vực vận chuyển'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <FormTera form={form}>
          <FormTeraItem
            label="Khu vực"
            name="code_city"
            rules={[
              ...(id && data?.all
                ? [{}]
                : [
                    {
                      required: messageValidate.emptyText,
                    },
                  ]),
            ]}
          >
            <SelectProvince
              placeholder={id && data?.all && 'Tất cả tỉnh thành'}
              disabled={id && data?.all}
            />
          </FormTeraItem>
          <FormTeraItem label="Phí vận chuyển" name="expense">
            <InputNumber min={0} max={MAXIMUM_CURRENCY} />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default ModalTransport;
