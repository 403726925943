// import { PAYMENT_METHOD_PERMISSION_KEY } from '_common/constants/permission';

export const PAYMENT_METHOD_URL = {
  list: {
    key: '1',
    path: '/finance/payment-method/list',
    shortenUrl: 'payment-method/list',
  },
  create: {
    key: '2',
    path: '/finance/payment-method/create',
    shortenUrl: 'payment-method/create',
  },
  update: {
    key: '3',
    path: '/finance/payment-method/update',
    shortenUrl: 'payment-method/update/:id',
  },
};
