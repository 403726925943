import { useState } from 'react';
import LectureContent from '../Form/Exercise/LectureContent';

const Lecture = ({ dataDetail }) => {
  const [selectedValue, setSelectedValue] = useState<number | undefined>();

  return (
    <LectureContent
      mode="update"
      courseId={dataDetail?.id}
      selectedValue={selectedValue}
      onSelectedLectureValue={setSelectedValue}
    />
  );
};

export default Lecture;
