import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourseCategory from '_common/dof/Select/SelectCourseCategory';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { COURSE_SYSTEM_STATUS, COURSE_TYPE } from '../../constants';
import RangeNumber from '_common/dof/Control/RangeNumber';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import SelectLecturer from '_common/dof/Select/SelectLecturer';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function UserFilter({ open, initialValue, onClose, onFilter }: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter(values), onClose();
  };

  const handleReset = () => {
    const values = {
      from_price: null,
      to_price: null,
      category_id: null,
      type: null,
      status: null,
      lecturer_id: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;
    form.reset(initialValue);
  }, [initialValue]);

  const typeOptions = Object.entries(COURSE_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  const statusOptions = Object.entries(COURSE_SYSTEM_STATUS).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem name="category_id" label="Danh mục khóa học">
          <SelectCourseCategory
            allowClear={true}
            paramsApi={{ include_id: initialValue?.category_id }}
          />
        </FormTeraItem>
        <FormTeraItem label="Giảng viên" name="lecturer_id">
          <SelectLecturer
            paramsApi={{
              include_id: initialValue?.lecturer_id,
            }}
          />
        </FormTeraItem>
        <FormTeraItem name="type" label="Loại khóa học">
          <Select options={typeOptions} allowClear={true} />
        </FormTeraItem>
        <FormTeraItem name="status" label="Trạng thái hệ thống">
          <Select options={statusOptions} allowClear={true} />
        </FormTeraItem>
        <FormTeraItem name="" label="Giá">
          <RangeNumber
            startFormName="from_price"
            endFormName="to_price"
            endInputProps={{
              max: MAXIMUM_CURRENCY,
              min: 0,
              placeholder: 'Đến khoảng',
            }}
            startInputProps={{
              max: MAXIMUM_CURRENCY,
              min: 0,
              placeholder: 'Từ khoảng',
            }}
          />
        </FormTeraItem>
        <a
          className="text-red-500 text-xxs font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default UserFilter;
