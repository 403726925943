import ImageBackground from '_common/component/ImageBackground';
import { DATE_FORMAT } from '_common/constants/common';
import { renderDescription } from '_common/utils/utils';
import moment from 'moment';
import {
  GENDER_TYPE,
  USER_STATUS,
  USER_STATUS_COLOR,
} from 'pages/UserManagement/User/constants';
import { useState } from 'react';
import { Col, Row, Tag } from 'tera-dls';
import { IFormModel } from '../..';
import UserDetail from 'pages/UserManagement/User/containers/Detail';

const Overview = ({ dataDetail }) => {
  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });

  const data1 = [
    {
      key: '1',
      title: 'Ảnh đại diện',
      value: (
        <div className="flex justify-start mb-2.5">
          <ImageBackground
            isView
            value={{ url: dataDetail?.avatar ?? '' }}
            isShowBtnDelete={false}
            imageClassName="!size-[100px] object-cover"
          />
        </div>
      ),
    },
  ];

  const data3 = [
    {
      key: '1',
      title: 'Mã người dùng',
      value: (
        <span
          className="cursor-pointer text-blue-600"
          onClick={() =>
            setOpenDetailModel({ open: true, value: dataDetail?.id })
          }
        >
          {dataDetail?.code}
        </span>
      ),
    },
    {
      key: '2',
      title: 'Họ và tên',
      value: dataDetail?.full_name,
    },
    {
      key: '3',
      title: 'Ngày sinh',
      value:
        dataDetail?.birthday &&
        moment(dataDetail?.birthday).format(DATE_FORMAT),
    },
  ];

  const data2 = [
    {
      key: '6',
      title: 'Số điện thoại',
      value: dataDetail?.phone,
    },
    {
      key: '7',
      title: 'Giới tính',
      value: GENDER_TYPE[dataDetail?.gender],
    },
    {
      key: '8',
      title: 'Email liên hệ',
      value: dataDetail?.email,
    },
    {
      key: '5',
      title: 'Trạng thái',
      value: (
        <Tag color={USER_STATUS_COLOR[dataDetail?.is_active] as any}>
          {USER_STATUS[dataDetail?.is_active]}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <div className="divide-y-[1px] divide-dashed">
        <Row className=" gap-3 pb-4">
          <Col className="col-span-2 gap-2.5">
            <Row className="grid-cols-2 xmd:grid-cols-3 gap-3">
              <Col className="space-y-2">{renderDescription(data1)}</Col>
              <Col className="space-y-2">{renderDescription(data3)}</Col>
              <Col className="space-y-2">{renderDescription(data2)}</Col>
            </Row>
          </Col>
        </Row>
      </div>
      {openDetailModel.open && (
        <UserDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
    </>
  );
};

export default Overview;
