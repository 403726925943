import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const OrderReportEndpoint = `${endpoint}/bake-teach/report/order`;
const OrderReportApi = {
  getOverview: async () =>
    await api
      .get(`${OrderReportEndpoint}/overview`)
      .then((data) => data?.data?.data),
  getChartPieSaleOrder: async ({ params }) =>
    await api
      .get(`${OrderReportEndpoint}/count-register`, params)
      .then((data) => data?.data?.data),
  getOrderType: async () =>
    await api
      .get(`${OrderReportEndpoint}/count-by-type`)
      .then((data) => data?.data?.data),
  getList: async ({ params }) =>
    await api
      .get(`${OrderReportEndpoint}/list`, params)
      .then((data) => data?.data?.data),
};
export default OrderReportApi;
