import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import FormAddress from '_common/component/FormAddress';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import UserAddressApi from 'pages/UserManagement/User/api/address';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
  userId: number | string;
}

const UserAddressForm = (props: IProps) => {
  const { open, value: id, onClose, userId } = props;

  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const { data: detail, refetch: fetchData } = useQuery(
    ['get-user-address-detail', id],
    () => UserAddressApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (detail) {
      form.reset({
        full_name: detail?.full_name,
        phone: detail?.phone,
        province: detail?.city,
        district: detail?.district,
        ward: detail?.ward,
        address: detail?.address,
        description: detail?.description,
      });
    }
  }, [detail]);

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      id ? UserAddressApi.update(variables) : UserAddressApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-user-address-list']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const handleSubmitForm = (values) => {
    const data = _.omit(values, ['province']);
    mutateAction({
      params: {
        ...data,
        city: values?.province,
        user_id: userId,
      },
      ...(id && { id }),
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={id ? 'Sửa địa chỉ giao hàng' : 'Thêm địa chỉ giao hàng'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={600}
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
      >
        <FormTera form={form} isLoading={false}>
          <FormTeraItem
            label="Họ và tên"
            name="full_name"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input maxLength={255} />
          </FormTeraItem>
          <FormTeraItem
            label="Số điện thoại"
            name="phone"
            rules={[
              {
                required: messageValidate.emptyText,
              },
              {
                pattern: {
                  value: REGEX.PHONE_NUMBER,
                  message: messageValidate.phone,
                },
              },
            ]}
          >
            <Input />
          </FormTeraItem>
          <FormAddress form={form} />
        </FormTera>
      </Modal>
    </>
  );
};

export default UserAddressForm;
