import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvoiceEndpoint = `${endpoint}/bake-teach/finance/invoice`;

const InvoiceApi = {
  getList: async ({ params }) =>
    await api
      .get(`${InvoiceEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getSummaryList: async ({ params }) =>
    await api
      .get(`${InvoiceEndpoint}/summary`, params)
      .then((res) => res.data?.data),
  getSummaryDetail: async (params) =>
    await api
      .get(`${InvoiceEndpoint}/summary-detail`, params)
      .then((data) => data?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvoiceEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  delete: async ({ id }) =>
    await api.delete(`${InvoiceEndpoint}/delete/${id}`).then((res) => res.data),
  approval: async ({ id, params }) =>
    await api
      .put(`${InvoiceEndpoint}/approve/${id}`, params)
      .then((res) => res.data),
};

export default InvoiceApi;
