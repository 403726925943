export const STUDENT_URL = {
  list: {
    key: 'student-list',
    path: '/student-management/student/list',
    shortenUrl: 'student/list',
  },
  detail: {
    key: 'student-detail',
    path: '/student-management/student/detail',
    shortenUrl: 'student/detail/:id',
  },
  update: {
    key: 'student-update',
    path: '/student-management/student/update',
    shortenUrl: 'student/update/:id',
  },
};
