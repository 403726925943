import { IRouteProps } from '_common/interface/router';
import User from '.';
import { USER_URL } from './url';
import UserAddress from './containers/Address';

export const UserRouter: IRouteProps[] = [
  {
    key: USER_URL.list.key,
    path: USER_URL.list.shortenUrl,
    component: <User />,
  },
  {
    key: USER_URL.address.key,
    path: USER_URL.address.shortenUrl,
    component: <UserAddress />,
  },
];
