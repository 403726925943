import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import NotificationApi from 'states/api/notification';
import { Badge, TabItemType, Tabs, Toggle } from 'tera-dls';

interface IHeaderModalMoreView {
  currentTab: string;
  checked: boolean;
  onChangeTab: (key: string) => void;
  onChangeChecked: (check: boolean) => void;
}

const TAB_ITEMS = {
  all: 'Tất cả',
  course: 'Khóa học',
  lecturer: 'Giảng viên',
  affiliate: 'Affiliates',
  finance: 'Tài chính',
};

const HeaderModalMoreView = ({
  currentTab,
  checked,
  onChangeChecked,
  onChangeTab,
}: IHeaderModalMoreView) => {
  const classNameBadge = 'px-1 text-red-500 bg-blue-100';

  const { data: summaryList } = useQuery(
    ['get-summary-notification-list'],
    () => NotificationApi.getSummary({ params: { isUnread: 1 } }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = Object.keys(TAB_ITEMS).map((item) => {
      const count =
        summaryList?.find((i) => i?.module == item)?.total_count || 0;
      return {
        key: item,
        label: (
          <h3 className="tab-table">
            <Badge count={count} className={classNameBadge}>
              <span>{TAB_ITEMS[item]}</span>
            </Badge>
          </h3>
        ),
      };
    });
    return tabs;
  }, [summaryList]);

  return (
    <div className="flex justify-between border-gray-200 border-b">
      <Tabs
        onChange={onChangeTab}
        items={tabItems}
        activeKey={currentTab}
        className="mb-0 border-0"
      />
      <div className="flex items-center gap-2.5 shrink-0">
        <span className="font-medium">Chưa đọc</span>
        <Toggle
          containerClassName="block"
          checked={checked}
          onChange={(value) => onChangeChecked(value.target.checked)}
        />
      </div>
    </div>
  );
};

export default observer(HeaderModalMoreView);
