import React, { useState } from 'react';
import Content from './Content';
import moment from 'moment';
export const StatisticRevenueBySystemContext = React.createContext(null);

function StatisticRevenueBySystem() {
  const [contextParams, setContextParams] = useState<any>({
    user_id: null,
    type_chart: 'date',
    time: [moment().subtract(1, 'months'), moment()],
  });
  return (
    <StatisticRevenueBySystemContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartClassName="!h-[420px]" />
    </StatisticRevenueBySystemContext.Provider>
  );
}

export default StatisticRevenueBySystem;
