import React, { useState } from 'react';
import Content from './content';
import moment from 'moment';
export const LineChartCustomParamContext = React.createContext(null);

const LineChartCustom = () => {
  const startMonth = moment().subtract(1, 'month');
  const endMonth = moment();

  const [contextParams, setContextParams] = useState<any>({
    date: [startMonth, endMonth],
  });

  return (
    <LineChartCustomParamContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartContentClassName="aspect-[2/1]" />
    </LineChartCustomParamContext.Provider>
  );
};

export default LineChartCustom;
