import { IRouteProps } from '_common/interface/router';
import { TRANSPORT_CONFIG_URL } from './url';
import TransportConfig from '.';

export const TransportConfigRouter: IRouteProps[] = [
  {
    key: TRANSPORT_CONFIG_URL.list.key,
    path: TRANSPORT_CONFIG_URL.list.shortenUrl,
    component: <TransportConfig />,
  },
];
