export const PROMOTION_STATUS = {
  0: 'Ngừng hoạt động',
  1: 'Đang hoạt động',
};

export const PROMOTION_STATUS_COLOR = {
  1: 'green03',
  0: 'gray02',
};

export const PROMOTION_TYPE = {
  apply_first_time: 'Áp dụng lần đầu',
  apply_forever: 'Áp dụng nhiều lần',
};

export const PROMOTION_APPLY_TYPE = {
  product: 'Sản phẩm',
  online_course: 'Khóa học online',
  offline_course: 'Khóa học offline',
};
