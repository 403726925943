import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {
  ChartDoughnutProps,
  Col,
  CurrencyDollarOutlined,
  Row,
  UserGroupOutlined,
  formatCurrency,
  formatNumber,
  Tooltip as TeraTooltip,
} from 'tera-dls';
// import { Plugin } from 'chart.js';
import { useQuery } from '@tanstack/react-query';
import GridBox from '_common/component/GridBox';
import NoData from '_common/component/NoData';
import { formatShortCurrency } from '_common/utils';
import { useMemo, useRef, useState } from 'react';
import img_aff_bg_vector_left from 'styles/images/Affiliates/aff_bg_vector_left.png';
import AffiliatesAPI from './_api';
import { TEXT_RECENT_REVENUE } from './constant';
import LineChartCustom from './containers/LineChartCustom';
ChartJS.register(ArcElement, Tooltip, Legend);
const AffiliatesSummaryReport = () => {
  const [hiddenItems, setHiddenItems] = useState([]);
  const chartRef = useRef(null);

  const { data: overview } = useQuery(
    ['get-overview-affiliate'],
    () => {
      return AffiliatesAPI.overview();
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { data: userRatio } = useQuery(
    ['get-user-ratio-affiliate'],
    () => {
      return AffiliatesAPI.userRatio();
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { data: topUser } = useQuery(
    ['get-top-user-affiliate'],
    () => {
      return AffiliatesAPI.topUser();
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { data: recentRevenue } = useQuery(
    ['get-recent-revenue-affiliate'],
    () => {
      return AffiliatesAPI.recentRevenue();
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const dataDoughNut: any = useMemo(() => {
    const arrColor = ['#FF715B', '#6665DD', '#34D1BF'];
    return {
      labels: ['USER', 'AFFILIATES', 'NEW USER'],
      datasets: [
        {
          data: [
            Math.round(
              (userRatio?.old_user_percentage / 100) * userRatio?.total_user,
            ) ?? 0,
            Math.round(
              (userRatio?.affiliate_percentage / 100) * userRatio?.total_user,
            ) ?? 0,
            Math.round(
              (userRatio?.new_user_percentage / 100) * userRatio?.total_user,
            ) ?? 0,
          ],
          backgroundColor: arrColor,
        },
      ],
    };
  }, [userRatio]);

  const optionDoughnut: ChartDoughnutProps['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const data = context.formattedValue;
            return data + ' người';
          },
        },
      },
    },
    cutout: '70%',
  };

  const handleLegendClick = (index) => {
    const chart = chartRef.current;

    if (chart) {
      const meta = chart.getDatasetMeta(0);
      const item = meta?.data[index];
      item.hidden = !item?.hidden;

      setHiddenItems((prev) =>
        item.hidden ? [...prev, index] : prev.filter((i) => i !== index),
      );

      chart?.update();
    }
  };

  return (
    <div className="tera-page">
      <h2 className="font-bold mb-2.5">BÁO CÁO CHUNG</h2>
      <div className=" w-full flex flex-col gap-[30px]">
        <Row className="xl:grid-cols-3 gap-5">
          <Col className="xl:col-span-2 rounded-[16px] relative over-view-left shadow-md flex items-center">
            <img
              className="absolute right-[2%] top-[50%] -translate-y-1/2 w-1/3 hidden xmd:block"
              src={img_aff_bg_vector_left}
              alt="aff_bg_vector_left"
            />
            <div className="relative flex flex-col gap-14 p-[50px]">
              <h2 className="font-medium text-[24px]">Tổng quan</h2>
              <div className="flex items-center gap-2.5">
                <div className="flex flex-col gap-5">
                  <div className="w-[50px] h-[50px] rounded bg-[#ddedf8]">
                    <UserGroupOutlined className="w-full h-full p-2.5 text-blue-800" />
                  </div>
                  <div className="w-[50px] h-[50px] rounded bg-[#ddedf8]">
                    <CurrencyDollarOutlined className="w-full h-full p-2.5 text-blue-800" />
                  </div>
                </div>
                <div className="flex flex-row gap-10">
                  <div className="flex flex-col gap-8">
                    <div className="text-blue-800 flex justify-center flex-col gap-2">
                      <p className="text-[16px]">Người giới thiệu</p>
                      <div className="flex gap-2.5">
                        <span className="text-[24px] font-bold">
                          {formatNumber(overview?.number_of_referrals)}
                        </span>
                      </div>
                    </div>
                    <div className="text-blue-800 flex justify-center flex-col gap-2">
                      <p className="text-[16px]">Tổng doanh thu (VND)</p>
                      <div className="flex gap-2.5">
                        <TeraTooltip
                          title={formatCurrency(overview?.total_revenue)}
                        >
                          <span className="text-[24px] font-bold">
                            {formatShortCurrency(overview?.total_revenue)}
                          </span>
                        </TeraTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-8">
                    <div className="text-blue-800 flex justify-center flex-col gap-2">
                      <p className="text-[16px]">Tổng thu</p>
                      <div className="flex gap-2.5">
                        <TeraTooltip
                          title={formatCurrency(
                            overview?.total_affiliate_commission,
                          )}
                        >
                          <span className="text-[24px] font-bold">
                            {formatShortCurrency(
                              overview?.total_affiliate_commission,
                            )}
                          </span>
                        </TeraTooltip>
                      </div>
                    </div>
                    <div className="text-blue-800 flex justify-center flex-col gap-2">
                      <p className="text-[16px]">Tổng chi</p>
                      <div className="flex gap-2.5">
                        <TeraTooltip
                          title={formatCurrency(overview?.total_expenditure)}
                        >
                          <span className="text-[24px] font-bold">
                            {formatShortCurrency(overview?.total_expenditure)}
                          </span>
                        </TeraTooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="flex flex-col gap-y-2.5 rounded-[16px] shadow-md bg-white py-4 px-8 ">
            <h2 className="font-medium text-2xl">Tỷ lệ người dùng</h2>
            {userRatio ? (
              <>
                <div className="px-[150px] relative">
                  <Doughnut
                    ref={chartRef}
                    className="m-auto !w-full !aspect-square"
                    data={dataDoughNut}
                    options={optionDoughnut}
                  />
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                    <p className="text-2xl font-bold">
                      {userRatio?.total_user}
                    </p>
                    <p className="text-xs font-light uppercase">người dùng</p>
                  </div>
                </div>
                <div className="flex justify-between">
                  {dataDoughNut.labels.map((label, index) => {
                    const percent =
                      (dataDoughNut.datasets[0].data[index] /
                        userRatio?.total_user) *
                      100;
                    const percentShow = Number.isInteger(percent)
                      ? percent
                      : percent.toFixed(2);
                    return (
                      <div
                        key={index}
                        className="flex items-center gap-x-2.5"
                        onClick={() => handleLegendClick(index)}
                      >
                        <span
                          className="px-2.5 py-1 rounded text-white cursor-pointer"
                          style={{
                            backgroundColor:
                              dataDoughNut.datasets[0].backgroundColor[index],
                            textDecoration: hiddenItems.includes(index)
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          {label}
                        </span>
                        <span className="font-bold">{percentShow}%</span>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <NoData />
            )}
          </Col>
          <GridBox className="xl:col-span-3" />
        </Row>
        <Row className="xmd:grid-cols-2 2xl:grid-cols-4 gap-5">
          <Col className="col-span-2 shadow-md bg-white rounded-[16px] max-h-[500px] ">
            <LineChartCustom />
          </Col>
          <Col className="!h-full shadow-md bg-white rounded-[16px] px-8 py-4 h-fit">
            <h3 className="text-2xl font-medium pb-8">Doanh thu gần đây</h3>
            {recentRevenue ? (
              <ul className="flex flex-col gap-2">
                {recentRevenue &&
                  Object.entries(recentRevenue)?.map(([key, value]) => {
                    return (
                      <li className="flex justify-between py-5 xmd:py-5 xl:py-5 border-b-[#E5E7EB] [&:not(:last-child)]:border-b-[1px]">
                        <span>{TEXT_RECENT_REVENUE[key]}</span>
                        <span className="text-[#3F83F8] font-medium">
                          {formatCurrency(value as number)}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div>
                <NoData />
              </div>
            )}
          </Col>
          <Col className="!h-full shadow-md bg-white rounded-[16px] px-8 py-4 h-fit">
            <h3 className="text-2xl font-medium pb-8">Top người dùng</h3>
            {topUser ? (
              <ul className="flex flex-col gap-2">
                {topUser?.map((item) => {
                  return (
                    <li className="flex justify-between py-5 xmd:py-5 xl:py-5  border-b-[#E5E7EB] [&:not(:last-child)]:border-b-[1px]">
                      <span>{item?.full_name}</span>
                      <span className="text-[#3F83F8] font-medium">
                        {formatCurrency(item?.total_amount)}
                      </span>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div>
                <NoData />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AffiliatesSummaryReport;
