import { useQuery } from '@tanstack/react-query';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import classNames from 'classnames';
import { useContext, useMemo, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import {
  ArrowsPointingInOutlined,
  ArrowsPointingOuOutlined,
  ChartLine,
  ChartLineProps,
  RangePicker,
  Tooltip,
  formatDate,
} from 'tera-dls';
import { LineChartCustomParamContext } from '.';
import AffiliatesAPI from '../../_api';
import { MARKETING_GROWTH, MARKETING_GROWTH_COLOR } from '../../constant';
import ModalChart from './Modal';

interface IContentProps {
  type?: 'small' | 'large';
  onClose?: () => void;
  chartClassName?: string;
  chartContentClassName?: string;
}

const Content = ({
  type = 'small',
  onClose,
  chartClassName,
  chartContentClassName,
}: IContentProps) => {
  const { contextParams, setContextParams } = useContext(
    LineChartCustomParamContext,
  );

  const { data: growthMarketing } = useQuery(
    ['get-marketing-growth-affiliate', contextParams],
    () => {
      const params = {
        start_date: formatDate(contextParams?.date[0], DATE_BACKEND_FORMAT),
        end_date: formatDate(contextParams?.date[1], DATE_BACKEND_FORMAT),
      };
      return AffiliatesAPI.growthMarketing({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const dataChart = useMemo(() => {
    const labels = growthMarketing?.map((item) => formatDate(item?.date));
    const datasets = Object.entries(MARKETING_GROWTH)?.map(([key, value]) => {
      const data = growthMarketing?.map((item) => item[key]);
      return {
        label: value,
        data,
        tension: 0.3,
        pointRadius: 5,
        borderColor: MARKETING_GROWTH_COLOR[key],
        backgroundColor: MARKETING_GROWTH_COLOR[key],
        pointBackgroundColor: MARKETING_GROWTH_COLOR[key],
        pointBorderColor: MARKETING_GROWTH_COLOR[key],
      };
    });
    return {
      labels,
      datasets,
    };
  }, [growthMarketing]);

  const optionsLine: ChartLineProps['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.3,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
        suggestedMax: 3,
      },
    },
  };
  const isSmallType = type === 'small';
  const [openModal, setOpenModal] = useState<boolean>();

  return (
    <>
      <div className="flex justify-between px-2.5 py-3 items-center border-b">
        <h3 className="font-medium">Báo cáo tiếp thị</h3>
        <div className="flex gap-2.5">
          <RangePicker
            allowClear={false}
            value={contextParams?.date}
            className="w-[300px] !ring-0 !bg-none !border-[1px] !border-[#fff] !border-b-[#374151] !focus:shadow-none"
            onChange={(value) => setContextParams({ date: value })}
          />
          <div className="flex items-center">
            {isSmallType ? (
              <Tooltip title="Mở rộng">
                <span>
                  <ArrowsPointingOuOutlined
                    className="text-[#374151] size-5 hover:cursor-pointer"
                    onClick={() => setOpenModal(true)}
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Thu gọn">
                <span>
                  <ArrowsPointingInOutlined
                    className="text-[#374151] size-5 hover:cursor-pointer"
                    onClick={onClose}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div
        className={customTwMerge(
          'p-2.5 h-full ',
          chartClassName || 'max-h-[410px]',
        )}
      >
        <ChartLine
          className={classNames('h-full', chartContentClassName)}
          data={dataChart}
          options={optionsLine}
        />
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </>
  );
};

export default Content;
