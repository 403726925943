import { useStores } from '_common/hooks';
import useGroupMenu from '_common/hooks/useGroupMenu';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Bars3Outlined, EllipsisHorizontalOutlined, Icon } from 'tera-dls';
import Header from '../Header';
import MoreMenu from './MoreMenu';
import { IMenu } from './interface';
import DrawerMenu from './DrawerMenu';
import Icons from '_common/component/Icons';
import customTwMerge from 'tailwind-merge.config';
import useClickOutside from '_common/component/Flow/useClickOutside';

export interface MenuProps {
  isExpand?: boolean;
}

const MenuComponent: React.FC<MenuProps> = observer(
  ({ isExpand }: MenuProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
      commonStore: { activeMenu, openMenuMore, setActiveMenu, setOpenMenuMore },
    } = useStores();
    const [openDrawerMenu, setOpenDrawerMenu] = useState<boolean>(false);
    const menus = useGroupMenu();
    const elementRef = useRef();
    const updateActiveMenu = () => {
      const splitUrl = location?.pathname?.split('/');
      const checkMenu: IMenu = menus?.find(
        (obj) => splitUrl.indexOf(obj?.key) > -1,
      );

      setActiveMenu(String(checkMenu?.key));
    };

    useEffect(() => {
      location?.pathname && updateActiveMenu();
    }, [location, menus]);

    const handleActiveMenu = (key: string) => {
      setActiveMenu(key);
    };

    const handleOpenMoreMenu = (): void => {
      setOpenMenuMore(!openMenuMore);
    };

    const handleCloseMoreMenu = () => {
      openMenuMore && setOpenMenuMore(false);
    };

    useClickOutside(elementRef, handleCloseMoreMenu);

    const customItemClasses = (key?: string) => {
      return classNames(
        'tera-menu relative min-w-[80px] flex gap-x-2 items-center px-2 justify-center border-b-2 cursor-pointer hover:bg-blue-50 hover:border-b-2 [&_.menu-icon]:hover:text-blue-600 [&_.menu-title]:hover:text-blue-600',
        activeMenu === key
          ? '[&_.menu-icon]:text-blue-600 [&_.menu-title]:text-blue-600 border-blue-600 bg-blue-50 tera-menu--active'
          : 'border-transparent',
      );
    };
    customItemClasses;
    const sliceMenu = useMemo(() => {
      const menu = [...menus].splice(0, 5);
      const menuRemain = [...menus].splice(5, 100);

      return {
        menu,
        menuRemain,
      };
    }, [menus]);

    const activeOtherMenu = useMemo(() => {
      const menuRemain = sliceMenu?.menuRemain.map((item) => item.key);
      return menuRemain?.includes(activeMenu);
    }, [activeMenu, sliceMenu]);
    return (
      <>
        <div
          ref={elementRef}
          className={`${
            isExpand ? 'xmd:left-[225px]' : 'xmd:left-[50px]'
          } transition-all fixed top-0 right-0 z-[49] px-2.5 h-[45px]  flex items-center justify-between bg-white border-b border-gray-200 text-xxs left-0`}
          style={{ boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)' }}
        >
          {/* <UserCompany /> */}
          <div
            className={'flex items-center gap-x-4 xmd:hidden'}
            onClick={() => setOpenDrawerMenu(true)}
          >
            <Bars3Outlined className="text-blue-800 w-7 h-7 cursor-pointer" />
            <span onClick={() => navigate('/')}>{/* <Logo /> */}</span>
          </div>
          <ul className={'hidden md:flex gap-x-[5px] h-full mr-auto'}>
            <li className="text-[#1C64F2] text-xxs relative min-w-[80px] flex gap-x-2 items-center px-2 justify-center">
              <Icons icon={'menu1'} />
              <span className="font-bold leading-[17px]">Bake Teach</span>
            </li>
            {sliceMenu.menu?.map((item: IMenu) => {
              const { id, key, icon, path, title, iconNode } = item;
              return (
                <li
                  className={customItemClasses(key)}
                  key={id}
                  onClick={() => handleActiveMenu(key)}
                >
                  {icon && (
                    <Icon
                      type={icon}
                      className={`size-5 shrink-0 ${
                        activeMenu === key ? 'text-blue-500' : 'text-gray-500'
                      }`}
                    />
                  )}
                  {iconNode && (
                    <i
                      className={customTwMerge(
                        'size-4 text-white menu-icon',
                        activeMenu === key ? 'text-blue-500' : 'text-gray-500',
                      )}
                    >
                      {iconNode}
                    </i>
                  )}

                  <span className="text-gray-800 text-xxs link-outer-container menu-title">
                    <Link to={path}> {title}</Link>
                  </span>
                </li>
              );
            })}
            {menus?.length > 6 && (
              <li
                className={`${customItemClasses()} ${
                  activeOtherMenu && '!bg-blue-50 !border-b-2 !border-blue-600'
                }`}
                onClick={handleOpenMoreMenu}
              >
                <i className="w-4 h-4 text-gray-600 menu-icon">
                  <EllipsisHorizontalOutlined />
                </i>
                <span
                  className={classNames(
                    'text-gray-800 text-xxs link-outer-container menu-title',
                    { '!text-blue-600': activeOtherMenu },
                  )}
                >
                  Khác
                </span>
              </li>
            )}
          </ul>
          <div />
          <Header />
        </div>
        <MoreMenu menus={sliceMenu.menuRemain} />
        <DrawerMenu
          open={openDrawerMenu}
          onClose={() => setOpenDrawerMenu(false)}
          containerClassName="p-0"
        />
      </>
    );
  },
);

export default MenuComponent;
