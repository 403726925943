import { useEffect, useRef, useState } from 'react';
import { Button, FormItem, Input, Tooltip, XMarkOutlined } from 'tera-dls';
//limit 10

const normalize = (value) => {
  if (typeof value == 'string') {
    return value?.trim();
  }
  return value;
};

const InputData = ({ data, updateData }) => {
  const [value, setValue] = useState<Array<string>>([]);
  const [input, setInput] = useState<any>();
  const [isFirst, setIsFirst] = useState<any>(false);
  const ref = useRef(null);
  const isDisabled = value?.length >= 10;

  const handleSubmitForm = () => {
    ref.current.focus();
    setIsFirst(false);
    if (!input || normalize(input) == '') return setInput('');
    setValue((prev = []) => {
      updateData([...prev, input]);
      return [...prev, input];
    });
    setInput('');
    setIsFirst(true);
  };

  useEffect(() => {
    if (data) {
      setValue(data);
    }
  }, [data]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !isDisabled) {
      handleSubmitForm();
    }
  };

  return (
    <div className=" mb-3">
      <div className="flex gap-2 w-full">
        <div className="flex gap-2 w-full p-1">
          <FormItem
            messages={'Vui lòng nhập'}
            isError={!isFirst && normalize(input) == ''}
            className="w-full"
          >
            <div className="flex gap-2 w-full">
              <Input
                ref={ref}
                value={input}
                onKeyDown={handleKeyDown}
                onChange={(e) => setInput(e.target.value)}
                className="flex-1"
                placeholder="Thêm các bằng cấp liên quan bạn có"
                maxLength={255}
              />
              <Button
                disabled={isDisabled}
                htmlType="button"
                className="font-light"
                onClick={handleSubmitForm}
              >
                Thêm
              </Button>
            </div>
          </FormItem>
        </div>
      </div>
      <div className="gap-1 flex flex-wrap">
        {value?.map((item, index) => (
          <div className="flex gap-2 bg-gray-300 text-sm py-[3px] px-2.5 rounded-full text-gray-600">
            <Tooltip title={item} className="z-[70]">
              <span className="leading-[24px] max-w-[200px] line-clamp-1">
                {item}
              </span>
            </Tooltip>
            <XMarkOutlined
              className="size-6 cursor-pointer"
              onClick={() =>
                setValue((prev = []) => {
                  const data = prev.filter((_, i) => i !== index);
                  updateData(data);
                  return data;
                })
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputData;
