import { useQuery } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  formatDate,
  getQueryParams,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import { COURSE_METHOD, COURSE_TYPE } from '../Course/constants';
import CertificateApi from './api';
import CertificateDetail from './containers/Detail';
import CertificateFilter from './containers/Filter';
import Searching from './containers/Searching';

interface IFormModel {
  open: boolean;
  value?: string | number;
}

const Certificate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-certificate-list', queryParams],
    () =>
      CertificateApi.getList({
        page: 1,
        limit: 10,
        ...queryParams,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: 'Xem',
        onClick: () => setOpenDetailModel({ open: true, value: record?.id }),
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã chứng chỉ',
      dataIndex: 'code',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user',
      render: (value) => (
        <HoverQuickView
          name={value?.full_name}
          email={value?.email}
          phone={value?.phone}
        >
          <span className="line-clamp-1 text-blue-600">{value?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'birthday',
      render: (val) => formatDate(val),
    },
    {
      title: 'Tên khóa học',
      dataIndex: 'course_name',
      render: (_, record) => record?.course?.name,
    },
    {
      title: 'Loại khóa học',
      dataIndex: 'type_course',
      render: (type_course) => COURSE_TYPE[type_course],
    },
    {
      title: 'Phân loại khóa học',
      dataIndex: 'classification',
      render: (classification) => COURSE_METHOD[classification],
    },
    {
      title: 'Ngày hoàn thành',
      dataIndex: 'time_complete_learning',
      render: (time_complete_learning) => formatDate(time_complete_learning),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH CHỨNG CHỈ"
          actionLeftRender={<Searching onSearch={handleSearch} />}
          onClickFilter={() => setOpenFilter(true)}
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <TableTera
              wrapperClassName="shadow-none"
              rowKey={'id'}
              loading={isFetching}
              data={response?.data ?? []}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
              columns={columns}
            />
          </div>
        </HeaderViewListV2>
      </div>

      {openDetailModel.open && (
        <CertificateDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
      {openFilter && (
        <CertificateFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default Certificate;
