const ExitIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2227 2.57812H3.11155C2.8758 2.57813 2.6497 2.67178 2.48301 2.83847C2.31631 3.00517 2.22266 3.23127 2.22266 3.46701V14.1337C2.22266 14.3694 2.31631 14.5955 2.48301 14.7622C2.6497 14.9289 2.8758 15.0226 3.11155 15.0226H10.2227C10.4584 15.0226 10.6845 14.9289 10.8512 14.7622C11.0179 14.5955 11.1115 14.3694 11.1115 14.1337V11.467H6.9471C6.82923 11.467 6.71618 11.4202 6.63283 11.3368C6.54948 11.2535 6.50266 11.1404 6.50266 11.0226C6.50266 10.9047 6.54948 10.7916 6.63283 10.7083C6.71618 10.625 6.82923 10.5781 6.9471 10.5781H11.1115V3.46701C11.1115 3.23127 11.0179 3.00517 10.8512 2.83847C10.6845 2.67178 10.4584 2.57813 10.2227 2.57812Z"
      fill="white"
    />
    <path
      d="M12.5138 8.47406C12.4288 8.40125 12.3194 8.3632 12.2076 8.36752C12.0957 8.37184 11.9896 8.41821 11.9105 8.49736C11.8313 8.57652 11.7849 8.68262 11.7806 8.79448C11.7763 8.90633 11.8143 9.0157 11.8872 9.10072L13.3894 10.5718H11.1094V11.4607H13.3894L11.8872 12.9985C11.8406 13.0383 11.8028 13.0874 11.7762 13.1425C11.7495 13.1977 11.7345 13.2577 11.7321 13.3189C11.7298 13.3801 11.7401 13.4412 11.7624 13.4982C11.7848 13.5552 11.8187 13.607 11.862 13.6503C11.9053 13.6937 11.9571 13.7276 12.0141 13.7499C12.0712 13.7722 12.1322 13.7826 12.1934 13.7802C12.2546 13.7778 12.3147 13.7628 12.3698 13.7362C12.4249 13.7095 12.474 13.6717 12.5138 13.6252L15.1094 11.0474L12.5138 8.47406Z"
      fill="white"
    />
  </svg>
);
export default ExitIcon;
