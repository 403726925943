import { useQuery } from '@tanstack/react-query';
import WithdrawalRequestApi from 'pages/Affiliate/WithdrawalRequest/api';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formatNumber, Spin } from 'tera-dls';

const Overview = () => {
  const { id } = useParams();
  const {
    data: response,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-affiliate-overview-statistic', id],
    () =>
      WithdrawalRequestApi.getStatistic({
        affiliate_id: id,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  return (
    <div className="bg-radian rounded-[30px]">
      <div className="rounded-[30px] p-[30px] over-view-left box-shadow-custom  flex justify-center items-center">
        <div className="flex flex-col w-full gap-10 ">
          <h2 className="font-medium text-[24px]">Tổng quan</h2>
          <div className="w-full">
            <Spin spinning={isFetching}>
              <div className="grid grid-cols-3">
                <div className="col-span-1 text-blue-800 flex justify-center flex-col gap-2.5">
                  <p className="text-[16px] leading-[18px]">Người giới thiệu</p>
                  <div className="flex gap-2.5">
                    <span className="text-[24px] leading-[26px] font-bold">
                      {response?.total_referrer ?? 0}
                    </span>
                  </div>
                </div>
                <div className="col-span-1 text-blue-800 flex justify-center flex-col gap-2.5">
                  <p className="text-[16px]">Tổng hoa hồng </p>
                  <div className="flex gap-2.5">
                    <span className="text-[24px] font-bold">
                      {formatNumber(response?.total_commission ?? 0)}
                    </span>
                  </div>
                </div>
                <div className="col-span-1 text-blue-800 flex justify-center flex-col gap-2.5">
                  <p className="text-[16px]">Số dư hiện tại</p>
                  <div className="flex gap-2.5">
                    <span className="text-[24px] font-bold">
                      {formatNumber(response?.total_commission_remaining ?? 0)}
                    </span>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
