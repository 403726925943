import { Modal, notification, Spin } from 'tera-dls';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DetectMedia from '_common/component/DetectMedia';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import HomeWorkApi from 'pages/CourseManagement/Course/api/HomeWork';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const StudentHomeWorkDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const form = useForm();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-homework-detail', id],
    () => HomeWorkApi.getDetail({ id }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) => HomeWorkApi.scoring(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-homework-list']);

          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const handleSubmitForm = (values) => {
    mutateAction({ id, params: values });
  };

  useEffect(() => {
    if (response) {
      form.reset({
        point: response?.point,
      });
    }
  }, [response]);

  useEffect(() => {
    id && refetch();
  }, [id]);

  return (
    <>
      <Modal
        open={open}
        title="Chi tiết nộp bài"
        closeIcon={false}
        maskClosable={false}
        width={600}
        onCancel={handleCancel}
        onOk={handleSubmit(handleSubmitForm)}
        okText="Chấm điểm"
        cancelText="Đóng"
      >
        <Spin spinning={isFetching || isLoading}>
          <FormTera form={form} onSubmit={handleSubmitForm}>
            <FormTeraItem label="Chấm điểm" name="point">
              <InputNumber min={0} max={10} />
            </FormTeraItem>
            <FormTeraItem label="Nội dung nộp">
              <div className="flex gap-2.5 items-center flex-wrap">
                {response?.files?.map((file) => (
                  <DetectMedia
                    src={file?.url}
                    type={file?.type_file}
                    className="w-[80px] h-[80px] rounded overflow-hidden"
                  />
                ))}
              </div>
            </FormTeraItem>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default StudentHomeWorkDetail;
