import { IRouteProps } from '_common/interface/router';
import UserReport from '.';
import { USER_REPORT_URL } from './url';

export const UserReportRouter: IRouteProps[] = [
  {
    key: USER_REPORT_URL.list.key,
    path: USER_REPORT_URL.list.shortenUrl,
    component: <UserReport />,
  },
];
