import { IRouteProps } from '_common/interface/router';
import AffiliateConfig from '.';
import { AFFILIATE_CONFIG_URL } from './url';

export const AffiliateConfigRouter: IRouteProps[] = [
  {
    key: AFFILIATE_CONFIG_URL.list.key,
    path: AFFILIATE_CONFIG_URL.list.shortenUrl,
    component: <AffiliateConfig />,
  },
];
