import CardDetail from '_common/component/CardDetail';
import customTwMerge from 'tailwind-merge.config';
import { Description, formatCurrency, Row } from 'tera-dls';

const DescriptionCustom = ({ label, children }) => {
  return (
    <Description
      label={label}
      className={customTwMerge('grid-cols-5 mb-0')}
      childrenClassName="col-span-4"
      labelClassName="text-clip"
    >
      {children}
    </Description>
  );
};
const CustomerInfo = ({ dataDetail }) => {
  const renderInfo = [
    {
      title: 'Địa chỉ giao hàng',
      value: dataDetail?.shipping_address,
    },
    {
      title: 'Số điện thoại',
      value: dataDetail?.phone,
    },
    {
      title: 'Họ và tên người nhận',
      value: dataDetail?.delivery_address?.full_name,
    },
    {
      title: 'Phí vận chuyển',
      value: formatCurrency(dataDetail?.shipping_fee ?? 0),
    },
  ];
  return (
    <CardDetail title="Thông tin giao hàng" className="h-full">
      <Row className={customTwMerge('grid-cols-1 xl:grid-cols-2 gap-2.5')}>
        {renderInfo.map(({ title, value }) => (
          <div>
            <DescriptionCustom label={title}>{value}</DescriptionCustom>
          </div>
        ))}
      </Row>
    </CardDetail>
  );
};

export default CustomerInfo;
