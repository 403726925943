import { IRouteProps } from '_common/interface/router';
import Course from '.';
import { COURSE_URL } from './url';

export const CourseReportRouter: IRouteProps[] = [
  {
    key: COURSE_URL.list.key,
    path: COURSE_URL.list.shortenUrl,
    component: <Course />,
  },
];
