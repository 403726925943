export const TYPE_INVOICE = {
  refund: 'Hoàn tiền',
  course_commission: 'Hoa hồng khóa học',
  payment: 'Thanh toán',
  deposit: 'Nạp tiền',
  withdraw: 'Rút tiền',
  affiliate_commission: 'Hoa hồng Affiliate',
};

export const STATUS_INVOICE = {
  approved: 'Hoàn thành',
  fail: 'Thất bại',
  // error: 'Báo lỗi',
  not_yet_paid: 'Chưa thanh toán',
};

export const STATUS_INVOICE_COLOR = {
  approved: 'green03',
  fail: 'gray01',
  // error: 'red03',
  not_yet_paid: 'yellow03',
};

export const STATUS_INVOICE_APPROVED = 'approved';

export const INVOICE_METHOD = {
  transfer: 'Chuyển khoản',
  wallet: 'Ví của tôi',
};

export const INVOICE_DETAIL_TAB_KEY = {
  information: 'Thông tin chi tiết',
  'transaction-history': 'Lịch sử giao dịch',
  'cash-receipt': 'Phiếu thu',
  'expense-voucher': 'Phiếu chi',
};
