export const AFFILIATE_OVERVIEW_URL = {
  list: {
    key: 'overview-list',
    path: '/affiliate/overview/list',
    shortenUrl: 'overview/list',
  },
  detail: {
    key: 'overview-detail',
    path: '/affiliate/overview/detail',
    shortenUrl: 'overview/detail/:id',
  },
};
