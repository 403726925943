import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CourseScheduleEndpoint = `${endpoint}/bake-teach/course/schedule`;
const CourseScheduleApi = {
  getList: async (params) =>
    await api
      .get(`${CourseScheduleEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CourseScheduleEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CourseScheduleEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CourseScheduleEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default CourseScheduleApi;
