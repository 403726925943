import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  ChartPie,
  formatCurrency,
  formatDate,
  Spin,
  useDetectDevice,
} from 'tera-dls';
import OrderReportApi from '../../api';
import { useQuery } from '@tanstack/react-query';
import { PORTFOLIO } from 'pages/Dashboard/constants';
import Header from 'pages/Dashboard/Header';
import startUpIdea from 'styles/images/pages/finance/start_up_idea.png';
import ModalChart from './ModalChart';

interface ChartPieCourseOrderProps {
  type?: string;
  chartClassName?: string;
  params?: any;
  onClose?: () => void;
}

export interface ChartPieCourseOrderRefs {
  refetchData?: () => void;
}

const ChartPieCourseOrder = (props, ref) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { type = 'small', chartClassName, params, onClose } = props;
  const { widthScreen } = useDetectDevice();

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-dashboard-course-revenue', params],
    () => {
      const data = {
        start_date: formatDate(params?.date[0], DATE_BACKEND_FORMAT),
        end_date: formatDate(params?.date[1], DATE_BACKEND_FORMAT),
      };
      return OrderReportApi.getChartPieSaleOrder({ params: data });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const total = useMemo(() => {
    if (!data?.revenue) return 0;
    return data?.revenue?.reduce(
      (total, current) => total + current?.total_sum,
      0,
    );
  }, [data]);

  const dataSource = useMemo(() => {
    const labels = data?.revenue
      ? data?.revenue?.map(
          (value: any) => PORTFOLIO?.[value?.classification]?.name,
        )
      : [];
    const colors = data?.revenue
      ? data?.revenue?.map(
          (value: any) => PORTFOLIO?.[value?.classification]?.color,
        )
      : [];

    const datasets: any = {
      data: data?.revenue
        ? data?.revenue?.map((value: any) => value?.total_sum)
        : [],
      backgroundColor: colors,
      borderWidth: 0,
    };
    return {
      labels,
      datasets: [datasets],
    };
  }, [data]);

  const options: any = {
    plugins: {
      datalabels: {
        formatter: function (_, values) {
          return `${Number(
            data?.revenue[values?.dataIndex]?.percentage,
          ).toFixed(1)} %`;
        },
        color: 'white',
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 10,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: true,
      },
      maintainAspectRatio: false,
    },
  };

  const isSmallType = type === 'small';
  const checkData = (data) =>
    data?.datasets?.[0]?.data.some((value) => value !== 0);

  useImperativeHandle(
    ref,
    () => ({
      refetchData() {
        refetch();
      },
    }),
    [],
  );

  return (
    <div className="rounded-[5px] border bg-white h-full overflow-hidden p-[15px]">
      <Header
        title="Doanh thu loại khóa học"
        isSetting={false}
        onZoomOut={() => setOpenModal(true)}
        onZoomIn={() => onClose()}
        isSmallType={isSmallType}
        isRefetch={false}
      />
      <p className="text-[#6B7280] text-[10px]">{`${formatDate(
        params?.date?.[0],
      )} - ${formatDate(params?.date?.[1])}`}</p>

      <p>
        <span className="font-semibold text-green-500">
          {formatCurrency(total)}
        </span>{' '}
      </p>
      <Spin spinning={isLoading || isRefetching}>
        <div className="flex justify-center">
          {!checkData(dataSource) ? (
            <div className="flex flex-col">
              <img src={startUpIdea} className="mb-[60px]" />
              <p className="text-gray-400 font-normal text-center w-full ">
                Chưa có dữ liệu
              </p>
            </div>
          ) : (
            <ChartPie
              key={widthScreen}
              data={dataSource}
              plugins={['data-labels']}
              options={options}
              className={chartClassName}
            />
          )}
        </div>
      </Spin>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
          params={params}
        />
      )}
    </div>
  );
};

export default forwardRef<ChartPieCourseOrderRefs, ChartPieCourseOrderProps>(
  ChartPieCourseOrder,
);
