import { IRouteProps } from '_common/interface/router';
import Course from '.';
import { COURSE_URL } from './url';
import CourseForm from './containers/Form';
import CourseDetail from './containers/Detail';
import CourseUpdate from './containers/Update';

export const CourseRouter: IRouteProps[] = [
  {
    key: COURSE_URL.list.key,
    path: COURSE_URL.list.shortenUrl,
    component: <Course />,
  },
  {
    key: COURSE_URL.create.key,
    path: COURSE_URL.create.shortenUrl,
    component: <CourseForm />,
  },
  {
    key: COURSE_URL.detail.key,
    path: COURSE_URL.detail.shortenUrl,
    component: <CourseDetail />,
  },
  {
    key: COURSE_URL.update.key,
    path: COURSE_URL.update.shortenUrl,
    component: <CourseUpdate />,
  },
];
