import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ImageBackground from '_common/component/ImageBackground';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import CourseCategoryApi from '../../api';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const CourseCategoryForm = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const [image, setImage] = useState<any>();
  const form = useForm();
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const {
    data: detail,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-course-category-detail', id],
    () => CourseCategoryApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (detail) {
      setImage(detail?.file_upload ?? { url: null });
      const data = _.pick(detail, ['name', 'type']);
      form.reset(data);
    }
  }, [detail]);

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      id
        ? CourseCategoryApi.update(variables)
        : CourseCategoryApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-category-list']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
        ...(image && { file_upload: image }),
      },
      ...(id && { id }),
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={id ? 'Sửa danh mục khóa học' : 'Thêm danh mục khóa học'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={500}
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
      >
        <Spin spinning={!!id && isFetching}>
          <FormTera form={form} isLoading={false}>
            <FormTeraItem label="Ảnh đại diện" name="">
              <ImageBackground
                value={image}
                onChange={(val) => {
                  setImage(val);
                }}
                isShowBtnDelete
                imageClassName="!size-[100px] object-cover"
                object_key={'course-category'}
                folder={'bake-teach'}
              />
            </FormTeraItem>
            <FormTeraItem
              label="Tên danh mục"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input maxLength={50} />
            </FormTeraItem>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default CourseCategoryForm;
