import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import React from 'react';
import { RadioProps, Radio as RadioTera } from 'tera-dls';

interface IProps extends RadioProps {
  listOption?: any[];
  handleChange?: (valueChecked: any) => void;
  inline?: boolean;
}

const Radio = React.memo(({ listOption, children, ...props }: IProps) => {
  const { form } = useTeraForm();
  const { register } = form;
  const { item, rules } = useTeraFormItem();
  return (
    <RadioTera.Group
      {...props}
      {...register(item?.name, (rules as any) ?? {})}
      inline={props?.inline}
    >
      {listOption?.map((child) => (
        <RadioTera key={child?.value} value={child?.value}>
          {child?.label}
        </RadioTera>
      ))}
      {children}
    </RadioTera.Group>
  );
});

export default Radio;
