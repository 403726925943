import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CourseProductEndpoint = `${endpoint}/bake-teach/course/product`;
const CourseProductApi = {
  getList: async (params) =>
    await api
      .get(`${CourseProductEndpoint}/list`, params)
      .then((data) => data?.data?.data),

  create: async ({ params }) =>
    await api
      .post(`${CourseProductEndpoint}/create`, params)
      .then((result) => result?.data),

  delete: async ({ id }) =>
    await api
      .delete(`${CourseProductEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default CourseProductApi;
