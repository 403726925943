import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { INVOICE_METHOD, STATUS_INVOICE, TYPE_INVOICE } from '../../constants';

interface InvoiceFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function InvoiceFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: InvoiceFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      start_created: values?.time?.[0]
        ? moment(values?.time[0]).format(DATE_BACKEND_FORMAT)
        : null,
      end_created: values?.time?.[1]
        ? moment(values?.time[1]).format(DATE_BACKEND_FORMAT)
        : null,
    };
    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      transaction_type: null,
      methods: null,
      status: null,
      time: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, [
      'transaction_type',
      'methods',
      'start_created',
      'end_created',
      'status',
    ]);
    const data = {
      ...values,
      time:
        values?.start_created && values?.end_created
          ? [moment(values?.start_created), moment(values?.end_created)]
          : null,
    };
    form.reset(data);
  }, [initialValue]);

  const optionsPaymentType = Object.entries(TYPE_INVOICE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );
  const optionsPaymentMethod = Object.entries(INVOICE_METHOD).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );
  const optionsStatusInvoice = Object.entries(STATUS_INVOICE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select options={optionsPaymentType} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select options={optionsPaymentMethod} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Trạng thái" name="status">
          <Select options={optionsStatusInvoice} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Ngày tạo" name="time">
          <RangePicker />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default InvoiceFilter;
