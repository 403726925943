import customTwMerge from 'tailwind-merge.config';
import { Col, Row } from 'tera-dls';
import Lecture from './Lecture';
import LectureItem from './LectureItem';
import CourseSectionApi from 'pages/CourseManagement/Course/api/Section';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
interface IProps {
  mode?: 'create' | 'update';
  courseId?: number;
  value?: any;
  selectedValue?: number;
  onSelectedLectureValue?: (id: number) => void;
  onUpdateLectureData?: (values: any) => void;
  onDeleteLecture?: (id: number) => void;
  onUpdateContentData?: (id: number, values: any) => void;
  onDeleteContent?: (parentId: number, id: number) => void;
}
const LectureContent = (props: IProps) => {
  const {
    courseId,
    mode = 'create',
    value,
    selectedValue,
    onSelectedLectureValue,
    onUpdateLectureData,
    onDeleteLecture,
    onUpdateContentData,
    onDeleteContent,
  } = props;

  const {
    data: list,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-course-section-list', courseId],
    () => CourseSectionApi.getList({ course_id: courseId }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!courseId,
    },
  );
  const data = mode === 'update' ? list : value;

  useEffect(() => {
    courseId && fetchData();
  }, [courseId]);

  return (
    <>
      <Row
        className={customTwMerge(
          'grid-cols-3 xmd:grid-cols-5 border rounded pb-2 border-gray-100',
          mode == 'update' ? 'max-h-[calc(100vh-190px)] overflow-auto' : '',
        )}
      >
        <Col className="col-span-1">
          <Lecture
            {...{
              mode,
              courseId,
              value: data,
              loading: isFetching,
              selectedValue: selectedValue,
              onSelectedValue: onSelectedLectureValue,
              onUpdateData: onUpdateLectureData,
              onDelete: onDeleteLecture,
            }}
          />
        </Col>
        <Col className="col-span-2 xmd:col-span-4">
          <LectureItem
            {...{
              mode,
              courseId,
              selectedValue: selectedValue,
              value: value?.find((i) => i.id === selectedValue)?.lessons,
              onUpdateData: onUpdateContentData,
              onDelete: onDeleteContent,
              lectureData: data,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default LectureContent;
