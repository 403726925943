import customTwMerge from 'tailwind-merge.config';
import { StarOutlined } from 'tera-dls';

export const renderDescription = (data, className?) =>
  data?.map((item, index) => (
    <div className="flex gap-5 col-span-1" key={item?.key ?? index}>
      <h6
        className={customTwMerge(
          `!text-gray-500 !font-normal detail-key w-full !text-sm`,
          `!max-w-[100px] !min-w-[100px] xmd:!max-w-[170px] xmd:!min-w-[170px]`,
          className,
        )}
      >
        {item?.title}
      </h6>
      <span className=" w-full detail-value !text-gray-700 !font-normal !text-sm">
        {item?.value}
      </span>
    </div>
  ));

export const renderRating = (star: number) => {
  return (
    <div className="flex gap-x-1 text-[#E3A008]">
      {[1, 2, 3, 4, 5].map((rate) =>
        rate <= Number(star) ? (
          <StarOutlined className="size-4 fill-[#E3A008]" />
        ) : (
          <StarOutlined className="size-4" />
        ),
      )}
    </div>
  );
};
