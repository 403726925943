import UploadFiles from '_common/dof/UploadFiles';
import { IFileUpload } from '_common/interface';
import React, { useEffect, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import { Progress, VideoCameraOutlined, XCircleSolid } from 'tera-dls';
import GoogleDriveVideo from './GoogleDriveVideo';
import NormalVideo from './NormalVideo';
import YoutubeVideo from './YoutubeVideo';

interface IProps {
  value?: IFileUpload;
  onChange?: (val: IFileUpload) => void;
  isShowBtnDelete?: boolean;
  videoClassName?: string;
  isView?: boolean;
  type?: 'uploaded_video' | 'gg_drive_link' | 'youtube_link';
  isReview?: boolean;
  size?: 'normal' | 'small' | 'large';
  showThumbnail?: boolean;
  onReceiveFiles?: (file: IFileUpload) => void;
  onRemove?: (file: IFileUpload) => void;
  [key: string]: any;
}

const VideoBackground = (props: IProps) => {
  const {
    onChange,
    value,
    type = 'uploaded_video',
    isShowBtnDelete,
    videoClassName,
    isReview = true,
    isView = false,
    size = 'normal',
    onReceiveFiles,
    onThumbnail,
    onRemove,
    ...restProps
  } = props;
  const [file, setFile] = useState<IFileUpload>();
  const [progress, setProgress] = useState<number>(null);

  useEffect(() => {
    value && setFile(value);
  }, [value]);

  const handleUploadThumbnail = (file: IFileUpload) => {
    onReceiveFiles && onReceiveFiles(file);
    onChange(file);
    setFile(file);
    setProgress(null);
  };

  const handleRemoveThumbnail = () => {
    onRemove && onRemove(file);
    setFile(null);
    onChange(null);
  };

  const nodeButtonDelete = (fn: () => void) => {
    return (
      <>
        <XCircleSolid
          className="cursor-pointer absolute z-10 w-6 right-[-10px] top-[-8px] fill-red-500"
          onClick={fn}
        />
        <div className="absolute -right-0.5 w-2 h-2 bg-white z-3" />
      </>
    );
  };

  const videoRender = {
    direct_link: (
      <NormalVideo
        url={file?.url}
        isView
        isCreate={!isView}
        isReview={isReview}
        size={size}
        videoClassName={videoClassName}
      />
    ),
    uploaded_video: (
      <NormalVideo
        url={file?.url}
        isView
        isCreate={!isView}
        isReview={isReview}
        size={size}
        videoClassName={videoClassName}
      />
    ),
    youtube_link: (
      <YoutubeVideo url={file?.url} isView isReview={isReview} size={size} />
    ),
    gg_drive_link: (
      <GoogleDriveVideo
        url={file?.url}
        isView
        isReview={isReview}
        size={size}
      />
    ),
  };

  if (isView) {
    return (
      <>
        <div className={customTwMerge('relative w-fit')}>
          <div className={customTwMerge('rounded-[10px] overflow-hidden')}>
            {videoRender[type]}
          </div>
        </div>
      </>
    );
  }

  function snapImage(video, url) {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext('2d')
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const image = canvas.toDataURL('image/png');
    const success = image.length > 100000;
    url;
    if (success) {
      setFile((prev = {}) => ({ ...prev, thumbnail: image }));
      onThumbnail && onThumbnail(image, file);
    }

    return success;
  }

  function handleChange(event) {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = function () {
      const blob = new Blob([fileReader.result], { type: file.type });
      const url = URL.createObjectURL(blob);
      const video = document.createElement('video');

      const timeupdate = function () {
        if (snapImage(video, url)) {
          video.removeEventListener('timeupdate', timeupdate);
          video.pause();
        }
      };

      video.addEventListener('loadeddata', function () {
        if (snapImage(video, url)) {
          video.removeEventListener('timeupdate', timeupdate);
        }
      });

      video.addEventListener('timeupdate', timeupdate);
      video.preload = 'metadata';
      video.src = url;
      video.muted = true;
      video.playsInline = true;
      video.play();
    };

    fileReader.readAsArrayBuffer(file);
  }

  return (
    <>
      {file?.url ? (
        <div className={customTwMerge('relative w-fit')}>
          {isShowBtnDelete && nodeButtonDelete(handleRemoveThumbnail)}
          <div className="rounded-[10px] overflow-hidden cursor-pointer bg-gray-200">
            {videoRender[type]}
          </div>
        </div>
      ) : (
        <UploadFiles
          object_id="thumbnail"
          object_key="product"
          folder="product"
          fileList={[file]}
          onReceiveFiles={handleUploadThumbnail}
          isSingle
          maxSize={100}
          isView={false}
          isCount={false}
          accept=".mp4"
          className="max-w-max"
          onChangeFile={handleChange}
          onProgressUpdate={(percentage) => setProgress(percentage)}
          onFailed={() => setProgress(null)}
          {...restProps}
        >
          <button
            className={customTwMerge(
              'w-[100px] h-[100px] border border-dashed border-gray-200 bg-gray-50 rounded-[10px] flex items-center justify-center',
              videoClassName,
            )}
            type="button"
          >
            {progress ? (
              <div className="px-5 w-full">
                <Progress percent={progress} size={5} />
                <p>{progress}%</p>
              </div>
            ) : (
              <VideoCameraOutlined className="w-8 h-8 text-gray-500" />
            )}
          </button>
        </UploadFiles>
      )}
    </>
  );
};

export default React.memo(VideoBackground);
