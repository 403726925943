import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CertificateConfigEndpoint = `${endpoint}/bake-teach/certificate`;
const CertificateConfigApi = {
  getList: async () =>
    await api
      .get(`${CertificateConfigEndpoint}/get-config`)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CertificateConfigEndpoint}/save-config`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CertificateConfigEndpoint}/update-config/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CertificateConfigEndpoint}/delete-config/${id}`)
      .then((result) => result?.data),
};
export default CertificateConfigApi;
