import { IRouteProps } from '_common/interface/router';
import { VIDEO_GUIDE_URL } from './url';
import VideoGuide from '.';

export const VideoGuideRouter: IRouteProps[] = [
  {
    key: VIDEO_GUIDE_URL.list.key,
    path: VIDEO_GUIDE_URL.list.shortenUrl,
    component: <VideoGuide />,
  },
];
