import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import { FormTeraItem } from '_common/dof/FormTera';
import SelectDistrict from '_common/dof/Select/SelectDistrict';
import SelectProvince from '_common/dof/Select/SelectProvince';
import SelectWard from '_common/dof/Select/SelectWard';

interface FormAddressProps {
  form: any;
  dataDetail?: any;
}

function FormAddress({ form }: FormAddressProps) {
  const handleReset = (type: 'province' | 'district' | 'ward') => {
    switch (type) {
      case 'province': {
        form.setValue('province', null);
        form.setValue('district', null);
        form.setValue('ward', null);

        break;
      }
      case 'district': {
        form.setValue('district', null);
        form.setValue('ward', null);
        break;
      }
      case 'ward': {
        form.setValue('ward', null);
        break;
      }
    }
  };

  return (
    <>
      <FormTeraItem
        label="Tỉnh/Thành phố"
        name="province"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <SelectProvince
          onClear={() => {
            handleReset('province');
          }}
          onChangeCustom={() => handleReset('district')}
        />
      </FormTeraItem>
      <FormTeraItem
        label="Quận/Huyện"
        name="district"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <SelectDistrict
          onClear={() => {
            handleReset('ward');
          }}
          paramsApi={{
            parent_key: form.watch('province'),
            include_code: form.watch('province'),
          }}
          // disabled={watch?.country !== codeCountry || !form.watch('province')}
          disabled={!form.watch('province')}
          onChangeCustom={() => handleReset('ward')}
        />
      </FormTeraItem>
      <FormTeraItem
        label="Phường/Xã"
        name="ward"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <SelectWard
          paramsApi={{
            parent_key: form.watch('district'),
            include_code: form.watch('district'),
          }}
          // disabled={watch?.country !== codeCountry || !form.watch('district')}
          disabled={!form.watch('district')}
        />
      </FormTeraItem>
      <FormTeraItem
        label="Địa chỉ cụ thể"
        name="address"
        rules={[
          {
            required: messageValidate.emptySelect,
          },
        ]}
      >
        <Input placeholder="Vui lòng nhập" />
      </FormTeraItem>
    </>
  );
}

export default FormAddress;
