import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import CourseSectionApi from 'pages/CourseManagement/Course/api/Section';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
  onSubmit?: (value) => void;
  name?: string;
  mode?: any;
  courseId?: number;
}

const LectureForm = (props: IProps) => {
  const {
    open,
    value: id,
    onClose,
    onSubmit,
    name,
    mode = 'create',
    courseId,
  } = props;
  const form = useForm();
  const ref = useRef(null);
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <p className="break-word">
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </p>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const { data: detail, refetch: fetchData } = useQuery(
    ['get-course-section-detail', id],
    () => CourseSectionApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id && mode == 'update',
    },
  );

  useEffect(() => {
    if (!!detail || !!name) form.setValue('name', detail?.name ?? name);
  }, [detail, name]);

  useEffect(() => {
    name && form.setValue('name', name);
  }, [name]);

  useEffect(() => {
    id && mode == 'update' && fetchData();
  }, [id, mode]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      id
        ? CourseSectionApi.update(variables)
        : CourseSectionApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-section-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    if (mode == 'update') {
      mutateAction({
        params: {
          ...values,
          course_id: courseId,
        },
        ...(id && { id }),
      });
      return;
    }
    onSubmit({ ...values, ...(id && { id }) });
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        title={id ? 'Sửa phần học' : 'Thêm phần học'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={500}
        maskClosable={false}
        footerClassName="flex !justify-center"
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
      >
        <FormTera form={form} isLoading={false} onSubmit={handleSubmitForm}>
          <FormTeraItem
            label="Tên phần học"
            name="name"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input maxLength={100} ref={ref} />
          </FormTeraItem>
        </FormTera>
      </Modal>
    </>
  );
};

export default LectureForm;
