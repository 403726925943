export const LECTURER_APPLICATION_URL = {
  list: {
    key: 'lecturer-application-list',
    path: '/lecturer-management/lecturer-application/list',
    shortenUrl: 'lecturer-application/list',
  },
  detail: {
    key: 'lecturer-application-detail',
    path: '/lecturer-management/lecturer-application/detail',
    shortenUrl: 'lecturer-application/detail/:id',
  },
};
