import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_BACKEND_FORMAT, TIME_FORMAT } from '_common/constants/common';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  notification,
} from 'tera-dls';
import CourseApi, { default as ProductApi } from '../../api';
import { COURSE_URL } from '../../url';
import Attachment from './Attachment';
import Device from './Device';
import Exercise from './Exercise';
import Gift from './Gift';
import Information from './Information';
import Material from './Material';
import Schedule from './Schedule';
import Tool from './Tool';

const CourseForm = () => {
  const navigate = useNavigate();
  const {
    course: {
      step,
      backStep,
      information,
      clear,
      exercise,
      gift,
      tool,
      device,
      material,
      schedule,
      attachments,
    },
  } = useStores();

  const actionRef = useRef(null);

  const { id, type } = useParams();
  const queryClient = useQueryClient();

  const isOnlineType = type === 'online';

  useEffect(() => {
    return () => clear();
  }, []);

  const { data: detail, refetch: fetchData } = useQuery(
    ['get-course-detail', id],
    () => CourseApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  useEffect(() => {
    if (detail) {
      1;
    }
  }, [detail]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      id ? ProductApi.update(variables) : CourseApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-course-detail']);
          notification.success({
            message: res?.msg,
          });
          clear();
          navigate(COURSE_URL.list.path);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );
  const handleSubmitForm = (step?) => {
    if (step != undefined) {
      let data: any = {
        1: {
          ...information,
          discount_time: information?.discount_time?.[0] && [
            information?.discount_time?.[0]?.format(DATE_BACKEND_FORMAT),
            information?.discount_time?.[1]?.format(DATE_BACKEND_FORMAT),
          ],
          link_url: {
            url: information?.link_url,
          },
        },
        2: {
          sections_study: exercise,
        },
        3: {
          attachments: attachments,
        },
        4: {
          gifts: gift?.map((item) => ({
            id: item?.id,
            quantity: item.quantity,
          })),
        },
        5: {
          device: device?.map((item) => item?.id),
        },
        6: {
          tool: tool?.map((item) => item?.id),
        },
        7: {
          ingredient: material?.map((item) => item?.id),
        },
      };
      if (isOnlineType) {
        if (step == 2 && (!schedule || schedule?.length == 0)) {
          notification.error({
            message: 'Vui lòng khai báo ít nhất 1 thời gian học',
          });
          return;
        }
        data = {
          1: {
            ...information,
            discount_time: information?.discount_time?.[0] && [
              information?.discount_time?.[0]?.format(DATE_BACKEND_FORMAT),
              information?.discount_time?.[1]?.format(DATE_BACKEND_FORMAT),
            ],
            link_url: {
              url: information?.link_url,
            },
          },
          2: {
            schedules: schedule?.map((item) => ({
              date: item?.date.format(DATE_BACKEND_FORMAT),
              start_time: item?.start_time?.format('HH:mm'),
              end_time: item?.end_time?.format('HH:mm'),
              limit_student: item?.limit_student,
            })),
          },
          3: {
            sections_study: exercise,
          },
          4: {
            attachments: attachments,
          },
          5: {
            gifts: gift?.map((item) => ({
              id: item?.id,
              quantity: item.quantity,
            })),
          },
          6: {
            device: device?.map((item) => item?.id),
          },
          7: {
            tool: tool?.map((item) => item?.id),
          },
          8: {
            ingredient: material?.map((item) => item?.id),
          },
        };
      }
      let result = {};

      Object.keys(data)
        ?.filter((key) => key <= step)
        ?.forEach((key) => {
          result = { ...result, ...data[key] };
        });

      mutateAction({
        params: {
          classification: type,
          ...result,
        },
        ...(id && { id }),
      });

      return;
    }

    mutateAction({
      params: {
        ...information,
        classification: type,
        sections_study: exercise,
        gifts: gift?.map((item) => ({ id: item?.id, quantity: item.quantity })),
        attachments: attachments,
        ...(isOnlineType && {
          schedules: schedule?.map((item) => ({
            date: item?.date.format(DATE_BACKEND_FORMAT),
            start_time: item?.start_time?.format(TIME_FORMAT),
            end_time: item?.end_time?.format(TIME_FORMAT),
          })),
        }),
        tool: tool?.map((item) => item?.id),
        device: device?.map((item) => item?.id),
        ingredient: material?.map((item) => item?.id),
        discount_time: information?.discount_time?.[0] && [
          information?.discount_time?.[0]?.format(DATE_BACKEND_FORMAT),
          information?.discount_time?.[1]?.format(DATE_BACKEND_FORMAT),
        ],
        link_url: {
          url: information?.link_url,
        },
        have_limit_student: Number(information?.have_limit_student),
      },
      ...(id && { id }),
    });
  };

  const stepDataOnline = {
    1: <Information ref={actionRef} />,
    2: <Schedule ref={actionRef} />,
    3: <Exercise ref={actionRef} />,
    4: <Attachment ref={actionRef} />,
    5: <Gift ref={actionRef} />,
    6: <Device ref={actionRef} />,
    7: <Tool ref={actionRef} />,
    8: <Material ref={actionRef} />,
  };

  const stepDataOffline = {
    1: <Information ref={actionRef} />,
    2: <Exercise ref={actionRef} />,
    3: <Attachment ref={actionRef} />,
    4: <Gift ref={actionRef} />,
    5: <Device ref={actionRef} />,
    6: <Tool ref={actionRef} />,
    7: <Material ref={actionRef} />,
  };

  return (
    <>
      <div className="tera-page-form !gap-0">
        <div className="page-header-sticky bg-[#FAFAF9]">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="size-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                containerItemClassName="text-sm"
                items={[
                  {
                    title: (
                      <a className="cursor-pointer text-blue-400 italic">
                        Danh sách khóa học
                      </a>
                    ),
                    onClick: () => navigate(COURSE_URL.list.path),
                  },
                  {
                    title: (
                      <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px]">
                        {id
                          ? `Sửa khóa học ${
                              isOnlineType ? 'online' : 'offline'
                            }`
                          : `Thêm khóa học ${
                              isOnlineType ? 'online' : 'offline'
                            }`}
                      </a>
                    ),
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function"></div>
          </div>
        </div>
        <div className="page-content-v2 bg-white shadow-xsm !rounded-xl !gap-1 flex !flex-col px-2.5 h-[calc(100vh-105px)] mx-2.5">
          <div className="overflow-scroll flex-1">
            {isOnlineType ? stepDataOnline[step] : stepDataOffline[step]}
          </div>
          <div>
            <div className="bg-white gap-2.5 w-full text-sm flex justify-end h-full rounded-t rounded-xl flex-1 pr-3">
              <Button
                type="alternative"
                className={customTwMerge(
                  'font-normal border border-blue-500 text-blue-500 px-5',
                  step == 1 && 'hidden',
                )}
                onClick={() => backStep()}
                loading={isLoading}
                disabled={isLoading}
              >
                Quay lại
              </Button>
              <Button
                className={customTwMerge(
                  'font-normal px-5',
                  (step == 1 || step == (isOnlineType ? 8 : 7)) && 'hidden',
                )}
                onClick={() => handleSubmitForm(step)}
                loading={isLoading}
                disabled={isLoading}
              >
                Bỏ qua và lưu
              </Button>
              <Button
                className="font-normal px-5"
                loading={isLoading}
                disabled={isLoading}
                onClick={() => {
                  if (isOnlineType) {
                    if (step == 8) {
                      handleSubmitForm();
                      return;
                    }
                    actionRef?.current?.nextStep?.();
                    return;
                  } else {
                    if (step == 7) {
                      handleSubmitForm();
                      return;
                    }
                    actionRef?.current?.nextStep?.();
                  }
                }}
              >
                {isOnlineType && (step >= 8 ? 'Lưu' : 'Tiếp theo')}
                {!isOnlineType && (step >= 7 ? 'Lưu' : 'Tiếp theo')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(CourseForm);
