import { useQuery } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { DefaultTag } from '_common/shared/utils';
import moment from 'moment';
import UserAddressApi from 'pages/UserManagement/User/api/address';
import { useEffect } from 'react';
import { Button, Description, Modal } from 'tera-dls';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const UserAddressDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;

  const { data: detail, refetch: fetchData } = useQuery(
    ['get-user-address-detail', id],
    () => UserAddressApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);
  detail;
  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết địa chỉ giao hàng'}
        closeIcon={false}
        width={500}
        maskClosable={false}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <div className="grid gap-1">
          <Description label="Họ và tên">{detail?.full_name}</Description>
          <Description label="Số điện thoại">{detail?.phone}</Description>
          <Description label="Địa chỉ cụ thể">{detail?.address}</Description>
          <Description label="Tỉnh/Thành phố">
            {detail?.city_info?.name}
          </Description>
          <Description label="Quận/Huyện">
            {detail?.district_info?.name}
          </Description>
          <Description label="Phường/Xã">{detail?.ward_info?.name}</Description>
          <Description label="Người tạo">
            <HoverQuickView
              // avatarUrl={submitter?.avatar_url}
              // email={submitter?.email}
              // phone={submitter?.phone}
              // code={submitter?.code}
              name={detail?.user_created?.full_name}
            >
              <span className="line-clamp-1 text-blue-600">
                {detail?.user_created?.full_name}
              </span>
            </HoverQuickView>
          </Description>

          <Description label="Ngày tạo">
            {moment(detail?.created_at).format(DATE_TIME_FORMAT)}
          </Description>
          {detail?.user_updated && (
            <>
              <Description label="Người cập nhật">
                <HoverQuickView
                  // avatarUrl={submitter?.avatar_url}
                  // email={submitter?.email}
                  // phone={submitter?.phone}
                  // code={submitter?.code}
                  name={detail?.user_updated?.full_name}
                >
                  <span className="line-clamp-1 text-blue-600">
                    {detail?.user_updated?.full_name}
                  </span>
                </HoverQuickView>
              </Description>

              <Description label="Ngày cập nhật">
                {moment(detail?.updated_at).format(DATE_TIME_FORMAT)}
              </Description>
            </>
          )}
        </div>
        <div className="w-[90px] mt-2">
          {Boolean(detail?.is_default) && <DefaultTag title={'Mặc định'} />}
        </div>
      </Modal>
    </>
  );
};

export default UserAddressDetail;
