import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CourseOrderEndpoint = `${endpoint}/bake-teach/course/invoice`;
const CourseOrderApi = {
  getList: async (params) =>
    await api
      .get(`${CourseOrderEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  calculateDiscountPrice: async (params) =>
    await api
      .get(`${CourseOrderEndpoint}/calculate-discount-price`, params)
      .then((data) => data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${CourseOrderEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CourseOrderEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${CourseOrderEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CourseOrderEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  approve: async ({ id }) =>
    await api
      .put(`${CourseOrderEndpoint}/approve/${id}`)
      .then((result) => result?.data),
  reject: async ({ id, params }) =>
    await api
      .put(`${CourseOrderEndpoint}/cancel/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CourseOrderEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default CourseOrderApi;
