import { IRouteProps } from '_common/interface/router';
import OrderReport from '.';
import { ORDER_REPORT_URL } from './url';

export const OrderReportRouter: IRouteProps[] = [
  {
    key: ORDER_REPORT_URL.list.key,
    path: ORDER_REPORT_URL.list.shortenUrl,
    component: <OrderReport />,
  },
];
