import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourse from '_common/dof/Select/SelectCourse';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import CourseApi from 'pages/CourseManagement/Course/api';
import { COURSE_METHOD } from 'pages/CourseManagement/Course/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem, Input, Modal, notification, Spin } from 'tera-dls';
import CourseActivityApi from '../../api';
import { COURSE_ACTIVITY_TYPE } from '../../constants';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const CourseActivityForm = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const form = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const course_id = form.watch('course_id');
  const activity_type = form.watch('activity_type');

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const {
    data: detail,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-course-activity-detail', id],
    () => CourseActivityApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  const { data: detailCourse } = useQuery(
    ['get-course-detail', course_id],
    () => CourseApi.getDetail(course_id),
    {
      enabled: !!course_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (detail) {
      const data = _.pick(detail, ['activity_type', 'course_id']);
      form.reset({ ...data });
    }
  }, [detail]);

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      id
        ? CourseActivityApi.update(variables)
        : CourseActivityApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-activity-list']);
          queryClient.invalidateQueries(['get-course-activity-summary']);
          queryClient.invalidateQueries(['get-course-activity-detail']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
      },
      ...(id && { id }),
    });
  };

  const methodOptions = Object.entries(COURSE_METHOD).map(([value, label]) => ({
    value,
    label,
  }));

  const typeOptions = Object.entries(COURSE_ACTIVITY_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <>
      <Modal
        open={open}
        title={
          id
            ? 'Sửa yêu cầu hoạt động khóa học'
            : 'Thêm yêu cầu hoạt động khóa học'
        }
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={500}
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
      >
        <Spin spinning={!!id && isFetching}>
          <FormTera form={form} isLoading={false}>
            <FormTeraItem
              label="Loại yêu cầu"
              name="activity_type"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <Select
                options={typeOptions}
                onChange={() => {
                  form.setValue('course_id', null);
                }}
              />
            </FormTeraItem>
            <FormTeraItem
              label="Khóa học"
              name="course_id"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectCourse
                disabled={!form.watch('activity_type')}
                paramsApi={{
                  ...(course_id && { include_id: course_id }),
                  ...(activity_type && { except_type_request: activity_type }),
                }}
              />
            </FormTeraItem>
            <FormTeraItem label="Phân loại khóa học" name="">
              <Select
                options={methodOptions}
                disabled
                placeholder=" "
                value={detailCourse?.classification}
              />
            </FormTeraItem>
            <FormItem label="Giảng viên" isRequired={false}>
              <Input disabled value={detailCourse?.lecturer?.full_name ?? ''} />
            </FormItem>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default CourseActivityForm;
