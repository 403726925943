import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import ActionCUD from '_common/component/TableColumnCustom/ActionCUD';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  MAXIMUM_CURRENCY,
  MAXIMUM_QUANTITY,
} from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { get } from 'lodash';
// import { observer } from 'mobx-react-lite';

import SelectUserAddress from '_common/dof/Select/SelectUserAddress';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import UserAddressApi from 'pages/UserManagement/User/api/address';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';
import {
  Button,
  ChevronDownOutlined,
  Col,
  Image,
  Row,
  Spin,
  formatCurrency,
  notification,
} from 'tera-dls';
import SaleOrderApi from '../../api';
import ProductModal from './ProductModal';
import UserModal from './UserModal';

export interface ISaleOrderFormContentRef {
  onSave: (status: string) => void;
  onSaveAndCreate: (status: string) => void;
}
export type SaleOrderFormContentField = 'user';

interface IFormProps {
  id: number | string;
  dataDetails?: any;
  onSuccess?: () => void;
  createParamsApi?: any;
  initialValues?: any;
  hiddenFields?: Array<SaleOrderFormContentField>;
  loading?: boolean;
}

const isBetweenTime = (startDate, endDate) => {
  const now = moment();
  return now.isBetween(startDate, endDate, 'date', '[]');
};
isBetweenTime;
const SaleOrderFormContent = (props: IFormProps, ref) => {
  const { id, dataDetails, onSuccess, hiddenFields = [], loading } = props;
  const defaultValues = {
    note: '',
    order_date: moment(),
    created_at: moment(),
    email: '',
    phone: '',
    shipping_fee: 0,
    user: '',
    user_address_id: '',
  };
  const navigate = useNavigate();

  const newForm = useForm<any>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });
  const [user, setUser] = useState<any>();
  const [userAddress, setUserAddress] = useState<any>();
  const addressDefaultRef = useRef(false);

  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const [openProductModal, setOpenProductModal] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState([]);
  const [final_total, setFinalTotal] = useState(0);
  const [isRedirect, setIsRedirect] = useState<boolean>(false);
  const [statusSave, setStatusSave] = useState<string>('');

  const [shipping_charge, setShippingCharge] = useState(0);

  const watchShippingType = newForm.watch('shipping_type');
  const user_address_id = newForm.watch('user_address_id');
  const discount_code = newForm.watch('discount_code');

  useEffect(() => {
    if (watchShippingType === 'shop') setShippingCharge(0);
  }, [watchShippingType]);

  const [discount, setDiscount] = useState(0);

  const tableRef = useRef(null);
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const handleReset = () => {
    newForm.reset(defaultValues);
    setDataSource([]);
    setUser({});
    setDiscount(0);
    setStatusSave(undefined);
    setFinalTotal(0);
    setUserAddress({});
  };

  const { data: address } = useQuery(
    ['get-user-address-default', user?.id],
    () =>
      UserAddressApi.getList({
        page: 1,
        limit: 10,
        user_id: user?.id,
        is_default: 1,
      }),
    {
      enabled: !!user?.id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: addressDetail } = useQuery(
    ['get-user-address-detail', user_address_id],
    () => UserAddressApi.getDetail(user_address_id),
    {
      enabled: !!user_address_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );
  useEffect(() => {
    if (!!addressDetail) {
      setUserAddress(addressDetail);
      newForm.clearErrors(['user_address_id']);
    }
  }, [addressDetail]);

  useEffect(() => {
    if (!!address?.data?.[0] && !!addressDefaultRef.current) {
      newForm.setValue('user_address_id', address?.data?.[0]?.id);
      addressDefaultRef.current = false;
    }
  }, [address, addressDefaultRef.current]);

  const { mutate: mutateCalculate, isLoading: isCalculating } = useMutation(
    (variables: any) => SaleOrderApi.calculate(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          console.log('res', res);

          setDiscount(res?.data?.discount_price ?? 0);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );
  const calculateDiscountDebounce = useCallback(
    _.debounce((userId, dataSource, discount_code) => {
      if (!userId || !dataSource || dataSource?.length == 0 || !discount_code)
        return;
      mutateCalculate({
        user_id: userId,
        products: dataSource?.map((item) => ({
          product_id: item?.id,
          quantity: item?.quantity,
        })),
        discount_code,
      });
    }, 500),
    [mutateCalculate],
  );

  const { mutate: mutateSaleOrder, isLoading: isLoadingMutate } = useMutation(
    (variables: any) => {
      const paramStatus = {
        ...variables,
        status: statusSave,
      };
      if (id)
        return SaleOrderApi.update({
          id,
          params: paramStatus,
        });

      return SaleOrderApi.create({
        params: paramStatus,
      });
    },
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-sale-order-list']);
          queryClient.invalidateQueries(['get-sale-order-detail']);
          handleReset();
          notification.success({
            message: res?.msg,
          });
          if (onSuccess) {
            onSuccess();
            return;
          }
          if (isRedirect) {
            navigate(-1);
          }
          setIsRedirect(false);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDeleteRow = (key, title) => {
    confirm.warning({
      title: 'Xóa sản phẩm đơn mua hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc chắn muốn xóa sản phẩm đơn mua hàng</p>
          <p>
            <strong>{title}</strong> này không?
          </p>
        </div>
      ),
      onOk: () => {
        tableRef?.current?.deleteRow(key);
      },
    });
  };

  useEffect(() => {
    if (!dataSource) return;

    const total = dataSource.reduce((acc, val) => {
      const result =
        Number(acc) + Number(get(val, 'quantity')) * Number(get(val, 'price'));
      return result;
    }, 0);

    setFinalTotal(total);
  }, [dataSource, discount, shipping_charge]);

  const products = get(dataDetails, 'products');

  useEffect(() => {
    if (!products) return;
    setDataSource(products?.map((item) => ({ ...item, id: item.product_id })));
  }, [products]);

  useEffect(() => {
    if (!dataDetails) return;
    newForm.reset({
      ...dataDetails,
    });
    dataDetails?.user && setUser(dataDetails?.user);
  }, [newForm, dataDetails]);

  useEffect(() => {
    if (!!id && !!dataDetails) {
      const data = _.pick(dataDetails, ['note', 'user_address_id']);

      const dataSet = {
        ...data,
        order_date: moment(dataDetails?.order_date),
        created_at: moment(dataDetails?.created_at),
        shipping_fee: Number(dataDetails?.shipping_fee),
        shipping_address: dataDetails?.shipping_address,
        discount_code: dataDetails?.discount_code,
      };
      newForm.reset(dataSet);
      setUser(dataDetails?.user);
      newForm.setValue('user', dataDetails?.user?.full_name);
      setShippingCharge(Number(dataDetails?.shipping_fee));
      calculateDiscountDebounce(
        dataDetails?.user?.id,
        dataDetails?.products?.map((item) => ({
          ...item,
          id: item.product_id,
        })),
        dataDetails?.discount_code,
      );
    } else {
      newForm.reset();
    }
  }, [dataDetails, id]);

  const handleChangeShippingCharge = (value) => {
    setShippingCharge(value);
  };

  useEffect(() => {
    if (!id) handleReset();
  }, [id]);

  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 150,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              imageClassName="object-cover"
              src={record?.image_product ?? record?.image_url}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1 ">
            <p className="text-[#1C64F2]">{record?.name_product ?? val}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'category',
      width: 120,
      render: (val, record) => record?.name_category ?? val?.name,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 120,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      width: 150,
      render: (price) => formatCurrency(Number(price)),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      editable: true,
      type: 'int',
      inputProps: {
        min: 1,
        max: MAXIMUM_QUANTITY,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
      },
      width: 120,
    },
    // {
    //   title: 'Giá khuyến mãi',
    //   dataIndex: 'promotional_price',
    //   width: 150,
    //   render: (total) => formatCurrency(Number(total)),
    // },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 150,
      render: (_, record) =>
        formatCurrency(
          Number(record?.quantity ?? 1) * Number(record?.price ?? 0),
        ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 60,
      fixed: 'right',
      render: (_, record) => {
        return (
          <ActionCUD
            size="small"
            onClickDelete={() => handleDeleteRow(record?.id, record?.title)}
          />
        );
      },
    },
  ];

  const handleSubmitForm = (values) => {
    if (!dataSource || dataSource?.length == 0) {
      notification.error({
        message: 'Vui lòng chọn ít nhất 1 sản phẩm',
      });
      return;
    }

    const newData = {
      ...values,
      user_id: user?.id,
      order_date: moment(values?.order_date).format(DATE_BACKEND_FORMAT),
      note: values?.note,
      phone: user?.phone,
      email: user?.email,
      shipping_fee: shipping_charge,
      products: dataSource?.map((i) => ({
        ...i,
        product_id: i?.id,
        name_product: i?.name_product ?? i?.name,
        image_product: i?.image_product ?? i?.image_url,
        name_category: i?.name_category ?? i?.category?.name,
      })),
      final_total: final_total,
      status: statusSave,
    };
    mutateSaleOrder(newData);
  };

  const handleSave = (status) => {
    if (isLoadingMutate) return;
    setIsRedirect(true);

    if (status) {
      setStatusSave(status);
    }
    setTimeout(() => {
      newForm.handleSubmit(handleSubmitForm)();
    }, 0);
  };

  const handleSaveAndCreate = (status) => {
    if (isLoadingMutate) return;
    if (status) {
      setStatusSave(status);
    }
    newForm.handleSubmit(handleSubmitForm)();
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        onSave: handleSave,
        onSaveAndCreate: handleSaveAndCreate,
      };
    },
    [ref, handleSave, handleSaveAndCreate],
  );

  isCalculating;
  return (
    <Spin spinning={false}>
      <FormTera form={newForm} object_type="crm" onSubmit={handleSubmitForm}>
        <CardFormV2
          title="Thông tin chung"
          id="information"
          className="form-section"
        >
          <Row className={'grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0'}>
            <FormTeraItem
              label="Họ và tên"
              name="user"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <Input
                placeholder="Vui lòng chọn"
                className="w-full cursor-pointer"
                onClick={() => {
                  setOpenUserModal(true);
                }}
                disabled={hiddenFields?.includes('user')}
                suffix={<ChevronDownOutlined className="w-4 cursor-pointer" />}
              />
            </FormTeraItem>
            <FormTeraItem label="Ngày bán hàng" name="order_date">
              <DatePicker
                placeholder="Vui lòng nhập"
                format={DATE_FORMAT}
                allowClear={false}
              />
            </FormTeraItem>
            <FormTeraItem label="Ngày tạo đơn" name="created_at">
              <DatePicker
                placeholder="Vui lòng chọn"
                format={DATE_FORMAT}
                disabled
              />
            </FormTeraItem>
            <Col className={'col-span-2 xl:col-span-3'}>
              <FormTeraItem label="Ghi chú" name="note">
                <TextArea placeholder="Vui lòng nhập" rows={10} />
              </FormTeraItem>
            </Col>
          </Row>
        </CardFormV2>

        <CardFormV2
          title="Thông tin giao hàng"
          id="delivery"
          className="form-section"
        >
          <Row className={'grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0'}>
            <FormTeraItem
              label="Địa chỉ giao hàng"
              name="user_address_id"
              rules={[{ required: messageValidate.emptySelect }]}
            >
              <SelectUserAddress
                disabled={!user?.id}
                paramsApi={{
                  user_id: user?.id,
                  include_id: newForm.watch('user_address_id'),
                }}
              />
            </FormTeraItem>

            <FormTeraItem label="Họ và tên người nhận" name="">
              <Input
                placeholder="Vui lòng nhập"
                value={userAddress?.full_name ?? ''}
                disabled
              />
            </FormTeraItem>
            <FormTeraItem label="Số điện thoại" name="">
              <Input
                placeholder="Vui lòng nhập"
                value={userAddress?.phone ?? ''}
                disabled
              />
            </FormTeraItem>
            <FormTeraItem label="Phí vận chuyển" name="shipping_fee">
              <InputNumber
                min={0}
                max={MAXIMUM_CURRENCY}
                onChange={handleChangeShippingCharge}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              />
            </FormTeraItem>
          </Row>
        </CardFormV2>

        <CardFormV2 title="Sản phẩm" id="product" className="form-section">
          <Button
            htmlType="button"
            className="mb-5 ml-auto"
            onClick={() => {
              setOpenProductModal(true);
            }}
            disabled={!get(user, 'id')}
          >
            Thêm nhiều sản phẩm
          </Button>
          <div className="overflow-hidden bg-white shadow-xsm rounded-[5px]">
            <TableTera
              actionRef={tableRef}
              columns={columns}
              mode="editable-cell"
              className="center-table"
              loading={loading}
              data={dataSource}
              rowKey={'id'}
              pagination={{}}
              editable={{
                onValuesChange: (_, list) => {
                  const newList = list?.filter((item) => !item?.isDelete);
                  calculateDiscountDebounce(user?.id, newList, discount_code);
                  setDataSource(newList);
                },
              }}
            />
          </div>
        </CardFormV2>

        <CardFormV2 title="Giảm giá" id="tax" className="form-section">
          <Row className={'grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0'}>
            <FormTeraItem label="Mã giảm giá" name="discount_code">
              <Input
                maxLength={100}
                onChange={(val) => {
                  if (val) {
                    calculateDiscountDebounce(
                      user?.id,
                      dataSource,
                      val?.target?.value,
                    );
                  }
                  setDiscount(0);
                }}
              />
            </FormTeraItem>
          </Row>
        </CardFormV2>
        <div className="text-right mt-2">
          <Spin spinning={isCalculating}>
            <div className="total-money-row">
              <span className="font-semibold">Tạm tính: </span>
              <span className="text-xl font-semibold text-green-500">
                +{formatCurrency(final_total)}
              </span>
            </div>
            {discount > 0 && (
              <div className="total-money-row">
                <span className="font-semibold">Giảm giá: </span>
                <span className="text-xl font-semibold text-green-500">
                  -{formatCurrency(discount)}
                </span>
              </div>
            )}
            {shipping_charge > 0 && (
              <div className="total-money-row">
                <span className="font-semibold">Phí vận chuyển: </span>
                <span className="text-xl font-semibold text-green-500">
                  +{formatCurrency(shipping_charge)}
                </span>
              </div>
            )}

            <div className="total-money-row">
              <span className="font-semibold">Thành tiền: </span>
              <span className="text-xl font-semibold text-green-500">
                {formatCurrency(final_total + shipping_charge - discount)}
              </span>
            </div>
          </Spin>
        </div>
      </FormTera>
      {openUserModal && (
        <UserModal
          open={openUserModal}
          onClose={() => setOpenUserModal(false)}
          selectedValue={user}
          onSubmit={(data) => {
            setUser(data);
            newForm.clearErrors(['user']);
            newForm.setValue('user', data?.full_name);
            newForm.setValue('user_address_id', null);
            setUserAddress(null);
            addressDefaultRef.current = true;
            calculateDiscountDebounce(data?.id, dataSource, discount_code);
          }}
        />
      )}
      {openProductModal && (
        <ProductModal
          open={openProductModal}
          onClose={() => setOpenProductModal(false)}
          selectedValue={dataSource}
          onSubmit={(values = {}) =>
            setDataSource((prev = []) => {
              const data = [
                ...prev,
                ...values?.map((item) => ({ ...item, quantity: 1 })),
              ];

              // const result = data?.map((item) => {
              //   if (item?.promotional_price != undefined) {
              //     if (!item?.promotional_time) {
              //       return { ...item, price: item?.promotional_price };
              //     }
              //     if (
              //       isBetweenTime(
              //         moment(item?.promotional_time?.[0]),
              //         moment(item?.promotional_time?.[1]),
              //       )
              //     ) {
              //       return { ...item, price: item?.promotional_price };
              //     }
              //     return { ...item, price: item?.unit_price };
              //   }
              //   return { ...item, price: item?.unit_price };
              // });

              calculateDiscountDebounce(user?.id, data, discount_code);
              return data;
            })
          }
        />
      )}
    </Spin>
  );
};

export default observer(
  forwardRef<ISaleOrderFormContentRef, IFormProps>(SaleOrderFormContent),
);
