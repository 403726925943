function Menu1(props) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.2309 15.7693V9.61539C10.2309 9.27552 10.5064 9 10.8463 9H13.3079C13.6477 9 13.9233 9.27552 13.9233 9.61539V15.7693M10.2309 15.7693H1.09098M10.2309 15.7693H13.9233M13.9233 15.7693H16.9093M15.7694 15.7693V6.20921M2.23079 15.7693V6.20941M2.23079 6.20941C3.17217 6.75353 4.39679 6.6229 5.20217 5.81752C5.23878 5.78091 5.274 5.74343 5.30782 5.70515C5.75887 6.21614 6.41876 6.53843 7.15393 6.53843C7.88914 6.53843 8.54907 6.21611 9.00012 5.70507C9.45116 6.21611 10.1111 6.53843 10.8463 6.53843C11.5814 6.53843 12.2413 6.21618 12.6923 5.70524C12.7261 5.74346 12.7613 5.78089 12.7978 5.81745C13.6033 6.6229 14.828 6.75348 15.7694 6.20921M2.23079 6.20941C2.04854 6.10407 1.8769 5.97344 1.72098 5.81752C0.759674 4.85621 0.759674 3.29763 1.72098 2.33633L2.69682 1.36049C2.92763 1.12967 3.24069 1 3.56711 1H14.433C14.7594 1 15.0724 1.12967 15.3033 1.36049L16.279 2.33626C17.2403 3.29756 17.2403 4.85614 16.279 5.81745C16.1232 5.9733 15.9516 6.10389 15.7694 6.20921M4.69236 13.3078H7.76933C8.1092 13.3078 8.38472 13.0322 8.38472 12.6924V9.61539C8.38472 9.27552 8.1092 9 7.76933 9H4.69236C4.35249 9 4.07697 9.27552 4.07697 9.61539V12.6924C4.07697 13.0322 4.35249 13.3078 4.69236 13.3078Z"
        stroke="#1C64F2"
        stroke-width="0.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Menu1;
