export const CASH_RECEIPT_URL = {
  list: {
    key: '1',
    path: '/finance/cash-receipt/list',
    shortenUrl: 'cash-receipt/list',
  },
  create: {
    key: '2',
    path: '/finance/cash-receipt/create',
    shortenUrl: 'cash-receipt/create',
  },
  update: {
    key: '3',
    path: '/finance/cash-receipt/update',
    shortenUrl: 'cash-receipt/update/:id',
  },
};
