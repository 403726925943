export const COURSE_METHOD = {
  online: 'Online',
  offline: 'Offline',
};

export const COURSE_METHOD_COLOR = {
  online: 'text-blue-800',
  offline: 'text-red-800',
};

export const COURSE_TYPE = {
  basic: 'Cơ bản',
  intermediate: 'Trung cấp',
  advanced: 'Nâng cao',
  professional: 'Chuyên nghiệp',
  master: 'Chuyên gia',
};

export const COURSE_SYSTEM_STATUS = {
  approve: 'Đã duyệt',
  pending: 'Chờ duyệt',
  reject: 'Từ chối',
};

export const COURSE_SYSTEM_STATUS_COLOR = {
  approve: 'green03',
  pending: 'yellow03',
  reject: 'red03',
};

export const COURSE_STATUS = {
  1: 'Đang hoạt động',
  0: 'Ngừng hoạt động',
};

export const COURSE_STATUS_COLOR = {
  1: 'green03',
  0: 'gray02',
};

export const LINK_COURSE_TYPE = {
  uploaded_video: 'Tải video từ máy',
  youtube_link: 'Link Youtube',
  gg_drive_link: 'Link Google Drive',
};

export const COURSE_DETAIL_TAB_KEY = {
  overview: 'Thông tin chung',
  schedules_count: 'Thời gian học',
  lessons_count: 'Bài học',
  attachments_count: 'Tài liệu đính kèm',
  description: 'Mô tả',
  student_count: 'Danh sách học viên',
  exercise_count: 'Bài tập học viên',
  rating_count: 'Đánh giá',
  gifts_count: 'Quà tặng',
  device_product_count: 'Gợi ý thiết bị',
  tool_product_count: 'Gợi ý dụng cụ',
  ingredient_product_count: 'Gợi ý nguyên liệu',
};

export const STUDENT_HOMEWORK_STATUS = {
  scored: 'Đã chấm',
  not_scored_yet: 'Chưa chấm',
};
