import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  getQueryParams,
  PaginationProps,
  Tabs,
  updateQueryParams,
} from 'tera-dls';
import CourseApi from './api';
import { COURSE_METHOD } from './constants';
import Overview from './Overview';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const Course = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const params = _.omit(queryParams, ['classification']);

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-course-list', queryParams],
    () =>
      CourseApi.getList({
        page: 1,
        limit: 10,
        ...params,
        ...(!!queryParams?.classification &&
          queryParams?.classification !== 'all' && {
            classification: queryParams?.classification,
          }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-course-summary-list', queryParams],
    () =>
      CourseApi.getSummaryList({
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ classification: key, page: 1 });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(COURSE_METHOD)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{COURSE_METHOD[key]}</span>
          <Badge
            showZero
            count={
              summary?.find((i) => i.classification == key)?.total_count ?? 0
            }
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={
                summary?.find((i) => i.classification === 'all')?.total_count ??
                0
              }
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  return (
    <>
      <div className="tera-page">
        <Overview
          {...{
            onSearch: handleSearch,
            headerProps: {
              middleContent: (
                <Tabs
                  onChange={handleChangeTab}
                  items={tabItems}
                  activeKey={queryParams?.classification || 'all'}
                  className="mb-0 pt-0 bg-white rounded-t-md"
                />
              ),
              filterCount: {
                filter: queryParams,
                params: [
                  'from_price',
                  'to_price',
                  'category_id',
                  'lecturer_id',
                  'type',
                  'status',
                ],
              },
            },
            loading: isFetching,
            value: response,
            onChangePage: handleChangePage,
            onFilter: handleFilter,
            filterValue: queryParams,
          }}
        />
      </div>
    </>
  );
};

export default Course;
