export const REFUND_URL = {
  list: {
    key: '',
    path: '/finance/refund/list',
    shortenUrl: 'refund/list',
  },
  detail: {
    key: '',
    path: '/finance/refund/detail',
    shortenUrl: 'refund/detail/:id',
  },
};
