function BakeTeachIcon(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 15.1125C0 6.82823 6.60323 0 14.8875 0C23.1718 0 30 6.60323 30 14.8875C30 23.1718 23.3968 30 15.1125 30C6.82823 30 0 23.3968 0 15.1125Z"
        fill="#1975A7"
      />
      <path
        d="M0 15.1125C0 6.82823 6.60323 0 14.8875 0C23.1718 0 30 6.60323 30 14.8875C30 23.1718 23.3968 30 15.1125 30C6.82823 30 0 23.3968 0 15.1125Z"
        fill="#FAD20A"
      />
      <path
        d="M16.0273 20.931C11.9904 20.931 10.6672 18.8541 10.2441 17.2817C10.547 18.8934 11.7157 21.368 16.0273 21.368C20.339 21.368 21.5064 18.8934 21.8093 17.2817C21.3863 18.8529 20.0643 20.931 16.0273 20.931Z"
        fill="#1B486A"
      />
      <path
        d="M16.0268 20.93C20.0637 20.93 21.3857 18.8519 21.8087 17.2807C21.9559 16.5012 21.9007 15.9243 21.9007 15.9243H10.1528C10.1528 15.9243 10.0976 16.5012 10.2436 17.2807C10.6666 18.8531 11.9898 20.93 16.0268 20.93Z"
        fill="#2E6187"
      />
      <path
        d="M21.9002 15.9257C21.9002 16.5063 19.2698 16.9777 16.0263 16.9777C12.7815 16.9777 10.1523 16.5063 10.1523 15.9257C10.1523 15.3439 12.7815 14.8726 16.0263 14.8726C19.2698 14.8726 21.9002 15.3439 21.9002 15.9257Z"
        fill="#1665A0"
      />
      <path
        d="M21.2959 15.9252C21.2959 16.3512 18.9365 16.6949 16.0265 16.6949C13.1152 16.6949 10.7559 16.3512 10.7559 15.9252C10.7559 15.5005 13.1152 15.0967 16.0265 15.0967C18.9365 15.0967 21.2959 15.5005 21.2959 15.9252Z"
        fill="#1B486A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0264 21.4533C11.5995 21.4533 10.4492 18.8486 10.1561 17.2946C10.0249 16.5999 10.0507 16.0512 10.0642 15.9223H10.063C10.063 15.1809 13.1348 14.7808 16.0264 14.7808C18.9119 14.7808 21.9776 15.1797 21.9887 15.9174C21.9936 15.9714 22.0365 16.5459 21.8955 17.2946C21.6036 18.8486 20.4521 21.4533 16.0264 21.4533ZM10.2371 15.9469C10.2383 15.9469 10.188 16.5041 10.3303 17.2615C10.6123 18.7615 11.7283 21.2766 16.0264 21.2766C20.3246 21.2766 21.4393 18.7615 21.7213 17.2615C21.8624 16.5152 21.8145 15.9542 21.8121 15.9309V15.9223C21.8121 15.4657 19.4355 14.9575 16.0264 14.9575C12.6161 14.9575 10.2408 15.4657 10.2408 15.9223L10.2371 15.9469Z"
        fill="white"
      />
      <path
        d="M16.026 17.0649C13.1344 17.0649 10.0625 16.6647 10.0625 15.9233H10.2403C10.2403 16.3787 12.6156 16.8869 16.026 16.8869C19.4351 16.8869 21.8116 16.3787 21.8116 15.9233H21.9882C21.9882 16.6647 18.9163 17.0649 16.026 17.0649Z"
        fill="white"
      />
      <path
        d="M11.4792 18.9221C11.0745 18.3538 10.7973 17.7082 10.6637 17.0245C10.6416 16.9128 10.4712 16.9594 10.492 17.0711C10.6293 17.7683 10.9138 18.4324 11.3259 19.0117C11.3921 19.1038 11.5454 19.0154 11.4792 18.9221Z"
        fill="white"
      />
      <path
        d="M11.8908 19.5372C11.9067 19.5213 11.919 19.4992 11.919 19.4759C11.919 19.4525 11.9129 19.428 11.8969 19.4108C11.8454 19.3592 11.7939 19.3077 11.7424 19.2561C11.7265 19.239 11.7056 19.2279 11.6823 19.2267C11.6602 19.2255 11.6333 19.234 11.6173 19.25C11.6014 19.266 11.5891 19.2868 11.5879 19.3101C11.5879 19.3335 11.594 19.358 11.6112 19.3752C11.6627 19.4268 11.7138 19.4783 11.7645 19.5299C11.7816 19.5471 11.8025 19.5581 11.8258 19.5593C11.8466 19.5606 11.8748 19.552 11.8908 19.5372Z"
        fill="white"
      />
      <path
        d="M21.4952 17.1359C21.4732 17.131 21.4486 17.1298 21.4278 17.1408C21.4082 17.1531 21.3898 17.1703 21.3836 17.1936C21.364 17.264 21.3448 17.3344 21.326 17.4048C21.3199 17.4268 21.3211 17.4514 21.3309 17.4723C21.3407 17.4919 21.3616 17.5103 21.3836 17.5165C21.4045 17.5226 21.429 17.5226 21.4499 17.5115C21.4707 17.5005 21.4879 17.4821 21.4952 17.4588C21.514 17.3884 21.5328 17.318 21.5517 17.2476C21.5578 17.2255 21.5578 17.2022 21.5467 17.1814C21.5369 17.1617 21.5161 17.1421 21.4952 17.1359Z"
        fill="white"
      />
      <path
        d="M21.2184 17.7525C21.0664 18.2471 20.8174 18.7074 20.4888 19.1052C20.4152 19.1923 20.544 19.3163 20.6163 19.2279C20.967 18.8056 21.2295 18.3196 21.3901 17.7954C21.4232 17.6862 21.2515 17.6445 21.2184 17.7525Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4905 19.3623C13.423 19.3623 13.3727 19.3389 13.3409 19.3168C13.2967 19.2862 13.2428 19.226 13.2293 19.1143C13.0907 17.9531 18.3159 11.9974 18.5379 11.7433L19.0664 11.1431L18.6826 11.8452C18.5207 12.141 14.707 19.0873 13.569 19.3524C13.5407 19.3598 13.515 19.3623 13.4905 19.3623ZM18.1136 12.5031C16.3845 14.5174 13.309 18.2907 13.4046 19.0934C13.4108 19.1438 13.4292 19.1622 13.4426 19.1708C13.4561 19.1806 13.4794 19.1916 13.5285 19.1806C14.3145 18.9965 16.81 14.8169 18.1136 12.5031Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9519 19.5196C13.7397 19.5196 13.5362 19.4521 13.3436 19.317C13.0469 19.1084 12.8911 18.8187 12.8813 18.4578C12.8225 16.252 18.3187 11.9166 18.5529 11.7324L18.7823 11.5532L18.6915 11.8306C18.5983 12.113 16.3996 18.7622 14.3075 19.4594C14.1865 19.4995 14.068 19.5196 13.9519 19.5196ZM18.4254 12.0626C17.3524 12.9354 13.0089 16.5871 13.0591 18.4529C13.0665 18.7598 13.1928 18.9942 13.4454 19.171C13.6968 19.3489 13.9617 19.387 14.2511 19.29C16.0206 18.7008 17.9705 13.3699 18.4254 12.0626Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9519 19.5196C13.7397 19.5196 13.5362 19.4521 13.3436 19.317C13.0469 19.1084 12.8911 18.8187 12.8813 18.4578C12.8225 16.252 18.3187 11.9166 18.5529 11.7324L18.7823 11.5532L18.6915 11.8306C18.5983 12.113 16.3996 18.7622 14.3075 19.4594C14.1865 19.4995 14.068 19.5196 13.9519 19.5196ZM18.4254 12.0626C17.3524 12.9354 13.0089 16.5871 13.0591 18.4529C13.0665 18.7598 13.1928 18.9942 13.4454 19.171C13.6968 19.3489 13.9617 19.387 14.2511 19.29C16.0206 18.7008 17.9705 13.3699 18.4254 12.0626Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5755 19.7602C14.1745 19.7602 13.7625 19.6116 13.3419 19.317C12.687 18.8567 12.3743 18.2946 12.4111 17.644C12.5595 15.0258 18.3182 11.8589 18.5634 11.7251L18.7192 11.6392L18.6934 11.8159C18.6505 12.0921 17.6388 18.5904 15.2291 19.6227C15.0158 19.7135 14.7975 19.7602 14.5755 19.7602ZM18.4874 11.9706C17.5407 12.5107 12.7165 15.3695 12.5877 17.6538C12.5546 18.2393 12.8428 18.7499 13.4436 19.171C14.0445 19.5932 14.6221 19.6902 15.1605 19.4594C17.2611 18.5597 18.3035 13.0446 18.4874 11.9706Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5755 19.7602C14.1745 19.7602 13.7625 19.6116 13.3419 19.317C12.687 18.8567 12.3743 18.2946 12.4111 17.644C12.5595 15.0258 18.3182 11.8589 18.5634 11.7251L18.7192 11.6392L18.6934 11.8159C18.6505 12.0921 17.6388 18.5904 15.2291 19.6227C15.0158 19.7135 14.7975 19.7602 14.5755 19.7602ZM18.4874 11.9706C17.5407 12.5107 12.7165 15.3695 12.5877 17.6538C12.5546 18.2393 12.8428 18.7499 13.4436 19.171C14.0445 19.5932 14.6221 19.6902 15.1605 19.4594C17.2611 18.5597 18.3035 13.0446 18.4874 11.9706Z"
        fill="white"
      />
      <path
        d="M21.7849 7.26488C21.5274 7.08445 21.1902 7.12004 21.032 7.3459L18.2863 11.2665C18.1281 11.4923 18.2078 11.8213 18.4654 12.0017C18.7229 12.1834 19.0601 12.1466 19.2183 11.9207L21.964 8.00014C22.1222 7.77429 22.0412 7.44532 21.7849 7.26488Z"
        fill="#1B486A"
      />
      <path
        d="M21.4619 7.72523C21.3675 7.65895 21.2448 7.67245 21.1872 7.75469L20.926 8.12785C20.8684 8.21009 20.8978 8.33038 20.991 8.39666C21.0854 8.46295 21.2093 8.44945 21.2669 8.36721L21.5281 7.99405C21.5858 7.91181 21.5563 7.79152 21.4619 7.72523Z"
        fill="#03376B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.1195 8.52887C21.0557 8.52887 20.9932 8.50801 20.9392 8.46995C20.8055 8.37667 20.7663 8.19991 20.8521 8.07839L21.1133 7.70523C21.155 7.64386 21.2225 7.60581 21.2997 7.59722C21.3733 7.58985 21.4481 7.60949 21.5119 7.65368C21.6456 7.7482 21.6848 7.92373 21.599 8.04647L21.3378 8.4184C21.2948 8.47977 21.2286 8.51783 21.1501 8.52642C21.1403 8.52805 21.1301 8.52887 21.1195 8.52887ZM21.3316 7.77275C21.3275 7.77275 21.323 7.77315 21.3181 7.77397C21.3034 7.7752 21.2752 7.78134 21.2581 7.80711L20.9968 8.17904C20.9674 8.22078 20.9883 8.28829 21.041 8.32511C21.0692 8.34475 21.1023 8.35334 21.1317 8.35089C21.1465 8.34843 21.1747 8.3423 21.1931 8.31652L21.4543 7.94459C21.4825 7.90286 21.4628 7.83657 21.4101 7.79852C21.3856 7.78134 21.3586 7.77275 21.3316 7.77275Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8195 12.2075C18.6834 12.2075 18.5411 12.1646 18.416 12.0774C18.2738 11.9768 18.1744 11.8344 18.1352 11.6748C18.0947 11.5103 18.1229 11.3483 18.2149 11.2182L20.9606 7.29762C21.1458 7.03248 21.5394 6.98584 21.8362 7.19451C21.9784 7.29393 22.0777 7.43632 22.117 7.59712C22.1574 7.76038 22.1292 7.9224 22.0373 8.05374L19.2916 11.9743C19.1837 12.1277 19.0071 12.2075 18.8195 12.2075ZM21.4315 7.24115C21.3003 7.24115 21.1789 7.29516 21.1053 7.3995L18.3596 11.3201C18.2983 11.4072 18.2787 11.5189 18.3069 11.6318C18.3363 11.7509 18.4111 11.8565 18.5178 11.9314C18.7349 12.0836 19.0169 12.0578 19.1469 11.8724L21.8926 7.95186C21.9539 7.86348 21.9735 7.75301 21.9453 7.63886C21.9159 7.52102 21.8411 7.41423 21.7344 7.33935C21.6399 7.27307 21.5333 7.24115 21.4315 7.24115Z"
        fill="white"
      />
      <path
        d="M10.3976 14.931C10.0726 15.0832 9.83471 15.3705 9.70104 15.6982C9.53917 16.0947 9.54653 16.5341 9.62133 16.949C9.64095 17.0607 9.81141 17.014 9.79179 16.9011C9.66916 16.2272 9.80037 15.4048 10.4871 15.0845C10.5901 15.0366 10.5006 14.8831 10.3976 14.931Z"
        fill="white"
      />
      <path
        d="M9.54921 15.0488C9.5161 15.0181 9.45601 15.0107 9.42413 15.0488C9.28188 15.2157 9.17151 15.4023 9.09426 15.6061C9.07709 15.6503 9.11265 15.7055 9.15557 15.7153C9.20585 15.7276 9.24754 15.7006 9.26471 15.6539C9.2688 15.6425 9.27329 15.631 9.2782 15.6196C9.27983 15.6147 9.28188 15.6102 9.28433 15.6061C9.28433 15.6036 9.28556 15.6012 9.28678 15.5999C9.29578 15.5778 9.30559 15.5557 9.31622 15.5337C9.33829 15.4886 9.362 15.4449 9.38734 15.4023C9.41187 15.3606 9.43803 15.3201 9.46582 15.2808C9.47236 15.271 9.47931 15.2612 9.48667 15.2513C9.49076 15.2464 9.49444 15.2415 9.49771 15.2366C9.49893 15.2354 9.49893 15.2354 9.49893 15.2341C9.50016 15.2341 9.50016 15.2341 9.50016 15.2329C9.51569 15.2133 9.53204 15.1936 9.54921 15.174C9.57864 15.1384 9.586 15.0832 9.54921 15.0488Z"
        fill="white"
      />
      <path
        d="M21.6937 14.931C22.0187 15.0832 22.2566 15.3705 22.3903 15.6982C22.5509 16.0947 22.5448 16.5341 22.47 16.949C22.4504 17.0607 22.2799 17.014 22.2995 16.9011C22.4209 16.2272 22.2909 15.4048 21.6042 15.0845C21.5012 15.0366 21.5907 14.8831 21.6937 14.931Z"
        fill="white"
      />
      <path
        d="M22.5429 15.0488C22.576 15.0181 22.6361 15.0107 22.668 15.0488C22.8103 15.2157 22.9206 15.4023 22.9979 15.6061C23.015 15.6503 22.9795 15.7055 22.9366 15.7153C22.8851 15.7276 22.8446 15.7006 22.8274 15.6539C22.8233 15.6425 22.8188 15.631 22.8139 15.6196C22.8123 15.6147 22.8103 15.6102 22.8078 15.6061C22.8078 15.6036 22.8066 15.6012 22.8053 15.5999C22.7955 15.5778 22.7857 15.5557 22.7759 15.5337C22.7538 15.4886 22.7301 15.4449 22.7048 15.4023C22.6803 15.3606 22.6541 15.3201 22.6263 15.2808C22.619 15.271 22.612 15.2612 22.6055 15.2513C22.6014 15.2464 22.5977 15.2415 22.5944 15.2366C22.5932 15.2354 22.5932 15.2354 22.592 15.2341C22.592 15.2341 22.592 15.2341 22.592 15.2329C22.5756 15.2133 22.5593 15.1936 22.5429 15.174C22.5123 15.1384 22.5061 15.0832 22.5429 15.0488Z"
        fill="white"
      />
      <path
        d="M20.4775 7.03003C19.8484 7.79475 19.2647 8.59383 18.7276 9.42607C18.6651 9.52181 18.8183 9.61019 18.8809 9.51444C19.4094 8.69572 19.9833 7.90767 20.6026 7.15523C20.675 7.06808 20.5511 6.94165 20.4775 7.03003Z"
        fill="white"
      />
      <path
        d="M19.4263 7.29778C19.3809 7.27323 19.3343 7.28919 19.3049 7.3297C19.1896 7.49172 19.0989 7.66971 19.0314 7.85628C19.0155 7.90047 19.0486 7.95571 19.0927 7.96553C19.143 7.97658 19.1847 7.9508 19.2019 7.90416C19.21 7.88124 19.2186 7.85833 19.2276 7.83542C19.2301 7.82969 19.2325 7.82437 19.235 7.81946C19.2362 7.81578 19.2362 7.81455 19.2362 7.81332C19.2411 7.80268 19.246 7.79205 19.2509 7.78141C19.2697 7.73967 19.2897 7.69876 19.311 7.65866C19.3539 7.57642 19.4042 7.49541 19.4582 7.4193C19.4851 7.38125 19.4667 7.31865 19.4263 7.29778Z"
        fill="white"
      />
      <path
        d="M19.8524 11.881C19.7617 12.2714 19.5029 12.6065 19.1498 12.7931C19.0492 12.8458 19.1375 12.9993 19.2393 12.9453C19.6305 12.739 19.9223 12.3598 20.0229 11.9277C20.0486 11.8172 19.8782 11.7693 19.8524 11.881Z"
        fill="white"
      />
      <path
        d="M14.7334 21.6717C13.5292 21.6496 12.3532 21.1746 11.474 20.351C11.3906 20.2736 11.2643 20.3988 11.3476 20.4762C12.2625 21.3317 13.4826 21.8264 14.7334 21.8485C14.8475 21.8509 14.8475 21.673 14.7334 21.6717Z"
        fill="white"
      />
      <path
        d="M15.3462 21.8909H15.9164C15.963 21.8909 16.0072 21.8491 16.0059 21.8013C16.0035 21.7534 15.9667 21.7129 15.9164 21.7129H15.3462C15.2996 21.7129 15.2555 21.7534 15.2579 21.8013C15.2604 21.8491 15.2972 21.8909 15.3462 21.8909Z"
        fill="white"
      />
      <path
        d="M20.8977 20.1162C20.867 20.0855 20.8033 20.0781 20.7726 20.1162C20.574 20.3641 20.3311 20.5728 20.0552 20.7287C19.9559 20.7852 20.0442 20.9386 20.1447 20.8821C20.4329 20.7189 20.6904 20.5004 20.8977 20.2414C20.9284 20.2046 20.9345 20.153 20.8977 20.1162Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.3874 9.99653C9.39476 10.409 9.03545 10.6729 8.69577 10.8202C8.25553 11.0092 7.77973 11.0988 7.30147 11.0914C7.21195 11.0902 7.17639 11.0067 7.19601 10.9405C7.12366 10.2678 7.07093 9.59392 7.03904 8.9188C7.02433 8.61439 7.0137 8.30997 7.00716 8.00556C7.00225 7.78952 6.95811 7.5072 7.1678 7.3685C7.50994 7.14264 7.97348 7.42987 8.1844 7.70605C8.43947 8.03747 8.50937 8.48427 8.36099 8.87584C8.29477 9.05382 8.19299 9.20971 8.0679 9.34474C8.2776 9.31773 8.49098 9.31896 8.7019 9.35578C9.03422 9.4147 9.38127 9.61969 9.3874 9.99653ZM8.55597 9.56691C8.19176 9.5264 7.82142 9.59146 7.49032 9.74858C7.49032 9.74858 7.49032 9.74817 7.49032 9.74735C7.48051 9.75103 7.47193 9.75594 7.46334 9.75963C7.34071 9.80995 7.25732 9.60374 7.37382 9.5485C7.38486 9.54359 7.3959 9.53991 7.40693 9.535C7.78831 9.37911 8.10715 9.04523 8.19176 8.63525C8.26166 8.29524 8.12922 7.92332 7.86434 7.69992C7.73681 7.5919 7.54673 7.49615 7.37505 7.53052C7.15432 7.57594 7.23648 7.95278 7.24016 8.11971C7.26346 9.03419 7.32354 9.94743 7.41797 10.8582C7.75643 10.8509 8.08998 10.7968 8.41004 10.6851C8.69577 10.5845 9.0796 10.4261 9.14704 10.091C9.2194 9.7363 8.83802 9.59883 8.55597 9.56691Z"
        fill="#1B486A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7401 11.0109C10.7131 11.1361 10.5034 11.1103 10.5144 10.9839C10.3146 11.071 10.0951 11.098 9.88168 11.0465C9.68425 10.9998 9.51624 10.8501 9.57511 10.6303C9.62538 10.4376 9.81423 10.3038 10.0031 10.2695C10.1735 10.2375 10.3894 10.2547 10.5439 10.3529C10.5194 10.2461 10.4654 10.1553 10.3796 10.0755C10.187 9.89385 9.90498 9.77356 9.64623 9.87421C9.58737 9.89631 9.51992 9.84844 9.50398 9.7932C9.48681 9.72937 9.52728 9.67414 9.58492 9.65081C9.91847 9.52193 10.2949 9.67905 10.5427 9.91227C10.6898 10.0497 10.7683 10.2179 10.7879 10.4168C10.8063 10.6144 10.783 10.8181 10.7401 11.0109ZM10.5267 10.7555C10.5206 10.6573 10.4899 10.5677 10.393 10.526C10.2434 10.4622 10.0239 10.4609 9.89026 10.5628C9.84366 10.5984 9.78112 10.6672 9.79339 10.731C9.80442 10.7924 9.89272 10.812 9.94299 10.8243C10.1122 10.8648 10.2949 10.8415 10.4495 10.7641C10.4777 10.7494 10.5034 10.7494 10.5267 10.7555Z"
        fill="#1B486A"
      />
      <path
        d="M11.9984 10.7318C11.7761 10.4413 11.5541 10.1508 11.3326 9.86034C11.519 9.60747 11.7127 9.35952 11.9199 9.12262C11.9616 9.07598 11.9665 9.00601 11.9199 8.95937C11.8783 8.91763 11.7985 8.91272 11.7569 8.95937C11.5656 9.17908 11.3865 9.40617 11.2112 9.63939C11.1956 9.20159 11.1858 8.7642 11.1817 8.32721C11.1805 8.17869 10.95 8.17869 10.9512 8.32721C10.9585 9.21223 10.9916 10.0972 11.0481 10.981C11.0579 11.1295 11.2884 11.1295 11.2798 10.981C11.2602 10.6913 11.2479 10.4004 11.2345 10.1107C11.4225 10.3562 11.6109 10.6021 11.7998 10.8485C11.8366 10.8976 11.8991 10.9246 11.9567 10.8902C12.0058 10.862 12.0364 10.7822 11.9984 10.7318Z"
        fill="#1B486A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2297 11.0236C12.917 11.1058 12.5332 11.1598 12.2794 10.9045C12.0991 10.7241 12.0562 10.4283 12.1494 10.195C12.1997 10.0698 12.283 9.9606 12.3971 9.88572C12.5283 9.79857 12.6828 9.78875 12.8336 9.82312C12.96 9.85135 13.0924 9.91027 13.1758 10.0146C13.2641 10.1263 13.2776 10.2773 13.1991 10.3976C13.0249 10.6701 12.6239 10.6173 12.3505 10.5645C12.3762 10.6627 12.4314 10.7511 12.5283 10.8039C12.7245 10.9131 12.9649 10.8542 13.1684 10.8014C13.3131 10.7634 13.3732 10.9868 13.2297 11.0236ZM12.3444 10.3411C12.3627 10.3374 12.3811 10.3362 12.3995 10.3399C12.5283 10.3644 12.6632 10.3792 12.7956 10.3706C12.879 10.3657 12.9955 10.335 13.0188 10.2417C13.0262 10.2147 13.0188 10.1889 13.0041 10.1668C12.9808 10.1349 12.9452 10.1128 12.9121 10.0944C12.8324 10.0514 12.7221 10.0207 12.6313 10.0355C12.4817 10.06 12.3775 10.2036 12.3444 10.3411Z"
        fill="#1B486A"
      />
      <path
        d="M14.4681 9.92062C14.2375 9.97954 13.9862 10.0532 13.8316 10.2484C13.7998 10.2889 13.774 10.3331 13.7519 10.3772C13.7433 10.3294 13.7335 10.2815 13.7262 10.2336C13.7139 10.1673 13.7041 10.0998 13.7225 10.0348C13.7605 9.89116 13.5373 9.82979 13.4993 9.9734C13.4552 10.1428 13.5079 10.3257 13.5398 10.4939C13.5733 10.6665 13.6109 10.8388 13.6526 11.0106C13.6833 11.1383 13.8782 11.1088 13.8795 10.9799C13.8807 10.786 13.8795 10.5601 14.0045 10.3993C14.1284 10.2422 14.3442 10.1907 14.5294 10.1428C14.6729 10.106 14.6128 9.88257 14.4681 9.92062Z"
        fill="#1B486A"
      />
      <path
        d="M16.0402 10.0037C16.0022 9.86008 15.7852 9.92023 15.8183 10.0651C15.8771 10.3253 15.7042 10.6052 15.421 10.6003C15.1316 10.5941 14.9758 10.2787 15.1365 10.0417C15.2211 9.919 15.0212 9.80362 14.9378 9.92514C14.6864 10.2958 14.9439 10.7954 15.3817 10.8286C15.6172 10.8457 15.8085 10.7365 15.9274 10.5683C15.9544 10.7623 15.952 10.9574 15.8857 11.1453C15.7521 11.5184 15.4283 11.6019 15.0702 11.6399C14.2854 11.7209 13.4908 11.7025 12.7109 11.5859C12.5662 11.5638 12.5036 11.786 12.6495 11.8081C13.5312 11.9407 14.4706 11.9873 15.3535 11.8339C15.7165 11.7713 15.9851 11.5577 16.1077 11.2066C16.2463 10.8138 16.142 10.3916 16.0402 10.0037Z"
        fill="#1B486A"
      />
    </svg>
  );
}

export default BakeTeachIcon;
