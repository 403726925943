import { Button, Col, notification, Row, Spin } from 'tera-dls';
import Card from './containers/Card';
import { useState } from 'react';
import ModalTransport from './containers/ModalForm';
import { useMutation, useQuery } from '@tanstack/react-query';
import TransportConfigApi from './api';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';

const TransportConfig = () => {
  const [openForm, setOpenForm] = useState({
    id: null,
    open: false,
  });
  const confirm = useConfirm();

  const { data, isLoading, refetch } = useQuery(
    ['get-transport-config-list'],
    () => TransportConfigApi.getList(),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { mutate: mutateDelete, isLoading: loadingMutate } = useMutation(
    (id: any) => TransportConfigApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa cấu hình vận chuyển',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa cấu hình vận chuyển</p>
          <p>
            <b>{record?.province?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  return (
    <div className="tera-page !bg-none">
      <div className="text-gray-700 font-semibold text-base uppercase mb-2.5">
        Cấu hình vận chuyển
      </div>
      <Row className="grid-cols-6">
        <Col>
          <Button onClick={() => setOpenForm({ open: true, id: null })}>
            + Thêm khu vực vận chuyển
          </Button>
        </Col>
        <Col className="col-span-5">
          <Spin spinning={isLoading || loadingMutate}>
            <div className="bg-white shadow border rounded-md p-2.5 flex flex-col gap-y-2.5">
              {data?.map((item) => (
                <Card
                  key={item?.id}
                  title={
                    item?.all === 1 ? 'Tất cả tỉnh thành' : item?.province?.name
                  }
                  amount={item?.expense}
                  onClickUpdate={() =>
                    setOpenForm({ open: true, id: item?.id })
                  }
                  onClickDelete={
                    item?.all !== 1 ? () => handleDelete(item) : undefined
                  }
                />
              ))}
            </div>
          </Spin>
        </Col>
      </Row>
      {openForm.open && (
        <ModalTransport
          id={openForm.id}
          open={openForm.open}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={refetch}
        />
      )}
    </div>
  );
};

export default TransportConfig;
