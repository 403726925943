import { IRouteProps } from '_common/interface/router';
import Lecturer from '.';
import { LECTURER_URL } from './url';
import LecturerDetail from './containers/Detail';
import LecturerForm from './containers/Form';

export const LecturerRouter: IRouteProps[] = [
  {
    key: LECTURER_URL.list.key,
    path: LECTURER_URL.list.shortenUrl,
    component: <Lecturer />,
  },
  {
    key: LECTURER_URL.detail.key,
    path: LECTURER_URL.detail.shortenUrl,
    component: <LecturerDetail />,
  },
  {
    key: LECTURER_URL.update.key,
    path: LECTURER_URL.update.shortenUrl,
    component: <LecturerForm />,
  },
];
