const CourseIcon = (props) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1514 10.8462V8.69231C14.1514 7.1629 12.9116 5.92308 11.3822 5.92308H10.1514C9.64164 5.92308 9.22837 5.5098 9.22837 5V3.76923C9.22837 2.23983 7.98854 1 6.45913 1H4.92067M6.76683 1H2.76683C2.25703 1 1.84375 1.41328 1.84375 1.92308V16.0769C1.84375 16.5867 2.25703 17 2.76683 17H13.2284C13.7382 17 14.1514 16.5867 14.1514 16.0769V8.38462C14.1514 4.30621 10.8452 1 6.76683 1Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.86808 10.6049L6.99075 9.16576C6.90941 9.12505 6.81901 9.10583 6.72814 9.10991C6.63728 9.114 6.54897 9.14125 6.4716 9.18908C6.39424 9.23692 6.3304 9.30374 6.28614 9.38321C6.24188 9.46267 6.21868 9.55213 6.21875 9.64309V12.3613C6.21868 12.4523 6.24188 12.5417 6.28614 12.6212C6.3304 12.7007 6.39424 12.7675 6.4716 12.8153C6.54897 12.8632 6.63728 12.8904 6.72814 12.8945C6.81901 12.8986 6.90941 12.8793 6.99075 12.8386L9.86808 11.3995C9.94181 11.3626 10.0038 11.3059 10.0471 11.2357C10.0904 11.1655 10.1134 11.0847 10.1134 11.0022C10.1134 10.9197 10.0904 10.8389 10.0471 10.7687C10.0038 10.6986 9.94181 10.6418 9.86808 10.6049Z"
      stroke="white"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 15C10.2091 15 12 13.2091 12 11C12 8.79086 10.2091 7 8 7C5.79086 7 4 8.79086 4 11C4 13.2091 5.79086 15 8 15Z"
      stroke="white"
      stroke-width="0.6"
    />
  </svg>
);
export default CourseIcon;
