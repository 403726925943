export const messageError = {
  ERROR_API: 'Đang có lỗi xảy ra!',
  FILE_NOT_FOUND: 'Không tìm thấy file đính kèm',
  DATA_NOT_FOUND: 'Không tìm thấy dữ liệu',
};

export const messageWarning = {
  WARNING_EXIT_1: 'Bạn có chắc muốn thoát bản ghi này không?',
  WARNING_EXIT_2: 'Sau khi thoát dữ liệu sẽ không được lưu.',
};

export const messageValidate = {
  emptyText: 'Vui lòng nhập trường này!',
  emptySelect: 'Vui lòng chọn trường này!',
  emptyFile: 'Vui lòng upload file!',
  email: 'Định dạng email không chính xác',
  phone: 'Số điện thoại không đúng định dạng!',
  id_number: 'Số CMND/CCCD không đúng định dạng!',
  key: 'Mã dữ liệu không dấu, ghi thường và không chứa ký tự đặc biệt',
  code: 'Mã code không dấu và chỉ chứa ký tự đặc biệt"_"',
  from_date: 'Vui lòng chọn ngày nhỏ hơn',
  to_date: 'Vui lòng chọn ngày lớn hơn',
  username: 'Tên đăng nhập không dấu và khoảng trắng',
  password: 'Mật khẩu không không dấu và khoảng trắng',
  min_length_password: 'Mật khẩu tối thiểu 8 ký tự',
  max_length_password: 'Mật khẩu không vượt quá 16 ký tự',
  confirm_password: 'Nhập lại mật khẩu không trùng với mật khẩu',
  max_number: 'Vui lòng nhập số lớn hơn',
  min_number: 'Vui lòng nhập số nhỏ hơn',
  abbreviation: 'Vui lòng nhập tối đa 2 từ',
  format: 'Sai định dạng',
  max_date: 'Vui lòng chọn thời gian lớn hơn',
  min_date: 'Vui lòng chọn thời gian nhỏ hơn',
};

export const messageValidateLength = {
  key: 'Không nhập quá 191 kí tự',
  text: 'Không nhập quá 100 kí tự',
  textLong: 'Không nhập quá 255 kí tự',
  textArea: 'Không nhập quá 1000 kí tự',
  minPhone: 'Nhập tối thiểu 8 kí tự',
  maxPhone: 'Nhập tối đa 20 kí tự',
};
