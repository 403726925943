import CardDetail from '_common/component/CardDetail';
import { get } from 'lodash';
import { formatCurrency } from 'tera-dls';

const PaymentInfo = ({ dataDetail }) => {
  const finalTotal = Number(get(dataDetail, 'final_total', 0));
  const shippingAmount = Number(get(dataDetail, 'shipping_fee', 0));

  const renderInfo = [
    {
      title: 'Tạm tính',
      value: formatCurrency(dataDetail?.total ?? 0),
    },
    // {
    //   title: 'Chiết khấu',
    //   value: `${formatCurrency(discountValue)}`,
    // },
    {
      title: 'Mã giảm giá',
      value: (
        <div className="flex gap-1">
          <span className="text-green-500">{dataDetail?.discount_code}</span> -{' '}
          {formatCurrency(dataDetail?.discount_price ?? 0)}
        </div>
      ),
    },
    {
      title: 'Phí vận chuyển',
      value: `+${formatCurrency(shippingAmount)}`,
    },
    {
      title: 'Thành tiền',
      value: formatCurrency(finalTotal),
    },
    {
      title: 'Hình thức thanh toán',
      value: '',
    },
  ];
  return (
    <CardDetail title="Thanh toán" className=" shadow-none">
      {renderInfo.map(
        ({ title, value }, index) =>
          title && (
            <div className="flex items-start pb-2.5" key={index}>
              <div className="detail-key">{title}</div>
              <div className="detail-value">{value}</div>
            </div>
          ),
      )}
    </CardDetail>
  );
};

export default PaymentInfo;
