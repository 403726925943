import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const LecturerEndpoint = `${endpoint}/bake-teach/lecturer`;
const LecturerApi = {
  getList: async (params) =>
    await api
      .get(`${LecturerEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${LecturerEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  getSummaryDetail: async (params) =>
    await api
      .get(`${LecturerEndpoint}/summary-detail`, params)
      .then((data) => data?.data?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${LecturerEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${LecturerEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${LecturerEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default LecturerApi;
