import { IRouteProps } from '_common/interface/router';
import CourseCategory from '.';
import { COURSE_CATEGORY_URL } from './url';

export const CourseCategoryRouter: IRouteProps[] = [
  {
    key: COURSE_CATEGORY_URL.list.key,
    path: COURSE_CATEGORY_URL.list.shortenUrl,
    component: <CourseCategory />,
  },
];
