import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const WithdrawalRequestEndpoint = `${endpoint}/bake-teach/affiliate/request-withdrawal`;
const WithdrawalRequestApi = {
  getList: async (params) =>
    await api
      .get(`${WithdrawalRequestEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${WithdrawalRequestEndpoint}/list-summary`, params)
      .then((data) => data?.data?.data),
  getStatistic: async (params) =>
    await api
      .get(`${WithdrawalRequestEndpoint}/detail-statistic`, params)
      .then((data) => data?.data?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${WithdrawalRequestEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${WithdrawalRequestEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  approve: async ({ id, params }) =>
    await api
      .put(`${WithdrawalRequestEndpoint}/approve/${id}`, params)
      .then((result) => result?.data),
  cancel: async ({ id, params }) =>
    await api
      .put(`${WithdrawalRequestEndpoint}/cancel/${id}`, params)
      .then((result) => result?.data),
};
export default WithdrawalRequestApi;
