import { useQuery } from '@tanstack/react-query';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import DashboardApi from 'pages/Dashboard/api';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import image01 from 'styles/images/pages/dashboard/dashboard-01.png';
import image02 from 'styles/images/pages/dashboard/dashboard-02.png';
import image03 from 'styles/images/pages/dashboard/dashboard-03.png';
import { formatCurrency, formatDate, formatNumber } from 'tera-dls';
import Card from '../Card';

export interface LocationRefs {
  refetchData?: () => void;
}

interface LocationProps {
  params?: any;
}

function Location({ params }, ref) {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-dashboard-overview', params],
    () => {
      const data = {
        start_date: formatDate(params?.date[0], DATE_BACKEND_FORMAT),
        end_date: formatDate(params?.date[1], DATE_BACKEND_FORMAT),
      };
      return DashboardApi.getOverview({ params: data });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const dataOrder = useMemo(() => {
    return [
      {
        title: 'Doanh số',
        content: (
          <p className="flex flex-col gap-2">
            <p className="flex gap-[5px] items-center">
              <span className="text-blue-600">
                Đơn khóa học ({formatNumber(data?.sales?.count_invoice_course)})
              </span>{' '}
              <span className="text-xl">
                {formatCurrency(data?.sales?.course_invoice)}
              </span>{' '}
            </p>
            <p className="flex gap-[5px] items-center">
              <span className="text-green-600">
                Đơn bán hàng ({formatNumber(data?.sales?.count_invoice_product)}
                )
              </span>{' '}
              <span className="text-xl">
                {formatCurrency(data?.sales?.product_invoice)}
              </span>
            </p>
          </p>
        ),
        image: image01,
      },
      {
        title: 'Hoàn tiền',
        content: (
          <p className="flex flex-col gap-2">
            <p className="flex gap-[5px] items-center">
              <span className="text-blue-600">Số đơn hoàn tiền</span>{' '}
              <span className="text-xl">
                {formatNumber(data?.refund?.total_count)}
              </span>{' '}
            </p>
            <p className="flex gap-[5px] items-center">
              <span className="text-green-600">Tổng giá trị hoàn tiền</span>{' '}
              <span className="text-xl">
                {formatCurrency(data?.refund?.total_amount)}
              </span>
            </p>
          </p>
        ),
        image: image02,
      },
      {
        title: 'Hoa hồng khóa học',
        content: (
          <p className="flex flex-col gap-2">
            <p className="flex gap-[5px] items-center">
              <span className="text-blue-600">Khóa học online</span>{' '}
              <span className="text-xl">
                {formatCurrency(data?.commission_course?.online)}
              </span>{' '}
            </p>
            <p className="flex gap-[5px] items-center">
              <span className="text-green-600">Khóa học offline</span>{' '}
              <span className="text-xl">
                {formatCurrency(data?.commission_course?.offline)}
              </span>
            </p>
          </p>
        ),
        image: image03,
      },
    ];
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        refetchData() {
          refetch();
        },
      };
    },
    [],
  );

  return (
    <div className="w-full p-[15px] rounded-[10px] border">
      <div className="font-medium text-[#1F2937] uppercase flex gap-5 items-center">
        <span className="leading-[15px]">Tổng quan</span>
      </div>
      <div className="mt-[15px] grid grid-cols-3 gap-2.5">
        {dataOrder.map((data, index) => (
          <Card
            key={index}
            title={data.title}
            content={data.content}
            image={data.image}
            loading={isLoading || isRefetching}
          />
        ))}
      </div>
    </div>
  );
}

export default forwardRef<LocationRefs, LocationProps>(Location);
