import { IRouteProps } from '_common/interface/router';
import CourseActivity from '.';
import { COURSE_ACTIVITY_URL } from './url';
import CourseActivityDetail from './containers/Detail';

export const CourseActivityRouter: IRouteProps[] = [
  {
    key: COURSE_ACTIVITY_URL.list.key,
    path: COURSE_ACTIVITY_URL.list.shortenUrl,
    component: <CourseActivity />,
  },
  {
    key: COURSE_ACTIVITY_URL.detail.key,
    path: COURSE_ACTIVITY_URL.detail.shortenUrl,
    component: <CourseActivityDetail />,
  },
];
