import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CommissionConfigEndpoint = `${endpoint}/bake-teach/course`;
const CommissionConfigApi = {
  getList: async () =>
    await api
      .get(`${CommissionConfigEndpoint}/get-config-commission`)
      .then((data) => data?.data?.data),
  save: async ({ params }) =>
    await api
      .put(`${CommissionConfigEndpoint}/save-config-commission`, params)
      .then((result) => result?.data),
};
export default CommissionConfigApi;
