import { messageValidate } from '_common/constants/message';
import Radio from '_common/dof/Control/Radio';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectUser from '_common/dof/Select/SelectUser';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FORMAT_DATE_DASHBOARD, listOptions } from 'pages/Dashboard/constants';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Row } from 'tera-dls';
import { StatisticRevenueBySystemContext } from '.';

interface IProps {
  open: boolean;
  onClose?: () => void;
}

const SettingModal = (props: IProps) => {
  const { onClose, open } = props;

  const form = useForm({ mode: 'onChange' });
  const time_type = form.watch('type_chart') || 'month';
  const { contextParams, setContextParams } = useContext(
    StatisticRevenueBySystemContext,
  );

  const handleSubmitForm = (value: any): void => {
    setContextParams({ ...value });
    onClose();
  };

  useEffect(() => {
    if (contextParams) {
      Object.entries(contextParams).forEach(
        ([fieldName, fieldValue]: [any, any]) => {
          form.setValue(fieldName, fieldValue);
        },
      );
    }
  }, [contextParams]);

  return (
    <Modal
      title="Tham số báo cáo"
      destroyOnClose
      closeIcon={false}
      className={'!w-[600px]'}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={onClose}
      open={open}
      centered={true}
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <Row className="gap-y-0">
          <FormTeraItem name="user_id" label="Người dùng">
            <SelectUser
              paramsApi={{
                include_id: contextParams?.user_id ?? undefined,
              }}
              placeholder="Tất cả người dùng"
            />
          </FormTeraItem>

          <FormTeraItem name="type_chart" label="Thống kê theo">
            <Radio
              listOption={listOptions}
              inline
              onChangeCapture={() => {
                form.setValue('time', [
                  moment().startOf('months'),
                  moment().endOf('months'),
                ]);
              }}
              className="flex justify-between"
            />
          </FormTeraItem>
          <FormTeraItem
            name="time"
            label="Giai đoạn"
            rules={[
              {
                required: messageValidate.emptySelect,
              },
            ]}
          >
            <RangePicker
              picker={time_type}
              format={FORMAT_DATE_DASHBOARD?.[time_type]}
              placeholder={['Bắt đầu', 'Kết thúc']}
              disabledDate={(currentDate, { type, from }) => {
                let limitDate;
                if (type === 'date' && !!from) {
                  limitDate = from.clone()?.add(3, 'months');
                }
                if (type === 'month' && !!from) {
                  limitDate = from.clone()?.add(1, 'year');
                }
                if (type === 'quarter' && !!from) {
                  limitDate = from.clone()?.add(10, 'year');
                }
                if (limitDate && currentDate.isAfter(limitDate)) return true;
                return false;
              }}
            />
          </FormTeraItem>
        </Row>
      </FormTera>
    </Modal>
  );
};

export default observer(SettingModal);
