type DescribeProductProps = {
  dataDetail: any;
};
const DescriptionProduct = ({ dataDetail }: DescribeProductProps) => {
  return (
    <div
      className={'w-full xmd:w-3/4 xl:w-1/2 mx-auto my-0 tailwind-preflight'}
    >
      <div dangerouslySetInnerHTML={{ __html: dataDetail?.information }} />
    </div>
  );
};

export default DescriptionProduct;
