import { useMutation, useQueryClient } from '@tanstack/react-query';
import Recharge from '_common/component/Icons/Recharge';
import Withdraw from '_common/component/Icons/Withdraw';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import {
  ColumnsType,
  CreditCardOutlined,
  DropdownItem,
  formatCurrency,
  formatDate,
  notification,
  Tag,
} from 'tera-dls';
import TransactionHistoryApi from '../../api';
import {
  TRANSACTION_HISTORY_METHOD,
  TRANSACTION_HISTORY_STATUS,
  TRANSACTION_HISTORY_STATUS_COLOR,
} from '../../constants';
import TransactionHistoryDetail from '../Detail';
import { INVOICE_URL } from 'pages/Finance/Invoice/url';
import { useNavigate } from 'react-router-dom';

function TransactionHistoryTable(props: ITeraTableProps) {
  const [isOpenDetail, setIsOpenDetail] = useState({ open: false, id: null });
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateChangeStatus, isLoading: isLoadingMutate } =
    useMutation(
      (variables: any) => {
        if (variables?.params?.status == 'approved') {
          return TransactionHistoryApi.approval({
            id: variables?.id,
            params: variables?.params,
          });
        }
        if (variables?.params?.status == 'fail')
          return TransactionHistoryApi.fail({
            id: variables?.id,
            params: variables?.params,
          });
        if (variables?.params?.status == 'error')
          return TransactionHistoryApi.error({
            id: variables?.id,
            params: variables?.params,
          });
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-invoice-list']);
            queryClient.invalidateQueries(['get-transaction-history-list']);
            queryClient.invalidateQueries([
              'get-summary-transaction-history-list',
            ]);
          }
        },
        onError: (error: any) => {
          ErrorToast({ errorProp: error?.data });
        },
      },
    );

  const handleChangeStatus = (
    record,
    status: 'approved' | 'error' | 'fail',
  ) => {
    const statusText = {
      approved: 'hoàn thành',
      error: 'báo lỗi',
      fail: 'thất bại',
    };
    const statusCode = {
      approved: 'approved',
      error: 'error',
      fail: 'fail',
    };
    confirm.warning({
      title: `Xác nhận ${statusText[status]} giao dịch`,
      content: (
        <div className="break-word">
          <p>
            Bạn có chắc chắn muốn{' '}
            <span className="font-semibold">{statusText[status]}</span> giao
            dịch
          </p>
          <p>
            <b>{record?.transaction_code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateChangeStatus({
          id: record?.id,
          params: {
            status: statusCode[status],
          },
        });
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    // if (hasPage(TRANSACTION_HISTORY_PERMISSION_KEY.VIEW_DETAIL))
    items.push({
      key: '1',
      label: 'Xem',
      onClick: () => setIsOpenDetail({ open: true, id: record?.id }),
    });
    if (
      // hasPage(TRANSACTION_HISTORY_PERMISSION_KEY.CHANGE_STATUS) &&
      ['pending', 'error'].includes(record?.status)
    )
      items.push({
        key: '2',
        label: 'Hoàn thành',
        onClick: () => handleChangeStatus(record, 'approved'),
      });
    if (
      // hasPage(TRANSACTION_HISTORY_PERMISSION_KEY.CHANGE_STATUS) &&
      ['pending', 'error'].includes(record?.status)
    )
      items.push({
        key: '3',
        label: 'Thất bại',
        onClick: () => handleChangeStatus(record, 'fail'),
      });
    if (
      // hasPage(TRANSACTION_HISTORY_PERMISSION_KEY.CHANGE_STATUS) &&
      ['pending'].includes(record?.status)
    )
      items.push({
        key: '4',
        label: 'Báo lỗi',
        onClick: () => handleChangeStatus(record, 'error'),
      });
    return items;
  };

  const RenderTransactionType = ({ type }) => {
    const objectType = {
      deposit: { name: 'Nạp tiền', icon: <Recharge /> },
      withdraw: { name: 'Rút tiền', icon: <Withdraw /> },
      payment: {
        name: 'Thanh toán',
        icon: <CreditCardOutlined className="text-blue-500 w-4" />,
      },
    };

    return (
      <div className="flex items-center gap-x-2">
        {objectType[type]?.icon}
        <span> {objectType[type]?.name}</span>
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_code',
      width: 100,
      render: (data, record) => (
        <span
          className="line-clamp-1 inline-block text-blue-500 cursor-pointer"
          onClick={() => {
            setIsOpenDetail({ open: true, id: record?.id });
          }}
        >
          {data}
        </span>
      ),
    },
    {
      title: 'Mã hóa đơn',
      dataIndex: 'invoice_code',
      width: 100,
      render: (data, record) => (
        <span
          className="line-clamp-1 inline-block text-blue-500 cursor-pointer"
          onClick={() => {
            record?.invoice_id &&
              navigate(`${INVOICE_URL.detail.path}/${record?.invoice_id}`);
          }}
        >
          {data}
        </span>
      ),
    },
    {
      title: 'Người dùng',
      dataIndex: 'user_name',
      width: 130,
      render: (user_name) => <p className="line-clamp-1 ">{user_name}</p>,
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 100,
      render: (transaction_type) => (
        <RenderTransactionType type={transaction_type} />
      ),
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      width: 100,
      render: (amount) => formatCurrency(amount),
    },
    {
      title: 'Phương thức',
      dataIndex: 'methods',
      width: 100,
      render: (methods) => TRANSACTION_HISTORY_METHOD[methods],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (status) =>
        status && (
          <Tag color={TRANSACTION_HISTORY_STATUS_COLOR[status]}>
            {TRANSACTION_HISTORY_STATUS[status]}
          </Tag>
        ),
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      width: 100,
      render: (created_at) =>
        created_at && formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Thời gian cập nhật',
      dataIndex: 'updated_at',
      width: 100,
      render: (updated_at) =>
        updated_at && formatDate(updated_at, DATE_TIME_FORMAT),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        columns={columns}
        loading={props?.loading || isLoadingMutate}
        {...props}
      />
      {isOpenDetail.open && (
        <TransactionHistoryDetail
          id={isOpenDetail.id}
          open={isOpenDetail.open}
          onCancel={() => setIsOpenDetail({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default TransactionHistoryTable;
