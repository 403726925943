import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCourseCategory from '_common/dof/Select/SelectCourseCategory';
import _ from 'lodash';
import { COURSE_TYPE } from 'pages/CourseManagement/Course/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function CourseOrderFilter({ open, initialValue, onClose, onFilter }: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    const values = {
      type: null,
      course_category_id: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;
    const data = _.pick(initialValue, ['type', 'course_category_id']);
    form.reset(data);
  }, [initialValue]);

  const typeOptions = Object.entries(COURSE_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem name="course_category_id" label="Danh mục khóa học">
          <SelectCourseCategory
            allowClear
            paramsApi={{ include_id: initialValue?.category_id }}
          />
        </FormTeraItem>
        <FormTeraItem label="Loại khóa học" name="type">
          <Select options={typeOptions} allowClear />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default CourseOrderFilter;
