import { useQuery } from '@tanstack/react-query';
import { HorizontalBarChartType } from '_common/component/Chart/BarChart/HorizontalBarChart';
import VerticalBarChart from '_common/component/Chart/BarChart/VerticalBarChart';
import { COURSE_TYPE } from 'pages/CourseManagement/Course/constants';
import React, { useMemo, useState } from 'react';
import { Spin } from 'tera-dls';
import CourseReportApi from '../../api';
import ModalChart from './ModalChart';
import Header from 'pages/Dashboard/Header';

interface CourseTypeProps {
  type?: HorizontalBarChartType;
  chartClassName?: string;
  onClose?: () => void;
}

export const CourseType = (props: CourseTypeProps) => {
  const { chartClassName, type, onClose } = props;
  const isSmallType = type === 'small';
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data, refetch, isFetching } = useQuery(
    ['get-course-type-report'],
    () => CourseReportApi.getCourseType(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const dataChart = useMemo(() => {
    if (!data) return { labels: [], datasets: [{ data: [] }] };
    const datasets = data?.map((item) => item?.total_count);
    const labels = data?.map((item) => COURSE_TYPE[item?.type]);
    return {
      labels,
      datasets: [
        {
          data: datasets,
          backgroundColor: '#E3A008',
        },
      ],
    };
  }, [data]);

  return (
    <div className="rounded border bg-white flex flex-col gap-2.5 p-5">
      <Header
        title="Số lượng loại khóa học"
        onClickRefresh={refetch}
        onZoomOut={() => setOpenModal(true)}
        onZoomIn={() => onClose()}
        isSmallType={isSmallType}
        isRefetch={false}
        isSetting={false}
      />
      <Spin spinning={isFetching}>
        <VerticalBarChart
          showLegend={false}
          data={dataChart}
          type={'large'}
          chartClassName={chartClassName}
          options={{
            interaction: {
              axis: 'y',
            },
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </Spin>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </div>
  );
};
