import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Controller } from 'react-hook-form';
import customTwMerge from 'tailwind-merge.config';
import { InputProps, Input as InputTera } from 'tera-dls';

const Input = forwardRef<HTMLInputElement, InputProps | any>((props, ref) => {
  const inputRef = useRef(null);
  const { form } = useTeraForm();
  const { item, config, rules } = useTeraFormItem();
  const { control } = form;

  useImperativeHandle(ref, () => inputRef.current);

  return (
    <Controller
      {...item}
      control={control}
      rules={rules}
      render={({ field }) => (
        <InputTera
          type="confirm-password"
          data-object_type={item?.object_type}
          data-object_id={item?.object_id}
          placeholder="Vui lòng nhập"
          {...field}
          ref={(el) => {
            field.ref(el);
            inputRef.current = el;
          }}
          {...props}
          {...config?.field}
          onChange={(val) => {
            field?.onChange?.(val);
            props?.onChange?.(val);
          }}
          className={customTwMerge(
            'w-full',
            props?.className,
            config?.class_name,
          )}
        />
      )}
    />
  );
});

export default Input;
