import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  messageValidate,
  messageValidateLength,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectProductCategoryParent from '_common/dof/Select/SelectProductCategoryParent';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import { default as ProductCategoryApi } from '../../api';
import ImageBackground from '_common/component/ImageBackground';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const ProductCategoryForm = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const [image, setImage] = useState<any>();
  const form = useForm();
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(
    ['get-product-category-detail', id],
    () => ProductCategoryApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (detail) {
      setImage(detail?.file_upload ?? { url: null });
      form.reset({
        id: detail?.id,
        parent_id: detail?.parent_id,
        name: detail?.name,
        description: detail?.description,
      });
    }
  }, [detail]);

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      id
        ? ProductCategoryApi.update(variables)
        : ProductCategoryApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-product-category-list']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
        ...(image && { file_upload: image }),
      },
      ...(id && { id }),
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={id ? 'Sửa danh mục sản phẩm' : 'Thêm danh mục sản phẩm'}
        closeIcon={false}
        okText="Đồng ý"
        cancelText="Hủy"
        width={500}
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
      >
        <Spin spinning={!!id && isLoading}>
          <FormTera form={form} isLoading={false}>
            <FormTeraItem label="Ảnh đại diện" name="">
              <ImageBackground
                value={image}
                onChange={(val) => {
                  setImage(val);
                }}
                isShowBtnDelete
                imageClassName="!size-[100px] object-cover"
                object_key={'course-category'}
                folder={'bake-teach'}
              />
            </FormTeraItem>
            <FormTeraItem
              label="Tên danh mục"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                  maxLength: {
                    value: 255,
                    message: messageValidateLength.textLong,
                  },
                },
              ]}
            >
              <Input placeholder="Vui lòng nhập" />
            </FormTeraItem>
            <FormTeraItem
              label="Danh mục cha"
              name="parent_id"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <SelectProductCategoryParent />
            </FormTeraItem>
            <FormTeraItem label="Mô tả" name="description">
              <TextArea maxLength={1000} rows={10} />
            </FormTeraItem>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default ProductCategoryForm;
