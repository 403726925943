import { ReactNode } from 'react';
import {
  ArrowPathOutlined,
  ArrowsPointingInOutlined,
  ArrowsPointingOuOutlined,
  Cog6ToothOutlined,
  Tooltip,
} from 'tera-dls';

interface HeaderProps {
  sub?: string | ReactNode;
  isSmallType?: boolean;
  title?: string;
  isZoom?: boolean;
  isRefetch?: boolean;
  isSetting?: boolean;
  renderComponent?: ReactNode;
  onClickRefresh?: () => void;
  onClickSetting?: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
}

const Header = ({
  title,
  sub,
  isSmallType,
  isZoom = true,
  isRefetch = true,
  isSetting = true,
  renderComponent,
  onClickRefresh,
  onZoomIn,
  onZoomOut,
  onClickSetting,
}: HeaderProps) => {
  return (
    <div className="flex item-center justify-between">
      <div>
        <p className="font-medium text-[#1F2937] uppercase">{title}</p>
        {sub && <p>{sub}</p>}
      </div>
      <div className="flex gap-2.5 items-center">
        {isRefetch && (
          <Tooltip title="Làm mới" className="z-[99]">
            <span className="flex items-center">
              <ArrowPathOutlined
                className="cursor-pointer w-4 h-4"
                onClick={onClickRefresh}
              />
            </span>
          </Tooltip>
        )}

        {isSetting && (
          <Tooltip title="Tham số báo cáo" className="z-[99]">
            <span className="flex items-center">
              <Cog6ToothOutlined
                className="cursor-pointer w-4 h-4"
                onClick={onClickSetting}
              />
            </span>
          </Tooltip>
        )}

        {isZoom && (
          <>
            {isSmallType ? (
              <Tooltip title="Mở rộng" className="z-[99]">
                <span className="flex items-center">
                  <ArrowsPointingOuOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={onZoomOut}
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Thu gọn" className="z-[99]">
                <span className="flex items-center">
                  <ArrowsPointingInOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={onZoomIn}
                  />
                </span>
              </Tooltip>
            )}
          </>
        )}
        {renderComponent}
      </div>
    </div>
  );
};

export default Header;
