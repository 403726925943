import VideoBackground from '_common/component/VideoBackground';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { Controller } from 'react-hook-form';

const VideoForm = () => {
  const { form } = useTeraForm();
  const { item, rules } = useTeraFormItem();
  const { control } = form;

  return (
    <Controller
      {...item}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <VideoBackground
            value={{ url: field?.value }}
            onChange={(val: any) => {
              field?.onChange?.(val ? val?.url : null);
            }}
            isShowBtnDelete
            object_key={'lecture-item'}
            folder={'lecture-item'}
            showThumbnail={true}
          />
        );
      }}
    />
  );
};

export default VideoForm;
