import { Row } from 'tera-dls';
import ChartLecturer from './containers/ChartLecturer';
import ChartStudent from './containers/ChartStudent';
import Overview from './containers/Overview';
import TableUserReport from './containers/Table';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const UserReport = () => {
  return (
    <div className="tera-page">
      <div className="text-gray-700 font-semibold text-base uppercase mb-[13px]">
        Báo cáo người dùng
      </div>
      <div className="flex flex-col gap-2.5">
        <Overview />
        <Row className="grid-cols-2 gap-2.5">
          <ChartLecturer />
          <ChartStudent />
        </Row>
        <TableUserReport />
      </div>
    </div>
  );
};

export default UserReport;
