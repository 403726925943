import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const RefundEndpoint = `${endpoint}/bake-teach/finance/refund`;

const RefundApi = {
  getList: async ({ params }) =>
    await api
      .get(`${RefundEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getSummaryList: async ({ params }) =>
    await api
      .get(`${RefundEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${RefundEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  delete: async ({ id }) =>
    await api.delete(`${RefundEndpoint}/delete/${id}`).then((res) => res.data),
  approve: async ({ id }) =>
    await api.put(`${RefundEndpoint}/approve/${id}`).then((res) => res.data),
  reject: async ({ id }) =>
    await api.put(`${RefundEndpoint}/reject/${id}`).then((res) => res.data),
};

export default RefundApi;
