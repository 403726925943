import { useQuery } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { textRating } from '_common/utils';
import { renderRating } from '_common/utils/utils';
import _ from 'lodash';
import moment from 'moment';
import { IFormModel } from 'pages/CourseManagement/Course';
import EvaluateApi from 'pages/CourseManagement/Course/api/Evaluate';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import no_homework from 'styles/images/no_homework.png';
import customTwMerge from 'tailwind-merge.config';
import {
  DropdownItem,
  formatNumber,
  getQueryParams,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import StudentEvaluateDetail from './Detail';
import Searching from './Searching';

const StudentEvaluate = ({ dataDetail }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });

  const params = _.pick(queryParams, ['studentEvaluate']);
  const paramsObject = params?.studentEvaluate
    ? JSON.parse(params?.studentEvaluate)
    : {};

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      studentEvaluate: JSON.stringify({
        ...paramsObject,
        ...data,
      }),
    });
    navigate(location.pathname + paramString);
  };

  const {
    data: response,
    // refetch,
    isFetching,
  } = useQuery(
    ['get-evaluate-list', params, dataDetail],
    () => {
      const queryParams =
        params?.studentEvaluate && JSON.parse(params?.studentEvaluate);

      return EvaluateApi.getList({
        page: 1,
        limit: 10,
        course_id: dataDetail?.id,
        ...queryParams,
      });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const {
    data: summary,
    // refetch,
  } = useQuery(
    ['get-evaluate-summary-list', params, dataDetail],
    () => {
      const queryParams =
        params?.studentEvaluate && JSON.parse(params?.studentEvaluate);

      return EvaluateApi.getSummary({
        ...queryParams,
        course_id: dataDetail?.id,
      });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const data = [
    {
      id: 'all',
      name: 'Tất cả',
      count: 0,
    },
    {
      id: 5,
      name: '5 sao',
      count: 0,
    },
    {
      id: 4,
      name: '4 sao',
      count: 0,
    },
    {
      id: 3,
      name: '3 sao',
      count: 0,
    },
    {
      id: 2,
      name: '2 sao',
      count: 0,
    },
    {
      id: 1,
      name: '1 sao',
      count: 0,
    },
  ];

  const dataSummary = useMemo(() => {
    if (!summary) return data;
    const result = data.map((item) => {
      const count =
        summary?.find((sum) => sum?.star == item?.id)?.total_count ?? 0;
      return {
        ...item,
        count,
      };
    });
    return result;
  }, [data, summary]);

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => setOpenFormModel({ open: true, value: record?.id }),
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Tên học viên',
      dataIndex: 'user',
      width: 200,
      render: (user) => (
        <HoverQuickView name={user?.full_name}>
          <span className="line-clamp-1 text-blue-600">{user?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số sao đánh giá',
      dataIndex: 'star_count',
      width: 150,
      render: (star_count) => (
        <div>
          <div className="flex gap-1 text-[#E3A008] items-center">
            {renderRating(star_count)}
            {textRating(star_count)}
          </div>
        </div>
      ),
    },
    {
      title: 'Thời gian đánh giá',
      dataIndex: 'created_at',
      width: 150,
      render: (val) => moment(val).format(DATE_TIME_FORMAT),
    },

    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];
  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(response?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  return !dataDetail?.average_rating ? (
    <div className="w-full flex justify-center py-5">
      <div className="flex flex-col justify-center gap-[24px]">
        <img src={no_homework} className="size-[100px] ml-2" />
        <p className="text-[#374151] tracking-[1px]">Chưa có đánh giá nào</p>
      </div>
    </div>
  ) : (
    <>
      <div className="space-y-3">
        <div className="flex gap-3 px-2.5">
          <div className="space-y-[5px] gap-[5px] text-center inline-block">
            <span className="font-medium text-yellow-500 font-base">
              {formatNumber(dataDetail?.average_rating)}
            </span>
            {renderRating(dataDetail?.average_rating)}
          </div>
          <div>
            <div className="flex gap-[5px]">
              {dataSummary?.map((item) => (
                <div
                  key={item?.id}
                  className={customTwMerge(
                    'rounded border border-[#D1D5DB] flex justify-center py-[4px] w-[160px] cursor-pointer',
                    item.id === (paramsObject?.star_count ?? 'all') &&
                      'border-blue-600 text-blue-600 cursor-default',
                  )}
                  onClick={() =>
                    handleUpdateQueryParams({
                      star_count: item.id === 'all' ? undefined : item.id,
                    })
                  }
                >
                  {item?.name} ({item?.count})
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pt-2.5 space-y-2.5 rounded shadow">
          <div className="flex justify-end pr-2.5">
            <Searching onSearch={handleSearch} defaultValues={params} />
          </div>
          <TableTera
            loading={isFetching}
            wrapperClassName="shadow-none"
            rowKey={'id'}
            data={response?.data || []}
            className="center-table"
            pagination={{
              onChange: handleChangePage,
              total: response?.total || 0,
              current: response?.current_page,
              pageSize: response?.per_page || 10,
              to: response?.to,
              from: response?.from || 10,
            }}
            columns={columns}
          />
        </div>
      </div>
      {openFormModel.open && (
        <StudentEvaluateDetail
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          value={openFormModel.value}
        />
      )}
    </>
  );
};

export default StudentEvaluate;
