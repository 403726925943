import { DATE_TIME_FORMAT } from '_common/constants/common';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { formatDate } from 'tera-dls';
import { ReactComponent as IconCourse } from 'styles/images/Notification/course.svg';
import { ReactComponent as IconAffiliate } from 'styles/images/Notification/affiliate.svg';
import { ReactComponent as IconFinance } from 'styles/images/Notification/finance.svg';
import { ReactComponent as IconLecturer } from 'styles/images/Notification/lecturer.svg';

type TIconNotification = 'investor' | 'sale' | 'hr' | 'finance';

interface ItemProps {
  type?: TIconNotification;
  isUnread?: boolean;
  content?: string;
  title?: string;
  date?: string;
  onClick?: () => void;
}

function Item(props: ItemProps, ref) {
  const { isUnread, type = 'investor', content, title, date, onClick } = props;

  const renderIcon = (type: TIconNotification) => {
    const icon = {
      course: <IconCourse />,
      lecturer: <IconLecturer />,
      affiliate: <IconAffiliate />,
      finance: <IconFinance />,
    };
    return icon[type];
  };

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={classNames(
        'p-2.5 flex justify-between cursor-pointer hover:bg-gray-50',
        {
          'bg-blue-50 hover:!bg-blue-100': isUnread,
        },
      )}
    >
      <div className="flex items-center gap-x-2.5">
        <span>{renderIcon(type)}</span>
        <div>
          <p className="text-sm font-medium line-clamp-1 break-word">{title}</p>
          <div
            className="text-xs line-clamp-1 break-word"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
      <p className="text-xs text-gray-500 shrink-0">
        {formatDate(date, DATE_TIME_FORMAT)}
      </p>
    </div>
  );
}

export default forwardRef(Item);
