import Filter from '_common/component/Filter';
import {
  DATE_BACKEND_FORMAT,
  MAXIMUM_CURRENCY,
} from '_common/constants/common';
import RangeNumber from '_common/dof/Control/RangeNumber';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { APPLY_TYPE } from 'pages/Affiliate/Config/constants';
import { ORDER_STATUS } from 'pages/Affiliate/Overview/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function FilterData({ open, initialValue, onClose, onFilter }: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter({
      ...values,
      start_order_date: values?.time?.[0]?.format(DATE_BACKEND_FORMAT),
      end_order_date: values?.time?.[1]?.format(DATE_BACKEND_FORMAT),
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      time: null,
      start_order_date: null,
      end_order_date: null,
      from_price: null,
      to_price: null,
      type_order: null,
      from_commission: null,
      to_commission: null,
      status: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    if (!initialValue) return;
    form.reset({
      ...initialValue,
      ...(initialValue?.start_order_date &&
        initialValue?.end_order_date && {
          time: [
            moment(initialValue?.start_order_date),
            moment(initialValue?.end_order_date),
          ],
        }),
    });
  }, [initialValue]);

  const typeOptions = Object.entries(_.omit(APPLY_TYPE, ['new_join'])).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  const statusOptions = Object.entries(ORDER_STATUS).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Ngày đặt hàng" name="time">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <FormTeraItem name="type_order" label="Loại đơn">
          <Select options={typeOptions} allowClear />
        </FormTeraItem>

        <FormTeraItem name="" label="Giá khóa học">
          <RangeNumber
            startFormName="from_price"
            endFormName="to_price"
            endInputProps={{
              max: MAXIMUM_CURRENCY,
              min: 0,
              placeholder: 'Đến khoảng',
            }}
            startInputProps={{
              max: MAXIMUM_CURRENCY,
              min: 0,
              placeholder: 'Từ khoảng',
            }}
          />
        </FormTeraItem>
        <FormTeraItem name="" label="Hoa hồng">
          <RangeNumber
            startFormName="from_commission"
            endFormName="to_commission"
            endInputProps={{
              min: 0,
              max: MAXIMUM_CURRENCY,
              placeholder: 'Đến khoảng',
            }}
            startInputProps={{
              min: 0,
              max: MAXIMUM_CURRENCY,
              placeholder: 'Từ khoảng',
            }}
          />
        </FormTeraItem>
        <FormTeraItem name="status" label="Trạng thái đơn hàng">
          <Select options={statusOptions} allowClear />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default FilterData;
