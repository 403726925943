import TableTera from '_common/dof/TableTera';
import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import { observer } from 'mobx-react-lite';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import {
  Button,
  formatCurrency,
  Image,
  notification,
  PaginationProps,
  PlusCircleOutlined,
  TrashOutlined,
} from 'tera-dls';
import ProductModal from './ProductModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CourseProductApi from '../../api/Product';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { PRODUCT_URL } from 'pages/SaleManagement/Product/url';
import { useNavigate } from 'react-router-dom';
import imageDefault from 'styles/images/image-default.png';

interface IProps {
  mode?: 'create' | 'update';
  dataDetail?: any;
}

const Device = ({ mode = 'create', dataDetail }: IProps, ref) => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const {
    course: { device, setDevice, nextStep },
  } = useStores();

  const [data, setData] = useState<any>([]);
  const [params, setParams] = useState<any>({ page: 1, limit: 10 });
  const queryClient = useQueryClient();
  const [openProduct, setOpenProduct] = useState<boolean>(false);
  const isUpdateMode = mode == 'update';

  const {
    data: list,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-course-device-list', dataDetail?.id],
    () =>
      CourseProductApi.getList({
        course_id: dataDetail?.id,
        ...params,
        key_default: 'device',
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!dataDetail?.id,
    },
  );

  useEffect(() => {
    dataDetail?.id && fetchData();
  }, [dataDetail?.id]);

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => {
        nextStep();
      },
    };
  });
  useEffect(() => {
    if (isUpdateMode && !!list?.data) {
      setData(
        list?.data?.map((i) => ({ ...i, ...(i?.product ?? {}), key: i?.id })),
      );
      return;
    }
    device?.length > 0 && setData(device);
  }, [device, list?.data, isUpdateMode]);

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseProductApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-device-list']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xoá thiết bị',
      content: (
        <>
          <p>Bạn có chắc muốn xóa thiết bị</p>
          <p>
            <span className="font-bold break-word">{name}</span> này không?
          </p>
        </>
      ),
      onOk: () => {
        if (isUpdateMode) {
          mutateDelete({ id });
          return;
        }
        setData((prev) => {
          const data = prev?.filter((i) => i?.id !== id);
          setDevice(data);
          return data;
        });
      },
    });
  };

  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (val, record) => (
        <div className="flex gap-x-2 items-start">
          <div className="h-[35px]">
            <Image
              defaultImage={imageDefault}
              alt={''}
              imageClassName="object-cover"
              src={record?.image_url}
              containerClassName={customTwMerge(
                'size-[35px] rounded overflow-hidden',
              )}
            />
          </div>

          <div className="flex flex-col gap-1 ">
            <p
              className="text-[#1C64F2] cursor-pointer"
              onClick={() =>
                navigate(`${PRODUCT_URL.detail.path}/${record?.id}`)
              }
            >
              {val}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: 'Danh mục sản phẩm',
      dataIndex: 'category',
      width: 150,
      render: (val) => val?.name,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_key',
      width: 150,
      render: (val) => UNIT_TYPE[val],
    },
    {
      title: 'Đơn giá',
      dataIndex: 'unit_price',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Giá khuyến mãi',
      dataIndex: 'promotional_price',
      width: 150,
      render: (val) => (val || val === 0) && formatCurrency(val),
    },
    {
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: any) => (
        <div className="flex justify-center">
          <TrashOutlined
            className="text-red-500 size-5 cursor-pointer"
            onClick={() =>
              handleDelete(
                isUpdateMode ? record?.key : record?.id,
                record?.name,
              )
            }
          />
        </div>
      ),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    setParams({
      page,
      limit: pageSize,
    });
  };

  return (
    <>
      <div className="space-y-2.5">
        <h4 className="text-blue-500 font-medium mb-5 ">Thêm gợi ý thiết bị</h4>
        <div className="flex justify-end">
          <Button
            className="font-normal flex gap-1"
            onClick={() => setOpenProduct(true)}
          >
            <PlusCircleOutlined className="w-4" /> <span>Thêm thiết bị</span>
          </Button>
        </div>
        <TableTera
          rowKey={'id'}
          data={data}
          loading={isUpdateMode && isFetching}
          pagination={{
            ...(isUpdateMode && {
              onChange: handleChangePage,
              total: list?.total || 0,
              current: list?.current_page,
              pageSize: list?.per_page || 10,
              to: list?.to,
              from: list?.from || 10,
            }),
          }}
          className="center-table"
          columns={columns}
        />
      </div>
      {openProduct && (
        <ProductModal
          open={openProduct}
          onClose={() => setOpenProduct(false)}
          selectedValue={data}
          onSubmit={(values) => {
            setData((prev = []) => {
              const data = [...prev, ...values];
              setDevice(data);
              return data;
            });
          }}
          type="device"
          mode={mode}
          courseId={dataDetail?.id}
          defaultKey={'device'}
        />
      )}
    </>
  );
};

export default observer(forwardRef(Device));
