import { IRouteProps } from '_common/interface/router';
import Student from '.';
import { STUDENT_URL } from './url';
import StudentDetail from './containers/Detail';
import StudentForm from './containers/Form';

export const StudentRouter: IRouteProps[] = [
  {
    key: STUDENT_URL.list.key,
    path: STUDENT_URL.list.shortenUrl,
    component: <Student />,
  },
  {
    key: STUDENT_URL.detail.key,
    path: STUDENT_URL.detail.shortenUrl,
    component: <StudentDetail />,
  },
  {
    key: STUDENT_URL.update.key,
    path: STUDENT_URL.update.shortenUrl,
    component: <StudentForm />,
  },
];
