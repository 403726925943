import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, notification, Spin } from 'tera-dls';

import { convertClassNameToInlineStyle } from '_common/component/CkEditor';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import FormTera from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { default as CourseApi } from '../../api';
import { COURSE_URL } from '../../url';
import InformationView from '../Form/InformationView';

const CourseUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const form = useForm({ mode: 'onChange' });
  const [image, setImage] = useState<any>();

  const typeFile = form.watch('link_url_type');
  const queryClient = useQueryClient();
  const price = form.watch('price');
  const typeDirectFile = form.watch('link_url_direct_type');
  const link_url_type = form.watch('link_url_type');
  const link_url_direct_type = form.watch('link_url_direct_type');

  const {
    data: dataDetail,
    isFetching,
    refetch,
  } = useQuery(['get-course-detail', id], () => CourseApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    if (dataDetail) {
      form.reset({
        ...dataDetail,
        is_active: Boolean(dataDetail?.is_active),
        ...(!!dataDetail?.discount_time?.[0] && {
          discount_time: [
            moment(dataDetail?.discount_time?.[0]),
            moment(dataDetail?.discount_time?.[1]),
          ],
          have_limit_student: Boolean(dataDetail?.have_limit_student),
        }),
      });
      !!dataDetail?.image
        ? setImage({ url: dataDetail?.image_url })
        : setImage({ url: '' });
    }
  }, [dataDetail]);

  useEffect(() => {
    !!id && refetch();
  }, [id]);

  const { mutate: mutateUpdate } = useMutation(
    (variables: any) => CourseApi.update(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-course-summary-list']);
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateUpdate({
      id,
      params: {
        ...values,
        classification: dataDetail?.classification,
        discount_time: values?.discount_time?.[0] && [
          values?.discount_time?.[0]?.format(DATE_BACKEND_FORMAT),
          values?.discount_time?.[1]?.format(DATE_BACKEND_FORMAT),
        ],
        link_url: {
          url: values?.link_url,
        },
        ...(values?.link_url_direct && {
          link_url_direct: {
            url: values?.link_url_direct,
          },
        }),
        file_upload: image,
        information: convertClassNameToInlineStyle(
          (window as any).editor ? (window as any).editor.getData() : null,
        ),
        have_limit_student: Number(values?.have_limit_student),
        is_active: Number(values?.is_active),
      },
    });
  };

  const buttonAction = (
    <>
      <div className={'flex gap-x-2.5'}>
        <ButtonCommon
          onClick={form.handleSubmit(handleSubmitForm)}
          className="page-header-btn"
        >
          Lưu
        </ButtonCommon>
      </div>
    </>
  );

  return (
    <Spin spinning={isFetching}>
      <div className="tera-page-form !gap-0">
        <div className="page-header-sticky bg-[#F3F3F9]">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                containerItemClassName="text-sm"
                items={[
                  {
                    title: (
                      <a className="text-blue-400 hover:text-blue-600">
                        Danh sách khóa học
                      </a>
                    ),
                    onClick: () => navigate(COURSE_URL.list.path),
                  },
                  {
                    title: `Sửa khóa học ${dataDetail?.classification}`,
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function">{buttonAction}</div>
          </div>
        </div>
        <div className="page-content-v2 bg-white shadow-xsm rounded-md px-2.5 h-full mx-2.5 !mb-2.5 !gap-y-0">
          <Spin spinning={isFetching}>
            <FormTera form={form} isLoading={false} onSubmit={handleSubmitForm}>
              <InformationView
                {...{
                  form,
                  image,
                  setImage,
                  type: dataDetail?.classification,
                  typeFile,
                  price,
                  typeDirectFile,
                  link_url_type,
                  link_url_direct_type,
                }}
              />
            </FormTera>
          </Spin>
        </div>
      </div>
    </Spin>
  );
};

export default CourseUpdate;
