import { useQuery } from '@tanstack/react-query';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import DashboardApi from 'pages/Dashboard/api';
import { PORTFOLIO } from 'pages/Dashboard/constants';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import startUpIdea from 'styles/images/pages/finance/start_up_idea.png';
import {
  ChartBarProps,
  ChartPie,
  formatCurrency,
  formatDate,
  Spin,
  useDetectDevice,
} from 'tera-dls';
import Header from '../../Header';
import ModalChart from './ModalChart';

export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
  params?: any;
}

export interface CourseOrderRefs {
  refetchData?: () => void;
}

const Content = (props: IProps, ref) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { type = 'small', chartClassName, params, onClose } = props;
  const { widthScreen } = useDetectDevice();

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-dashboard-course-revenue', params],
    () => {
      const data = {
        start_date: formatDate(params?.date[0], DATE_BACKEND_FORMAT),
        end_date: formatDate(params?.date[1], DATE_BACKEND_FORMAT),
      };
      return DashboardApi.getCourseRevenue({ params: data });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const total = useMemo(() => {
    if (!data?.revenue) return 0;
    return data?.revenue?.reduce(
      (total, current) => total + current?.total_sum,
      0,
    );
  }, [data]);

  const dataSource = useMemo(() => {
    const labels = data?.revenue
      ? data?.revenue?.map(
          (value: any) => PORTFOLIO?.[value?.classification]?.name,
        )
      : [];
    const colors = data?.revenue
      ? data?.revenue?.map(
          (value: any) => PORTFOLIO?.[value?.classification]?.color,
        )
      : [];

    const datasets: any = {
      data: data?.revenue
        ? data?.revenue?.map((value: any) => value?.total_sum)
        : [],
      backgroundColor: colors,
      borderWidth: 0,
    };
    return {
      labels,
      datasets: [datasets],
    };
  }, [data]);

  const options: any = {
    plugins: {
      datalabels: {
        formatter: function (_, values) {
          const percentage = Number(
            data?.revenue[values?.dataIndex]?.percentage,
          );
          console.log('percentage', percentage);

          if (Number.isInteger(percentage)) return percentage + ' %';
          return `${percentage.toFixed(2)} %`;
        },
        color: 'white',
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 10,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: true,
      },
      maintainAspectRatio: false,
    },
  };

  const isSmallType = type === 'small';
  const checkData = (data) =>
    data?.datasets?.[0]?.data.some((value) => value !== 0);

  useImperativeHandle(
    ref,
    () => ({
      refetchData() {
        refetch();
      },
    }),
    [],
  );

  return (
    <Spin spinning={isLoading || isRefetching}>
      <Header
        title="Doanh thu loại khóa học"
        isSetting={false}
        onZoomOut={() => setOpenModal(true)}
        onZoomIn={() => onClose()}
        isSmallType={isSmallType}
        onClickRefresh={refetch}
      />
      <p className="text-[#6B7280] text-[10px]">{`${formatDate(
        params?.date?.[0],
      )} - ${formatDate(params?.date?.[1])}`}</p>

      <p>
        <span className="font-semibold text-green-500">
          {formatCurrency(total)}
        </span>{' '}
      </p>

      <div className="flex justify-center">
        {!checkData(dataSource) ? (
          <div className="flex flex-col">
            <img src={startUpIdea} className="mb-[60px]" />
            <p className="text-gray-400 font-normal text-center w-full ">
              Chưa có dữ liệu
            </p>
          </div>
        ) : (
          <ChartPie
            key={widthScreen}
            data={dataSource}
            plugins={['data-labels']}
            options={options}
            className={chartClassName}
          />
        )}
      </div>

      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
          params={params}
        />
      )}
    </Spin>
  );
};

export default forwardRef<CourseOrderRefs, IProps>(Content);
