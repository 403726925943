const QuestionIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.33333 8.33333L8.37021 8.31489C8.87965 8.06018 9.45326 8.52031 9.31512 9.07287L8.68488 11.5938C8.54674 12.1464 9.12035 12.6065 9.62979 12.3518L9.66667 12.3333M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM9 5.66667H9.00667V5.67333H9V5.66667Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default QuestionIcon;
