import { useQuery } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ImageBackground from '_common/component/ImageBackground';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import moment from 'moment';
import { useEffect } from 'react';
import { Button, Description, Modal, Spin } from 'tera-dls';
import CourseCategoryApi from '../../api';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const CourseCategoryDetail = (props: IProps) => {
  const { open, value: id, onClose } = props;

  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(
    ['get-course-category-detail', id],
    () => CourseCategoryApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết danh mục khóa học'}
        closeIcon={false}
        width={500}
        maskClosable={false}
        footer={<Button onClick={onClose}>Đóng</Button>}
      >
        <Spin spinning={isLoading}>
          <Description label="Ảnh đại diện">
            <div className="flex justify-start mb-2.5">
              <ImageBackground
                isView
                value={{ url: detail?.file_upload?.url }}
                isShowBtnDelete={false}
                imageClassName="!size-[100px] object-cover"
              />
            </div>
          </Description>
          <Description label="Mã danh mục khoá học">{detail?.code}</Description>
          <Description label="Tên danh mục khoá học">
            {detail?.name}
          </Description>
          <Description label="Người tạo">
            <HoverQuickView
              // avatarUrl={submitter?.avatar_url}
              // email={submitter?.email}
              // phone={submitter?.phone}
              // code={submitter?.code}
              name={detail?.user_created?.full_name}
            >
              <span className="line-clamp-1 text-blue-600">
                {detail?.user_created?.full_name}
              </span>
            </HoverQuickView>
          </Description>

          <Description label="Ngày tạo">
            {moment(detail?.created_at).format(DATE_TIME_FORMAT)}
          </Description>
          {detail?.user_updated && (
            <>
              <Description label="Người cập nhật">
                <HoverQuickView
                  // avatarUrl={submitter?.avatar_url}
                  // email={submitter?.email}
                  // phone={submitter?.phone}
                  // code={submitter?.code}
                  name={detail?.user_updated?.full_name}
                >
                  <span className="line-clamp-1 text-blue-600">
                    {' '}
                    {detail?.user_updated?.full_name}
                  </span>
                </HoverQuickView>
              </Description>

              <Description label="Ngày cập nhật">
                {moment(detail?.updated_at).format(DATE_TIME_FORMAT)}
              </Description>
            </>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default CourseCategoryDetail;
