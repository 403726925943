import { IRouteProps } from '_common/interface/router';
import { COURSE_CONFIG_URL } from './url';
import CourseConfig from '.';

export const CourseConfigRouter: IRouteProps[] = [
  {
    key: COURSE_CONFIG_URL.list.key,
    path: COURSE_CONFIG_URL.list.shortenUrl,
    component: <CourseConfig />,
  },
];
