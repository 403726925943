import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo, useState } from 'react';
import {
  ChartBarProps,
  ChartLine,
  ChartLineProps,
  formatDate,
  Spin,
} from 'tera-dls';
import { StatisticRevenueBySystemContext } from '.';
import DashboardApi from '../api';
import {
  FORMAT_DATE_BACKEND_DASHBOARD,
  SYSTEM_REVENUE,
  SYSTEM_REVENUE_COLOR,
} from '../constants';
import Header from '../Header';
import { formatDateTime } from '../utils';
import ModalChart from './ModalChart';
import SettingModal from './SettingModal';
import NoData from '_common/component/NoData';
export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
}

const Content = (props: IProps) => {
  const { type = 'small', onClose, chartClassName } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const isSmallType = type === 'small';
  const [setting, setSetting] = useState({
    open: false,
  });

  const { contextParams } = useContext(StatisticRevenueBySystemContext);

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['get-system-revenue-dashboard', contextParams],
    () => {
      const data = {
        type_chart: contextParams?.type_chart,
        from: formatDate(
          contextParams?.time[0],
          FORMAT_DATE_BACKEND_DASHBOARD?.[contextParams?.type_chart],
        ),
        to: formatDate(
          contextParams?.time[1],
          FORMAT_DATE_BACKEND_DASHBOARD?.[contextParams?.type_chart],
        ),
        user_id: contextParams?.user_id || undefined,
      };
      return DashboardApi.getSystemRevenue({ params: data });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const transformData = (data) => {
    const result = {};

    Object.keys(data).forEach((type) => {
      data[type].forEach((entry) => {
        const { time, total } = entry;

        if (!result[time]) {
          result[time] = {};
        }

        result[time][type] = total;
      });
    });

    return result;
  };

  const dataChart = useMemo(() => {
    if (!data)
      return {
        labels: [],
        datasets: [],
      };
    const dataConvert = transformData(data);

    const dataFilter = Object.entries(dataConvert).reduce(
      (result, [key, value]) => {
        if (Object.values(value).every((value) => value === 0))
          return { ...result };
        return { ...result, [key]: value };
      },
      {},
    );

    const labels = Object.keys(dataFilter).map((value) => value);
    const datasets = Object.entries(SYSTEM_REVENUE)?.map(([key, value]) => {
      const dataRevenue = Object.values(dataFilter).map((i) => i[key]);
      return {
        label: value,
        data: [0, ...dataRevenue],
        tension: 0.3,
        pointRadius: 5,
        borderColor: SYSTEM_REVENUE_COLOR[key],
        backgroundColor: SYSTEM_REVENUE_COLOR[key],
        pointBackgroundColor: SYSTEM_REVENUE_COLOR[key],
        pointBorderColor: SYSTEM_REVENUE_COLOR[key],
      };
    });
    return {
      labels: ['', ...labels],
      datasets,
    };
  }, [data]);

  const isHasData = useMemo(() => {
    if (!dataChart) return false;
    const check = dataChart?.datasets?.some((item) =>
      item?.data?.some((i) => !!i),
    );
    return check;
  }, [dataChart]);

  const options: ChartLineProps['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.3,
      },
    },

    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  const renderTime = () => {
    const { time, type_chart } = contextParams;

    const start_date = time?.[0];
    const end_date = time?.[1];
    if (!start_date || !end_date) return '';
    return ` ${formatDateTime(start_date, type_chart)} - ${formatDateTime(
      end_date,
      type_chart,
    )}`;
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <Header
        isSmallType={isSmallType}
        title="Doanh thu theo hệ thống"
        // isSetting={isSmallType}
        onClickSetting={() => setSetting((prev) => ({ ...prev, open: true }))}
        onZoomIn={onClose}
        onZoomOut={() => setOpenModal(true)}
        onClickRefresh={refetch}
      />

      <div className="text-[10px]">
        <span className="text-gray-500"> {renderTime()}</span>
      </div>
      <div className={chartClassName}>
        {isHasData ? (
          <ChartLine data={dataChart} options={options} />
        ) : (
          <NoData />
        )}
      </div>

      {setting.open && (
        <SettingModal
          open={setting.open}
          onClose={() => setSetting((prev) => ({ ...prev, open: false }))}
        />
      )}

      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </Spin>
  );
};

export default observer(Content);
