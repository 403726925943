export const mockData = {
  doanhthu: [
    {
      id: 1,
      time: 'Hôm nay',
      total: 999000,
    },
    {
      id: 2,
      time: 'Hôm qua',
      total: 999000,
    },
    {
      id: 3,
      time: 'Tháng này',
      total: 999000,
    },
    {
      id: 4,
      time: 'Quý 1',
      total: 999000,
    },
    {
      id: 5,
      time: 'Quý 2',
      total: 999000,
    },
  ],
  top: [
    {
      id: 1,
      name: 'Vua Gia An',
      total: 888000,
    },
    {
      id: 2,
      name: 'Vũ Bảo Châu',
      total: 888000,
    },
    {
      id: 3,
      name: 'Đỗ Lan chi',
      total: 888000,
    },
    {
      id: 4,
      name: 'Nguyễn Thị Minh Ngọc',
      total: 888000,
    },
    {
      id: 5,
      name: 'Phạm Tú Trinh',
      total: 888000,
    },
  ],
};

export const listReferer = [
  {
    id: 11,
    user_id: 774,
    conversion_rate: 10, // tỷ lệ chuyển đổi
    commission_total: 10000000, // tổng tiền hoa hồng
    commission_percentage: 5, // hoa hồng
    date_registered: '2024-03-26 11:10:08',
    created_by: 774,
    created_at: '2024-03-26T04:08:00.000000Z',
    updated_at: '2024-03-26T04:10:08.000000Z',
    deleted_at: null,
    click: 0,
    full_name: 'Demo 1',
  },
  {
    id: 12,
    user_id: 1164,
    conversion_rate: 20,
    commission_total: 20000000,
    commission_percentage: 25,
    date_registered: '2024-03-26 11:11:24',
    created_by: 1164,
    created_at: '2024-03-26T04:11:17.000000Z',
    updated_at: '2024-03-26T04:11:24.000000Z',
    deleted_at: null,
    click: 0,
    full_name: 'Xuân Tân',
  },
];

export const labels = [
  'dd/mm/yyyy',
  'dd/mm/yyyy',
  'dd/mm/yyyy',
  'dd/mm/yyyy',
  'dd/mm/yyyy',
];

export const TEXT_RECENT_REVENUE = {
  total_today: 'Hôm nay',
  total_yesterday: 'Hôm qua',
  total_this_week: 'Tuần này',
  total_this_month: 'Tháng này',
  total_this_quarter: 'Quý này',
};

export const MARKETING_GROWTH = {
  total_count_invoice: 'Đơn hàng',
  total_count_affiliate: 'Người tham gia',
  total_count_invitation: 'Click',
};

export const MARKETING_GROWTH_COLOR = {
  total_count_invoice: '#1A56DB',
  total_count_affiliate: '#F3722C',
  total_count_invitation: '#F94144',
};
