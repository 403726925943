import { Row } from 'tera-dls';
import { CourseType } from './containers/CourseType';
import Overview from './containers/Overview';
import RegisteredStudents from './containers/RegisteredStudents';
import TableCourseReport from './containers/Table';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const Course = () => {
  return (
    <div className="tera-page">
      <div className="text-gray-700 font-semibold text-base uppercase mb-[13px]">
        Báo cáo khóa học
      </div>
      <div className="flex flex-col gap-2.5">
        <Overview />
        <Row className="grid-cols-2 gap-2.5">
          <RegisteredStudents type="small" chartClassName="!h-[350px]" />
          <CourseType type="small" chartClassName="!h-[350px]" />
        </Row>
        <TableCourseReport />
      </div>
    </div>
  );
};

export default Course;
