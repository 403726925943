import { IRouteProps } from '_common/interface/router';
import SaleOrder from '.';
import { SALE_ORDER_URL } from './url';
import SaleOrderForm from './containers/Form';
import SaleOrderDetail from './containers/Detail';

export const SaleOrderRouter: IRouteProps[] = [
  {
    key: SALE_ORDER_URL.list.key,
    path: SALE_ORDER_URL.list.shortenUrl,
    component: <SaleOrder />,
  },
  {
    key: SALE_ORDER_URL.create.key,
    path: SALE_ORDER_URL.create.shortenUrl,
    component: <SaleOrderForm />,
  },
  {
    key: SALE_ORDER_URL.update.key,
    path: SALE_ORDER_URL.update.shortenUrl,
    component: <SaleOrderForm />,
  },
  {
    key: SALE_ORDER_URL.detail.key,
    path: SALE_ORDER_URL.detail.shortenUrl,
    component: <SaleOrderDetail />,
  },
];
