export const WITHDRAWAL_REQUEST_STATUS = {
  approve: 'Đã duyệt',
  pending: 'Chờ duyệt',
  canceled_by_system: 'Hủy bởi hệ thống',
  canceled_by_user: 'Hủy bởi người mua',
};

export const WITHDRAWAL_REQUEST_STATUS_COLOR = {
  approve: 'green03',
  pending: 'yellow03',
  canceled_by_system: 'red03',
  canceled_by_user: 'gray02',
};
