import { makeAutoObservable } from 'mobx';

export class Course {
  step = 1;
  information = null;
  exercise = [];
  gift = null;
  device = null;
  tool = null;
  material = null;
  schedule = null;
  attachments = null;

  constructor() {
    makeAutoObservable(this);
  }

  setStep = (value: number) => {
    this.step = value;
  };

  nextStep = () => {
    this.step = this.step + 1;
  };

  backStep = () => {
    if (this.step == 1) return;
    this.step = this.step - 1;
  };

  setInformation = (value = {}) => {
    this.information = { ...(this.information ?? {}), ...value };
  };

  setExercise = (value = []) => {
    this.exercise = value;
  };

  setGift = (value = []) => {
    this.gift = value;
  };

  setSchedule = (value = []) => {
    this.schedule = value;
  };

  setAttachments = (value = []) => {
    this.attachments = value;
  };

  setDevice = (value = []) => {
    this.device = value;
  };

  setTool = (value = []) => {
    this.tool = value;
  };

  setMaterial = (value = []) => {
    this.material = value;
  };

  clear = () => {
    this.step = 1;
    this.information = null;
    this.exercise = null;
    this.gift = null;
    this.device = null;
    this.tool = null;
    this.material = null;
    this.schedule = null;
    this.attachments = null;
  };
}
