import React, { useState } from 'react';
import Content from './Content';
import moment from 'moment';
export const ChartStudentContext = React.createContext(null);

const ChartStudent = () => {
  const [contextParams, setContextParams] = useState({
    date: [moment().subtract(1, 'months'), moment()],
  });
  return (
    <ChartStudentContext.Provider value={{ contextParams, setContextParams }}>
      <Content chartClassName="!h-[350px]" type="small" />
    </ChartStudentContext.Provider>
  );
};

export default ChartStudent;
