import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import VideoBackground from '_common/component/VideoBackground';
import { useEffect, useState } from 'react';
import { Col, notification, Row } from 'tera-dls';
import GuideApi from './api';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { convertArrayToObject } from '_common/utils';

const className =
  'bg-white col-span-1 shadow-md rounded-[10px] p-[16px] flex flex-col justify-center items-center gap-[16px]';
const VideoGuide = () => {
  const [videoLeft, setVideoLeft] = useState<any>();
  const [videoRight, setVideoRight] = useState<any>();
  const [videoCenter, setVideoCenter] = useState<any>();
  const queryClient = useQueryClient();

  const { data: response, refetch } = useQuery(
    ['get-guide-list'],
    () => GuideApi.getList(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (response) {
      const object = convertArrayToObject(response, 'type');
      setVideoLeft(object?.left);
      setVideoRight(object?.right);
      setVideoCenter(object?.center);
    }
  }, [response]);

  const { mutate: mutateCreate } = useMutation(
    (variables: any) => GuideApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-guide-list']);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => GuideApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-guide-list']);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  return (
    <div className="tera-page !bg-none">
      <div className="text-gray-700 font-semibold text-base uppercase mb-2.5">
        Danh sách video hướng dẫn
      </div>
      <Row className="grid-cols-3 gap-[37px]">
        <Col className={className}>
          <div>
            <VideoBackground
              key={1}
              videoClassName={'!size-[150px]'}
              type={'uploaded_video'}
              value={{ url: videoLeft?.url }}
              onChange={(val: any) => {
                setVideoLeft(val ? val?.url : null);
              }}
              onReceiveFiles={(val: any) => {
                mutateCreate({
                  params: {
                    type: 'left',
                    file_upload: val,
                  },
                });
              }}
              onRemove={(fileDelete) => {
                if (!!fileDelete) {
                  mutateDelete({ id: 'left' });
                  setVideoLeft(undefined);
                }
              }}
              isShowBtnDelete
              object_key={'guide'}
              folder={'guide'}
              showThumbnail={true}
            />
          </div>
          <p>Góc quay bên trái</p>
        </Col>
        <Col className={className}>
          <div>
            <VideoBackground
              key={2}
              videoClassName={'!size-[150px]'}
              type={'uploaded_video'}
              value={{ url: videoCenter?.url }}
              onChange={(val: any) => {
                setVideoCenter(val ? val?.url : null);
              }}
              onReceiveFiles={(val: any) => {
                mutateCreate({
                  params: {
                    type: 'center',
                    file_upload: val,
                  },
                });
              }}
              onRemove={(fileDelete) => {
                if (!!fileDelete) {
                  mutateDelete({ id: 'center' });
                  setVideoCenter(undefined);
                }
              }}
              isShowBtnDelete
              object_key={'guide'}
              folder={'guide'}
              showThumbnail={true}
            />
          </div>
          <p>Góc quay chính diện</p>
        </Col>
        <Col className={className}>
          <div>
            <VideoBackground
              key={3}
              videoClassName={'!size-[150px]'}
              type={'uploaded_video'}
              value={{ url: videoRight?.url }}
              onChange={(val: any) => {
                setVideoRight(val ? val?.url : null);
              }}
              onReceiveFiles={(val: any) => {
                mutateCreate({
                  params: {
                    type: 'right',
                    file_upload: val,
                  },
                });
              }}
              onRemove={(fileDelete) => {
                if (!!fileDelete) {
                  mutateDelete({ id: 'right' });
                  setVideoRight(undefined);
                }
              }}
              isShowBtnDelete
              object_key={'guide'}
              folder={'guide'}
              showThumbnail={true}
            />
          </div>
          <p>Góc quay bên phải</p>
        </Col>
      </Row>
    </div>
  );
};

export default VideoGuide;
