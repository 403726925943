import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { IFormModel } from 'pages/CourseManagement/Course';
import {
  COURSE_METHOD,
  COURSE_METHOD_COLOR,
  COURSE_TYPE,
} from 'pages/CourseManagement/Course/constants';
import { useState } from 'react';
import { DropdownItem, formatCurrency, notification, Tag } from 'tera-dls';
import CourseOrderApi from '../api';
import {
  COURSE_ORDER_COMPLETE_STATUS,
  COURSE_ORDER_COMPLETE_STATUS_COLOR,
  COURSE_ORDER_STATUS,
  COURSE_ORDER_STATUS_COLOR,
} from '../constants';
import CourseOrderDetail from './Detail';
import RejectModal from './RejectModal';

const CourseOrderTable = ({ loading, data, ...restProps }) => {
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const [openDetailModel, setOpenDetailModel] = useState<IFormModel>({
    open: false,
  });

  const [openRejectModel, setOpenRejectModel] = useState<IFormModel>({
    open: false,
  });

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => CourseOrderApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-order-list']);
          queryClient.invalidateQueries(['get-course-order-summary']);
          queryClient.invalidateQueries(['get-course-list']);
          queryClient.invalidateQueries(['get-student-detail-summary']);

          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa đơn khóa học',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận xóa đơn khóa học</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const { mutate: mutateChangeStatus } = useMutation(
    (variables: any) => {
      if (variables?.status === 'cancel')
        return CourseOrderApi.reject({
          id: variables?.id,
          params: variables?.params,
        });
      return CourseOrderApi.approve({ id: variables?.id });
    },
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-order-list']);
          queryClient.invalidateQueries(['get-course-order-summary']);
          queryClient.invalidateQueries(['get-course-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  // const handleApprove = (record): void => {
  //   confirm.warning({
  //     title: `Xác nhận duyệt đơn khóa học`,
  //     content: (
  //       <p className="break-word">
  //         <p>Bạn có chắc muốn duyệt đơn khóa học</p>
  //         <p>
  //           <b>{record?.course?.name}</b> này không?
  //         </p>
  //       </p>
  //     ),
  //     onOk: () => {
  //       mutateChangeStatus({ status: 'approve', id: record?.id });
  //     },
  //   });
  // };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: 'Xem',
        onClick: () => setOpenDetailModel({ open: true, value: record?.id }),
      },
      // ...(record?.status == 'pending'
      //   ? [
      //       {
      //         key: 2,
      //         label: 'Duyệt',
      //         onClick: () => handleApprove(record),
      //       },
      //       {
      //         key: 3,
      //         label: 'Hủy',
      //         onClick: () => setOpenRejectModel({ open: true, value: record }),
      //       },
      //       // {
      //       //   key: 4,
      //       //   label: 'Sửa',
      //       //   onClick: () =>
      //       //     setOpenFormModel({ open: true, value: record?.id }),
      //       // },
      //     ]
      //   : []),
      ...(record?.status == 'cancel'
        ? [
            {
              key: 5,
              label: <a className="text-red-500">Xóa</a>,
              onClick: () => handleDelete(record?.id, record?.course?.name),
            },
          ]
        : []),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã đơn',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user',
      width: 150,
      render: (value) => (
        <HoverQuickView
          name={value?.full_name}
          avatarUrl={value?.avatar}
          email={value?.email}
          phone={value?.phone}
        >
          <span className="line-clamp-1 text-blue-600">{value?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Tên khóa học',
      dataIndex: 'course',
      width: 150,
      render: (val, record) => (
        <span
          className="line-clamp-2  text-blue-600 cursor-pointer inline"
          onClick={() => setOpenDetailModel({ open: true, value: record?.id })}
        >
          {val?.name}
        </span>
      ),
    },
    {
      title: 'Giảng viên',
      dataIndex: 'lecturer',
      width: 150,
      render: (value) => (
        <HoverQuickView
          name={value?.full_name}
          avatarUrl={value?.avatar}
          email={value?.email}
          phone={value?.phone}
        >
          <span className="line-clamp-1 text-blue-600">{value?.full_name}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Danh mục khóa học',
      dataIndex: 'course_category',
      width: 150,
      render: (val) => val?.name,
    },
    {
      title: 'Loại đơn',
      dataIndex: 'classification',
      width: 150,
      render: (val) => (
        <span className={`${COURSE_METHOD_COLOR[val]}`}>
          {COURSE_METHOD[val]}
        </span>
      ),
    },
    {
      title: 'Loại khóa học',
      width: 150,
      dataIndex: 'type',
      render: (val) => COURSE_TYPE[val],
    },
    {
      title: 'Thành tiền',
      width: 150,
      dataIndex: 'total',
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Trạng thái hóa đơn',
      width: 150,
      dataIndex: 'status',
      render: (status) =>
        status && (
          <Tag color={COURSE_ORDER_STATUS_COLOR[status]}>
            {COURSE_ORDER_STATUS[status]}
          </Tag>
        ),
    },
    {
      title: 'Trạng thái hoàn thành',
      width: 150,
      dataIndex: 'status_complete',
      render: (status) =>
        status && (
          <Tag color={COURSE_ORDER_COMPLETE_STATUS_COLOR[status]}>
            {COURSE_ORDER_COMPLETE_STATUS[status]}
          </Tag>
        ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <TableTera
        wrapperClassName="shadow-none"
        rowKey={'id'}
        {...restProps}
        loading={loading}
        data={data ?? []}
        columns={columns}
      />
      {openDetailModel.open && (
        <CourseOrderDetail
          open={openDetailModel.open}
          onClose={() => setOpenDetailModel({ open: false })}
          value={openDetailModel.value}
        />
      )}
      {openRejectModel?.open && (
        <RejectModal
          open={openRejectModel?.open}
          onClose={() => setOpenRejectModel({ open: false })}
          value={openRejectModel?.value}
          onSubmit={(values, record) =>
            mutateChangeStatus({
              status: 'cancel',
              id: record?.id,
              params: values,
            })
          }
        />
      )}
    </>
  );
};

export default CourseOrderTable;
