import { useQuery } from '@tanstack/react-query';
import CardDetailProduct from '_common/component/CardDetail';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { INDEX_NUMBER_KEY } from '_common/dof/TableTera/constants';
import moment from 'moment';
import ProductApi from 'pages/SaleManagement/Product/api';
import { UNIT_TYPE } from 'pages/SaleManagement/Product/constants';
import { ReactNode, useEffect, useState } from 'react';
import customMerge from 'tailwind-merge.config';
import {
  Col,
  ColumnsType,
  Description,
  formatCurrency,
  formatDate,
  PaginationProps,
  Row,
} from 'tera-dls';

type GeneralProductProps = {
  dataDetail?: any;
};

export const DescriptionCustom = (props: {
  label: string;
  content: ReactNode;
  className?: string;
  childrenClassName?: string;
}) => {
  return (
    <Description
      label={props.label}
      className={customMerge('grid-cols-2', props?.className)}
      childrenClassName={props?.childrenClassName}
    >
      {props.content}
    </Description>
  );
};

const GeneralProduct = ({ dataDetail }: GeneralProductProps) => {
  const [params, setParams] = useState<any>({ page: 1, limit: 10 });

  const { data: log, refetch: fetchLog } = useQuery(
    ['get-product-log', dataDetail?.id, params],
    () =>
      ProductApi.getActivityLog({
        object_id: dataDetail?.id,
        object_type: 'product',
        ...params,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!dataDetail?.id,
    },
  );

  useEffect(() => {
    !!dataDetail?.id && fetchLog();
  }, [dataDetail?.id]);

  const columnsHistory: ColumnsType = [
    { title: 'STT', dataIndex: INDEX_NUMBER_KEY, width: 80 },
    {
      title: 'Người cập nhật',
      dataIndex: 'created_by',
      width: 200,
      render: (created_by) => (
        <div>
          <p className="text-green-500">[{created_by.code}]</p>
          <p>{created_by?.full_name}</p>
        </div>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      width: 150,
      render: (val) => val && moment(val).format(DATE_TIME_FORMAT),
    },
    { title: 'Nội dung', dataIndex: 'content', width: 150 },
  ];

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    setParams({
      page,
      limit: pageSize,
    });
  };

  return (
    <Row className={'grid-cols-1 gap-4'}>
      <Col className="space-y-4 col-span-2">
        <CardDetailProduct
          title="Thông tin sản phẩm"
          className="border border-blue-400"
        >
          <Row className={'grid-cols-1 xl:grid-cols-2'}>
            <Col>
              <DescriptionCustom
                label="Tên sản phẩm"
                content={dataDetail?.name || '__'}
              />
              <DescriptionCustom
                label="SKU"
                content={dataDetail?.sku || '__'}
              />

              <DescriptionCustom
                label="Mã vạch / Barcode"
                content={dataDetail?.barcode || '__'}
              />
            </Col>
          </Row>
        </CardDetailProduct>

        <Row className="grid-cols-3 gap-4">
          <CardDetailProduct title="Giá" className="border border-blue-400">
            <Row className={'grid-cols-1 gap-y-0 '}>
              <DescriptionCustom
                label="Giá bán"
                content={formatCurrency(dataDetail?.unit_price ?? 0)}
              />
              <DescriptionCustom
                label="Giá khuyến mãi"
                content={
                  dataDetail?.promotional_price &&
                  formatCurrency(dataDetail?.promotional_price)
                }
              />
              <DescriptionCustom
                label="Thời gian khuyến mãi"
                content={
                  dataDetail?.promotional_time?.[0]
                    ? `${moment(dataDetail?.promotional_time?.[0]).format(
                        DATE_FORMAT,
                      )} - ${moment(dataDetail?.promotional_time?.[1]).format(
                        DATE_FORMAT,
                      )}`
                    : '__'
                }
              />
            </Row>
          </CardDetailProduct>
          <CardDetailProduct
            title="Phân loại"
            className="border border-blue-400"
          >
            <Row className={'grid-cols-1 gap-y-0 '}>
              <DescriptionCustom
                label="Đơn vị"
                content={
                  dataDetail?.unit_key ? UNIT_TYPE[dataDetail?.unit_key] : '__'
                }
              />
              <DescriptionCustom
                label="Danh mục"
                content={
                  dataDetail?.category?.name || dataDetail?.category_id || '__'
                }
              />
            </Row>
          </CardDetailProduct>

          <CardDetailProduct
            title="Thông tin khác"
            className="border border-blue-400"
          >
            <DescriptionCustom
              label="Người tạo"
              content={dataDetail?.user_created?.full_name || '__'}
            />
            <DescriptionCustom
              label="Ngày tạo"
              content={
                formatDate(dataDetail?.created_at, DATE_TIME_FORMAT) || '__'
              }
            />
          </CardDetailProduct>
        </Row>
        <CardDetailProduct
          title="Lịch sử hoạt động"
          className="border border-blue-400"
        >
          <TableTera
            columns={columnsHistory}
            pagination={{
              onChange: handleChangePage,
              total: log?.total || 0,
              current: log?.current_page,
              pageSize: log?.per_page || 10,
              to: log?.to,
              from: log?.from || 10,
            }}
            data={log?.data}
          />
        </CardDetailProduct>
      </Col>
    </Row>
  );
};

export default GeneralProduct;
