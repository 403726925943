export const PORTFOLIO = {
  online: {
    name: 'Online',
    color: '#F8961E',
  },
  offline: {
    name: 'Offline',
    color: '#31C48D',
  },
};

export const ORDER_TYPE = {
  order: 'Đơn đặt hàng',
  sell: 'Đơn bán hàng',
};

export const listOptions = [
  {
    label: 'Ngày',
    value: 'date',
  },
  {
    label: 'Tháng',
    value: 'month',
  },
  {
    label: 'Quý',
    value: 'quarter',
  },
  {
    label: 'Năm',
    value: 'year',
  },
];

export const FORMAT_DATE_DASHBOARD = {
  date: 'DD/MM/YYYY',
  month: 'MM/YYYY',
  quarter: '[Q]Q/YYYY',
  year: 'YYYY',
};

export const FORMAT_DATE_BACKEND_DASHBOARD = {
  date: 'YYYY-MM-DD',
  month: 'YYYY-MM',
  quarter: '[Q]Q-YYYY',
  year: 'YYYY',
};

export const SYSTEM_REVENUE = {
  course: 'Khóa học',
  product: 'Sản phẩm',
};

export const SYSTEM_REVENUE_COLOR = {
  course: '#1A56DB',
  product: '#F3722C',
};
