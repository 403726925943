import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UserAddressEndpoint = `${endpoint}/bake-teach/user/address`;
const UserAddressApi = {
  getList: async (params) =>
    await api
      .get(`${UserAddressEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${UserAddressEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${UserAddressEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${UserAddressEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  setDefault: async ({ id }) =>
    await api
      .put(`${UserAddressEndpoint}/set-default/${id}`)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${UserAddressEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default UserAddressApi;
