import { useQuery } from '@tanstack/react-query';
import React from 'react';
import notoPalmUpHand from 'styles/images/pages/finance/noto_palm-up-hand.png';
import price_order from 'styles/images/pages/finance/price_order.png';
import CashReceiptApi from '../../api';
import CardStatistic, { ICardStatistic } from './CardStatistic';

const Statistic = () => {
  const { data: statistic, isFetching } = useQuery(
    ['get-cash-receipt-statistic'],
    () => CashReceiptApi.getStatistic(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  console.log('statistic', statistic);

  const sum = statistic?.sum;
  const sum_month = statistic?.sum_month;
  const count = statistic?.count;

  const data: Array<ICardStatistic> = [
    {
      title: 'Tổng thu nhập',
      amount: sum ?? 0,
      // percentage:
      //   amountOfYears?.ratio === 'no_old_data' ? 0 : amountOfYears?.ratio,
      unitText: 'đ',
      // type:
      //   isNumber(amountOfYears?.ratio) && Number(amountOfYears?.ratio) < 0
      //     ? 'down'
      //     : 'up',
      image: notoPalmUpHand,
      key: 'number-order',
      subText: 'So với năm trước',
    },
    {
      title: 'Thu nhập trong tháng',
      amount: sum_month ?? 0,
      // percentage:
      //   amountOfMonth?.ratio === 'no_old_data' ? 0 : amountOfMonth?.ratio,
      unitText: 'đ',
      // type:
      //   isNumber(amountOfMonth?.ratio) && Number(amountOfMonth?.ratio) < 0
      //     ? 'down'
      //     : 'up',
      image: notoPalmUpHand,
      key: 'price-order',
    },
    {
      title: 'Tổng phiếu thu',
      amount: count ?? 0,
      // percentage:
      //   voucherOfMonth?.ratio === 'no_old_data' ? 0 : voucherOfMonth?.ratio,
      // type:
      //   isNumber(voucherOfMonth?.ratio) && Number(voucherOfMonth?.ratio) < 0
      //     ? 'down'
      //     : 'up',
      unitText: '',
      image: price_order,
      key: 'cost-order',
    },
  ];

  return (
    <div className="grid grid-cols-3 gap-[16px] flex-wrap mb-2.5">
      {data.map((item) => (
        <CardStatistic
          loading={isFetching}
          key={item.key}
          title={item.title}
          image={item.image}
          amount={item.amount}
          percentage={item.percentage}
          type={item.type as any}
          imageClassName={item.imageClassName}
          unitText={item.unitText}
          subText={item.subText}
        />
      ))}
    </div>
  );
};

export default React.memo(Statistic);
