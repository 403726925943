import { useQuery } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  getQueryParams,
  PaginationProps,
  Tabs,
  updateQueryParams,
} from 'tera-dls';
import CourseOrderApi from './api';

import { COURSE_ORDER_STATUS } from './constants';
import CourseOrderTable from './containers/CourseOrderTable';
import CourseOrderFilter from './containers/Filter';
import CourseOrderForm from './containers/Form';
import Searching from './containers/Searching';

interface IFormModel {
  open: boolean;
  value?: string | number;
}

const CourseOrder = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [openFormModel, setOpenFormModel] = useState<IFormModel>({
    open: false,
  });

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-course-order-list', queryParams],
    () =>
      CourseOrderApi.getList({
        page: 1,
        limit: 10,
        ...queryParams,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-course-order-summary', queryParams],
    () =>
      CourseOrderApi.getSummaryList({
        ...queryParams,
        status: undefined,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: Record<string, any>): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(COURSE_ORDER_STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{COURSE_ORDER_STATUS[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH ĐƠN KHÓA HỌC"
          // onClickButtonAdd={() => setOpenFormModel({ open: true })}
          actionLeftRender={<Searching onSearch={handleSearch} />}
          onClickFilter={() => setOpenFilter(true)}
          middleContent={
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
          }
          filterCount={{
            filter: queryParams,
            params: ['type', 'course_category_id'],
          }}
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <CourseOrderTable
              loading={isFetching}
              data={response?.data ?? []}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
            />
          </div>
        </HeaderViewListV2>
      </div>
      {openFormModel.open && (
        <CourseOrderForm
          open={openFormModel.open}
          onClose={() => setOpenFormModel({ open: false })}
          // value={openFormModel.value}
        />
      )}
      {openFilter && (
        <CourseOrderFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default CourseOrder;
