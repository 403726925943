import { useMutation } from '@tanstack/react-query';
import BakeTeachIcon from '_common/component/Icons/BakeTeachIcon';
import { useStores } from '_common/hooks';
import useSubMenu from '_common/hooks/useSubMenu';
import classNames from 'classnames';
import { groupBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import ExitIcon from 'styles/images/Icons/ExitIcon';
import {
  Bars3BottomRightOutlined,
  Collapse,
  Icon,
  notification,
  Tooltip,
} from 'tera-dls';
import { IMenu } from './interface';
import menu from './menu';

export interface InlineMenuProps {
  containerClassName?: string;
  isExpand?: boolean;
  onChangeSize?: (expand: boolean) => void;
}

const InlineMenu: React.FC<InlineMenuProps> = observer(
  ({ isExpand, containerClassName, onChangeSize }) => {
    const {
      commonStore: { activeMenu: activeGroupKey, clear: clearCRM },
      authStore: { clear },
    } = useStores();
    const location = useLocation();
    const navigate = useNavigate();
    const listMenu = useSubMenu();

    const [collapseActiveKey, setCollapseActiveKey] = useState<string>();

    const handleClick = (path: string): void => {
      if (path === window.location.pathname) return;
      navigate(path);
    };

    const { mutate: onLogout } = useMutation(AuthApi.logout, {
      onSuccess: () => {
        clear();
        clearCRM();
      },
      onError: (error: any) => {
        clear();
        clearCRM();
        const errorMessage = error?.msg;
        notification.error({
          message: errorMessage,
        });
      },
    });

    const getActiveKeyInURL = (activeGroupKey): string => {
      const split = location?.pathname?.split('/');
      const activeGroupKeyIndex = split?.findIndex(
        (key) => key === activeGroupKey,
      );
      return split?.[activeGroupKeyIndex + 1] ?? '';
    };
    useEffect(() => {
      const activeKey = getActiveKeyInURL(activeGroupKey);

      setCollapseActiveKey(activeKey);
    }, [location?.pathname, activeGroupKey]);

    const handleChangeSizeMenu = () => {
      onChangeSize(!isExpand);
    };

    const menuClasses = classNames(
      `transition-all h-full py-5 bg-blue-800 overflow-hidden`,
      containerClassName,
      {
        'w-[225px] rounded-se-[20px] rounded-ee-[20px] pr-2.5': isExpand,
        'w-[50px] px-0': !isExpand,
      },
    );

    const isActiveKey = (key: string): boolean => collapseActiveKey === key;

    const getActiveClasses = (activeKeys: any = []): string => {
      const isActive = activeKeys.some((key) =>
        location?.pathname.includes(key),
      );
      return isActive ? 'bg-white/25 ' : '';
    };

    const groupListMenu = groupBy(listMenu, 'parentGroupKey');
    console.log(groupListMenu);

    const listCollapse = useCallback(
      (arrMenu) =>
        arrMenu?.map((item: IMenu) => {
          const children = item.children;
          return {
            key: item.key,
            onClick: () => item.path && handleClick(item.path),
            label: (
              <Tooltip
                className={isExpand && 'hidden'}
                title={item.title}
                placement="right"
              >
                <div
                  className={classNames(
                    'flex items-center gap-2.5 font-normal text-gray-200',
                    { '!gap-1 py-[8px] justify-center w-full': !isExpand },
                  )}
                >
                  {isExpand && (
                    <div
                      className={`w-[3px] h-[15px] rounded-r-[2px] ${
                        !isActiveKey(item.key) ? '' : 'bg-[#00AFEF]'
                      }`}
                    />
                  )}
                  {item.icon && (
                    <Icon
                      type={item.icon}
                      className={`${
                        isExpand ? 'w-4 h-4' : 'w-5 h-5'
                      } shrink-0 `}
                    />
                  )}
                  {item?.iconNode && (
                    <i className="w-4 h-4 text-white menu-icon">
                      {item.iconNode}
                    </i>
                  )}
                  <span
                    className={classNames('text-wrap leading-[20px]', {
                      hidden: !isExpand,
                    })}
                  >
                    {item.title}
                  </span>
                </div>
              </Tooltip>
            ),
            ...(children?.length > 0 && isExpand
              ? {
                  children: (
                    <div className="flex flex-col">
                      {children?.map((child) => {
                        return (
                          <div
                            key={child.id}
                            onClick={() => handleClick(child.path)}
                            className={classNames(
                              `text-gray-200 flex items-center gap-2.5 cursor-pointer py-2.5 pl-6 rounded-e-full `,
                              {
                                'bg-white/25':
                                  getActiveKeyInURL(child?.parentKey) ===
                                    child?.activeKeys ||
                                  location?.pathname.includes(child.path),
                              },
                            )}
                          >
                            {child.name}
                          </div>
                        );
                      })}
                    </div>
                  ),
                }
              : { children: null }),
          };
        }),
      [listMenu, collapseActiveKey, getActiveClasses],
    );

    // if (!listMenu?.length) return <></>;

    const renderTitle = (title) => {
      if (isExpand) return title;
      return title.slice(0, 1);
    };

    const elementRef = useRef(null);

    const renderHeading = () => {
      const data = menu.groupMenu.find((item) => item?.key === activeGroupKey);
      return data?.title || 'Bake Teach';
    };

    return (
      <div className="hidden xmd:block fixed top-0 left-0 transition-all h-screen shrink-0 bg-gradient-to-b from-white to-[#F3F3F9]">
        <div className={menuClasses}>
          <div className="flex flex-col gap-y-5 items-center h-full">
            <div
              className={classNames(
                'flex items-center w-full justify-between',
                {
                  'flex-col': !isExpand,
                  'gap-y-5': !isExpand,
                },
              )}
            >
              <div
                className={classNames('flex items-center', {
                  'gap-x-4 pl-2.5': isExpand,
                })}
              >
                <BakeTeachIcon />
                <h1
                  className={classNames('text-[21px] text-white font-medium', {
                    hidden: !isExpand,
                  })}
                  // key={key}
                >
                  {renderHeading()}
                </h1>
              </div>
              <Bars3BottomRightOutlined
                className="w-5 h-5 text-white cursor-pointer"
                onClick={handleChangeSizeMenu}
              />
            </div>
            <div
              ref={elementRef}
              className={classNames(
                'flex-1 flex flex-col gap-y-5 overflow-auto w-full scrollbar-none justify-between',
                {
                  'shadow-md inset-y-0 bottom-0':
                    elementRef.current?.scrollHeight >
                    elementRef.current?.clientHeight,
                },
              )}
            >
              <div className="flex flex-col gap-y-5">
                {Object.entries(groupListMenu).map(([key, value]) => {
                  const name = menu?.parentGroup?.find(
                    (i) => i.key === key,
                  )?.title;

                  return (
                    <div
                      className={classNames('flex flex-col gap-y-[5px]', {
                        'items-center': !isExpand,
                      })}
                    >
                      {(key !== 'undefined' || isExpand) && name && (
                        <Tooltip
                          className={isExpand && 'hidden'}
                          title={name || ''}
                          placement="right"
                        >
                          <h3
                            className={classNames(
                              'ml-2.5 uppercase text-gray-200 font-medium transition-all leading-6',
                              {
                                '!ml-0 !font-bold w-5 h-5 rounded-full bg-white/25 flex items-center justify-center':
                                  !isExpand,
                              },
                            )}
                          >
                            {renderTitle(name || '')}
                          </h3>
                        </Tooltip>
                      )}
                      <Collapse
                        accordion
                        activeKey={collapseActiveKey}
                        containerClassName="flex flex-col gap-1 w-full"
                        headingClassName={classNames(
                          'p-2.5 bg-blue100 border-none !rounded-e-full text-gray-200 focus:ring-0',
                          { 'p-0 !rounded-none': !isExpand },
                        )}
                        contentClassName={classNames(
                          'border-none p-0 !text-red-500',
                        )}
                        activeClassName="bg-white/25 text-gray-200"
                        // onChange={(key: any) => {
                        //   if (key?.length) setCollapseActiveKey(key[0]);
                        // }}
                        items={listCollapse(value)}
                      />
                    </div>
                  );
                })}
              </div>
              <div />
              {isExpand && (
                <div
                  className={classNames(
                    'flex items-center gap-2.5 font-normal text-gray-200 ml-5 pb-2 cursor-pointer',
                  )}
                  onClick={() => onLogout()}
                >
                  <i className="w-4 h-4 text-white menu-icon">
                    <ExitIcon />
                  </i>

                  <span className={classNames('text-wrap')}>Đăng xuất</span>
                </div>
              )}
            </div>

            {isExpand && (
              <p className="text-gray-200">@Copyright Bake Teach ver 1.0</p>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default InlineMenu;
