import { useQuery } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import TableTera from '_common/dof/TableTera';
import UserApi from 'pages/UserManagement/User/api';
import { Key, useEffect, useState } from 'react';
import customTwMerge from 'tailwind-merge.config';
import { AnyObject, Modal, PaginationProps, TableRowSelection } from 'tera-dls';
import Searching from './Searching';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
  onSubmit?: (val: any) => void;
  selectedValue?: any;
}

const UserModal = (props: IProps) => {
  const { open, onClose, selectedValue, onSubmit } = props;
  const [params, setParams] = useState<any>({ limit: 10, page: 1 });
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-user-list', params],
    () =>
      UserApi.getList({
        page: 1,
        limit: 10,
        status: 1,
        ...params,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setSelectedRowKeys([selectedValue?.id]);
      setSelectedRows(selectedValue);
    }
  }, [selectedValue]);

  const columns: any = [
    {
      title: 'Mã người dùng',
      dataIndex: 'code',
      width: 150,
      render: (val) => <div className="py-1">{val}</div>,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 200,
      render: (value, record) => (
        <HoverQuickView
          name={value}
          avatarUrl={record?.avatar}
          email={record?.email}
          phone={record?.phone}
        >
          <span className="line-clamp-1 text-blue-600">{value}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
    },
  ];

  const handleSearch = ({ keyword }): void => {
    setParams((prev = {}) => ({ ...prev, keyword, page: 1 }));
  };

  const rowSelection: TableRowSelection<AnyObject> = {
    type: 'radio',
    selectedRowKeys: selectedRowKeys,
    // onChange: (selectedRowKeys, record) => {
    //   setSelectedRows(record);
    //   setSelectedRowKeys(selectedRowKeys);
    // },
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams((prev = {}) => ({ ...prev, limit: pageSize, page: page }));
  };

  return (
    <>
      <Modal
        open={open}
        title={'Danh sách người dùng'}
        closeIcon={false}
        onCancel={onClose}
        okText="Đồng ý"
        className="!w-[90%] xmd:!w-[80%]"
        onOk={() => {
          console.log('selectedRows', selectedRows);

          onSubmit && onSubmit(selectedRows);
          onClose();
        }}
        cancelText="Hủy"
      >
        <div className="flex gap-2 mb-2.5 items-center">
          <Searching onSearch={handleSearch} />{' '}
        </div>
        <TableTera
          rowKey={'id'}
          loading={isFetching}
          data={response?.data ?? []}
          pagination={{
            onChange: handleChangePage,
            total: response?.total || 0,
            current: response?.current_page,
            pageSize: response?.per_page,
            to: response?.to,
            from: response?.from,
          }}
          columns={columns}
          rowSelection={rowSelection}
          className="center-table"
          onRow={(record: any) => ({
            onClick: () => {
              if (selectedRowKeys !== record?.id) {
                setSelectedRows(record);
                setSelectedRowKeys([record?.id]);
              }
            },
            className: customTwMerge(
              'cursor-pointer',
              selectedRowKeys.includes(record?.id) && '!bg-[#e8f5ff]',
            ),
          })}
        />
      </Modal>
    </>
  );
};

export default UserModal;
