import React, { useState } from 'react';
import Content from './Content';
import moment from 'moment';
export const ChartLecturerContext = React.createContext(null);

const ChartLecturer = () => {
  const [contextParams, setContextParams] = useState({
    date: [moment().subtract(1, 'months'), moment()],
  });
  return (
    <ChartLecturerContext.Provider value={{ contextParams, setContextParams }}>
      <Content chartClassName="!h-[350px]" type="small" />
    </ChartLecturerContext.Provider>
  );
};

export default ChartLecturer;
