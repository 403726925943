import { IRouteProps } from '_common/interface/router';
import Certificate from '.';
import { CERTIFICATE_SUGGESTION_URL } from './url';

export const CertificateRouter: IRouteProps[] = [
  {
    key: CERTIFICATE_SUGGESTION_URL.list.key,
    path: CERTIFICATE_SUGGESTION_URL.list.shortenUrl,
    component: <Certificate />,
  },
];
