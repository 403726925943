import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UserEndpoint = `${endpoint}/bake-teach/user`;
const UserApi = {
  getList: async (params) =>
    await api
      .get(`${UserEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getSummaryList: async (params) =>
    await api
      .get(`${UserEndpoint}/summary-list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${UserEndpoint}/create`, params)
      .then((result) => result?.data),
  getDetail: async (id: any) =>
    await api
      .get(`${UserEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${UserEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  changePassword: async (params) =>
    await api
      .put(`${UserEndpoint}/change-password`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${UserEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default UserApi;
