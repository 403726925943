import { Col, Row } from 'tera-dls';
import StatisticsOverview from './StatisticsOverview';
import TopLecturer from './TopLecturer';
import TopCourse from './TopCourse';
import StatisticRevenueBySystem from './StatisticRevenueBySystem';

function Dashboard() {
  return (
    <div className="tera-page">
      <Row className="grid-cols-2">
        <Col className="col-span-2 bg-white p-4 rounded shadow">
          <StatisticsOverview />
        </Col>
      </Row>
      <Row className="mt-5 bg-white shadow p-4 rounded">
        <StatisticRevenueBySystem />
      </Row>
      <Row className="grid-cols-2 mt-5 bg-white shadow p-4 rounded">
        <Col className="col-span-1 p-4 border rounded-[10px]">
          <TopLecturer />
        </Col>
        <Col className="col-span-1 p-4 border rounded-[10px]">
          <TopCourse />
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
