function Menu14(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.83333 1C9.83333 0.723858 10.0572 0.5 10.3333 0.5C14.2914 0.5 17.5 3.70863 17.5 7.66667C17.5 7.94281 17.2761 8.16667 17 8.16667H10.3333C10.0572 8.16667 9.83333 7.94281 9.83333 7.66667V1ZM10.8333 1.51998V7.16667H16.48C16.2387 4.15966 13.8403 1.76125 10.8333 1.51998Z"
        fill="#6B7280"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 10.3333C0.5 6.37529 3.70863 3.16667 7.66667 3.16667C7.94281 3.16667 8.16667 3.39052 8.16667 3.66667V9.83333H14.3333C14.6095 9.83333 14.8333 10.0572 14.8333 10.3333C14.8333 14.2914 11.6247 17.5 7.66667 17.5C3.70863 17.5 0.5 14.2914 0.5 10.3333ZM7.16667 4.18664C3.99474 4.44115 1.5 7.0959 1.5 10.3333C1.5 13.7391 4.26091 16.5 7.66667 16.5C10.9041 16.5 13.5588 14.0053 13.8134 10.8333H7.66667C7.39052 10.8333 7.16667 10.6095 7.16667 10.3333V4.18664Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu14;
