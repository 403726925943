import { useQuery } from '@tanstack/react-query';
import IconTransaction from '_common/component/Icons/Transaction';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { copyText } from '_common/utils';
import {
  PAYMENT_METHOD_TYPE_TEXT,
  PAYMENT_TYPE,
} from 'pages/Finance/constants';
import { useEffect } from 'react';
import bgInvoice from 'styles/images/pages/finance/bg-invoice.png';
import {
  DocumentDuplicateOutlined,
  formatCurrency,
  formatDate,
  Modal,
  Spin,
  Tag,
  Tooltip,
} from 'tera-dls';
import TransactionHistoryApi from '../../api';
import {
  TRANSACTION_HISTORY_STATUS,
  TRANSACTION_HISTORY_STATUS_COLOR,
} from '../../constants';

interface TransactionHistoryDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function TransactionHistoryDetail({
  id,
  open,
  onCancel,
}: TransactionHistoryDetailProps) {
  const textLight = 'text-gray-500 font-light';
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-transaction-history-detail', id],
    () => TransactionHistoryApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  const PAYMENT_TYPE_COLOR = {
    pay: {
      svg: '#1C64F2',
      text: 'text-blue-500',
    },
    deposit: {
      svg: '#0E9F6E',
      text: 'text-green-500',
    },
    withdraw: {
      svg: '#E02424',
      text: 'text-red-500',
    },
  };

  return (
    <Modal
      title="Chi tiết giao dịch"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading}>
        {isError ? (
          <NoData />
        ) : (
          <div className="rounded-md overflow-hidden shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)] w-full">
            <div
              className="p-4"
              style={{
                backgroundImage: `linear-gradient(rgba(255,255,255,0.95),rgba(255,255,255,0.95)),url(${bgInvoice})`,
                backgroundPosition: 'center',
              }}
            >
              <div className="flex flex-col gap-y-3 pb-4 mb-4 border-b border-dashed">
                <div className="flex gap-x-2.5 items-center">
                  <IconTransaction
                    className="w-8 h-8"
                    fill={
                      PAYMENT_TYPE_COLOR[dataDetails?.transaction_type]?.svg
                    }
                  />
                  <div className="flex flex-col gap-y-[5px]">
                    <span className={textLight}>
                      {PAYMENT_TYPE[dataDetails?.transaction_type]}
                    </span>
                    <span
                      className={`${
                        PAYMENT_TYPE_COLOR[dataDetails?.transaction_type]?.text
                      } font-bold`}
                    >
                      {formatCurrency(dataDetails?.amount)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-y-[7px]">
                  <div className="flex justify-between items-center">
                    <span className={textLight}>Trạng thái</span>
                    {dataDetails?.status && (
                      <Tag
                        className="font-medium"
                        color={
                          TRANSACTION_HISTORY_STATUS_COLOR[dataDetails?.status]
                        }
                      >
                        {TRANSACTION_HISTORY_STATUS[dataDetails?.status]}
                      </Tag>
                    )}
                  </div>
                  <div className="flex justify-between items-center">
                    <span className={textLight}>Họ và tên</span>
                    <span>{dataDetails?.user_name}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className={textLight}>Thời gian</span>
                    <span>
                      {formatDate(dataDetails?.created_at, DATE_TIME_FORMAT)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-3 pb-4 mb-4 border-b border-dashed">
                <div className="flex flex-col gap-y-[7px]">
                  <div className="flex justify-between items-center">
                    <span className={textLight}>Mã giao dịch</span>
                    <span className="flex gap-x-1 items-center">
                      <span>{dataDetails?.transaction_code}</span>
                      <Tooltip placement="top" title="Sao chép">
                        <DocumentDuplicateOutlined
                          className="w-5 h-5 text-blue-600 shrink-0 cursor-pointer"
                          onClick={() =>
                            copyText(dataDetails?.transaction_code)
                          }
                        />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className={textLight}>Mã hóa đơn</span>
                    <span className="flex gap-x-1 items-center">
                      <span>{dataDetails?.invoice_code}</span>
                      <Tooltip placement="top" title="Sao chép">
                        <DocumentDuplicateOutlined
                          className="w-5 h-5 text-blue-600 shrink-0 cursor-pointer"
                          onClick={() => copyText(dataDetails?.invoice_code)}
                        />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className={textLight}>Phương thức thanh toán</span>
                    <span>
                      {PAYMENT_METHOD_TYPE_TEXT[dataDetails?.methods]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                {dataDetails?.card_back && (
                  <div className="p-4 border rounded-md flex flex-col gap-y-2.5">
                    <div className="grid grid-cols-2 gap-x-6">
                      <span className="text-right">
                        {dataDetails?.card_back?.card_type?.object_type === 1
                          ? 'Số tài khoản nhận'
                          : 'Số điện thoại nhận'}
                      </span>
                      <span>{dataDetails?.card_back?.account_number}</span>
                    </div>
                    <div className="grid grid-cols-2 gap-x-6">
                      <span className="text-right">Chủ tài khoản nhận</span>
                      <span className="break-word">
                        {dataDetails?.card_back?.account_name}
                      </span>
                    </div>
                    <div className="grid grid-cols-2 gap-x-6">
                      <span className="text-right">
                        {dataDetails?.card_back?.card_type?.object_type === 1
                          ? 'Ngân hàng'
                          : 'Ví điện tử'}
                      </span>
                      <span>
                        {dataDetails?.card_back?.card_type?.card_name}
                      </span>
                    </div>
                  </div>
                )}
                {dataDetails?.card_to && (
                  <div className="p-4 border rounded-md flex flex-col gap-y-2.5">
                    <div className="grid grid-cols-2 gap-x-6">
                      <span className="text-right">
                        {dataDetails?.card_to?.card_type?.object_type === 1
                          ? 'Số tài khoản chuyển'
                          : 'Số điện thoại chuyển'}
                      </span>
                      <span>{dataDetails?.card_to?.account_number}</span>
                    </div>
                    <div className="grid grid-cols-2 gap-x-6">
                      <span className="text-right">Chủ tài khoản chuyển</span>
                      <span className="break-word">
                        {dataDetails?.card_to?.account_name}
                      </span>
                    </div>
                    <div className="grid grid-cols-2 gap-x-6">
                      <span className="text-right">
                        {dataDetails?.card_to?.card_type?.object_type === 1
                          ? 'Ngân hàng'
                          : 'Ví điện tử'}
                      </span>
                      <span>{dataDetails?.card_to?.card_type?.card_name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="py-2 flex gap-x-2.5 items-center bg-blue-100 justify-center">
              <span>
                <LifebuoyOutlined className="w-5 h-5 text-blue-500" />
              </span>
              <span className="text-blue-500 font-light hover:underline cursor-pointer">
                Liên hệ hỗ trợ
              </span>
            </div> */}
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default TransactionHistoryDetail;
