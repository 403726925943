import DetectMedia from '_common/component/DetectMedia';

function Exercise({ data }) {
  return (
    <div className="rounded-md p-2.5 border border-blue-500 flex flex-col gap-y-2.5">
      <div className="flex justify-between">
        <p>{data?.lesson?.name}</p>
        <div className="flex gap-x-2.5">
          {data?.point ? (
            <>
              <span>{data?.point} điểm</span>
              {data?.point < 5 ? (
                <span className="text-red-500 uppercase">
                  {' '}
                  Học viên chưa đạt
                </span>
              ) : (
                <span className="text-green-500 uppercase">
                  {' '}
                  Học viên đã đạt
                </span>
              )}
            </>
          ) : (
            'Chưa chấm'
          )}
        </div>
      </div>
      <div className="flex gap-2.5">
        {data?.files?.map((file) => (
          <DetectMedia
            src={file?.url}
            type={file?.type_file}
            className="w-[100px] h-[100px] rounded overflow-hidden"
          />
        ))}
      </div>
    </div>
  );
}

export default Exercise;
