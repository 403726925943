import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UserReportEndpoint = `${endpoint}/bake-teach/report/user`;
const UserReportApi = {
  getOverview: async () =>
    await api
      .get(`${UserReportEndpoint}/overview`)
      .then((data) => data?.data?.data),
  getChart: async ({ params }) =>
    await api
      .get(`${UserReportEndpoint}/chart`, params)
      .then((data) => data?.data?.data),
  getList: async ({ params }) =>
    await api
      .get(`${UserReportEndpoint}/list`, params)
      .then((data) => data?.data?.data),
};
export default UserReportApi;
