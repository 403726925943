import { Button, Modal } from 'tera-dls';
import RegisteredStudents from '.';

interface IProps {
  open: boolean;
  onClose?: () => void;
  type?: any;
}
const ModalChart = (props: IProps) => {
  const { onClose, open, type } = props;
  return (
    <Modal
      destroyOnClose
      closeIcon={false}
      closable
      onCancel={onClose}
      className="xl:w-[1200px]"
      open={open}
      centered={true}
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <RegisteredStudents
        type={type}
        onClose={onClose}
        chartClassName="!h-[450px]"
      />
    </Modal>
  );
};

export default ModalChart;
