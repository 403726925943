import React, { useRef, useState } from 'react';
import PlayIcon from 'styles/images/Icons/PlayIcon';
import imageError from 'styles/images/video-clip-deny.png';
import customTwMerge from 'tailwind-merge.config';
import ModalPlayVideo from './ModalPlayVideo';

// type Isize = 'small' | 'normal';
const NormalVideo = (props) => {
  const {
    url,
    isView = false,
    isReview = true,
    size = 'normal',
    videoClassName,
    ...restProps
  } = props;
  const [errorThumbnail, setErrorThumbnail] = useState<boolean>(false);
  const [openReview, setOpenReview] = useState<boolean>(false);
  // const { form } = useTeraForm();
  const isSmallSize = size === 'small';
  const isLargeSize = size === 'large';

  const videoRef = useRef(null);

  const [loading, setLoading] = useState(() => isView);

  const handleLoadedMetadata = (event) => {
    event;
    // const videoDuration = event.target.duration;
    // setDuration(videoDuration);
    // form?.setValue('updated_video_duration', videoDuration);
    setLoading(false);
  };

  if (errorThumbnail) {
    return (
      <>
        <img
          className={customTwMerge(
            'h-full flex justify-center cursor-default',
            videoClassName,
            isSmallSize && '!size-[40px]',
            isLargeSize && '!w-[300px] h-[175px]',
          )}
          {...restProps}
          src={imageError}
        />
      </>
    );
  }
  // if (isView) {
  return (
    <>
      <div
        className={customTwMerge(
          'rounded-[10px] size-[82px] overflow-hidden cursor-pointer bg-gray-200 relative',
          isSmallSize && '!size-[40px]',
          isLargeSize && '!w-[300px] h-[175px]',
          (!isReview || errorThumbnail) &&
            '!cursor-default pointer-events-none',
          !isView && 'w-[1000px] h-[700px] bg-inherit border border-gray-700',
          videoClassName,
        )}
        onClick={() =>
          isView && isReview && !errorThumbnail && setOpenReview(true)
        }
      >
        {!loading && isReview && isView && (
          <div className="absolute flex justify-center items-center size-full">
            <PlayIcon
              style={{
                boxShadow: '0px 0px 4px 3px white',
              }}
              className={customTwMerge(
                'size-[28px] bg-white drop-shadow-sm rounded-full shadow-sm',
                isSmallSize && 'size-[12px] mb-3',
              )}
            />
          </div>
        )}
        {loading && isView && (
          <div className="absolute flex justify-center items-center size-full bg-[#ffffff63]">
            <div
              className={customTwMerge(
                'size-[40px] animate-spin border-2 !border-b-white rounded-full border-blue-700',
                isSmallSize && '!size-[12px] !border-[1px]',
              )}
            />
          </div>
        )}

        <video
          {...{
            src: `${url}#t=1`,
            preload: 'metadata',
            ref: videoRef,
            onError: () => {
              setErrorThumbnail(true);
              setLoading(false);
            },
            className: customTwMerge(
              'size-full',
              isSmallSize && '!size-[40px]',
              isLargeSize && '!w-[300px] !h-[175px]',
              videoClassName,
              !isView && 'h-[700px] w-[1000px]',
            ),
            controls: !isView,
            onLoadedMetadata: handleLoadedMetadata,
            // ...(isView
            //   ? {
            //       ref: videoRef,
            //       onError: () => {
            //         setErrorThumbnail(true);
            //         setLoading(false);
            //       },
            //       className: customTwMerge(
            //         'size-full',
            //         isSmallSize && '!size-[40px]',
            //         isLargeSize && '!w-[300px] !h-[175px]',
            //         videoClassName,
            //       ),
            //       onLoadedMetadata: handleLoadedMetadata,
            //     }
            //   : {
            //       onError: () => {
            //         setErrorThumbnail(true);
            //         setLoading(false);
            //       },
            //       className: customTwMerge('h-[700px] w-[1000px]'),
            //       controls: true,
            //     }),
          }}
        />
      </div>
      {openReview && (
        <ModalPlayVideo
          open={openReview}
          onClose={() => setOpenReview(false)}
          url={url}
          type={'uploaded_video'}
        />
      )}
    </>
  );
  // }
  // return (
  //   <video
  //     src={url}
  //     onError={() => {
  //       setErrorThumbnail(true);
  //       setLoading(false);
  //     }}
  //     className={customTwMerge('h-[700px] w-[1000px]')}
  //     controls
  //     preload="metadata"
  //   />
  // );
};

export default React.memo(NormalVideo);
