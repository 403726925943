export const COURSE_ACTIVITY_STATUS = {
  pending: 'Chờ duyệt',
  approve: 'Đã duyệt',
  reject: 'Từ chối',
};

export const COURSE_ACTIVITY_STATUS_COLOR = {
  pending: 'yellow03',
  approve: 'green03',
  reject: 'red03',
};

export const COURSE_ACTIVITY_TYPE = {
  open: 'Mở khóa học',
  close: 'Đóng khóa học',
};
