import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const DashboardEndpoint = `${endpoint}/bake-teach/dashboard`;
const DashboardApi = {
  getOverview: async ({ params }) =>
    await api
      .get(`${DashboardEndpoint}/overview`, params)
      .then((data) => data.data?.data),
  getCourseRevenue: async ({ params }) =>
    await api
      .get(`${DashboardEndpoint}/pie`, params)
      .then((data) => data.data?.data),
  getSystemRevenue: async ({ params }) =>
    await api
      .get(`${DashboardEndpoint}/growth`, params)
      .then((data) => data.data?.data),
  getTopLecturer: async () =>
    await api
      .get(`${DashboardEndpoint}/top-lecturer`)
      .then((data) => data.data?.data),
  getTopCourse: async () =>
    await api
      .get(`${DashboardEndpoint}/top-course`)
      .then((data) => data.data?.data),
};
export default DashboardApi;
