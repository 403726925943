import classNames from 'classnames';
import React, { useState } from 'react';
import { PlayCircleOutlined } from 'tera-dls';
import ModalVideo from './ModalVideo';
interface VideoProps {
  className?: string;
  src?: string;
}

function Video({ className, src }: VideoProps) {
  const [isModal, setIsModal] = useState<boolean>(false);
  return (
    <div className={classNames('relative group', className)}>
      <video className="object-cover w-full h-full">
        <source src={src} type="video/mp4" />
      </video>
      <div
        onClick={() => setIsModal(true)}
        className="absolute w-full h-full top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 bg-black/20 cursor-pointer"
      >
        <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
          <PlayCircleOutlined className="w-6 h-6 text-white" />
        </div>
      </div>
      {isModal && (
        <ModalVideo
          open={isModal}
          onCancel={() => setIsModal(false)}
          src={src}
        />
      )}
    </div>
  );
}

export default Video;
