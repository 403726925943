function Menu3(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 1.27415C0 0.872486 0.325611 0.546875 0.727273 0.546875H15.2727C15.6744 0.546875 16 0.872486 16 1.27415V4.91051H14.5455V2.00142H1.45455V9.27415H6.90909V10.7287H0.727273C0.325611 10.7287 0 10.4031 0 10.0014V1.27415Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0912 4.18111H2.90942V2.72656H13.0912V4.18111Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9241 5.51785C11.4003 4.88988 12.3443 4.88988 12.8205 5.51785L13.1863 6.00023L13.786 5.91777C14.5667 5.81042 15.2343 6.47796 15.1269 7.2587L15.0445 7.85843L15.5268 8.22419C16.1548 8.70035 16.1548 9.64439 15.5268 10.1205L15.0445 10.4863L15.1269 11.086C15.2343 11.8668 14.5667 12.5343 13.786 12.427L13.1863 12.3445L12.8205 12.8269C12.3443 13.4549 11.4003 13.4549 10.9241 12.8269L10.5584 12.3445L9.95865 12.427C9.17792 12.5343 8.51038 11.8668 8.61772 11.086L8.70018 10.4863L8.2178 10.1205C7.58983 9.64439 7.58983 8.70035 8.2178 8.22419L8.65722 8.8037L8.2178 8.22419L8.70018 7.85843L8.61772 7.2587C8.51038 6.47796 9.17791 5.81042 9.95865 5.91777L10.5584 6.00023L10.9241 5.51785ZM11.8723 6.67476L11.6239 7.00242C11.3641 7.34506 10.9396 7.52088 10.5136 7.4623L10.1062 7.40629L10.1623 7.81366C10.2208 8.23966 10.045 8.66411 9.70237 8.92392L9.37471 9.17237L9.70237 9.42081C10.045 9.68062 10.2208 10.1051 10.1623 10.5311L10.1062 10.9384L10.5136 10.8824C10.9396 10.8239 11.3641 10.9997 11.6239 11.3423L11.8723 11.67L12.1208 11.3423C12.3806 10.9997 12.805 10.8239 13.231 10.8824L13.6384 10.9384L13.5824 10.5311C13.5238 10.1051 13.6996 9.68062 14.0423 9.42081L14.3699 9.17237L14.0423 8.92392C13.6996 8.66411 13.5238 8.23966 13.5824 7.81366L13.6384 7.40629L13.231 7.4623C12.805 7.52088 12.3806 7.34506 12.1208 7.00242L11.8723 6.67476ZM14.648 8.96152L14.6477 8.96175L14.648 8.96152Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.517 13.607V11.7188H9.0625V14.7261C9.0625 14.9722 9.18705 15.2017 9.39347 15.3359C9.5999 15.47 9.86018 15.4906 10.0851 15.3906L11.8718 14.5966L13.6584 15.3906C13.8833 15.4906 14.1436 15.47 14.35 15.3359C14.5565 15.2017 14.681 14.9722 14.681 14.7261V11.7188H13.2265V13.607L12.1671 13.1361C11.9791 13.0526 11.7644 13.0526 11.5764 13.1361L10.517 13.607Z"
        fill="white"
      />
    </svg>
  );
}

export default Menu3;
