import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { IFormModel } from 'pages/CourseManagement/Course';
import CourseLessonApi from 'pages/CourseManagement/Course/api/Lesson';
import { useEffect, useState } from 'react';
import no_data from 'styles/images/no_data.png';
import { Button, notification, Spin } from 'tera-dls';
import LectureItemForm from './Form';
import Item from './Item';

const LectureItem = ({
  value,
  onUpdateData,
  selectedValue,
  onDelete,
  mode,
  courseId,
  lectureData,
}) => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [openForm, setOpenForm] = useState<IFormModel>({
    open: false,
  });
  const isUpdate = mode == 'update';

  const {
    data: list,
    refetch: fetchData,
    isFetching,
  } = useQuery(
    ['get-course-lesson-list', selectedValue],
    () => CourseLessonApi.getList({ section_id: selectedValue }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!selectedValue,
    },
  );

  const data = mode === 'update' ? list : value;

  useEffect(() => {
    selectedValue && fetchData();
  }, [selectedValue]);

  const { mutate: mutateDelete, isLoading } = useMutation(
    (variables: any) => CourseLessonApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-lesson-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa bài học',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa bài học </p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        if (isUpdate) {
          mutateDelete({ id });
          return;
        }
        onDelete(selectedValue, id);
      },
    });
  };

  if (lectureData?.length === 0) {
    return (
      <div className="flex justify-center items-center h-[150px]">
        <div className="flex flex-col gap-1">
          <img src={no_data} />
          <p className="text-gray-500 text-xs italic">Chưa có phần học nào</p>
        </div>
      </div>
    );
  }

  return (
    selectedValue != undefined && (
      <>
        <Spin spinning={isUpdate && (isFetching || isLoading)}>
          <div className="p-2.5 space-y-2.5">
            <div className="flex justify-end">
              <Button
                className="bg-green-500 font-normal"
                onClick={() => setOpenForm({ open: true })}
              >
                Thêm bài học
              </Button>
            </div>
            {data?.map((item) => (
              <Item
                item={item}
                onEdit={() => setOpenForm({ open: true, value: item })}
                onDelete={() => handleDelete(item?.id, item?.name)}
              />
            ))}
          </div>
        </Spin>

        {openForm.open && (
          <LectureItemForm
            open={openForm.open}
            onClose={() => setOpenForm({ open: false })}
            value={openForm.value}
            onSubmit={(values) => onUpdateData(selectedValue, values)}
            mode={mode}
            sectionId={selectedValue}
            courseId={courseId}
          />
        )}
      </>
    )
  );
};

export default LectureItem;
