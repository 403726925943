import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CourseReportEndpoint = `${endpoint}/bake-teach/report/course`;
const CourseReportApi = {
  getOverview: async () =>
    await api
      .get(`${CourseReportEndpoint}/overview`)
      .then((data) => data?.data?.data),
  getRegister: async () =>
    await api
      .get(`${CourseReportEndpoint}/count-register`)
      .then((data) => data?.data?.data),
  getCourseType: async () =>
    await api
      .get(`${CourseReportEndpoint}/count-by-type`)
      .then((data) => data?.data?.data),
  getList: async ({ params }) =>
    await api
      .get(`${CourseReportEndpoint}/list`, params)
      .then((data) => data?.data?.data),
};
export default CourseReportApi;
