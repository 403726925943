import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  notification,
  Tag,
} from 'tera-dls';
import RefundApi from '../../api';
import {
  STATUS_REFUND,
  STATUS_REFUND_COLOR,
  TYPE_REFUND,
} from '../../constants';
import { REFUND_URL } from '../../url';
import { DATE_TIME_FORMAT } from '_common/constants/common';

function RefundTable(props: ITeraTableProps) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateChangeStatus } = useMutation(
    (params: any) => {
      if (params?.status === 'approve')
        return RefundApi.approve({ id: params?.id });
      return RefundApi.reject({ id: params?.id });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-refund-list']);
          queryClient.invalidateQueries(['get-refund-summary']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleChangeStatus = (status: 'reject' | 'approve', record) => {
    const text = {
      approve: 'duyệt',
      reject: 'từ chối',
    };
    confirm.warning({
      title: `Xác nhận ${text[status]} hoàn tiền`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc chắn muốn {text[status]} hoàn tiền</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateChangeStatus({
          id: record?.id,
          status,
        });
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [
      {
        key: '1',
        label: 'Xem',
        onClick: () =>
          record?.id && navigate(`${REFUND_URL.detail.path}/${record?.id}`),
      },
    ];

    if (record?.status === 'pending')
      items.push({
        key: '2',
        label: 'Duyệt',
        onClick: () => handleChangeStatus('approve', record),
      });
    if (record?.status === 'pending')
      items.push({
        key: '3',
        label: 'Từ chối',
        onClick: () => handleChangeStatus('reject', record),
      });

    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã đơn',
      dataIndex: 'code',
      width: 200,
      render: (name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() => navigate(`${REFUND_URL.detail.path}/${record?.id}`)}
        >
          {name}
        </p>
      ),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'user',
      width: 200,
      render: (user) => (
        <HoverQuickView name={user?.full_name}>
          <p className="line-clamp-2">{user?.full_name}</p>
        </HoverQuickView>
      ),
    },
    {
      title: 'Loại hoàn tiền',
      dataIndex: 'classification',
      width: 200,
      render: (classification) => TYPE_REFUND[classification],
    },
    {
      title: 'Giá trị hoàn tiền',
      dataIndex: 'total',
      width: 200,
      render: (total) => formatCurrency(total),
    },
    {
      title: 'Ngày yêu cầu',
      dataIndex: 'created_at',
      width: 200,
      render: (created_at) => formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (status) => (
        <Tag color={STATUS_REFUND_COLOR[status]}>{STATUS_REFUND[status]}</Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <TableTera
      zebra={false}
      columns={columns}
      loading={props?.loading}
      {...props}
    />
  );
}

export default RefundTable;
