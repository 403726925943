import { Button, Modal } from 'tera-dls';
import Content, { OpportunityValueStatisticByStage } from './Content';

interface IProps {
  open: boolean;
  onClose?: () => void;
  type?: OpportunityValueStatisticByStage;
}
const ModalChart = (props: IProps) => {
  const { onClose, open, type } = props;
  return (
    <Modal
      title={''}
      destroyOnClose
      closeIcon={false}
      closable
      onCancel={onClose}
      className="modal-fullscreen"
      open={open}
      centered={true}
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <Content
        type={type}
        onClose={onClose}
        chartClassName="h-[calc(100%-50px)]"
      />
    </Modal>
  );
};

export default ModalChart;
