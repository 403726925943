import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ImageBackground from '_common/component/ImageBackground';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_BACKEND_FORMAT, REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePickerProps, Modal, notification, Row, Spin } from 'tera-dls';
import UserApi from '../../api';
import { GENDER_TYPE, USER_TYPE } from '../../constants';
import _ from 'lodash';
import moment from 'moment';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const UserForm = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const [image, setImage] = useState<any>();
  const form = useForm();
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const {
    data: detail,
    refetch: fetchData,
    isFetching,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    if (detail) {
      // detail?.file_upload && setImage(detail?.file_upload);
      const data = _.pick(detail, [
        'full_name',
        'address',
        'phone',
        'email',
        'type',
        'gender',
      ]);
      form.reset({
        ...data,
        is_active: Boolean(detail?.is_active),
        ...(detail.birthday && { birthday: moment(detail.birthday) }),
      });
      setImage({ url: detail?.avatar });
    } else {
      form.setValue('type', 'student');
      form.setValue('is_active', true);
      form.setValue('gender', 'male');
    }
  }, [detail]);

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const { mutate: mutateAction, isLoading } = useMutation(
    (variables: any) =>
      id ? UserApi.update(variables) : UserApi.create(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-user-list']);
          queryClient.invalidateQueries(['get-user-summary']);
          queryClient.invalidateQueries(['get-lecturer-list']);

          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
        is_active: Number(values?.is_active),
        ...(values?.birthday && {
          birthday: values?.birthday.format(DATE_BACKEND_FORMAT),
        }),
        avatar: image?.url ?? null,
      },
      ...(id && { id }),
    });
  };

  const typeOptions = Object.entries(USER_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  const genderOptions = Object.entries(GENDER_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    const today = moment();
    return current && current > today;
  };

  return (
    <>
      <Modal
        open={open}
        title={id ? 'Sửa người dùng' : 'Thêm người dùng'}
        closeIcon={false}
        okText="Lưu"
        cancelText="Hủy"
        className="!w-[90%] xmd:!w-[60%]"
        maskClosable={false}
        onOk={handleSubmit(handleSubmitForm)}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
      >
        <Spin spinning={!!id && isFetching}>
          <FormTera form={form} isLoading={false}>
            <FormTeraItem label="Ảnh đại diện" name="">
              <ImageBackground
                value={image}
                onChange={(val) => {
                  setImage(val);
                }}
                isShowBtnDelete
                imageClassName="!size-[100px] object-cover"
                object_key={'course-category'}
                folder={'bake-teach'}
              />
            </FormTeraItem>
            <Row className=" grid-cols-2 gap-3">
              <FormTeraItem
                label="Họ và tên"
                name="full_name"
                rules={[
                  {
                    required: messageValidate.emptyText,
                  },
                ]}
              >
                <Input maxLength={255} />
              </FormTeraItem>
              <FormTeraItem
                label="Email liên hệ"
                name="email"
                rules={[
                  {
                    pattern: {
                      value: REGEX.EMAIL,
                      message: 'Địa chỉ Email không hợp lệ',
                    },
                  },
                ]}
              >
                <Input maxLength={255} />
              </FormTeraItem>
              <FormTeraItem label="Ngày sinh" name="birthday">
                <DatePicker
                  placeholder={'Vui lòng chọn'}
                  disabledDate={disabledDate}
                />
              </FormTeraItem>
              <FormTeraItem label="Loại người dùng" name="type">
                <Select
                  allowClear={false}
                  placeholder="Vui lòng chọn"
                  options={typeOptions}
                  disabled={!!id}
                />
              </FormTeraItem>
              <FormTeraItem
                label="Số điện thoại"
                name="phone"
                {...(!id && {
                  rules: [
                    {
                      required: messageValidate.emptyText,
                    },
                    {
                      pattern: {
                        value: REGEX.PHONE_NUMBER,
                        message: messageValidate.phone,
                      },
                    },
                  ],
                })}
              >
                <Input disabled={!!id} />
              </FormTeraItem>
              {!id && (
                <FormTeraItem
                  label="Mật khẩu mới"
                  name="password"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.PASSWORD),
                        message: messageValidate.password,
                      },
                      minLength: {
                        value: 8,
                        message: messageValidate.min_length_password,
                      },
                      maxLength: {
                        value: 16,
                        message: messageValidate.max_length_password,
                      },
                    },
                  ]}
                >
                  <InputPassword />
                </FormTeraItem>
              )}
              <FormTeraItem label="Giới tính" name="gender">
                <Select
                  allowClear={false}
                  placeholder="Vui lòng chọn"
                  options={genderOptions}
                />
              </FormTeraItem>

              {!id && (
                <FormTeraItem
                  label="Nhập lại mật khẩu"
                  name="confirm_password"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.PASSWORD),
                        message: messageValidate.password,
                      },
                      minLength: {
                        value: 8,
                        message: messageValidate.min_length_password,
                      },
                      maxLength: {
                        value: 16,
                        message: messageValidate.max_length_password,
                      },
                      validate: {
                        existed: (value, values) => {
                          const password = values?.password.trim();
                          if (value?.trim() !== password) {
                            return messageValidate.confirm_password;
                          }
                        },
                      },
                    },
                  ]}
                >
                  <InputPassword />
                </FormTeraItem>
              )}
              <FormTeraItem name="is_active" label="" className="w-full">
                <CheckBox labelClassName="font-normal text-[13px]">
                  Hoạt động
                </CheckBox>
              </FormTeraItem>
            </Row>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default UserForm;
