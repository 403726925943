import { useQuery } from '@tanstack/react-query';
import HorizontalBarChart, {
  HorizontalBarChartType,
} from '_common/component/Chart/BarChart/HorizontalBarChart';
import { useMemo, useState } from 'react';
import { Spin } from 'tera-dls';
import CourseReportApi from '../../api';
import { COURSE_TYPE, COURSE_TYPE_COLOR } from '../../constants';
import ModalChart from './ModalChart';
import NoData from '_common/component/NoData';
import Header from 'pages/Dashboard/Header';

interface RegisteredStudentsProps {
  type?: HorizontalBarChartType;
  chartClassName?: string;
  onClose?: () => void;
}

const RegisteredStudents = (props: RegisteredStudentsProps) => {
  const { chartClassName, type, onClose } = props;
  const isSmallType = type === 'small';
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data, refetch, isFetching } = useQuery(
    ['get-register-student-report'],
    () => CourseReportApi.getRegister(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const transformData = (data) => {
    const result = {};

    Object.keys(data).forEach((type) => {
      data[type].forEach((entry) => {
        const { time, total_count } = entry;

        if (!result[time]) {
          result[time] = {};
        }

        result[time][type] = total_count;
      });
    });

    return result;
  };

  const dataChart = useMemo(() => {
    if (!data)
      return {
        datasets: [
          {
            data: [],
          },
        ],
        labels: [],
      };
    const dataConvert = transformData(data);

    const dataFilter = Object.entries(dataConvert).reduce(
      (result, [key, value]) => {
        if (Object.values(value).every((value) => value === 0))
          return { ...result };
        return { ...result, [key]: value };
      },
      {},
    );

    const labels = Object.keys(dataFilter).map((value) => value);
    const datasets = Object.entries(COURSE_TYPE)?.map(([key, value]) => {
      const dataRevenue = Object.values(dataFilter).map((i) => i[key]);
      return {
        label: value,
        data: dataRevenue,
        backgroundColor: COURSE_TYPE_COLOR[key],
        borderWidth: 1,
        maxBarThickness: 50, // Chiều rộng cột tối đa (px)
      };
    });
    return {
      labels,
      datasets,
    };
  }, [data]);

  const isHasData = useMemo(() => {
    if (!dataChart) return false;
    const check = dataChart?.datasets?.some((item) =>
      item?.data?.some((i) => !!i),
    );
    return check;
  }, [dataChart]);

  const options: any = {
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="rounded border bg-white flex flex-col gap-2.5 p-5">
      <Header
        title="Số lượng học viên đăng ký"
        onClickRefresh={refetch}
        onZoomOut={() => setOpenModal(true)}
        onZoomIn={() => onClose()}
        isSmallType={isSmallType}
        isRefetch={false}
        isSetting={false}
      />
      <Spin spinning={isFetching}>
        {isHasData ? (
          <HorizontalBarChart
            defaultLabelMaxLength={100}
            type={type}
            data={dataChart}
            chartClassName={chartClassName}
            containerClassName="pl-2 pr-5"
            options={options}
          />
        ) : (
          <NoData />
        )}
      </Spin>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </div>
  );
};

export default RegisteredStudents;
