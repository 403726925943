import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const GuideEndpoint = `${endpoint}/bake-teach/video-guide`;
const GuideApi = {
  getList: async () =>
    await api.get(`${GuideEndpoint}/list`).then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${GuideEndpoint}/create`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${GuideEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default GuideApi;
