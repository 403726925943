import { useQuery } from '@tanstack/react-query';
import HorizontalBarChart from '_common/component/Chart/BarChart/HorizontalBarChart';
import NoData from '_common/component/NoData';
import { FORMAT_DATE_BY_VARIABLE } from '_common/constants/common';
import moment from 'moment';
import StatisticApi from 'pages/Finance/Statistic/api';
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ChartBarProps, Spin } from 'tera-dls';
import { StatisticCashExpenseContext } from '../..';
import Header from '../../../Header';
import ModalChart from './Modal';
export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
}

export interface StatisticCashExpenseByTimeRefs {
  refetchData?: () => void;
}

const Content = (props, ref) => {
  const { type = 'small', onClose, chartClassName = '' } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const { contextParams } = useContext(StatisticCashExpenseContext);

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-time', contextParams],
    () =>
      StatisticApi.getChart({
        params: {
          chart_type: 'byDay',
          investor_id: contextParams?.investor_id,
          receipt_transaction_type:
            contextParams?.receipt_transaction_type?.join(','),
          payment_transaction_type:
            contextParams?.payment_transaction_type?.join(','),
          date_from: contextParams?.date[0],
          date_to: contextParams?.date[1],
          payment_method: contextParams?.payment_method?.join(','),
          show_by: contextParams?.show_by,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        refetchData() {
          refetch();
        },
      };
    },
    [],
  );

  const dataSource = useMemo(() => {
    const data = response?.group_by;
    const labels = data?.map((item) => item?.date) || [];
    const dataCash = data?.map((item) => item?.receipt) || [];
    const dataExpense = data?.map((item) => item?.payment) || [];

    return {
      labels: labels?.map((date) => {
        if (date.includes(' - ')) {
          const data = date.split(' - ');
          const dateStart = moment(data[0]).format(
            FORMAT_DATE_BY_VARIABLE[contextParams?.show_by],
          );
          const dateEnd = moment(data[1]).format(
            FORMAT_DATE_BY_VARIABLE[contextParams?.show_by],
          );
          return `${dateStart} - ${dateEnd}`;
        }
        return moment(date).format(
          FORMAT_DATE_BY_VARIABLE[contextParams?.show_by],
        );
      }),

      datasets: [
        {
          label: 'Thu',
          data: dataCash,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: '#3F83F8',
          barThickness: 50,
        },
        {
          label: 'Chi',
          data: dataExpense,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: '#F9C74F',
          barThickness: 50,
        },
      ],
    };
  }, [response, contextParams]);

  const isSmallType = type === 'small';

  return (
    <>
      <div className="rounded-[5px] border-[1px] bg-white h-full flex flex-col overflow-hidden">
        <Header
          title={'Thu chi theo ngày'}
          onClickRefresh={() => refetch()}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <Spin spinning={isRefetching}>
          <div className="p-[16px] h-full ">
            {response?.group_by?.length > 0 ? (
              <HorizontalBarChart
                defaultLabelMaxLength={100}
                type={type}
                data={dataSource}
                chartClassName={chartClassName}
                containerClassName="pl-2 pr-5"
              />
            ) : (
              <NoData />
            )}
          </div>
        </Spin>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </>
  );
};

export default forwardRef<StatisticCashExpenseByTimeRefs, IProps>(Content);
