import { useQuery } from '@tanstack/react-query';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { formatShortCurrency } from '_common/utils';
import classNames from 'classnames';
import moment from 'moment';
import AffiliatesAPI from 'pages/Affiliate/General/_api';
import { useForm } from 'react-hook-form';
import img_tear from 'styles/images/Affiliates/tear.png';
import {
  Col,
  CreditCardOutlined,
  CursorArrowRippleOutlined,
  formatCurrency,
  formatDate,
  formatNumber,
  Row,
  Tooltip,
  UserGroupOutlined,
} from 'tera-dls';

interface IGridBoxProps {
  className?: string;
}

const GridBox = ({ className }: IGridBoxProps) => {
  const startMonth = moment().subtract(1, 'month');
  const endMonth = moment();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      date: [startMonth, endMonth],
    },
  });

  const date = form.watch('date');

  const start_date = date[0];
  const end_date = date[1];

  const { data: marketingIndex } = useQuery(
    ['get-marketing-index-affiliate', start_date, end_date],
    () => {
      const params = {
        start_date: formatDate(start_date, DATE_BACKEND_FORMAT),
        end_date: formatDate(end_date, DATE_BACKEND_FORMAT),
      };
      return AffiliatesAPI.marketingIndex({ params });
    },
    {
      enabled: !!start_date && !!end_date,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  return (
    <FormTera className={classNames('w-full', className)} form={form}>
      <div
        className="rounded-[16px] over-view-sell shadow-md"
        style={{
          background:
            'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 0%, #90EBFF 100%), linear-gradient(90deg, #FFF 0%, #2252CD 100%)',
        }}
      >
        <Row className="grid-cols-2 py-5 px-8 flex items-center justify-between">
          <Col className="text-[#374151] text-[24px] font-medium">
            Chỉ số tiếp thị
          </Col>
          <Col className="flex gap-2.5">
            <FormTeraItem name="date">
              <RangePicker
                className="!bg-transparent !border-l-0 !border-t-0 !border-l-0 !border-r-0 !border-b-1 !border-b-black !rounded-none !ring-0"
                placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                allowClear={false}
              />
            </FormTeraItem>
          </Col>
        </Row>
        <Row className="sm:grid-cols-2 xmd:grid-cols-3 p-5 xmd:p-7 xl:p-8">
          <Col className="relative py-6 xmd:py-8 xl:py-10">
            <img
              className="absolute top-0 left-1/2 -translate-x-1/2"
              src={img_tear}
              alt="tear-custom"
            />
            <div className="flex flex-col items-center justify-between gap-y-[62px] h-full text-[#374151] box-content">
              <div className="text-4xl font-bold text-center">
                {formatNumber(marketingIndex?.total_click ?? 0)}
              </div>
              <div className="flex items-center justify-between gap-x-2.5">
                <CursorArrowRippleOutlined className="w-5 h-5" />
                <span className="text-[16px]">Click</span>
              </div>
            </div>
          </Col>
          <Col className="relative py-6 xmd:py-8 xl:py-10">
            <img
              className="absolute top-0 left-1/2 -translate-x-1/2"
              src={img_tear}
              alt="tear-custom"
            />
            <div className="flex flex-col items-center justify-between gap-y-[62px] h-full text-[#374151] box-content">
              <div className="text-4xl font-bold text-center">
                {formatNumber(marketingIndex?.total_affiliate ?? 0)}
              </div>
              <div className="flex items-center justify-between gap-x-2.5">
                <UserGroupOutlined className="w-5 h-5" />
                <span className="text-[16px]">Người tham gia</span>
              </div>
            </div>
          </Col>
          <Col className="relative py-6 xmd:py-8 xl:py-10">
            <img
              className="absolute top-0 left-1/2 -translate-x-1/2"
              src={img_tear}
              alt="tear-custom"
            />
            <div className="flex flex-col items-center justify-between gap-y-[62px] h-full text-[#374151] box-content">
              <div className="text-4xl font-bold text-center">
                <Tooltip
                  placement="top"
                  title={formatCurrency(
                    marketingIndex?.total_commission_affiliates ?? 0,
                  )}
                >
                  <div>
                    {formatShortCurrency(
                      marketingIndex?.total_commission_affiliates ?? 0,
                    )}
                  </div>
                </Tooltip>
              </div>
              <div className="flex items-center justify-between gap-x-2.5">
                <CreditCardOutlined className="w-5 h-5" />
                <span className="text-[16px]">Payout</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </FormTera>
  );
};

export default GridBox;
