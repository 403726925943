import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { removeNullObject } from '_common/utils';
import _ from 'lodash';
import StudentApi from 'pages/StudentManagement/Student/api';
import { STUDENT_URL } from 'pages/StudentManagement/Student/url';
import {
  USER_STATUS,
  USER_STATUS_COLOR,
} from 'pages/UserManagement/User/constants';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  Tag,
  updateQueryParams,
} from 'tera-dls';

const Student = ({ dataDetail }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const params = _.pick(queryParams, ['student']);

  const paramsObject = params?.student ? JSON.parse(params?.student) : {};

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      student: JSON.stringify({
        ...paramsObject,
        ...data,
      }),
    });
    navigate(location.pathname + paramString);
  };

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-student-list', paramsObject],
    () =>
      StudentApi.getList({
        page: 1,
        limit: 10,
        course_id: dataDetail?.id,
        ...removeNullObject(paramsObject),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!dataDetail?.id,
    },
  );

  useEffect(() => {
    !!dataDetail?.id && refetch();
  }, [dataDetail?.id]);

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => StudentApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-student-list']);
          queryClient.invalidateQueries(['get-student-summary']);
          queryClient.invalidateQueries(['get-course-summary-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa học viên',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa học viên</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => navigate(`${STUDENT_URL.detail.path}/${record?.id}`),
      },
      {
        key: 2,
        label: <a>Sửa</a>,
        onClick: () => navigate(`${STUDENT_URL.update.path}/${record?.id}`),
      },
      ...(record.is_active === 0
        ? [
            {
              key: 7,
              label: <a className="text-red-500">Xóa</a>,
              onClick: () => handleDelete(record?.id, record?.full_name),
            },
          ]
        : []),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã người dùng',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 200,
      render: (value) => (
        <HoverQuickView name={value}>
          <span className="line-clamp-1 text-blue-600">{value}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
    },
    {
      title: 'Số lượng khóa học',
      width: 150,
      dataIndex: 'courses_count',
      render: (val) => val ?? 0,
    },
    {
      title: 'Số lượng chứng chỉ',
      width: 150,
      dataIndex: 'student',
      render: (val) => val ?? 0,
    },
    {
      title: 'Trạng thái',
      width: 150,
      dataIndex: 'is_active',
      render: (val) => (
        <Tag color={USER_STATUS_COLOR[val]}>{USER_STATUS[val]}</Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  // const handleSearch = ({ keyword }: Record<string, any>): void => {
  //   handleUpdateQueryParams({ keyword, page: 1 });
  // };

  return (
    <div className="space-y-2.5">
      {/* <div className="flex justify-end">
        <Searching onSearch={handleSearch} />
      </div> */}
      <TableTera
        loading={isFetching}
        wrapperClassName="shadow-none"
        rowKey={'id'}
        data={response?.data ?? []}
        className="center-table"
        pagination={{
          onChange: handleChangePage,
          total: response?.total || 0,
          current: response?.current_page,
          pageSize: response?.per_page || 10,
          to: response?.to,
          from: response?.from || 10,
        }}
        columns={columns}
      />
    </div>
  );
};

export default Student;
