import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import HeaderViewList from '_common/component/HeaderViewList';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageWarning } from '_common/constants/message';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { usePrompt } from '_common/hooks/usePrompt';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BookmarkOutlined, Button, notification, Row } from 'tera-dls';
import CommissionConfigApi from './api';
import CertificateConfig from './containers/CertificateConfig';

const CourseConfig = () => {
  const form = useForm({
    defaultValues: {
      course_offline: 1,
      course_online: 1,
    },
  });

  const isDirty = form.formState.isDirty;
  const queryClient = useQueryClient();

  const {
    data: dataDetails,
    isLoading,
    refetch,
  } = useQuery(
    ['get-course-course-config-list'],
    () => CommissionConfigApi.getList(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  usePrompt(
    <p className="break-word">
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </p>,
    isDirty,
  );

  const { mutate: mutateUpdate, isLoading: isLoadingMutate } = useMutation(
    (params: any) => CommissionConfigApi.save(params),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-course-course-config-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateUpdate({
      params: {
        ...values,
      },
    });
  };

  useEffect(() => {
    if (dataDetails) {
      form.reset({
        course_offline: dataDetails?.course_offline,
        course_online: dataDetails?.course_online,
      });
    }
  }, [dataDetails]);

  const disabled = isLoading || isLoadingMutate;

  return (
    <div className="tera-page !bg-none">
      <HeaderViewList title="Cấu hình khóa học" contentClassName="p-2.5 pt-0">
        <CardFormV2 title="Hoa hồng khóa học">
          <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
            <Row className="grid-cols-2 gap-x-2.5">
              <FormTeraItem
                name="course_offline"
                label="Khóa học offline"
                help={'<100'}
              >
                <InputNumber
                  min={1}
                  max={100}
                  suffix={<span className="mr-5">%</span>}
                />
              </FormTeraItem>
              <FormTeraItem
                name="course_online"
                label="Khóa học online"
                help={'<100'}
              >
                <InputNumber
                  min={1}
                  max={100}
                  suffix={<span className="mr-5">%</span>}
                />
              </FormTeraItem>
            </Row>
            <Button
              type="success"
              icon={<BookmarkOutlined className="w-4" />}
              disabled={!isDirty || disabled}
              loading={disabled}
              className="bg-green-500 hover:bg-green-600 ml-auto"
            >
              Lưu
            </Button>
          </FormTera>
        </CardFormV2>
        <CertificateConfig />
      </HeaderViewList>
    </div>
  );
};

export default CourseConfig;
