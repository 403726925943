import { useState } from 'react';
import ReactPlayer from 'react-player';
import imageError from 'styles/images/video-clip-deny.png';
import customTwMerge from 'tailwind-merge.config';
import ModalPlayVideo from './ModalPlayVideo';
import PlayIcon from 'styles/images/Icons/PlayIcon';

const getThumbnail = (url) => {
  if (!url) return;

  let id = '';
  const urlObj = new URL(url);
  if (url?.includes('watch?v')) {
    // id = url?.substring(url?.lastIndexOf('?v=') + '?v='.length);
    const v = urlObj.searchParams.get('v');
    id = v;
  } else if (urlObj.pathname.includes('/shorts')) {
    id = urlObj.pathname.split('/').pop();
  } else {
    id = url?.substring(
      url?.indexOf('be/') + 'be/'.length,
      url?.indexOf('?si='),
    );
  }

  return `https://img.youtube.com/vi/${id}/0.jpg`;
};

const YoutubeVideo = (props) => {
  const {
    url,
    isView = false,
    isReview = true,
    size = 'normal',
    ...restProps
  } = props;
  const [errorThumbnail, setErrorThumbnail] = useState<boolean>(false);
  const [openReview, setOpenReview] = useState<boolean>(false);
  const isSmallSize = size === 'small';
  const isLargeSize = size === 'large';

  if (isView) {
    return (
      <>
        <div
          className={customTwMerge(
            'cursor-pointer relative size-[82px]',
            isSmallSize && '!size-[40px]',
            isLargeSize && '!w-[300px] h-[175px]',
            (!isReview || errorThumbnail) &&
              'cursor-default pointer-events-none',
          )}
          onClick={() => isReview && !errorThumbnail && setOpenReview(true)}
        >
          {isReview && !errorThumbnail && (
            <div className="absolute flex justify-center items-center size-full">
              <PlayIcon
                style={{
                  boxShadow: '0px 0px 4px 3px white',
                }}
                className={customTwMerge(
                  'size-[28px] bg-white drop-shadow-sm rounded-full shadow-sm',
                  isSmallSize && 'size-[12px]',
                )}
              />
            </div>
          )}
          <img
            className=" w-full h-full object-cover"
            {...restProps}
            src={errorThumbnail ? imageError : getThumbnail(url)}
            onError={() => {
              setErrorThumbnail(true);
            }}
          />
          <ReactPlayer
            width={'100%'}
            height={66}
            onError={() => setErrorThumbnail(true)}
            style={{
              objectFit: 'cover',
              display: 'none',
            }}
            playing={false}
            {...restProps}
            url={url}
          />
        </div>
        {openReview && (
          <ModalPlayVideo
            open={openReview}
            onClose={() => setOpenReview(false)}
            url={url}
            type={'youtube_link'}
          />
        )}
      </>
    );
  }
  return (
    <ReactPlayer width={1000} height={700} controls {...restProps} url={url} />
  );
};

export default YoutubeVideo;
