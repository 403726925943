const OrderIcon = (props) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1514 10.8462V8.69231C13.1514 7.1629 11.9116 5.92308 10.3822 5.92308H9.15144C8.64164 5.92308 8.22837 5.5098 8.22837 5V3.76923C8.22837 2.23983 6.98854 1 5.45913 1H3.92067M5.76683 1H1.76683C1.25703 1 0.84375 1.41328 0.84375 1.92308V16.0769C0.84375 16.5867 1.25703 17 1.76683 17H12.2284C12.7382 17 13.1514 16.5867 13.1514 16.0769V8.38462C13.1514 4.30621 9.84524 1 5.76683 1Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.46565 13.3837C5.46565 13.7241 5.18967 14.0001 4.84924 14.0001C4.5088 14.0001 4.23283 13.7241 4.23283 13.3837M5.46565 13.3837C5.46565 13.0432 5.18967 12.7672 4.84924 12.7672C4.5088 12.7672 4.23283 13.0432 4.23283 13.3837M5.46565 13.3837H7.9313M4.23283 13.3837H3.46231C3.20698 13.3837 3 13.1767 3 12.9213V11.5346M10.0887 13.3837C10.0887 13.7241 9.81277 14.0001 9.47233 14.0001C9.1319 14.0001 8.85592 13.7241 8.85592 13.3837M10.0887 13.3837C10.0887 13.0432 9.81277 12.7672 9.47233 12.7672C9.1319 12.7672 8.85592 13.0432 8.85592 13.3837M10.0887 13.3837L10.5511 13.3836C10.8064 13.3836 11.0149 13.1764 10.9992 12.9216C10.9128 11.5213 10.4369 10.2263 9.67847 9.1439C9.52962 8.93147 9.2886 8.80483 9.02961 8.79062H7.9313M8.85592 13.3837H7.9313M7.9313 8.79062V8.39679C7.9313 8.16358 7.75767 7.96629 7.52571 7.94222C6.84861 7.87195 6.16134 7.83594 5.46565 7.83594C4.76997 7.83594 4.08269 7.87195 3.40559 7.94222C3.17363 7.96629 3 8.16358 3 8.39679V11.5346M7.9313 8.79062V11.5346M7.9313 13.3837V11.5346M7.9313 11.5346H3"
      stroke="white"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default OrderIcon;
