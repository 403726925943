import { useMutation, useQueryClient } from '@tanstack/react-query';
import CkEditor, {
  convertClassNameToInlineStyle,
} from '_common/component/CkEditor';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { FormTeraItem } from '_common/dof/FormTera';
import UploadFiles from '_common/dof/UploadFiles';
import useConfirm from '_common/hooks/useConfirm';
import LecturerApplicationApi from 'pages/LecturerManagement/LecturerApplication/api';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Modal, notification, Row } from 'tera-dls';
import InputData from './InputData';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const RegistrationForm = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const [degree, setDegree] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const form = useForm();
  const {
    formState: { isDirty },
  } = form;

  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const information = form.watch('information');

  const handleCancel = () => {
    if (isDirty || files?.length > 0 || degree?.length > 0) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const { mutate: mutateRegistration } = useMutation(
    (variables: any) => LecturerApplicationApi.register(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-user-list']);
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = () => {
    mutateRegistration({
      params: {
        degree,
        attachment: files,
        information: convertClassNameToInlineStyle(
          (window as any).editor ? (window as any).editor.getData() : null,
        ),
        user_id: id,
      },
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={'Đăng ký giảng viên'}
        closeIcon={false}
        className="w-[90%] xmd:w-[70%]"
        okText="Đồng ý"
        cancelText="Hủy"
        maskClosable={false}
        onOk={handleSubmitForm}
        onCancel={handleCancel}
      >
        <Row className="grid-cols-2 gap-5 overflow-hidden">
          <Col className="col-span-1">
            <FormTeraItem label="Bằng cấp">
              <InputData data={degree} updateData={setDegree} />
            </FormTeraItem>

            <FormTeraItem label="Bằng cấp đính kèm" name="">
              <UploadFiles
                fileList={files}
                maxFileNumber={10}
                maxSize={5}
                isCount={false}
                accept="application/pdf"
                onReceiveFiles={(_, files) => setFiles(files)}
                onRemove={(fileDelete) => {
                  const newList = files?.filter(
                    (file) => file.id !== fileDelete?.id,
                  );
                  setFiles(newList);
                }}
              />
            </FormTeraItem>
          </Col>
          <Col className="col-span-1">
            <FormTeraItem label="Mô tả giảng viên" name="">
              <div className="tailwind-preflight">
                <CkEditor
                  name="content"
                  data={information}
                  editorCallback={(editor) =>
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        'height',
                        '400px',
                        editor.editing.view.document.getRoot(),
                      );
                    })
                  }
                />
              </div>
            </FormTeraItem>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default RegistrationForm;
