import ActionCUD from '_common/component/TableColumnCustom/ActionCUD';
import React from 'react';
import { formatCurrency } from 'tera-dls';

interface CardProps {
  title: string;
  amount: number;
  onClickDelete?: () => void;
  onClickUpdate?: () => void;
}

const Card = (props: CardProps) => {
  const { title, amount, onClickUpdate, onClickDelete } = props;
  return (
    <div className="p-2.5 rounded-md border flex items-center gap-x-2.5">
      <div className="flex-1">
        <p className="font-medium mb-2.5">{title}</p>
        <p className="text-green-500">{formatCurrency(amount)}</p>
      </div>
      <div className="shrink-0 flex items-center gap-x-2.5">
        <ActionCUD
          onClickDelete={onClickDelete}
          onClickUpdate={onClickUpdate}
        />
      </div>
    </div>
  );
};

export default Card;
