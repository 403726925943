import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  Tabs,
  Tag,
  updateQueryParams,
} from 'tera-dls';
import LecturerApplicationApi from './api';
import {
  LECTURER_APPLICATION_STATUS,
  LECTURER_APPLICATION_STATUS_COLOR,
} from './constants';
import Searching from './containers/Searching';
import { LECTURER_APPLICATION_URL } from './url';

export interface IFormModel {
  open: boolean;
  value?: string | number;
}

const LecturerApplication = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  // const [openFilter, setOpenFilter] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const params = _.omit(queryParams, ['status']);
  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(
    ['get-lecturer-application-list', queryParams],
    () =>
      LecturerApplicationApi.getList({
        page: 1,
        limit: 10,
        ...params,
        ...(!!queryParams?.status &&
          queryParams?.status !== 'all' && { status: queryParams?.status }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-lecturer-application-summary', queryParams],
    () =>
      LecturerApplicationApi.getSummaryList({
        ...queryParams,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
    refetchSummary();
  }, []);

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }: Record<string, any>): void => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  // const handleFilter = (values: Record<string, any>): void => {
  //   handleUpdateQueryParams({ ...values, page: 1 });
  // };

  const handleChangeTab = (key: any): void => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(LECTURER_APPLICATION_STATUS)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{LECTURER_APPLICATION_STATUS[key]}</span>
          <Badge
            showZero
            count={summary?.find((i) => i.status == key)?.total_count ?? 0}
          />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả'}</span>
            <Badge
              showZero
              count={summary?.find((i) => i.status === 'all')?.total_count ?? 0}
            />
          </h3>
        ),
      },
      ...data,
    ];
  }, [summary]);

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => LecturerApplicationApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-lecturer-application-list']);
          queryClient.invalidateQueries(['get-lecturer-application-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateChangeStatus } = useMutation(
    (variables: any) => {
      console.log(variables);

      if (variables?.status === 'approve')
        return LecturerApplicationApi.approve({ id: variables?.id });
      return LecturerApplicationApi.reject({ id: variables?.id });
    },
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          refetch();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa đăng ký giảng viên',
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xóa đăng ký giảng viên</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const handleChangePage: PaginationProps['onChange'] = (
    page: number,
    pageSize: number,
  ): void => {
    handleUpdateQueryParams({
      page,
      limit: pageSize,
    });
  };

  const handleChangeStatus = (
    record: any,
    status: 'approve' | 'reject',
  ): void => {
    const text = {
      approve: 'duyệt',
      reject: 'từ chối',
    };
    confirm.warning({
      title: `Xác nhận ${text[status]} đăng ký giảng viên`,
      content: (
        <p className="break-word">
          <p>Bạn có chắc muốn xác nhận {text[status]} đăng ký giảng viên</p>
          <p>
            <span className="font-bold break-word">
              {` ${record?.user?.full_name} `}{' '}
            </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateChangeStatus({ id: record?.id, status });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () =>
          navigate(`${LECTURER_APPLICATION_URL.detail.path}/${record?.id}`),
      },
      ...(record?.status == 'pending'
        ? [
            {
              key: 2,
              label: <a>Duyệt</a>,
              onClick: () => handleChangeStatus(record, 'approve'),
            },
            {
              key: 3,
              label: <a>Từ chối</a>,
              onClick: () => handleChangeStatus(record, 'reject'),
            },
          ]
        : []),

      ...(record?.status == 'reject'
        ? [
            {
              key: 7,
              label: <a className="text-red-500">Xóa</a>,
              onClick: () => handleDelete(record?.id, record?.user?.full_name),
            },
          ]
        : []),
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã Người dùng',
      dataIndex: 'code',
      width: 150,
      render: (_, record) => record?.user?.code,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 200,
      render: (_, record) => (
        <HoverQuickView
          name={record?.user?.full_name}
          avatarUrl={record?.user?.avatar}
          email={record?.user?.email}
          phone={record?.user?.phone}
        >
          <span className="line-clamp-1 text-blue-600">
            {record?.user?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 150,
      render: (_, record) => record?.user?.phone,
    },
    {
      title: 'Email liên hệ',
      width: 150,
      dataIndex: 'email',
      render: (_, record) => record?.user?.email,
    },

    {
      title: 'Trạng thái',
      width: 150,
      dataIndex: 'status',
      render: (val) => (
        <Tag color={LECTURER_APPLICATION_STATUS_COLOR[val]}>
          {LECTURER_APPLICATION_STATUS[val]}
        </Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="DANH SÁCH ĐĂNG KÝ GIẢNG VIÊN"
          actionLeftRender={<Searching onSearch={handleSearch} />}
          // onClickFilter={() => setOpenFilter(true)}
          middleContent={
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
          }
        >
          <div className="page-content-v2  h-full !mb-2.5 !gap-y-0">
            <TableTera
              loading={isFetching}
              wrapperClassName="shadow-none"
              rowKey={'id'}
              data={response?.data ?? []}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page,
                pageSize: response?.per_page || 10,
                to: response?.to,
                from: response?.from || 10,
              }}
              columns={columns}
            />
          </div>
        </HeaderViewListV2>
      </div>

      {/* {openFilter && (
        <UserFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )} */}
    </>
  );
};

export default LecturerApplication;
