import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const HomeWorkEndpoint = `${endpoint}/bake-teach/course/assignment`;
const HomeWorkApi = {
  getList: async (params) =>
    await api
      .get(`${HomeWorkEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${HomeWorkEndpoint}/detail/${id}`)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${HomeWorkEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${HomeWorkEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  scoring: async ({ id, params }) =>
    await api
      .put(`${HomeWorkEndpoint}/scoring/${id}`, params)
      .then((result) => result?.data),
  showApp: async ({ id, params }) =>
    await api
      .put(`${HomeWorkEndpoint}/show-app/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${HomeWorkEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default HomeWorkApi;
