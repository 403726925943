const DriveIcon = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.05841 11.4673L1.67583 12.5336C1.80412 12.7582 1.98853 12.9346 2.20503 13.0629C2.82514 12.2757 3.25681 11.6717 3.50002 11.2507C3.74684 10.8236 4.05018 10.1554 4.41006 9.2462C3.44013 9.11853 2.70514 9.05469 2.20509 9.05469C1.72512 9.05469 0.990088 9.11853 0 9.2462C0 9.49475 0.0641477 9.7433 0.192443 9.96785L1.05841 11.4673Z"
      fill="#0066DA"
    />
    <path
      d="M11.7951 13.0629C12.0117 12.9346 12.1961 12.7582 12.3243 12.5337L12.5809 12.0927L13.8078 9.96785C13.9337 9.74817 14 9.4994 14.0002 9.2462C13.0043 9.11853 12.2707 9.05469 11.7992 9.05469C11.2925 9.05469 10.5588 9.11853 9.59814 9.2462C9.95376 10.1604 10.2531 10.8286 10.4962 11.2507C10.7414 11.6767 11.1743 12.2807 11.7951 13.0629Z"
      fill="#EA4335"
    />
    <path
      d="M6.99995 4.75524C7.71744 3.88871 8.21191 3.22053 8.48334 2.7507C8.70194 2.37237 8.94249 1.76832 9.20498 0.938537C8.98848 0.810241 8.73993 0.746094 8.48334 0.746094H5.51657C5.25998 0.746094 5.01148 0.81828 4.79492 0.938537C5.12888 1.8902 5.41223 2.56748 5.64498 2.97037C5.90222 3.41563 6.35388 4.01059 6.99995 4.75524Z"
      fill="#00832D"
    />
    <path
      d="M9.58989 9.24609H4.41005L2.20508 13.0628C2.42153 13.1911 2.67008 13.2552 2.92667 13.2552H11.0733C11.3299 13.2552 11.5785 13.1831 11.7949 13.0627L9.58989 9.24609Z"
      fill="#2684FC"
    />
    <path
      d="M6.99998 4.75415L4.795 0.9375C4.57844 1.0658 4.39404 1.24216 4.26574 1.46671L0.192443 8.52279C0.0665132 8.74246 0.000173987 8.99123 0 9.24444H4.41006L6.99998 4.75415Z"
      fill="#00AC47"
    />
    <path
      d="M11.7709 4.99478L9.73418 1.46671C9.60594 1.24216 9.42148 1.0658 9.20498 0.9375L7 4.75421L9.58986 9.24449H13.9919C13.9919 8.99589 13.9278 8.74739 13.7995 8.52285L11.7709 4.99478Z"
      fill="#FFBA00"
    />
  </svg>
);
export default DriveIcon;
