import { useQuery } from '@tanstack/react-query';
import TabForm, { TabFormMenuProps } from '_common/component/UIV2/TabForm';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  CubeOutlined,
  Dropdown,
  EllipsisHorizontalOutlined,
  InformationCircleOutlined,
  ItemType,
  TicketOutlined,
  TruckOutlined,
} from 'tera-dls';
import SaleOrderApi from '../../api';
import { SALE_ORDER_URL } from '../../url';
import SaleOrderFormContent, {
  ISaleOrderFormContentRef,
} from './SaleOrderFormContent';

const classIcon = 'w-5 h-5';

export const TABS_FORM: TabFormMenuProps = {
  information: {
    title: 'Thông tin chung',
    icon: <InformationCircleOutlined className={classIcon} />,
  },
  delivery: {
    title: 'Thông tin giao hàng',
    icon: <TruckOutlined className={classIcon} />,
  },
  product: {
    title: 'Sản phẩm',
    icon: <CubeOutlined className={classIcon} />,
  },
  tax: {
    title: 'Giảm giá',
    icon: <TicketOutlined className={classIcon} />,
  },
};

function SaleOrderForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const actionRef = useRef<ISaleOrderFormContentRef>(null);

  const {
    data: dataDetails,
    refetch,
    isLoading,
  } = useQuery(
    ['get-sale-order-detail', id],
    () => {
      return SaleOrderApi.getDetail(id);
    },
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          className="cursor-pointer text-blue-400 italic"
          onClick={() => navigate(`${SALE_ORDER_URL.list.path}`)}
        >
          Danh sách đơn bán hàng
        </a>
      ),
    },
    {
      title: id ? 'Sửa đơn bán hàng' : 'Thêm đơn bán hàng',
    },
  ];

  const dropdownItems = [
    {
      key: '1',
      label: 'Lưu và thêm mới',
      onClick: () => actionRef?.current?.onSaveAndCreate?.('ordered'),
    },

    {
      key: '2',
      label: 'Lưu và duyệt',
      onClick: () => actionRef?.current?.onSave?.('approve'),
    },

    {
      key: '3',
      label: 'Lưu ',
      onClick: () => actionRef?.current?.onSave?.('pending'),
    },
  ];

  return (
    <div className="tera-page-form !gap-0 relative">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="cursor-pointer page-header__breadcrumb-back"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="h-6" />
            </div>
            <Breadcrumb separator={'/'} items={BreadcrumbItem} />
          </div>

          <div className="page-header-v2__function">
            {!id && (
              <div className={'hidden xl:flex xl:gap-x-2.5'}>
                <Button
                  htmlType="submit"
                  className="page-header-btn flex gap-1 font-normal"
                  onClick={() =>
                    actionRef?.current?.onSaveAndCreate?.('pending')
                  }
                >
                  <BookmarkOutlined className="size-4" /> Lưu và thêm mới
                </Button>

                <Button
                  htmlType="submit"
                  type="success"
                  className="page-header-btn bg-green-500 hover:bg-green-700 flex gap-1 font-normal"
                  onClick={() => actionRef?.current?.onSave?.('approve')}
                >
                  <BookmarkOutlined className="size-4" /> Lưu và duyệt
                </Button>
              </div>
            )}
            <Button
              htmlType="submit"
              className="page-header-btn bg-green-500 hover:bg-green-700 flex gap-1 font-normal"
              onClick={() =>
                actionRef?.current?.onSave?.(dataDetails?.status ?? 'pending')
              }
            >
              <BookmarkOutlined className="size-4" /> Lưu
            </Button>
            {!id && (
              <div className={'xl:hidden'}>
                <Dropdown menu={{ items: dropdownItems }} trigger="click">
                  <Button
                    icon={<EllipsisHorizontalOutlined />}
                    type="alternative"
                    className="page-header-btn"
                  />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="page-content-v2">
        <TabForm menu={TABS_FORM}>
          <SaleOrderFormContent
            id={id}
            ref={actionRef}
            dataDetails={dataDetails}
            loading={isLoading && !!id}
          />
        </TabForm>
      </div>
    </div>
  );
}

export default SaleOrderForm;
