import Radio from '_common/dof/Control/Radio';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import customTwMerge from 'tailwind-merge.config';
import { ExclamationTriangleOutlined, Modal } from 'tera-dls';
import { COURSE_ORDER_REJECT_REASON } from '../../constants';
import TextArea from '_common/dof/Control/TextArea';
import { useEffect } from 'react';
import { messageValidate } from '_common/constants/message';
const classNameContent = customTwMerge(
  'modal-confirm__content text-base w-full break-word',
);
const classNameContainer = customTwMerge('modal-confirm md:w-[500px] w-full');
const RejectModal = ({ open, onClose, value: record, onSubmit }) => {
  const form = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    form.setValue('reason', '1');
  }, []);

  const handleSubmitForm = (values) => {
    onSubmit(
      {
        reason_cancel:
          values?.reason == 6
            ? values?.message
            : COURSE_ORDER_REJECT_REASON[values?.reason],
      },
      record,
    );
    onClose();
  };

  return (
    <Modal
      destroyOnClose
      okText="Đồng ý"
      cancelText="Hủy"
      closeIcon={false}
      open={open}
      title="Xác nhận hủy đơn khóa học"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={onClose}
      className={classNameContainer}
    >
      <div className="modal-confirm__wrapper flex flex-col items-center gap-4 w-full">
        <div className="modal-confirm__icon w-20 h-20 m-auto font-thin">
          <ExclamationTriangleOutlined color="#F5C344" />
        </div>
        <div className={classNameContent}>
          <p className="break-word text-center mb-2">
            <p>Bạn có chắc muốn hủy đơn khóa học</p>
            <p>
              <b>{record?.course?.name}</b> này không?
            </p>
          </p>
          <FormTera form={form}>
            <FormTeraItem name="reason" label="">
              <Radio
                listOption={Object.entries(COURSE_ORDER_REJECT_REASON)?.map(
                  ([value, label]) => ({ value, label }),
                )}
              />
            </FormTeraItem>
            {form.watch('reason') === '6' && (
              <FormTeraItem
                name="message"
                label=""
                rules={[
                  {
                    required: messageValidate?.emptyText,
                  },
                ]}
              >
                <TextArea rows={4} />
              </FormTeraItem>
            )}
          </FormTera>
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;
