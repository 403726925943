export const LECTURER_APPLICATION_STATUS = {
  approve: 'Đã duyệt',
  pending: 'Chờ duyệt',
  reject: 'Từ chối',
};

export const LECTURER_APPLICATION_STATUS_COLOR = {
  approve: 'green03',
  pending: 'yellow03',
  reject: 'red03',
};
