export const COURSE_ACTIVITY_URL = {
  list: {
    key: 'course-activity-list',
    path: '/course-management/course-activity/list',
    shortenUrl: 'course-activity/list',
  },
  detail: {
    key: 'course-activity-detail',
    path: '/course-management/course-activity/detail',
    shortenUrl: 'course-activity/detail/:id',
  },
};
